import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { TemporaryCacheKeys } from "@azure/msal-browser/dist/internals";

export class AuthClient extends PublicClientApplication {
  constructor() {
    const msalConfig: Configuration = {
      auth: {
        clientId: process.env.REACT_APP_GRAPH_CLIENT_ID,
        authority: process.env.REACT_APP_GRAPH_AUTHORITY,
        navigateToLoginRequestUrl: false,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
      system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
      },
    };

    super(msalConfig);
  }

  public redirectUri: string;

  public getTargetOriginUriAfterLogin() {
    return this.browserStorage.getTemporaryCache(TemporaryCacheKeys.ORIGIN_URI, true);
  }
}
