import { PnPFetchClient } from "@App/libs/pnp";
import { EventMessage, EventMessageUtils, InteractionStatus } from "@azure/msal-browser";

import { AuthClient } from "./authClient";

export const msalInstance = (function () {
  return new AuthClient();
})();

export const interactions = (function () {
  enum MsalProviderActionType {
    UNBLOCK_INPROGRESS = "UNBLOCK_INPROGRESS",
    EVENT = "EVENT",
  }

  const state = {
    inProgress: InteractionStatus.Startup,
  };

  function getInteractionStatus() {
    return state.inProgress;
  }

  function updateState(message: EventMessage, type: MsalProviderActionType) {
    let newInProgress = state.inProgress;

    switch (type) {
      case MsalProviderActionType.UNBLOCK_INPROGRESS:
        if (state.inProgress === InteractionStatus.Startup) {
          newInProgress = InteractionStatus.None;
        }
        break;
      case MsalProviderActionType.EVENT:
        const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
        if (status) {
          newInProgress = status;
        }
        break;
      default:
        throw new Error(`Unknown action type: ${type}`);
    }

    if (newInProgress !== state.inProgress) {
      state.inProgress = newInProgress;
    }
  }

  return {
    updateState,
    getInteractionStatus,
    MsalProviderActionType,
  };
})();

export const fetchClientFactory = new PnPFetchClient(msalInstance);
