import { useDispatch, useSelector } from "react-redux";

import { ISearchItem } from "SP/search/search.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import {
  clearListSearchResults,
  getGlobalSearchResults,
  getListSearchResults,
  refinementSearchRequest,
  resetGlobalSearchResults,
  resetListSearchResults,
  setRowsLimit,
  setSearchType,
} from "Store/actions/search.actions";
import { IRootReducerState } from "Store/reducers";
import { IListSearchResults, SearchTypes } from "Store/reducers/search.reducer";

export const useSearch = () => {
  const dispatch = useDispatch();

  const loading = useSelector<IRootReducerState, boolean>((state) => state.search.loading);
  const isGlobalSearchResultsLoaded = useSelector<IRootReducerState, boolean>(
    (state) => state.search.isGlobalSearchResultsLoaded,
  );
  const globalSearchResults = useSelector<IRootReducerState, ISearchItem[]>(
    (state) => state.search.globalSearchResults,
  );
  const rowsLimit = useSelector<IRootReducerState, number>((state) => state.search.rowsLimit);

  const totalRows = useSelector<IRootReducerState, number>((state) => state.search.totalRows);

  const handleGetGlobalSearchResults = (query: string) => {
    dispatch(getGlobalSearchResults(query));
  };

  const handleResetGlobalSearchResults = () => dispatch(resetGlobalSearchResults());

  const handleSetSearchType = (value: SearchTypes) => dispatch(setSearchType(value));

  const searchType = useSelector<IRootReducerState, SearchTypes>((state) => state.search.searchType);

  const handleGetRefinedSearchResults = () => dispatch(refinementSearchRequest());

  const handleSetRowsLimit = (value: number) => dispatch(setRowsLimit(value));

  return {
    loading,
    searchType,
    isGlobalSearchResultsLoaded,
    globalSearchResults,
    rowsLimit,
    totalRows,
    getGlobalSearchResults: handleGetGlobalSearchResults,
    resetGlobalSearchResults: handleResetGlobalSearchResults,
    setSearchType: handleSetSearchType,
    getRefinedGlobalSearchResults: handleGetRefinedSearchResults,
    setRowsLimit: handleSetRowsLimit,
  };
};

export const useDocumentsSearch = () => {
  const dispatch = useDispatch();

  const isListSearchResultsLoaded = useSelector<IRootReducerState, boolean>(
    (state) => state.search.isListSearchResultsLoaded,
  );
  const allListsSearchResults = useSelector<IRootReducerState, IListSearchResults>(
    (state) => state.search.listSearchResults,
  );

  const handleGetListSearchResults = (query: string, list: LibraryName, listItems: IAttachedFile[]) => {
    dispatch(getListSearchResults(query, list, listItems));
  };

  const handleClearListSearchResults = (list: LibraryName) => {
    dispatch(clearListSearchResults(list));
  };

  const handleResetListSearchResults = () => {
    dispatch(resetListSearchResults());
  };

  return {
    isListSearchResultsLoaded,
    allListsSearchResults,
    getListSearchResults: handleGetListSearchResults,
    clearListSearchResults: handleClearListSearchResults,
    resetListSearchResults: handleResetListSearchResults,
  };
};
