import { IItemUpdateResult, IList, sp } from "@pnp/sp/presets/all";

import { getFormattedFriendlyUrl } from "SP/sp.helpers";

import { filterQueryNotFolder, regulationsListRequestInfo } from "../../constants";
import { IRegulationDTO } from "./regulations.types";

export class RegulationsRepository {
  private regulationsList: IList = sp.web.lists.getByTitle("EPAM Regulations");
  public async getAll(): Promise<IRegulationDTO[]> {
    return await this.regulationsList.items
      .select(...regulationsListRequestInfo.selectArray)
      .expand(...regulationsListRequestInfo.expandArray)
      .filter(filterQueryNotFolder)
      .getAll(5000);
  }
  public async getById(id: number): Promise<IRegulationDTO> {
    return await this.regulationsList.items
      .getById(id)
      .select(...regulationsListRequestInfo.selectArray)
      .expand(...regulationsListRequestInfo.expandArray)
      .get();
  }

  public async getByServerRelativeUrl(url: string): Promise<IRegulationDTO> {
    const file = await sp.web.getFileByServerRelativeUrl(url).getItem();
    const item: IRegulationDTO = await file
      .select(...regulationsListRequestInfo.selectArray)
      .expand(...regulationsListRequestInfo.expandArray)
      .get();

    return item;
  }

  public async getByFriendlyUrl(friendlyURL: string): Promise<IRegulationDTO[]> {
    const formattedUrl = getFormattedFriendlyUrl(friendlyURL);

    return await this.regulationsList.items
      .filter(`FriendlyURL eq '${formattedUrl}'`)
      .select(...regulationsListRequestInfo.selectArray)
      .expand(...regulationsListRequestInfo.expandArray)
      .get();
  }

  public async updateDocument(id: number, data: Partial<IRegulationDTO>): Promise<IItemUpdateResult> {
    return await this.regulationsList.items.getById(id).update(data);
  }
}
