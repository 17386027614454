import { ForkEffect, spawn } from "redux-saga/effects";

import documentsSagas from "./documents.sagas";
import drivesSagas from "./drives.sagas";
import filterSagas from "./filter.sagas";
import generalSagas from "./general.sagas";
import searchResultsSagas from "./search.sagas";
import sitePagesSagas from "./site-pages.sagas";
import { standardsIconsSagas } from "./standards-icons.sagas";
import termsSagas from "./terms.sagas";
import usersSagas from "./users.sagas";

export const rootSaga = function* (): Iterator<ForkEffect> {
  yield spawn(documentsSagas);
  yield spawn(generalSagas);
  yield spawn(sitePagesSagas);
  yield spawn(termsSagas);
  yield spawn(usersSagas);
  yield spawn(searchResultsSagas);
  yield spawn(filterSagas);
  yield spawn(standardsIconsSagas);
  yield spawn(drivesSagas);
};
