import React, { FC } from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification.svg";
import { FlexRow, IconContainer, Tooltip } from "@epam/loveship";

import { DocumentText } from "Components/documents/document-text";
import { getSubscribeTooltipText } from "Helpers/subscribeHelper";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

interface IDocumentHeaderProps {
  attachedFile: IAttachedFile;
  isSubscribed: boolean;
}

export const DocumentHeader: FC<IDocumentHeaderProps> = ({ attachedFile, isSubscribed }) => (
  <FlexRow cx="min-h-0">
    <DocumentText caption={attachedFile?.SubjectDoc || attachedFile?.CertificateArtifactType || "Role"} />
    {isSubscribed && (
      <>
        <span className="dot dot--dark" style={{ marginLeft: 6 }} />
        <IconContainer icon={notificationIcon} color="night500" />
        <Tooltip placement="bottom" content={getSubscribeTooltipText(attachedFile.sourceLibrary)}>
          <DocumentText caption="Subscribed" />
        </Tooltip>
      </>
    )}
  </FlexRow>
);
