import React, { useEffect } from "react";

import { useSitePagesMethods } from "Hooks/useSitePages";

import { DynamicSitePage } from "./DynamicSitePage";

export const HomePage = () => {
  const { getSitePageByUrl } = useSitePagesMethods();

  useEffect(() => {
    getSitePageByUrl("/Home.aspx");
  }, []);

  return <DynamicSitePage pageUrl={`/Home.aspx`} />;
};
