import React, { FC, useCallback } from "react";

import { FlexRow, Panel } from "@epam/loveship";

import { ButtonWithAnalytics } from "Components/button-with-analytics";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { getLinkProps } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";

import { LinkedList, PageText } from "./page-card-components";
import { IPageCardProps, RowTypes } from "./page-card-components/page-card-components.interface";

import "./page-card.scss";

export const PageCard: FC<IPageCardProps> = ({ card }) => {
  const { currentUser } = useUsersData();
  const onLinkClicked = useCallback(
    (url: string) => {
      analyticsHelper.analyticsService?.provideLinkClick(url, currentUser?.jobTitle);
    },
    [currentUser],
  );

  const cn = withNaming("page-card");
  const renderPageCardElement = (element) => {
    switch (element.type) {
      case RowTypes.LABEL:
        return <PageText caption={element.label.caption} className={element.className} fontSize="14" lineHeight="18" />;
      case RowTypes.LINKED_LIST:
        return (
          <LinkedList
            className={element.className}
            linkedList={element.linkedList}
            iconPosition={element.iconPosition}
            onLinkClicked={onLinkClicked}
          />
        );
      case RowTypes.BUTTON:
        return (
          <ButtonWithAnalytics
            cx={cn(element.className)}
            caption={element.button.caption}
            {...getLinkProps(element.button.link)}
          />
        );
    }
  };

  return (
    <Panel cx={cn("", [card.className, "shadow"])} background="white">
      {card.title ? <PageText caption={card.title} className="title" fontSize="18" lineHeight="24" /> : null}
      <FlexRow cx={cn("content")} alignItems="stretch">
        <div className={cn("left-row")}>{card.leftRow.map((rowItem) => renderPageCardElement(rowItem))}</div>
        {card.rightRow ? (
          <div className={cn("right-row")}>{card.rightRow.map((rowItem) => renderPageCardElement(rowItem))}</div>
        ) : null}
      </FlexRow>
    </Panel>
  );
};
