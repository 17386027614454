import React, { CSSProperties, FC, useRef, useState } from "react";

import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down_down-18.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up_up-18.svg";
import { LinkButton } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { useContentOverflow } from "Hooks/useContentOverflow";

import "./show-curtain.scss";

interface IShowCurtainProps {
  maxHeight: number;
  byTextLines?: boolean;
  cx?: string;
}

export const ShowCurtain: FC<IShowCurtainProps> = ({ maxHeight, byTextLines, cx = "", children }) => {
  const cn = withNaming("show-curtain");
  const [isOpen, setOpen] = useState(false);
  const contentRef = useRef(null);
  const { isOverflow } = useContentOverflow({ contentRef, maxHeight });
  const isClosed = isOverflow && !isOpen;

  const calcStyles = (): CSSProperties => {
    if (byTextLines) {
      return {
        height: maxHeight + 30,
      };
    }

    return { maxHeight };
  };

  return (
    <div className={cn("")} {...(isClosed && { style: calcStyles() })}>
      <div ref={contentRef} className={cn("content", [cx])}>
        {children}
      </div>
      {isClosed && <div className={cn("curtain", { byTextLines })} />}
      {isOverflow && (
        <LinkButton
          cx={cn("show-toggle", { opened: isOpen }, ["unselect"])}
          size="30"
          fontSize="14"
          lineHeight="18"
          icon={isOpen ? chevronUp : chevronDown}
          caption={`Show ${isOpen ? "Less" : "More"}`}
          onClick={() => setOpen((v) => !v)}
        />
      )}
    </div>
  );
};
