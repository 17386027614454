import _isEmpty from "lodash/isEmpty";
import React from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification.svg";
import { createDateString } from "@App/libs/dayjs";
import { IconContainer, Tooltip } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { FoundContent } from "Components/found-content";
import { SharePointImage } from "Components/sharepoint-image/sharepoint-image";
import { Tooltip as CustomTooltip } from "Components/tooltip";
import { getSubscribeTooltipText } from "Helpers/subscribeHelper";
import { highlightWithTiming } from "Helpers/timingElements";
import { getDocumentDownloadUrl } from "Helpers/utils";
import { CertificateField } from "SP/fields";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { renderLink, renderText, renderTextWithTooltip } from "../documents-table.helpers";
import { IColumn, IRowProps } from "../documents-table.interface";

function renderFirstColumn(row: IAttachedFile, { searchValue, onFileNameClick, subscribeParams }: IRowProps) {
  const isEmptyTitle = _isEmpty(row?.Title);
  const linkClassName = isEmptyTitle ? "link" : null;
  const onLinkClick = isEmptyTitle ? onFileNameClick : null;

  return (
    <div className="flex items-center">
      <CustomTooltip
        cx="certificate-icon-tooltip"
        trigger="hover"
        placement="bottom"
        content={() => <SharePointImage src={row?.iconUrl} alt="certificate icon" width="96" height="96" />}
      >
        <div className={linkClassName}>
          <SharePointImage
            className="self-start"
            src={row?.iconUrl}
            alt="certificate icon"
            width="24"
            height="24"
            onClick={onLinkClick}
          />
        </div>
      </CustomTooltip>
      <div className="certificate-info">
        <div className="flex">
          <Tooltip content={row.Title} placement="bottom">
            {renderLink({
              cx: "file-title hover-underline",
              caption: highlightWithTiming({ elementtiming: "certificate-name", query: searchValue, value: row.Title }),
              onClick: onFileNameClick,
            })}
          </Tooltip>
          {subscribeParams.isSubscribed && (
            <Tooltip content={getSubscribeTooltipText(row.sourceLibrary)} placement="bottom">
              <IconContainer cx="file-subscribe file-subscribe--regulation" icon={notificationIcon} color="night500" />
            </Tooltip>
          )}
        </div>
        <div onClick={onLinkClick} className={linkClassName}>
          {renderText(row.CertificateArtifactType, { cx: "one-line", font: "sans", fontSize: "12", color: "night600" })}
        </div>
        {!!searchValue && <FoundContent hitHighlightedAll={row.HitHighlightedAll} />}
      </div>
    </div>
  );
}

export const certificatesColumns: IColumn[] = [
  {
    key: "Document",
    caption: CertificateField.Document,
    render: (row, rowProps) => renderFirstColumn(row, rowProps),
    isSortable: true,
    width: 270,
    minWidth: 270,
  },
  {
    key: "EPAMDocumentID",
    caption: CertificateField.EPAMDocumentID,
    render: (row) => renderTextWithTooltip(row?.EPAMDocumentID, { cx: "two-lines" }),
    isSortable: true,
    width: 140,
    minWidth: 140,
  },
  {
    key: "Standard",
    caption: CertificateField.Standard,
    render: (row) => renderTextWithTooltip(row?.Standard?.join(", "), { cx: "two-lines" }),
    isSortable: true,
    width: 84,
    minWidth: 84,
  },
  {
    key: "CertificationBody",
    caption: CertificateField.CertificationBody,
    render: (row) => renderText(row?.CertificationBody),
    isSortable: true,
    width: 105,
    minWidth: 105,
  },
  {
    key: "LocationCountry",
    caption: CertificateField.LocationCountry,
    render: (row) => renderText(row?.LocationCountry?.join(", ")),
    isSortable: true,
    width: 76,
    minWidth: 76,
  },
  {
    key: "LocationCity",
    caption: CertificateField.LocationCity,
    render: (row) => renderTextWithTooltip(row?.LocationCity?.join(", "), { cx: "two-lines" }),
    isSortable: true,
    width: 78,
    minWidth: 70,
  },
  {
    key: "IssueDate",
    caption: CertificateField.IssueDate,
    render: (row) => renderText(createDateString(row?.IssueDate)),
    isSortable: true,
    width: 98,
    minWidth: 92,
  },
  {
    key: "ValidFrom",
    caption: CertificateField.ValidFrom,
    render: (row) => renderText(createDateString(row?.ValidFrom)),
    isSortable: true,
    width: 98,
    minWidth: 95,
  },
  {
    key: "ValidUntil",
    caption: CertificateField.ValidUntil,
    render: (row) => renderText(createDateString(row?.ValidUntil)),
    isSortable: true,
    width: 98,
    minWidth: 96,
  },
  {
    key: "actions",
    caption: "",
    render: (row, { subscribeParams, onOpenInfoPanel }) => (
      <DocumentActionsDropdown
        fileType={row.fileInfo.FileType}
        serverRelativeUrl={row.fileInfo.ServerRelativeUrl}
        viewUrl={row.viewUrl}
        downloadUrl={getDocumentDownloadUrl(row.viewUrl, row.FriendlyUrl)}
        friendlyUrl={row.FriendlyUrl}
        subscribeParams={subscribeParams}
        onOpenInfoPanel={onOpenInfoPanel}
      />
    ),
    width: 18,
  },
];
