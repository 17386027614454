import React, { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ClassNameFormatter } from "@bem-react/classname";
import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronRight } from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import { IconContainer } from "@epam/loveship";

import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { isExternalUrl } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { IVoidCb } from "SP/helpers.types";

import "./accordion-link.scss";

interface IAccordionProps {
  title: string;
  level: number;
  to: string;
  isSelected: boolean;
  isExpandable: boolean;
  defaultExpanded: boolean;
  onSelect: IVoidCb;
}

interface IGetCommonPropsParams {
  cn: ClassNameFormatter;
  level: number;
  isSelected: boolean;
  onSelect: IVoidCb;
  isClickable: boolean;
}

function getCommonProps({ cn, level, isSelected, onSelect, isClickable }: IGetCommonPropsParams) {
  return {
    style: { paddingLeft: level * 12 },
    className: cn("toggler", { selected: isSelected, clickable: isClickable }, ["flex items-center"]),
    ...(isClickable ? { onClick: onSelect } : {}),
  };
}

export const AccordionLink: FC<IAccordionProps> = ({
  title,
  level,
  to,
  children,
  isExpandable,
  isSelected,
  defaultExpanded,
  onSelect,
}) => {
  const cn = withNaming("accordion-link");
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const commonProps = getCommonProps({ cn, level, isSelected, onSelect, isClickable: !!to });
  const { currentUser } = useUsersData();

  const onLinkClicked = () => {
    analyticsHelper.analyticsService?.provideLinkClick(to, currentUser?.jobTitle);
    commonProps?.onClick();
  };

  const handleToggle = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpanded((v) => !v);
  }, []);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const renderLink = useCallback(() => {
    const linkBody = (
      <>
        {isExpandable && (
          <IconContainer
            cx={cn("toggler-chevron-icon")}
            rawProps={{ "data-testid": "toggler-chevron-icon" }}
            icon={isExpanded ? chevronDown : chevronRight}
            onClick={handleToggle}
          />
        )}
        <div title={title} className={cn("toggler-title", { isNotExpandable: !isExpandable }, ["cut-text"])}>
          {title}
        </div>
      </>
    );

    if (isExternalUrl(to)) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" {...commonProps} onClick={onLinkClicked}>
          {linkBody}
        </a>
      );
    } else if (to) {
      return (
        <Link
          to={{
            pathname: to,
            state: { isMenu: true },
          }}
          {...commonProps}
          title={title}
          onClick={onLinkClicked}
        >
          {linkBody}
        </Link>
      );
    }

    return <div {...commonProps}>{linkBody}</div>;
  }, [isExpandable, isSelected, to, isExpanded, handleToggle, onLinkClicked]);

  return (
    <div className={cn("", ["flex flex-col"])}>
      {renderLink()}
      {isExpanded ? <div className={cn("body", ["flex flex-col"])}>{children}</div> : null}
    </div>
  );
};
