import React from "react";

import { LinkButton, LinkButtonMods } from "@epam/loveship";
import { ButtonProps } from "@epam/uui-components";

import { analyticsHelper } from "Helpers/analyticsHelper";
import { replacePageUrlPrefix } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";

export const DocumentsTableLink = (props: ButtonProps & LinkButtonMods) => {
  const { currentUser } = useUsersData();
  const href = replacePageUrlPrefix(props?.href);
  const onClick = () => {
    let linkValue = href;
    if (props?.link?.pathname) {
      linkValue = `${window.location.host}${props?.link?.pathname}`;
    }

    analyticsHelper.analyticsService?.provideLinkClick(linkValue, currentUser?.jobTitle);
    props?.onClick?.();
  };

  return (
    <div data-testid="document-link" onClick={onClick} className="table-link-container">
      <LinkButton size="none" color="sky" fontSize="14" lineHeight="18" {...props} href={href} onClick={null} />
    </div>
  );
};
