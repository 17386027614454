import React, { FC, useEffect, useMemo, useState } from "react";

import { FlexCell, Spinner } from "@epam/loveship";

import { useFiltersData, useFiltersMethods } from "Components/documents-filter/documents-filter.hooks";
import { exportItemsToExcel } from "Components/documents-toolbar/documents-toolbar.helpers";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { PageTitle } from "Components/page-title";
import { SubscriptionsNotFound } from "Components/results-not-found/results-not-found";
import { getFiltersByActiveTab } from "Components/search/search.helpers";
import {
  getItemNameByTab,
  getSubscriptionsData,
  subscriptionsTabs,
} from "Components/subscriptions/subscriptions.helpers";
import { SubscriptionsTabType } from "Components/subscriptions/subscriptions.interfaces";
import { SubscriptionsList } from "Components/subscriptions/subscriptions-list";
import { SubscriptionsToolbar } from "Components/subscriptions/subscriptions-toolbar";
import { TabsSlider } from "Components/tabs-slider";
import { withNaming } from "Helpers/bemClassname";
import { handleAllCheckboxLabel, isNotEmpty } from "Helpers/utils";
import { useDocumentsData } from "Hooks/useDocuments";
import { useRowsCheck } from "Hooks/useRowsCheck";
import { useUsersData } from "Hooks/useUsers";
import { ISearchItem } from "SP/search/search.types";

import "./subscriptions.scss";

export const Subscriptions: FC = () => {
  const cn = withNaming("subscriptions");

  const [activeTab, setActiveTab] = useState<SubscriptionsTabType>(SubscriptionsTabType.All);
  const { setInfoPanel } = useInfoPanel();
  const { allDocuments, isDocumentsLoaded } = useDocumentsData();
  const { getFilteredDataList, setFilterGroupsResults } = useFiltersMethods();
  const isDataLoaded = isDocumentsLoaded;

  const { currentUser } = useUsersData();
  const { allSelectedFilters } = useFiltersData();
  const { setInfoPanelFilters, setFiltersLibrary, resetFilters, setIsPageToggle } = useFiltersMethods();

  const { items, infoPanelFilters, filtersLibrary } = useMemo(() => {
    const items = getSubscriptionsData({ currentUser, allDocuments, activeTab });
    const { filtersLibrary, infoPanelFilters } = getFiltersByActiveTab(activeTab, items);
    const filteredItems = getFilteredDataList(items, allSelectedFilters[filtersLibrary]);
    const sortedItems = filteredItems.sort((a, b) => (a.Title || "").localeCompare(b.Title || ""));

    setFilterGroupsResults(filteredItems, allSelectedFilters[filtersLibrary]);

    return {
      items: sortedItems,
      infoPanelFilters,
      filtersLibrary,
    };
  }, [allDocuments, allSelectedFilters, currentUser, activeTab]);

  const isResultsPresent = items.length > 0;

  const { selectedRows, indeterminate, onCheckToggle, onToggleAll, resetRows } = useRowsCheck(items);

  useEffect(() => {
    setFiltersLibrary(filtersLibrary);
  }, [filtersLibrary]);

  useEffect(() => {
    if (isNotEmpty(infoPanelFilters[0]?.data)) {
      setInfoPanelFilters(infoPanelFilters);
    } else {
      setInfoPanelFilters([]);
    }
  }, [JSON.stringify(infoPanelFilters)]);

  useEffect(() => {
    setIsPageToggle("isSubscriptionsPage", true);

    return () => {
      resetFilters(true);
      setFiltersLibrary(null);
      setInfoPanel(null);
      setIsPageToggle("isSubscriptionsPage", false);
    };
  }, []);

  useEffect(() => {
    if (selectedRows.length > 0) {
      resetRows();
    }
  }, [items]);

  const handleTabChange = (newActiveTab: string) => () => {
    resetRows();
    setActiveTab(newActiveTab as SubscriptionsTabType);
  };

  const exportResultsCallback = () => {
    exportItemsToExcel(activeTab, items as ISearchItem[], selectedRows, "Subscriptions");
  };

  return (
    <FlexCell cx={cn("")}>
      <div className={cn("header")}>
        <PageTitle title="My Subscriptions" defaultCopyUrl={location.href} />
      </div>
      <TabsSlider borderBottom tabs={subscriptionsTabs} activeTab={activeTab} onTabChange={handleTabChange} />
      <SubscriptionsToolbar
        cn={cn}
        hasSelected={!!selectedRows?.length}
        indeterminate={indeterminate}
        onToggleAll={onToggleAll}
        exportCallback={exportResultsCallback}
        checkboxLabel={handleAllCheckboxLabel(selectedRows?.length, items?.length, getItemNameByTab(activeTab))}
        items={items}
        selectedRows={selectedRows}
        activeTab={activeTab}
      />
      {!isDataLoaded && <Spinner cx={cn("spinner")} color={"sky"} />}
      {isDataLoaded && isResultsPresent && (
        <SubscriptionsList
          cn={cn}
          activeTab={activeTab}
          selectedRows={selectedRows}
          items={items}
          onCheckRowCallback={onCheckToggle}
        />
      )}
      {isDataLoaded && !isResultsPresent && <SubscriptionsNotFound />}
    </FlexCell>
  );
};
