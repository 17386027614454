import React, { FC } from "react";

import { FlexCell } from "@epam/loveship";

import { WebParts } from "Components/web-parts";
import { withNaming } from "Helpers/bemClassname";

import {
  IOneColumnLayoutProps,
  IOneThirdLayoutProps,
  IThreeColumnsLayoutProps,
  ITwoColumnsLayoutProps,
} from "./sections-layouts.interface";

const cn = withNaming("layout");

export const OneColumnLayout: FC<IOneColumnLayoutProps> = ({ data, sectionIndex }) => (
  <FlexCell cx={cn("", { "one-column": true })} grow={2}>
    <WebParts webParts={data.webParts} column="webParts" sectionIndex={sectionIndex} />
  </FlexCell>
);

export const TwoColumnsLayout: FC<ITwoColumnsLayoutProps> = ({ data: { left, right }, sectionIndex }) => (
  <>
    <FlexCell cx={cn("", { "two-column": true, left: true })} grow={1} alignSelf="flex-start">
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell cx={cn("", { "two-column": true, right: true })} grow={1} alignSelf="flex-start">
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);

export const ThreeColumnsLayout: FC<IThreeColumnsLayoutProps> = ({ data: { left, center, right }, sectionIndex }) => (
  <>
    <FlexCell cx={cn("", { "three-column": true, left: true })} grow={1} alignSelf="flex-start">
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell cx={cn("", { "three-column": true, center: true })} grow={1} alignSelf="flex-start">
      <WebParts webParts={center} column="center" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell cx={cn("", { "three-column": true, right: true })} grow={1} alignSelf="flex-start">
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);

export const OneThirdLayout: FC<IOneThirdLayoutProps> = ({ isLeft, isRight, data: { left, right }, sectionIndex }) => (
  <>
    <FlexCell cx={cn("", { "one-third": true, left: true })} grow={isLeft ? 1 : 2} alignSelf="flex-start">
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell cx={cn("", { "one-third": true, right: true })} grow={isRight ? 1 : 2} alignSelf="flex-start">
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);
