import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IVoidCb, IVoidCbWithParam } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { ISubscriptions } from "SP/subscriptions/subscriptions.types";

export interface IUser {
  email: string;
  name: string;
  id: number;
  jobTitle?: string;
  photo?: string;
}

export interface IUserWithPermsAndSubscriptions extends IUser {
  canEditSitePage: boolean;
  subscriptions: ISubscriptions;
}

export interface IUserEntityDTO {
  Email: string;
  Id: number;
  DisplayName: string;
}

export enum UsersActionsTypes {
  GET_CURRENT_USER_REQUEST = "[USERS] GET CURRENT USER REQUEST",
  GET_CURRENT_USER_SUCCESS = "[USERS] GET CURRENT USER SUCCESS",
  GET_CURRENT_USER_FAILURE = "[USERS] GET CURRENT USER FAILURE",

  SUBSCRIBE_TO_ITEM_TOGGLE_REQUEST = "[USERS] SUBSCRIBE TO ITEM TOGGLE REQUEST",
  SUBSCRIBE_TO_ITEM_TOGGLE_SUCCESS = "[USERS] SUBSCRIBE TO ITEM TOGGLE SUCCESS",
  SUBSCRIBE_TO_ITEM_TOGGLE_FAILURE = "[USERS] SUBSCRIBE TO ITEM TOGGLE FAILURE",

  TOGGLE_SUBSCRIBE_LOADING = "[USERS] TOGGLE SUBSCRIBE LOADING",

  GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST = "[USERS] GET CURRENT USER FAVORITE FILTERS REQUEST",
  GET_CURRENT_USER_FAVORITE_FILTERS_SUCCESS = "[USERS] GET CURRENT USER FAVORITE FILTERS SUCCESS",
  GET_CURRENT_USER_FAVORITE_FILTERS_FAILURE = "[USERS] GET CURRENT USER FAVORITE FILTERS FAILURE",

  SAVE_FAVORITE_FILTER_REQUEST = "[USERS] SAVE FAVORITE FILTER REQUEST",
  SAVE_FAVORITE_FILTER_SUCCESS = "[USERS] SAVE FAVORITE FILTER SUCCESS",
  SAVE_FAVORITE_FILTER_FAILURE = "[USERS] SAVE FAVORITE FILTER FAILURE",

  REMOVE_FAVORITE_FILTER_REQUEST = "[USERS] REMOVE FAVORITE FILTER REQUEST",
  REMOVE_FAVORITE_FILTER_SUCCESS = "[USERS] REMOVE FAVORITE FILTER SUCCESS",
  REMOVE_FAVORITE_FILTER_FAILURE = "[USERS] REMOVE FAVORITE FILTER FAILURE",
}

export interface IGetCurrentUserRequestAction {
  type: UsersActionsTypes.GET_CURRENT_USER_REQUEST;
  cb?: (IUser) => void;
}

export function getCurrentUser(cb?: (IUser) => void): IGetCurrentUserRequestAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_REQUEST,
    cb,
  };
}

export interface IGetCurrentUserSuccessAction {
  type: UsersActionsTypes.GET_CURRENT_USER_SUCCESS;
  payload: IUserWithPermsAndSubscriptions;
}

export function getCurrentUserSuccess(user: IUserWithPermsAndSubscriptions): IGetCurrentUserSuccessAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
}

export interface IGetCurrentUserFailureAction {
  type: UsersActionsTypes.GET_CURRENT_USER_FAILURE;
  error: string;
}

export function getCurrentUserFailure(error): IGetCurrentUserFailureAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_FAILURE,
    error: error.message,
  };
}

export type ISubscribeItem = "eclNews" | "document" | "role";

export interface ISubscribeToItemToggleParams {
  item: ISubscribeItem;
  isSubscribed: boolean;
  items?: Partial<IRegulation & ICertificate & IRole>[];
  cb?: IVoidCb;
}

export interface ISubscribeToItemToggleRequestAction extends ISubscribeToItemToggleParams {
  type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_REQUEST;
}

export function subscribeToItemToggle(params: ISubscribeToItemToggleParams): ISubscribeToItemToggleRequestAction {
  return {
    type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_REQUEST,
    ...params,
  };
}

export interface ISubscribeToItemToggleSuccessAction {
  type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_SUCCESS;
  payload: ISubscriptions;
}

export function subscribeToItemToggleSuccess(subscriptions: ISubscriptions): ISubscribeToItemToggleSuccessAction {
  return {
    type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_SUCCESS,
    payload: subscriptions,
  };
}

export interface ISubscribeToItemToggleFailureAction {
  type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_FAILURE;
  error: string;
}

export function subscribeToItemToggleFailure(error): ISubscribeToItemToggleFailureAction {
  return {
    type: UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_FAILURE,
    error: error.message,
  };
}

export interface IGetCurrentUserFavoriteFiltersRequestAction {
  type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST;
  isNeedForceUpdate: boolean;
}

export function getCurrentUserFavoriteFilters(isNeedForceUpdate: boolean): IGetCurrentUserFavoriteFiltersRequestAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST,
    isNeedForceUpdate,
  };
}

export interface IGetCurrentUserFavoriteFiltersSuccessAction {
  type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_SUCCESS;
  payload: IFavoriteFilter[];
}

export function getCurrentUserFavoriteFiltersSuccess(
  payload: IFavoriteFilter[],
): IGetCurrentUserFavoriteFiltersSuccessAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_SUCCESS,
    payload,
  };
}

export interface IGetCurrentUserFavoriteFiltersFailureAction {
  type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_FAILURE;
  error: string;
}

export function getCurrentUserFavoriteFiltersFailure(error): IGetCurrentUserFavoriteFiltersFailureAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_FAILURE,
    error: error.message,
  };
}

export interface ISaveFavoriteFilterRequestAction {
  type: UsersActionsTypes.SAVE_FAVORITE_FILTER_REQUEST;
  payload: IFavoriteFilter;
  cb: IVoidCbWithParam;
}

export function saveFavoriteFilter(payload: IFavoriteFilter, cb: IVoidCbWithParam): ISaveFavoriteFilterRequestAction {
  return {
    type: UsersActionsTypes.SAVE_FAVORITE_FILTER_REQUEST,
    payload,
    cb,
  };
}

export interface ISaveFavoriteFilterSuccessAction {
  type: UsersActionsTypes.SAVE_FAVORITE_FILTER_SUCCESS;
}

export function saveFavoriteFilterSuccess(): ISaveFavoriteFilterSuccessAction {
  return {
    type: UsersActionsTypes.SAVE_FAVORITE_FILTER_SUCCESS,
  };
}

export interface ISaveFavoriteFilterFailureAction {
  type: UsersActionsTypes.SAVE_FAVORITE_FILTER_FAILURE;
  error: string;
}

export function saveFavoriteFilterFailure(error): ISaveFavoriteFilterFailureAction {
  return {
    type: UsersActionsTypes.SAVE_FAVORITE_FILTER_FAILURE,
    error: error.message,
  };
}

export interface IRemoveFavoriteFilterRequestAction {
  type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_REQUEST;
  payload: IFavoriteFilter;
  cb: IVoidCb;
  matchCb: IVoidCb;
}

export function removeFavoriteFilter(
  payload: IFavoriteFilter,
  cb: IVoidCb,
  matchCb: IVoidCb,
): IRemoveFavoriteFilterRequestAction {
  return {
    type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_REQUEST,
    payload,
    cb,
    matchCb,
  };
}

export interface IRemoveFavoriteFilterSuccessAction {
  type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_SUCCESS;
}

export function removeFavoriteFilterSuccess(): IRemoveFavoriteFilterSuccessAction {
  return {
    type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_SUCCESS,
  };
}

export interface IRemoveFavoriteFilterFailureAction {
  type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_FAILURE;
  error: string;
}

export function removeFavoriteFilterFailure(error): IRemoveFavoriteFilterFailureAction {
  return {
    type: UsersActionsTypes.REMOVE_FAVORITE_FILTER_FAILURE,
    error: error.message,
  };
}

export type IUsersActionsTypes =
  | IGetCurrentUserRequestAction
  | IGetCurrentUserSuccessAction
  | IGetCurrentUserFailureAction
  | ISubscribeToItemToggleRequestAction
  | ISubscribeToItemToggleSuccessAction
  | ISubscribeToItemToggleFailureAction
  | IGetCurrentUserFavoriteFiltersRequestAction
  | IGetCurrentUserFavoriteFiltersSuccessAction
  | IGetCurrentUserFavoriteFiltersFailureAction
  | ISaveFavoriteFilterRequestAction
  | ISaveFavoriteFilterSuccessAction
  | ISaveFavoriteFilterFailureAction
  | IRemoveFavoriteFilterRequestAction
  | IRemoveFavoriteFilterSuccessAction
  | IRemoveFavoriteFilterFailureAction;
