import { ClientNotFoundError } from "@App/errors/ClientNotFoundError";

import { DrivesRepository } from "./drives.repository";
import { IDrivesDTO } from "./drives.types";

export class DrivesService {
  drivesRepository: DrivesRepository;
  drivesInfo: IDrivesDTO;

  constructor(siteId: string) {
    this.drivesRepository = new DrivesRepository(siteId);
  }

  async getDriveIdByListTitle(listTitle): Promise<string> {
    if (!this.drivesInfo) {
      this.drivesInfo = await this.drivesRepository.getDrivesIds();
    }

    if (this.drivesInfo.value.length == 0) {
      throw new ClientNotFoundError("Lists not found");
    }

    const itemIndex = this.drivesInfo.value.findIndex((x) => x.name === listTitle);

    if (itemIndex === -1) {
      throw new ClientNotFoundError(`Not found list with name ${listTitle}`);
    }

    return this.drivesInfo.value[itemIndex].id;
  }

  async getThumbnailUrl(driveId: string, filePath: string): Promise<string> {
    const result = await this.drivesRepository.getThumbnailUrl(driveId, filePath);

    return result;
  }
}
