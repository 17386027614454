import _isEqual from "lodash/isEqual";
import _omit from "lodash/omit";
import qs from "qs";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { toolbarFilters } from "Components/documents-filter/filters/filters.helpers";
import { ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { getFieldIfExist, updateObject } from "Helpers/utils";
import { IRootReducerState } from "Store/reducers";
import { ISelectedFilter } from "Store/reducers/filters.reducer";

export interface IDocumentPageQuery {
  documentUrl?: string;
  forceDownload?: boolean;
}

function getPermanentQueries(query, permanentQueryKeys: string[]) {
  const newQuery = {};

  permanentQueryKeys.forEach((queryKey) => {
    const queryValue = getFieldIfExist(queryKey, query);

    if (queryValue) {
      newQuery[queryKey] = queryValue;
    }
  });

  return newQuery;
}

function isNeedToUpdateQuery(query, permanentQueryKeys: string[]) {
  return permanentQueryKeys.some((queryKey) => getFieldIfExist(queryKey, query));
}

const useQuery = () => {
  const history = useHistory();
  const { search, pathname, hash } = useLocation();
  const hasParams = search.indexOf("=") > -1;

  const isSearchPage = useSelector<IRootReducerState, boolean>((state) => state.filters.isSearchPage);

  const getQuery = useCallback(() => {
    return getParams<ISelectedFilter>(hasParams, search);
  }, [hasParams, search]);

  const getDocumentQuery = useCallback(() => {
    return getParams<IDocumentPageQuery>(hasParams, search);
  }, [hasParams, search]);

  function getParams<T>(hasParams: boolean, search: string) {
    return hasParams
      ? (qs.parse(decodeURI(search), { ignoreQueryPrefix: true, strictNullHandling: true }) as unknown as T)
      : ({} as unknown as T);
  }

  const setQuery = useCallback(
    (newQuery: ISelectedFilter) => {
      const oldQuery = getQuery();
      const permanentQueryKeys = [
        ToolbarKeys.activeTab,
        ...(isSearchPage ? [ToolbarKeys.searchQuery, ToolbarKeys.searchType] : []),
      ];

      if (isNeedToUpdateQuery(oldQuery, permanentQueryKeys)) {
        newQuery = updateObject(getPermanentQueries(oldQuery, permanentQueryKeys), newQuery);
      }

      if (_isEqual(oldQuery, newQuery)) return;

      if (newQuery.showSubscriptionsOnly !== undefined) {
        newQuery = _omit(newQuery, "showSubscriptionsOnly");
      }

      const search = qs.stringify(newQuery, { strictNullHandling: true });
      history.replace(window.location.pathname + "?" + search + hash);
    },
    [getQuery, pathname, hash, isSearchPage],
  );

  const clearQuery = useCallback(
    (permanentQueryKeys: string[] = toolbarFilters) => {
      const query = getQuery();

      if (permanentQueryKeys && isNeedToUpdateQuery(query, permanentQueryKeys)) {
        const newQuery = getPermanentQueries(query, permanentQueryKeys);

        return setQuery(newQuery);
      }

      history.replace(pathname);
    },
    [getQuery, setQuery, pathname],
  );

  return {
    hasParams,
    query: getQuery(),
    documentQuery: getDocumentQuery(),
    setQuery,
    clearQuery,
  };
};

export default useQuery;
