import React, { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { DocumentDownload } from "Components/document-download";
import { DocumentPreview } from "Components/document-preview";
import { Loader } from "Components/loader";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { useUsersData } from "Hooks/useUsers";
import { getDownloadedDocumentUrl } from "SP/sp.helpers";

export interface IDocumentViewProps {
  documentUrl: string;
  documentName: string;
  forceDownload?: boolean;
}

export const DocumentViewer: FC<IDocumentViewProps> = ({ documentUrl, documentName, forceDownload }) => {
  const [objectUrl, setObjectUrl] = useState<string>("");
  const [isPdfDoc, setIsPdfDoc] = useState(false);
  const { currentUser } = useUsersData();

  useEffect(() => {
    async function downloadDocument(mimeType: string) {
      const windowBlobUrl = await getDownloadedDocumentUrl(documentUrl, mimeType);

      setObjectUrl(windowBlobUrl);
    }

    const url = new URL(documentUrl);

    if (url.pathname.toLocaleLowerCase().endsWith(".pdf")) {
      setIsPdfDoc(true);
      downloadDocument("application/pdf");
    } else {
      downloadDocument("application/vnd.openxmlformats-officedocument.wordprocessingml.document");
    }
  }, [documentUrl]);

  useEffect(() => {
    if (currentUser) {
      const cleanDocumentUrl = documentUrl.split("?")[0];
      analyticsHelper.analyticsService.provideDocumentView(currentUser?.jobTitle, cleanDocumentUrl);
    }
  }, [documentUrl, currentUser]);

  const renderPageContent = useCallback(
    (documentName: string, objectUrl: string) => {
      if (isPdfDoc && !forceDownload) {
        return <DocumentPreview objectUrl={objectUrl} documentName={documentName} />;
      }

      return <DocumentDownload documentName={documentName} downloadUrl={objectUrl} />;
    },
    [isPdfDoc],
  );

  return (
    <>
      <Helmet>
        <title>{documentName}</title>
      </Helmet>
      {objectUrl ? renderPageContent(documentName, objectUrl) : <Loader />}
    </>
  );
};
