import React, { FC } from "react";

import { IFilter } from "Components/documents-filter/documents-filter.interface";
import { filterInvolveItems, getFilterPanelKeys } from "Components/documents-filter/filters/filters.helpers";
import { Tooltip } from "Components/tooltip";
import { isNotEmpty } from "Helpers/utils";
import { FilterValueType, ISelectedFilter, ISubarea, ITimePeriod } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";

interface IDocumentsFilterTooltipProps {
  title: string;
  enabled: boolean;
  infoPanelFilters: IFilter[];
  selectedFilters: ISelectedFilter;
}

type IRenderTooltipFilterParams = {
  type: FilterValueType | "subscription";
  label: string;
  values: string | JSX.Element[];
};

const MAX_SHOWN_FILTERS_COUNT = 10;

function isLimitExceeds(count: number) {
  return count > MAX_SHOWN_FILTERS_COUNT;
}

function getDateContentValue({ fromDate, toDate }: ITimePeriod) {
  return `${fromDate ? fromDate : ""}${fromDate && toDate ? " - " : ""}${toDate ? toDate : ""}`;
}

function getInvolveLabel(selectedFilter: ISubarea | ITimePeriod) {
  if (selectedFilter.type === FilterValueType.subarea && selectedFilter.filterInvolve) {
    return filterInvolveItems.find(({ id }) => id === selectedFilter.filterInvolve).label;
  }

  return "";
}

function getFilterItemsCount(selectedFilter: ISubarea | ITimePeriod) {
  if (selectedFilter.type === FilterValueType.subarea) {
    return selectedFilter.items.length;
  }

  return 1;
}

function getSlicedFilterItems(count: number, filterItems: string[]) {
  const cutCount = isLimitExceeds(count) ? MAX_SHOWN_FILTERS_COUNT - (count - filterItems.length) : undefined;

  return filterItems.slice(0, cutCount);
}

export const DocumentsFilterTooltip: FC<IDocumentsFilterTooltipProps> = ({
  title,
  enabled,
  infoPanelFilters,
  selectedFilters,
  children,
}) => {
  const filterPanelKeys = getFilterPanelKeys(selectedFilters);

  if (!selectedFilters || !filterPanelKeys.length || !enabled) {
    return <>{children}</>;
  }

  const renderTooltipFilter = ({ type, label, values }: IRenderTooltipFilterParams) => {
    // Render nothing when max limit is exceeds by assigning "values" to null
    if (!values) {
      return null;
    }

    return (
      <div className={cn("tooltip-filter", { [type]: true })}>
        <div className={cn("tooltip-filter-label")}>{label}</div>
        <div className={cn("tooltip-filter-values")}>{values}</div>
      </div>
    );
  };

  const renderContent = () => {
    const isSubscriptionFilterChecked = selectedFilters?.showSubscriptionsOnly;
    let filtersCount = isSubscriptionFilterChecked ? 1 : 0;

    return (
      <>
        <div className={cn("tooltip-title")}>{title}</div>
        {isSubscriptionFilterChecked
          ? renderTooltipFilter({ type: "subscription", label: "Subscriptions only:", values: "Yes" })
          : ""}
        {infoPanelFilters
          .filter(({ key }) => filterPanelKeys.includes(key))
          .map(({ key, caption }) => {
            let contentValue: string | JSX.Element[] = null;
            const selectedFilter = selectedFilters[key] as ISubarea | ITimePeriod;
            const involveLabel = getInvolveLabel(selectedFilter);
            // Increase count base on filter type
            filtersCount += getFilterItemsCount(selectedFilter);

            if (selectedFilter.type === FilterValueType.subarea && isNotEmpty(selectedFilter.items)) {
              // Get sliced items when max limit is exceeds
              const slicedFilterItems = getSlicedFilterItems(filtersCount, selectedFilter.items);

              contentValue = slicedFilterItems.length
                ? slicedFilterItems.map((item) => (
                    <div key={item} className={cn("tooltip-filter-value")}>
                      {item?.length == 0 ? "Empty" : item}
                    </div>
                  ))
                : null;
            } else if (
              selectedFilter.type === FilterValueType.timePeriod &&
              (selectedFilter.fromDate || selectedFilter.toDate)
            ) {
              contentValue = isLimitExceeds(filtersCount) ? null : getDateContentValue(selectedFilter);
            }

            return renderTooltipFilter({
              type: selectedFilter.type,
              label: `${caption}${involveLabel && `, ${involveLabel}`}:`,
              values: contentValue,
            });
          })}
        {isLimitExceeds(filtersCount) ? `and ${filtersCount - MAX_SHOWN_FILTERS_COUNT} more` : ""}
      </>
    );
  };

  return (
    <Tooltip cx={cn("tooltip")} trigger="hover" placement="left" content={renderContent}>
      {children}
    </Tooltip>
  );
};
