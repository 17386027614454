import React, { useEffect, useMemo, useState } from "react";

import { DocumentViewer } from "Components/document-viewer";
import { Loader } from "Components/loader";
import { getDocumentName, getServerRelativeUrl, performRedirectToRelativeUrl } from "Helpers/utils";
import useQuery from "Hooks/useQuery";
import { CertificatesService } from "SP/documents/certificates/certificates.service";
import { RegulationsService } from "SP/documents/regulations/regulations.service";
import { TemplatesService } from "SP/documents/templates/templates.service";

export const OldUrlDocumentPage = () => {
  const { documentQuery } = useQuery();
  const [loadedFriendlyUrl, setLoadedFriendlyUrl] = useState(false);

  const documentName = useMemo(() => {
    return getDocumentName(documentQuery.documentUrl);
  }, [documentQuery.documentUrl]);

  useEffect(() => {
    async function fetchData(url: string) {
      const serverRelativeUrl = decodeURI(new URL(url).pathname);
      let fileService: RegulationsService | TemplatesService | CertificatesService;

      if (serverRelativeUrl.startsWith(`${getServerRelativeUrl()}/EPAM Regulations`)) {
        fileService = new RegulationsService();
      } else if (serverRelativeUrl.startsWith(`${getServerRelativeUrl()}/EPAM Templates`)) {
        fileService = new TemplatesService();
      } else if (serverRelativeUrl.startsWith(`${getServerRelativeUrl()}/EPAM Certificates`)) {
        fileService = new CertificatesService();
      }

      const document = await fileService.getByServerRelativeUrl(serverRelativeUrl, []);

      performRedirectToRelativeUrl(document.FriendlyUrl);

      setLoadedFriendlyUrl(true);
    }

    fetchData(documentQuery.documentUrl);
  }, [documentQuery.documentUrl]);

  return loadedFriendlyUrl ? (
    <DocumentViewer
      documentUrl={documentQuery.documentUrl}
      forceDownload={documentQuery.forceDownload}
      documentName={documentName}
    />
  ) : (
    <Loader />
  );
};
