import { sp, Web } from "@pnp/sp/presets/all";

import {
  certificatesListRequestInfo,
  filterQueryNotFolder,
  regulationsListRequestInfo,
  templatesListRequestInfo,
} from "SP/constants";
import { CertificatesService } from "SP/documents/certificates/certificates.service";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { filterRegulationByFolder, mapDocuments } from "SP/sp.helpers";
import { IAllDocuments } from "Store/actions/documents.actions";
import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

import { ICertificateDTO } from "./certificates/certificates.types";
import { IRegulation, IRegulationDTO } from "./regulations/regulations.types";

export class GetAllDocumentsService {
  private _invalidItemsCount;
  private certificatesService = new CertificatesService();

  public get InvalidItemsCount() {
    return this._invalidItemsCount;
  }

  public async getAllDocuments(icons: IStandardIcon[]) {
    const lists = {
      [LibraryName.regulations]: regulationsListRequestInfo,
      [LibraryName.templates]: templatesListRequestInfo,
      [LibraryName.certificates]: certificatesListRequestInfo,
    };
    const allDocuments: IAllDocuments = {
      [LibraryName.certificates]: [],
      [LibraryName.templates]: [],
      [LibraryName.regulations]: [],
    };
    const Url = process.env.REACT_APP_BASE_URL;
    const web = Web(Url);
    const batch = sp.createBatch();

    for (const list in lists) {
      web.lists
        .getByTitle(list)
        .items.inBatch(batch)
        .filter(filterQueryNotFolder)
        .select(...lists[list].selectArray)
        .expand(...lists[list].expandArray)
        .top(5000)
        .usingCaching()
        .get()
        .then(this.handleBatchCallback(list as LibraryName, allDocuments, icons));
    }
    await batch.execute();

    allDocuments[LibraryName.templates] = allDocuments[LibraryName.templates].filter((x) =>
      filterRegulationByFolder(x as IRegulation),
    );

    return allDocuments;
  }
  private handleBatchCallback =
    (list: LibraryName, allDocuments: IAllDocuments, icons) => (data: ICertificateDTO[] | IRegulationDTO[]) => {
      this._invalidItemsCount = 0;
      switch (list) {
        case LibraryName.certificates: {
          const validItems = (data as any).filter((cert) => this.certificatesService.isValidCertificate(cert));
          this._invalidItemsCount = data.length - validItems.length;

          allDocuments[list] = validItems.map((doc) =>
            this.certificatesService.mapCertificates(<ICertificateDTO>doc, icons),
          );
          break;
        }
        case LibraryName.regulations:
        case LibraryName.templates: {
          allDocuments[list] = data.map((doc) => mapDocuments(<IRegulationDTO>doc, list));
          break;
        }
      }
    };
}
