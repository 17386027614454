import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IFoldersInfo } from "SP/helpers.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";

export enum DocumentsActionsTypes {
  GET_ALL_DOCUMENTS_REQUEST = "[GENERAL] GET ALL DOCUMENTS REQUEST",
  GET_ALL_DOCUMENTS_SUCCESS = "[GENERAL] GET ALL DOCUMENTS SUCCESS",
  GET_ALL_DOCUMENTS_FAILURE = "[GENERAL] GET ALL DOCUMENTS FAILURE",

  GET_ALL_LIBRARY_ROOT_FOLDERS_REQUEST = "[DOCUMENTS] GET ALL LIBRARY ROOT FOLDERS REQUEST",
  GET_ALL_LIBRARY_ROOT_FOLDERS_SUCCESS = "[DOCUMENTS] GET ALL LIBRARY ROOT FOLDERS SUCCESS",
  GET_ALL_LIBRARY_ROOT_FOLDERS_FAILURE = "[DOCUMENTS] GET ALL LIBRARY ROOT FOLDERS FAILURE",

  GET_DOCUMENT_TYPE_DESCRIPTIONS_REQUEST = "[DOCUMENTS] GET DOCUMENT TYPE DESCRIPTIONS REQUEST",
  GET_DOCUMENT_TYPE_DESCRIPTIONS_SUCCESS = "[DOCUMENTS] GET DOCUMENT TYPE DESCRIPTIONS SUCCESS",
  GET_DOCUMENT_TYPE_DESCRIPTIONS_FAILURE = "[DOCUMENTS] GET DOCUMENT TYPE DESCRIPTIONS FAILURE",

  DOWNLOAD_DOCUMENTS_REQUEST = "[DOCUMENTS] DOWNLOAD DOCUMENTS REQUEST",
  DOWNLOAD_DOCUMENTS_SUCCESS = "[DOCUMENTS] DOWNLOAD DOCUMENTS SUCCESS",
  DOWNLOAD_DOCUMENTS_FAILURE = "[DOCUMENTS] DOWNLOAD DOCUMENTS FAILURE",
}

// GET ALL DOCUMENTS START
export interface IGetAllDocumentsRequestAction {
  type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_REQUEST;
}

export function getAllDocuments(): IGetAllDocumentsRequestAction {
  return {
    type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_REQUEST,
  };
}

export interface IAllDocuments {
  [LibraryName.regulations]: IRegulation[];
  [LibraryName.templates]: IRegulation[];
  [LibraryName.certificates]: ICertificate[];
}

export interface IGetAllDocumentsSuccessAction {
  type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_SUCCESS;
  payload: IAllDocuments;
  invalidItemsCount: number;
}

export function getAllDocumentsSuccess(
  allDocuments: IAllDocuments,
  invalidItemsCount: number,
): IGetAllDocumentsSuccessAction {
  return {
    type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_SUCCESS,
    payload: allDocuments,
    invalidItemsCount,
  };
}

export interface IGetAllDocumentsFailureAction {
  type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_FAILURE;
  error: string;
}

export function getAllDocumentsFailure(error): IGetAllDocumentsFailureAction {
  return {
    type: DocumentsActionsTypes.GET_ALL_DOCUMENTS_FAILURE,
    error: error.message,
  };
}
// GET ALL DOCUMENTS END

// GET DOCUMENT TYPE DESCRIPTIONS START
export interface IGetDocumentTypeDescriptionsRequestAction {
  type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_REQUEST;
}

export function getDocumentTypeDescriptions(): IGetDocumentTypeDescriptionsRequestAction {
  return {
    type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_REQUEST,
  };
}

export interface IGetDocumentTypeDescriptionsSuccessAction {
  type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_SUCCESS;
  payload: IFoldersInfo;
}

export function getDocumentTypeDescriptionsSuccess(payload: IFoldersInfo): IGetDocumentTypeDescriptionsSuccessAction {
  return {
    type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_SUCCESS,
    payload,
  };
}

export interface IGetDocumentTypeDescriptionsFailureAction {
  type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_FAILURE;
  error: string;
}

export function getDocumentTypeDescriptionsFailure(error): IGetDocumentTypeDescriptionsFailureAction {
  return {
    type: DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_FAILURE,
    error: error.message,
  };
}
// GET DOCUMENT TYPE DESCRIPTIONS END

// DOWNLOAD DOCUMENTS REQUEST START
export interface IDownloadDocumentsAction {
  type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_REQUEST;
  documents: IAttachedFile[];
  archiveName: string;
}

export function downloadDocuments(documents: IAttachedFile[], archiveName: string): IDownloadDocumentsAction {
  return {
    type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_REQUEST,
    documents,
    archiveName,
  };
}

export interface IDownloadDocumentsSuccessAction {
  type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_SUCCESS;
}

export function downloadDocumentsSuccess(): IDownloadDocumentsSuccessAction {
  return {
    type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
  };
}

export interface IDownloadDocumentsFailureAction {
  type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_FAILURE;
  error: string;
}

export function downloadDocumentsFailure(error): IDownloadDocumentsFailureAction {
  return {
    type: DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_FAILURE,
    error: error.message,
  };
}
// DOWNLOAD DOCUMENTS REQUEST END

export type IDocumentsActionsTypes =
  | IGetAllDocumentsRequestAction
  | IGetAllDocumentsSuccessAction
  | IGetAllDocumentsFailureAction
  | IGetDocumentTypeDescriptionsRequestAction
  | IGetDocumentTypeDescriptionsSuccessAction
  | IGetDocumentTypeDescriptionsFailureAction
  | IDownloadDocumentsAction
  | IDownloadDocumentsSuccessAction
  | IDownloadDocumentsFailureAction;
