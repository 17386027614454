import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";

import { RolesRepository } from "./rolesRegistry.repository";
import { IRole, IRoleDTO } from "./rolesRegistry.types";

export class RolesService {
  repository = new RolesRepository();
  private mapRegulations = (role: IRoleDTO, regulations: IAttachedFile[]): IRole => {
    return {
      Approvedby: role.Approvedby,
      DocumentArea: role.DocumentArea,
      Group: role.Group,
      Id: role.Id,
      PublishedDate: role.Published_x0020_date && new Date(role.Published_x0020_date),
      RoleCategory: role.Roles_x0020_Category,
      Title: role.Title,
      ShortDescription: role.Short_x0020_description_x0020_in,
      Status: role.Status,
      Created: new Date(role.Created),
      Author: { email: role.Author.EMail, id: role.Author.Id, name: role.Author.Title },
      Modified: new Date(role.Modified),
      Editor: { email: role.Editor.EMail, id: role.Editor.Id, name: role.Editor.Title },
      Regulations: role.Regulations.map((regulation) => {
        return { Description: regulation.Title, Url: regulation.Url };
      }),
      roleUrl: `/roles/${role.Id}`,
      UniqueId: role.Id.toString(),
      sourceLibrary: LibraryName.roles,
      Documents: this.formatDocuments(role, regulations),
    };
  };

  private formatDocuments = (role: IRoleDTO, regulations: IAttachedFile[]) =>
    role.DocumentsId.map((docId) => regulations.find((doc) => doc.Id === docId)).filter(Boolean);

  public async getAll(regulations: IAttachedFile[]): Promise<IRole[]> {
    const allRoles = await this.repository.getAll();
    return allRoles.map((role) => this.mapRegulations(role, regulations));
  }
  public async getById(id: number, regulations: IAttachedFile[]): Promise<IRole> {
    const role = await this.repository.getById(id);
    return this.mapRegulations(role, regulations);
  }
}
