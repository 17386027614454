import _noop from "lodash/noop";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { svc } from "@App/services";
import { ReactComponent as chevronDownIcon } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronUpIcon } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-18.svg";
import {
  ErrorNotification,
  FlexCell,
  FlexRow,
  FlexSpacer,
  IconButton,
  LabeledInput,
  MultiSwitch,
  PageButton,
  PickerInput,
  Text,
  TextInput,
} from "@epam/loveship";
import { ArrayDataSource, INotification } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import { Loader } from "Components/loader";
import { withNaming } from "Helpers/bemClassname";
import { ErrorMessages, validatePageTitle } from "Helpers/constants";
import { getSitePagesHierarchyOptions, getTermOptions } from "Helpers/optionsHelper";
import { getTitleErrorMessage, removeUrlPrefix } from "Helpers/utils";
import { useSitePagesData, useSitePagesMethods } from "Hooks/useSitePages";
import { useTermsData } from "Hooks/useTerms";
import { ILibraryFolder, IPickerInputOption } from "SP/helpers.types";
import { PageType } from "SP/sitePages/sitePages.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { IRootReducerState } from "Store/reducers";

import { INewPageFormData, INewPageFormProps } from "./new-page-form.types";

import "./new-page-form.scss";

const initialFormData: INewPageFormData = {
  pageTitle: null,
  pageType: PageType.Child,
  selectedFolderOption: null,
  selectedTermsOptions: [],
};

const errorCb = (message: string) => {
  svc.uuiNotifications.show(
    (props: INotification) => (
      <ErrorNotification {...props}>
        <Text size="36" fontSize="14">
          {message}
        </Text>
      </ErrorNotification>
    ),
    {
      position: "bot-left",
      duration: 7,
    },
  );
};

function checkIsInvalidPageTitle(pageTitle: string): boolean {
  return !pageTitle || pageTitle?.length > 255 || !validatePageTitle.test(pageTitle);
}

export const NewPageForm: FC<INewPageFormProps> = ({ title, modalProps, defaultFormData = initialFormData }) => {
  const cn = withNaming("new-page-form");

  const [formValue, setFormValue] = useState<INewPageFormData>(defaultFormData);
  const { addNewSitePage, getSitePagesHierarchy } = useSitePagesMethods();

  const { loading } = useSitePagesData();
  const { push: redirectTo } = useHistory();

  const isInvalidPageTitle = checkIsInvalidPageTitle(formValue?.pageTitle);

  const { allTerms } = useTermsData();
  const sitePagesHierarchy = useSelector<IRootReducerState, ILibraryFolder[]>(
    (state) => state.sitePages.sitePagesHierarchy,
  );

  const dataSources = useMemo(() => {
    return {
      sitePagesHierarchy: new ArrayDataSource({
        items: getSitePagesHierarchyOptions(sitePagesHierarchy),
      }),
      associatedTermsDataSource: new ArrayDataSource({
        items: getTermOptions(allTerms),
      }),
      pageTypes: [
        { id: PageType.Parent, caption: PageType.Parent },
        { id: PageType.Child, caption: PageType.Child },
      ],
    };
  }, [allTerms, sitePagesHierarchy]);

  const redirectCb = useCallback(
    (pageUrl: string) => {
      modalProps.abort();
      redirectTo(removeUrlPrefix(pageUrl));
    },
    [redirectTo],
  );

  const handleFormChange = (fieldKey: keyof INewPageFormData) => (newValue) => {
    setFormValue({ ...formValue, [fieldKey]: newValue });
  };

  const handleSave = () => {
    const folderServerRelativeUrl = formValue.selectedFolderOption.item.ServerRelativeUrl;
    const selectedTerms = formValue.selectedTermsOptions?.map((term: IPickerInputOption<ISPTermObject>) => term.item);

    addNewSitePage(
      {
        pageTitle: formValue.pageTitle,
        pageType: formValue.pageType,
        folderServerRelativeUrl: folderServerRelativeUrl,
        selectedTerms: selectedTerms || [],
      },
      redirectCb,
      errorCb,
    );
  };

  useEffect(() => {
    getSitePagesHierarchy();
  }, []);

  const renderLocationToggler = useCallback((props: PickerTogglerProps<IPickerInputOption<ILibraryFolder>>) => {
    const selectedLocationId = props.selection[0]?.value?.id as string;
    const breadcrumbs = removeUrlPrefix(selectedLocationId)?.split("/").slice(1).join(" > ");

    return (
      <div
        className={cn("picker-toggler", { invalid: !selectedLocationId }, ["flex items-center"])}
        ref={props.ref}
        onClick={props.onClick}
      >
        <TextInput
          isReadonly
          cx={cn("picker-toggler-input")}
          placeholder="Select location"
          value={breadcrumbs}
          onValueChange={_noop}
        />
        {selectedLocationId && (
          <IconButton cx={cn("picker-toggler-button", ["m-0"])} icon={closeIcon} onClick={props.onClear} />
        )}
        <IconButton cx={cn("picker-toggler-button")} icon={props.isOpen ? chevronUpIcon : chevronDownIcon} />
      </div>
    );
  }, []);

  const content = (
    <>
      <FlexRow cx={cn("row", { "has-error": isInvalidPageTitle })} alignItems="top">
        <FlexCell grow={2}>
          <LabeledInput
            cx={cn("label")}
            label="Title"
            isInvalid={isInvalidPageTitle}
            validationMessage={getTitleErrorMessage(formValue.pageTitle)}
            isRequired
          >
            <TextInput
              value={formValue.pageTitle}
              onValueChange={handleFormChange("pageTitle")}
              placeholder="Enter page title"
              isInvalid={isInvalidPageTitle}
            />
          </LabeledInput>
        </FlexCell>
        <FlexCell cx={cn("switch")} grow={1}>
          <LabeledInput cx={cn("label")} label="Page Type" isRequired>
            <MultiSwitch
              value={formValue.pageType}
              onValueChange={handleFormChange("pageType")}
              items={dataSources.pageTypes}
            />
          </LabeledInput>
        </FlexCell>
        <FlexSpacer />
      </FlexRow>
      <FlexRow cx={cn("row")}>
        <FlexCell grow={1}>
          <LabeledInput cx={cn("label")} label="Associated Terms">
            <PickerInput
              valueType="entity"
              selectionMode="multi"
              searchPosition="body"
              placeholder="Select terms"
              getName={(item) => item.label}
              value={formValue.selectedTermsOptions}
              onValueChange={handleFormChange("selectedTermsOptions")}
              dataSource={dataSources.associatedTermsDataSource}
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow cx={cn("row", { location: true, "has-error": !formValue.selectedFolderOption })}>
        <FlexCell grow={1}>
          <LabeledInput
            cx={cn("label")}
            label="Location"
            isInvalid={!formValue.selectedFolderOption}
            validationMessage={ErrorMessages.noLocation}
            isRequired
          >
            <PickerInput
              valueType="entity"
              selectionMode="single"
              searchPosition="body"
              getName={(item) => item.label}
              value={formValue.selectedFolderOption}
              onValueChange={handleFormChange("selectedFolderOption")}
              renderToggler={renderLocationToggler}
              dataSource={dataSources.sitePagesHierarchy}
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow>
        <FlexCell grow={1}>
          <FlexSpacer />
        </FlexCell>
        <FlexCell width="auto">
          <PageButton cx={cn("button")} fill="none" color="night600" caption="Cancel" onClick={modalProps.abort} />
        </FlexCell>
        <FlexCell width="auto">
          <PageButton
            cx={cn("button")}
            color="grass"
            caption={title}
            isDisabled={!formValue?.selectedFolderOption || isInvalidPageTitle}
            onClick={handleSave}
          />
        </FlexCell>
      </FlexRow>
    </>
  );

  return (
    <div className={cn("")}>
      <div className={cn("title")}>{title}</div>
      {loading ? <Loader /> : content}
    </div>
  );
};
