import produce from "immer";
import { Reducer } from "redux";

import { updateObject } from "Helpers/utils";
import { ISearchItem } from "SP/search/search.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { ISearchActionsTypes, SearchActionsTypes } from "Store/actions/search.actions";

export type IListSearchResults = Partial<Record<LibraryName, IAttachedFile[]>>;

export enum SearchTypes {
  "all" = "All words",
  "exact" = "Exact phrase",
}

export interface ISearchState {
  loading: boolean;
  requestsCount: number;
  searchType: SearchTypes;
  searchQuery: string;
  globalSearchResults: ISearchItem[];
  listSearchResults: IListSearchResults;
  isListSearchResultsLoaded: boolean;
  isGlobalSearchResultsLoaded: boolean;
  rowsLimit: number;
  totalRows: number;
}

export const defaultListSearchResults = {
  [LibraryName.regulationsAndTemplates]: null,
  [LibraryName.regulations]: null,
  [LibraryName.templates]: null,
  [LibraryName.certificates]: null,
  [LibraryName.roles]: null,
};

const initialState: ISearchState = {
  requestsCount: 0,
  loading: false,
  searchType: SearchTypes.all,
  searchQuery: "",
  globalSearchResults: [],
  listSearchResults: defaultListSearchResults,
  isListSearchResultsLoaded: false,
  isGlobalSearchResultsLoaded: true,
  rowsLimit: 100,
  totalRows: 0,
};

const searchReducer: Reducer<ISearchState, ISearchActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case SearchActionsTypes.REFINEMENT_SEARCH_REQUEST:
    case SearchActionsTypes.GLOBAL_SEARCH_RESULTS_REQUEST:
      draft.requestsCount++;
      draft.loading = true;
      draft.isGlobalSearchResultsLoaded = false;
      break;
    case SearchActionsTypes.REFINEMENT_SEARCH_SUCCESS:
    case SearchActionsTypes.GLOBAL_SEARCH_RESULTS_SUCCESS: {
      if (--draft.requestsCount === 0) {
        draft.loading = false;
        draft.isGlobalSearchResultsLoaded = true;
        draft.globalSearchResults = action.payload.searchItems;
        draft.totalRows = action.payload.totalRows;
      }

      break;
    }
    case SearchActionsTypes.REFINEMENT_SEARCH_FAILURE:
    case SearchActionsTypes.GLOBAL_SEARCH_RESULTS_FAILURE:
      draft.loading = false;
      draft.isGlobalSearchResultsLoaded = true;
      break;

    case SearchActionsTypes.RESET_GLOBAL_SEARCH_RESULTS:
      draft.globalSearchResults = [];
      break;

    case SearchActionsTypes.LIST_SEARCH_RESULTS_REQUEST:
      draft.requestsCount++;
      draft.isListSearchResultsLoaded = false;
      break;
    case SearchActionsTypes.LIST_SEARCH_RESULTS_SUCCESS:
      if (--draft.requestsCount === 0) {
        draft.isListSearchResultsLoaded = true;
        draft.listSearchResults = updateObject(draft.listSearchResults, {
          [action.list]: action.payload,
        });
      }
      break;
    case SearchActionsTypes.LIST_SEARCH_RESULTS_FAILURE:
      draft.isListSearchResultsLoaded = true;
      break;

    case SearchActionsTypes.CLEAR_LIST_SEARCH_RESULTS:
      draft.listSearchResults = updateObject(draft.listSearchResults, {
        [action.list]: null,
      });
      break;
    case SearchActionsTypes.RESET_LIST_SEARCH_RESULTS:
      draft.listSearchResults = defaultListSearchResults;
      break;
    case SearchActionsTypes.SET_SEARCH_TYPE:
      draft.searchType = action.payload;
      break;
    case SearchActionsTypes.SET_SEARCH_QUERY:
      draft.searchQuery = action.payload;
      break;
    case SearchActionsTypes.SET_ROWS_LIMIT:
      draft.rowsLimit = action.payload;
      break;
  }
}, initialState);

export default searchReducer;
