import { sp } from "@pnp/sp/presets/all";

import { listSubscriptionKeys } from "SP/constants";
import { ISpDocument } from "SP/documents/documents.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

import { ISubscriptions, ISubscriptionsItem } from "./subscriptions.types";

export class SubscriptionsRepository {
  private subscriptionsList = sp.web.lists.getByTitle("Subscribers");
  private subscribersNotificationsList = sp.web.lists.getByTitle("Subscribers Notifications");

  public async getCurrentUserSubscriptions(userId: number): Promise<ISubscriptions> {
    const subscriptions = await this.subscriptionsList.items.filter(`AuthorId eq ${userId}`).get();
    if (subscriptions.length > 0) return subscriptions[0];
  }
  public async unsubscribeFromECLNews(subscriptions: ISubscriptions): Promise<ISubscriptions> {
    await this.subscriptionsList.items.getById(subscriptions.Id).update({ subscribedToECLNews: false });
    subscriptions.subscribedToECLNews = false;
    return subscriptions;
  }
  public async subscribeToECLNews(subscriptions?: ISubscriptions): Promise<Partial<ISubscriptionsItem>> {
    const subscribeObject = { subscribedToECLNews: true };
    if (subscriptions) {
      await this.subscriptionsList.items.getById(subscriptions.Id).update(subscribeObject);
      await this.createSubscribersNotificationItem(subscriptions, true);
      return subscribeObject;
    }

    const newItem = await this.subscriptionsList.items.add(subscribeObject);
    await this.createSubscribersNotificationItem(newItem.data, false);
    return newItem.data;
  }

  private createSubscribersNotificationItem = async (data, isExistingItem: boolean) => {
    const newItem = {
      Title: data.Id.toString(),
      itemVersion: data.OData__UIVersionString,
    };

    if (isExistingItem) {
      const newVersion = +data.OData__UIVersionString + 1;
      newItem.itemVersion = `${newVersion.toString()}.0`;
    }

    await this.subscribersNotificationsList.items.add(newItem);
  };

  private getSubscriptionsItem = (items: ISpDocument[] | IRole[], subscriptions?: ISubscriptions) => {
    const subscribeObject: Partial<ISubscriptionsItem> = {};

    for (const libraryName in listSubscriptionKeys) {
      const selectedItems = this.getSelectedItemIds(items, libraryName as LibraryName);
      const fieldInternalName = listSubscriptionKeys[libraryName];

      if (subscriptions) {
        const libraryItems: number[] = subscriptions[fieldInternalName];

        subscribeObject[fieldInternalName] = {
          results: libraryItems.concat(selectedItems),
        };
      } else {
        subscribeObject[fieldInternalName] = {
          results: selectedItems,
        };
      }
    }

    return subscribeObject;
  };

  public async subscribeToItems(
    items: ISpDocument[] | IRole[],
    subscriptions?: ISubscriptions,
  ): Promise<Partial<ISubscriptionsItem>> {
    const subscribeObject = this.getSubscriptionsItem(items, subscriptions);

    if (subscriptions) {
      await this.subscriptionsList.items.getById(subscriptions.Id).update(subscribeObject);
      await this.createSubscribersNotificationItem(subscriptions, true);
      return subscribeObject;
    }

    const newItem = await this.subscriptionsList.items.add(subscribeObject);
    await this.createSubscribersNotificationItem(newItem.data, false);
    return newItem.data;
  }

  public async unsubscribeFromItems(
    items: ISpDocument[] | IRole[],
    subscriptions: ISubscriptions,
  ): Promise<Partial<ISubscriptionsItem>> {
    const subscribeObject: Partial<ISubscriptionsItem> = {};

    for (const libraryName in listSubscriptionKeys) {
      const selectedItems = this.getSelectedItemIds(items, libraryName as LibraryName);
      const fieldInternalName = listSubscriptionKeys[libraryName];
      const libraryItems: number[] = subscriptions[fieldInternalName];

      subscribeObject[fieldInternalName] = {
        results: libraryItems.filter((docId: number) => !selectedItems.includes(docId)),
      };
    }
    await this.subscriptionsList.items.getById(subscriptions.Id).update(subscribeObject);

    return subscribeObject;
  }

  private getSelectedItemIds = (items: Partial<ISpDocument & IRole>[], libraryName: LibraryName) =>
    items.filter((item) => item.sourceLibrary === libraryName).map((item) => item.Id);
}
