import _isEqual from "lodash/isEqual";
import React, { FC, useEffect } from "react";
import { Prompt, Redirect } from "react-router-dom";

import { FlexRow, Text } from "@epam/loveship";

import { Breadcrumbs, IBreadcrumbItem } from "Components/breadcrumbs";
import { Documents } from "Components/documents";
import { DocumentsView } from "Components/documents-view";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { LandingPage } from "Components/landing-page";
import { Layout } from "Components/layout";
import { PageDetails } from "Components/page-details";
import { PageRibbon } from "Components/page-ribbon";
import { PageTitle } from "Components/page-title";
import { PageVersions } from "Components/page-versions";
import { Sections } from "Components/sections";
import { SeoHead } from "Components/seo-head/seo-head";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { getNotificationSubscribeParams, SubscribeItemTypes } from "Helpers/subscribeHelper";
import { useAnalytics } from "Hooks/useAnalytics";
import { useBeforeunload } from "Hooks/useBeforeunload";
import { useSitePagesActions, useSitePagesData, useSitePageVersions } from "Hooks/useSitePages";
import { useUsersActions, useUsersData, useUsersMethods } from "Hooks/useUsers";
import { IHyperLinkField, IVoidCb } from "SP/helpers.types";

function generateBreadcrumbsItems(breadcrumbItems: IHyperLinkField[]): IBreadcrumbItem[] {
  return breadcrumbItems.map((item) => ({
    title: item.Description,
    value: item.Url,
  }));
}

const fullPageComponents = {
  LandingPage,
  RegulationsByDocumentType: DocumentsView,
  Regulations: DocumentsView,
  Templates: DocumentsView,
  Certificates: DocumentsView,
  RolesRegistry: DocumentsView,
};

export interface IDynamicSitePageProps {
  pageUrl: string;
}

export const DynamicSitePage: FC<IDynamicSitePageProps> = ({ pageUrl }) => {
  const sitePageUrl = pageUrl;

  const { loading, sitePage, editPageBackup, files, filesLoading } = useSitePagesData(sitePageUrl);

  const { selectedPageVersion } = useSitePageVersions(sitePageUrl);

  const {
    isSitePageInVersionHistoryMode,
    isSitePageInEditMode,
    setSitePageEditMode,
    setSitePageVersionHistoryMode,
    clearSitePageInValid,
  } = useSitePagesActions();

  const { currentUser } = useUsersData();

  const { subscriptionsLoading } = useUsersActions();
  const { subscribeToItemToggle } = useUsersMethods();
  const { setInfoPanel } = useInfoPanel();

  const pageInfo = isSitePageInVersionHistoryMode ? selectedPageVersion?.pageInfo : sitePage?.pageInfo;
  const pageType = pageInfo?.fullPageComponent?.type;

  const isSubscribed = currentUser?.subscriptions?.subscribedToECLNews;
  const FullPageComponent = fullPageComponents[pageType];

  const handleSubscribe = (cb: IVoidCb) => {
    subscribeToItemToggle({
      item: "eclNews",
      isSubscribed,
      cb,
    });
  };

  function isShowPrompt(): boolean {
    return isSitePageInEditMode && !_isEqual(editPageBackup, sitePage);
  }

  const { sessionStartSent, setSessionStartSent } = useAnalytics();

  useEffect(() => {
    if (sitePage && currentUser && !sessionStartSent) {
      analyticsHelper.analyticsService.provideSessionStart(currentUser?.jobTitle, sitePage?.Title);
      setSessionStartSent(true);
    }
  }, [sitePage, currentUser]);

  useEffect(() => {
    return () => {
      if (!loading) {
        setSitePageVersionHistoryMode(sitePageUrl, false);
        setSitePageEditMode(false, sitePageUrl);
      }

      clearSitePageInValid();
      setInfoPanel(null);
    };
  }, [sitePageUrl]);

  useBeforeunload((event) => {
    if (isShowPrompt()) {
      event.preventDefault();
    }
  });

  return (
    <>
      {sitePage?.redirectToPage && <Redirect to={sitePage?.redirectToPage} />}
      <Prompt when={isShowPrompt()} message="Changes you made may not be saved." />
      <Layout loading={loading} isContentLoaded={!!sitePage} isLandingPage={pageType === "LandingPage"}>
        {sitePage && (
          <>
            <SeoHead page={sitePage?.Title} />
            {currentUser?.canEditSitePage && (
              <PageRibbon
                sitePageId={sitePage?.Id}
                publishedDate={sitePage?.Modified}
                checkoutUser={sitePage?.CheckoutUser}
                currentUser={currentUser}
                sitePageUrl={sitePageUrl}
              />
            )}
            {isSitePageInEditMode ? (
              <Text color="night400" size="none" fontSize="14" lineHeight="24">
                Editing page
              </Text>
            ) : (
              <Breadcrumbs
                isLink
                fontSize="14"
                lineHeight="24"
                items={generateBreadcrumbsItems(sitePage?.breadcrumbItems)}
              />
            )}
            <PageTitle
              title={sitePage?.Title}
              subscribe={
                pageInfo?.showSubscribeButton &&
                getNotificationSubscribeParams({
                  type: SubscribeItemTypes.eclNews,
                  isSubscribed,
                  subscriptionsLoading,
                  onSubscribe: handleSubscribe,
                })
              }
            />
            {FullPageComponent ? (
              <FullPageComponent
                key={pageType}
                pageType={pageType}
                sitePageUrl={sitePageUrl}
                listData={pageInfo?.fullPageComponent.data}
              />
            ) : (
              <FlexRow cx="default-page" alignItems="top" spacing="12">
                <Sections isEditMode={isSitePageInEditMode} sections={pageInfo?.sections} />
                <Documents
                  filesCount={sitePage.pageInfo?.attachedFiles?.items?.length}
                  attachedFiles={files}
                  isNoSections={!pageInfo?.sections?.length}
                  filesLoading={filesLoading}
                />
              </FlexRow>
            )}
            {currentUser?.canEditSitePage && isSitePageInEditMode && <PageDetails sitePage={sitePage} />}
            {currentUser?.canEditSitePage && !isSitePageInEditMode && isSitePageInVersionHistoryMode && (
              <PageVersions sitePageUrl={sitePageUrl} />
            )}
          </>
        )}
      </Layout>
    </>
  );
};
