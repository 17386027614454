import { ObjectStorageCache } from "Helpers/cache/ObjectStorageCache";
import { StorageCache } from "Helpers/cache/StorageCache";
import { getServerRelativeUrl } from "Helpers/utils";
import { graphConfig, requestWithAuth } from "Libs/graph";

import { IDrivesDTO, IThumbnailDTO } from "./drives.types";

const THUMBNAIL_URL_EXPIRES_MIN = 5 * 60;

export class DrivesRepository {
  siteId: string;
  storage: Storage;

  private get storageKeyPrefix() {
    const ServerRelativeUrl = getServerRelativeUrl();
    return `${ServerRelativeUrl}/thumbnails`;
  }

  constructor(siteId: string) {
    this.siteId = siteId;
    this.storage = localStorage;
  }

  async getDrivesIds(): Promise<IDrivesDTO> {
    const apiEndpoint = graphConfig.getDrivesIdEndpoint(this.siteId);

    let result = ObjectStorageCache.Restore<IDrivesDTO>(`${this.storageKeyPrefix}-${apiEndpoint}`, this.storage);

    if (!result) {
      result = await requestWithAuth({
        url: apiEndpoint,
      });

      ObjectStorageCache.Cache(`${this.storageKeyPrefix}-${apiEndpoint}`, result, this.storage);
    }

    return result;
  }

  async getThumbnailUrl(driveId: string, filePath: string): Promise<string> {
    const apiEndpoint = graphConfig.getThumbnailUrlEndpoint(this.siteId, driveId, filePath);
    let url = StorageCache.RestoreExpiring(`${this.storageKeyPrefix}-${apiEndpoint}`, this.storage);

    if (!url) {
      const response: IThumbnailDTO = await await requestWithAuth({
        url: apiEndpoint,
      });

      url = response.url;
      StorageCache.CacheExpiring(
        `${this.storageKeyPrefix}-${apiEndpoint}`,
        url,
        THUMBNAIL_URL_EXPIRES_MIN,
        this.storage,
      );
    }

    return url;
  }
}
