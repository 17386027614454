import { useDispatch, useSelector } from "react-redux";

import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { getAllRoles, getRoleById, resetRole } from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";

export const useRolesData = () => {
  const allRoles = useSelector<IRootReducerState, IRole[]>((state) => state.general.allRoles);
  const isRolesLoaded = useSelector<IRootReducerState, boolean>((state) => state.general.isRolesLoaded);

  const role = useSelector<IRootReducerState, IRole>((state) => state.general.role);
  const roleLoading = useSelector<IRootReducerState, boolean>((state) => state.general.roleLoading);

  return {
    allRoles,
    isRolesLoaded,
    role,
    roleLoading,
  };
};

export const useRolesMethods = () => {
  const dispatch = useDispatch();

  const handleGetAllRoles = () => dispatch(getAllRoles());
  const handleGetRoleById = (roleId: number) => dispatch(getRoleById(roleId));
  const handleResetRole = () => dispatch(resetRole());

  return {
    getAllRoles: handleGetAllRoles,
    getRoleById: handleGetRoleById,
    resetRole: handleResetRole,
  };
};
