import React, { FC } from "react";

import { Panel, Text } from "@epam/loveship";

import { RichTextEditor } from "Components/rich-text-editor";
import { withNaming } from "Helpers/bemClassname";
import { isDetailCard } from "Helpers/utils";
import { useSitePagesActions } from "Hooks/useSitePages";
import { IRichTextEditorWebPart } from "SP/sitePages/sitePages.types";
import { DetailTypes, ISelectedDetailInfo } from "Store/reducers/site-pages.reducer";

import { IWebPartItemProps } from "../web-parts";
import { ISelectDetailProps } from "../web-parts-web-part";

import "./web-parts-card.scss";

interface IWebPartsCardProps extends IWebPartItemProps {
  data: IRichTextEditorWebPart;
  surroundedBy: "accordion" | "card";
}

export function isCardSelected(
  selectedDetail: ISelectedDetailInfo,
  { sectionIndex, webPartIndex, column }: ISelectDetailProps,
): boolean {
  return (
    isDetailCard(selectedDetail.type) &&
    selectedDetail.sectionIndex === sectionIndex &&
    selectedDetail.column === column &&
    selectedDetail.webPartIndex === webPartIndex
  );
}

export const WebPartsCard: FC<IWebPartsCardProps> = ({ data, webPart, pathKeys, selectDetailProps, surroundedBy }) => {
  const cn = withNaming("web-parts-card");
  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } = useSitePagesActions();

  const handleSelectDetail = () => {
    if (isSitePageInEditMode) {
      selectSitePageDetail({ type: DetailTypes.card, ...selectDetailProps });
    }
  };

  const slateEditor = (
    <RichTextEditor
      cx={cn("slate-editor")}
      isEditMode={isSitePageInEditMode}
      data={data}
      pathKeys={pathKeys}
      webPart={webPart}
    />
  );

  const content = (
    <>
      <div className={cn("row", { header: true }, ["flex justify-between"])}>
        <Text color="night800" size="24" font="sans-semibold" fontSize="18" lineHeight="24">
          {data.accordionTitle}
        </Text>
      </div>
      <div data-testid="slateEditor" className={cn("row")}>
        <div className="divider" />
        {slateEditor}
      </div>
    </>
  );

  return (
    <Panel
      cx={cn(
        "",
        {
          selected: isCardSelected(selectedDetailInfo, selectDetailProps),
          ["clear-card"]: !surroundedBy,
          "edit-mode": isSitePageInEditMode,
        },
        ["shadow"],
      )}
      background="white"
      onClick={handleSelectDetail}
    >
      {surroundedBy === "card" || !surroundedBy ? slateEditor : content}
    </Panel>
  );
};
