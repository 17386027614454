import React, { FC } from "react";

import { LinkButton, Text } from "@epam/loveship";

import { Modal } from "Components/modal";
import { useDocumentsData } from "Hooks/useDocuments";
import { FullPageComponentTypes } from "SP/sitePages/sitePages.types";

import { generateShowFilterModalInfo, getShowFilterDefaultItems } from "./documents-toolbar.helpers";
import { DocumentsModalProps, IDataPicker } from "./documents-toolbar.interface";

export const DocumentsModal: FC<DocumentsModalProps> = ({ cn, modalProps, pickerProps, pageType, activeTab }) => {
  const { items } = pickerProps.dataSource["props"];
  const { documentTypeDescriptions } = useDocumentsData();
  const {
    modalTitle,
    templates = [],
    leftCol = [],
    rightCol = [],
  } = generateShowFilterModalInfo(pageType, activeTab, items);
  const showFilterItems = getShowFilterDefaultItems();

  const isTemplatesPage = pageType === FullPageComponentTypes.Templates;

  const handlePickerValueChange = (id: string) => () => {
    const selectedItem = pickerProps.dataSource.getById(id);
    pickerProps.view["handleOnSelect"](selectedItem);
    modalProps.abort();
  };

  const renderDocumentItem = (item: IDataPicker) => (
    <div className={cn("modal-item")}>
      <LinkButton
        caption={item.label}
        font="sans-semibold"
        fontSize="14"
        lineHeight="18"
        color="sky"
        onClick={handlePickerValueChange(item.id)}
      />
      <Text
        cx={cn("modal-item-text", { templates: isTemplatesPage }, ["p-0"])}
        size="none"
        fontSize="12"
        lineHeight="18"
        color="night600"
      >
        {item.additionalLabel}
      </Text>
    </div>
  );

  const renderTemplatesDescription = renderDocumentItem({
    label: showFilterItems.templates.label,
    additionalLabel: documentTypeDescriptions?.templatesDescription,
    id: leftCol.length ? showFilterItems.templates.id : showFilterItems.all.id,
  });

  const renderTemplatesList = () => (
    <ul className={cn("modal-list")}>
      {templates.map((item) => (
        <li key={item.id}>
          <LinkButton
            caption={item.label}
            font="sans"
            fontSize="12"
            lineHeight="18"
            onClick={handlePickerValueChange(item.id)}
          />
        </li>
      ))}
    </ul>
  );

  return (
    <Modal modalProps={modalProps} title={modalTitle} width={720}>
      <div className={cn("modal-body", ["flex"])}>
        {isTemplatesPage ? (
          <>
            <div className={cn("modal-body-left")}>{renderTemplatesDescription}</div>
            <div className={cn("modal-body-right")}>{renderTemplatesList()}</div>
          </>
        ) : (
          <>
            {!!leftCol.length && <div className={cn("modal-body-left")}>{leftCol.map(renderDocumentItem)}</div>}
            {(!!templates.length || !!rightCol.length) && (
              <div className={cn("modal-body-right")}>
                {rightCol.map(renderDocumentItem)}
                {!!templates.length && (
                  <>
                    {renderTemplatesDescription}
                    {renderTemplatesList()}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
