import produce from "immer";
import _clone from "lodash/clone";
import _isObject from "lodash/isObject";
import _omit from "lodash/omit";
import { Reducer } from "redux";

import { FilterInvolve, IFilter, IFilterKeys } from "Components/documents-filter/documents-filter.interface";
import { getFilterPanelKeys } from "Components/documents-filter/filters/filters.helpers";
import { ISort } from "Components/documents-table/documents-table.interface";
import { updateObject } from "Helpers/utils";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IRefinementItem } from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { FiltersActionsTypes, IFiltersActionsTypes } from "Store/actions/filters.actions";

export enum FilterValueType {
  "timePeriod" = "time-period",
  "subarea" = "subarea",
}

export type ITimePeriod = {
  type: FilterValueType.timePeriod;
  fromDate: string;
  toDate: string;
};

export type ISubarea = {
  type: FilterValueType.subarea;
  filterInvolve: FilterInvolve;
  items: string[];
};

export type ISelectedFilterValue = ISubarea | ITimePeriod | ISort | string | boolean;

export type ISelectedFilter = Partial<Record<IFilterKeys, ISelectedFilterValue>>;

export type IFiltersGroup = {
  name: string;
  enabled: boolean;
  filters: ISelectedFilter;
};

export type ISelectedFilters = Record<LibraryName, IFiltersGroup[]>;

export type IRefinementFilterKeys =
  | "RefinableString00"
  | "RefinableString01"
  | "RefinableString02"
  | "RefinableString03"
  | "RefinableString04"
  | "RefinableString05"
  | "RefinableString06"
  | "RefinableString07"
  | "RefinableString08"
  | "RefinableDate00"
  | "RefinableDate01"
  | "RefinableDate02";

export type IRefinementFilter = Partial<Record<IRefinementFilterKeys, ISelectedFilterValue>>;

export type IRefinementFiltersGroup = {
  filters: IRefinementFilter;
};

export type IFilterGroupResult = {
  lastChangedFilterField?: string;
  result: any[];
};

export interface IFiltersState {
  infoPanelFilters: IFilter[];
  selectedFilters: ISelectedFilters;
  filtersGuids: Record<LibraryName, string>;
  filtersLibrary: LibraryName;
  isSearchPage: boolean;
  isSubscriptionsPage: boolean;
  isRolesRegistryPage: boolean;
  activePresets: Partial<Record<LibraryName, IFavoriteFilter>>;
  isResetDocumentViewToggle: boolean;
  filterGroupResults: IFilterGroupResult[];
  refiners: Record<LibraryName, IRefinementItem[]>;
}

const defaultGroup: IFiltersGroup = {
  name: "Filter Group 1",
  enabled: true,
  filters: {},
};

export const initialGroupDocuments: IFiltersGroup = {
  name: "Filter Group 1",
  enabled: true,
  filters: { show: "", sort: { field: "Document", asc: true } },
};

export const initialGroupRoles: IFiltersGroup = {
  name: "Filter Group 1",
  enabled: true,
  filters: { show: "", sort: { field: "RoleName", asc: true } },
};

export const defaultSelectedFilters = {
  [LibraryName.all]: [_clone(initialGroupDocuments)],
  [LibraryName.regulationsAndTemplates]: [_clone(initialGroupDocuments)],
  [LibraryName.regulations]: [_clone(initialGroupDocuments)],
  [LibraryName.templates]: [_clone(initialGroupDocuments)],
  [LibraryName.certificates]: [_clone(initialGroupDocuments)],
  [LibraryName.roles]: [_clone(initialGroupRoles)],
  [LibraryName.pages]: [_clone(initialGroupDocuments)],
};

export const defaultFiltersGuids = {
  [LibraryName.all]: null,
  [LibraryName.regulationsAndTemplates]: null,
  [LibraryName.regulations]: null,
  [LibraryName.templates]: null,
  [LibraryName.certificates]: null,
  [LibraryName.roles]: null,
  [LibraryName.pages]: null,
};

export const defaultActivePresets = {
  [LibraryName.regulationsAndTemplates]: null,
  [LibraryName.regulations]: null,
  [LibraryName.templates]: null,
  [LibraryName.certificates]: null,
  [LibraryName.roles]: null,
};

export const defaultRefiners = {
  [LibraryName.all]: [],
  [LibraryName.regulationsAndTemplates]: [],
  [LibraryName.regulations]: [],
  [LibraryName.templates]: [],
  [LibraryName.certificates]: [],
  [LibraryName.roles]: [],
  [LibraryName.pages]: [],
};

const initialState: IFiltersState = {
  infoPanelFilters: [],
  selectedFilters: defaultSelectedFilters,
  filtersGuids: defaultFiltersGuids,
  filtersLibrary: null,
  isSearchPage: false,
  isSubscriptionsPage: false,
  isRolesRegistryPage: false,
  activePresets: defaultActivePresets,
  isResetDocumentViewToggle: false,
  filterGroupResults: [],
  refiners: defaultRefiners,
};

export function isNeedToRemoveFilterKey(value: ISelectedFilterValue) {
  const isFilter = _isObject(value) && "type" in value;

  if (isFilter && value.type === FilterValueType.subarea) {
    return value.items.length === 0;
  } else if (isFilter && value.type === FilterValueType.timePeriod) {
    return !value.fromDate && !value.toDate;
  } else if (typeof value === "boolean") {
    return !value;
  }

  return false;
}

export function deleteFiltersGroup(groups: IFiltersGroup[], groupIndex: number) {
  if (groupIndex >= groups.length) return groups;

  let result: IFiltersGroup[] = [];
  if (groupIndex === 0) {
    const { filters } = groups[0];
    const savedParams = _omit(filters, getFilterPanelKeys(filters));
    if (groups.length === 1) {
      result = [{ name: "Filter Group 1", enabled: true, filters: savedParams }];
    } else {
      const newGroups = groups.filter((_, i) => i !== groupIndex);
      newGroups[0].filters = updateObject(groups[1].filters, savedParams);
      result = newGroups;
    }
  } else {
    result = groups.filter((_, i) => i !== groupIndex);
  }

  return result;
}

const filtersReducer: Reducer<IFiltersState, IFiltersActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case FiltersActionsTypes.SET_INFO_PANEL_FILTERS:
      draft.infoPanelFilters = action.infoPanelFilters;
      break;

    case FiltersActionsTypes.SET_FILTERS_LIBRARY:
      draft.filtersLibrary = action.filtersLibrary;
      break;

    case FiltersActionsTypes.GET_FILTER_BY_GUID_SUCCESS:
    case FiltersActionsTypes.SET_FILTERS:
      draft.selectedFilters = updateObject(draft.selectedFilters, {
        [draft.filtersLibrary]: action.selectedFilterGroups,
      });
      break;
    case FiltersActionsTypes.UPDATE_FILTER_RESULT:
      if (action.filterGroupResultList && action.filterGroupResultList.length) {
        draft.filterGroupResults = action.filterGroupResultList.map((item, index) => ({
          result: item.result,
          lastChangedFilterField: draft.filterGroupResults[index]?.lastChangedFilterField || "",
        }));
      }
      break;
    case FiltersActionsTypes.SET_CHECKED_FILTER_FIELD:
      if (draft.filterGroupResults && draft.filterGroupResults.length && draft.filterGroupResults[action.groupIndex]) {
        draft.filterGroupResults[action.groupIndex].lastChangedFilterField = action.field;
      }
      break;
    case FiltersActionsTypes.SET_FILTER:
      if (draft.selectedFilters[draft.filtersLibrary].length == 0) {
        draft.selectedFilters[draft.filtersLibrary].push({ ...defaultGroup });
      }
      if (action.key === "show") {
        for (let index = 0; index < draft.selectedFilters[draft.filtersLibrary].length; index++) {
          draft.selectedFilters[draft.filtersLibrary][index].filters = updateObject(
            draft.selectedFilters[draft.filtersLibrary][index].filters,
            {
              [action.key]: action.value,
            },
          );
        }
      }
      if (isNeedToRemoveFilterKey(action.value)) {
        draft.selectedFilters[draft.filtersLibrary][action.groupIndex] = updateObject(
          draft.selectedFilters[draft.filtersLibrary][action.groupIndex],
          {
            filters: _omit(draft.selectedFilters[draft.filtersLibrary][action.groupIndex].filters, [action.key]),
          },
        );
      } else {
        draft.selectedFilters[draft.filtersLibrary][action.groupIndex].filters = updateObject(
          draft.selectedFilters[draft.filtersLibrary][action.groupIndex].filters,
          {
            [action.key]: action.value,
          },
        );
      }
      break;

    case FiltersActionsTypes.RESET_FILTERS:
      if (action.isResetAll) {
        draft.selectedFilters = defaultSelectedFilters;
      } else {
        const { filters } = draft.selectedFilters[draft.filtersLibrary][0];
        const newFilters = _omit(filters, getFilterPanelKeys(filters));
        draft.selectedFilters = updateObject(draft.selectedFilters, {
          [draft.filtersLibrary]: [{ name: "Filter Group 1", enabled: true, filters: newFilters }],
        });
      }
      break;

    case FiltersActionsTypes.SET_IS_PAGE_TOGGLE:
      draft[action.isPageKey] = action.toggle;
      break;

    case FiltersActionsTypes.SET_ACTIVE_PRESET:
      draft.activePresets = updateObject(draft.activePresets, {
        [draft.filtersLibrary]: action.activePreset,
      });
      break;

    case FiltersActionsTypes.RESET_ACTIVE_PRESET:
      if (action.isResetAll) {
        draft.activePresets = defaultActivePresets;
      } else {
        draft.activePresets = updateObject(draft.activePresets, {
          [draft.filtersLibrary]: null,
        });
      }
      break;

    case FiltersActionsTypes.TOGGLE_DOCUMENT_VIEW_RESET:
      draft.isResetDocumentViewToggle = !draft.isResetDocumentViewToggle;
      break;

    case FiltersActionsTypes.SAVE_TEMP_FILTER_SUCCESS:
    case FiltersActionsTypes.SET_FILTER_GUID:
      draft.filtersGuids[draft.filtersLibrary] = action.guid;
      break;

    case FiltersActionsTypes.ADD_FILTERS_GROUP:
      const newGroup = action.group;
      newGroup.filters.show =
        draft.selectedFilters[draft.filtersLibrary][
          draft.selectedFilters[draft.filtersLibrary].length - 1
        ].filters.show;
      const oldGroups = draft.selectedFilters[draft.filtersLibrary];
      draft.selectedFilters[draft.filtersLibrary] = oldGroups ? oldGroups.concat(newGroup) : [newGroup];
      break;

    case FiltersActionsTypes.DISABLE_FILTERS_GROUP:
      draft.selectedFilters[draft.filtersLibrary][action.groupIndex].enabled = false;
      break;

    case FiltersActionsTypes.ENABLE_FILTERS_GROUP:
      draft.selectedFilters[draft.filtersLibrary][action.groupIndex].enabled = true;
      break;

    case FiltersActionsTypes.RENAME_FILTERS_GROUP:
      draft.selectedFilters[draft.filtersLibrary][action.groupIndex].name = action.name;
      break;

    case FiltersActionsTypes.DELETE_FILTERS_GROUP:
      draft.selectedFilters[draft.filtersLibrary] = deleteFiltersGroup(
        draft.selectedFilters[draft.filtersLibrary],
        action.groupIndex,
      );
      break;
    case FiltersActionsTypes.SET_REFINERS:
      draft.refiners[draft.filtersLibrary] = action.payload;
      break;
    case FiltersActionsTypes.RESET_ALL_REFINERS:
      draft.refiners = defaultRefiners;
      break;
  }
}, initialState);

export default filtersReducer;
