import { IFilter, IFilterKeys } from "Components/documents-filter/documents-filter.interface";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IVoidCbWithParam } from "SP/helpers.types";
import { IRefinementItem } from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { IFilterGroupResult, IFiltersGroup, IFiltersState, ISelectedFilterValue } from "Store/reducers/filters.reducer";

export enum FiltersActionsTypes {
  SET_INFO_PANEL_FILTERS = "[FILTERS] SET INFO PANEL FILTERS",
  SET_FILTERS_LIBRARY = "[FILTERS] SET FILTERS LIBRARY",
  SET_FILTERS = "[FILTERS] SET FILTERS",
  SET_FILTER = "[FILTERS] SET FILTER",
  RESET_FILTERS = "[FILTERS] RESET FILTERS",
  SET_IS_PAGE_TOGGLE = "[FILTERS] SET IS PAGE TOGGLE",
  SET_ACTIVE_PRESET = "[FILTERS] SET ACTIVE PRESET",
  RESET_ACTIVE_PRESET = "[FILTERS] RESET ACTIVE PRESET",
  TOGGLE_DOCUMENT_VIEW_RESET = "[FILTERS] TOGGLE DOCUMENT VIEW RESET",

  GET_FILTER_BY_GUID_REQUEST = "[FILTERS] GET BY GUID REQUEST",
  GET_FILTER_BY_GUID_SUCCESS = "[FILTERS] GET BY GUID SUCCESS",
  GET_FILTER_BY_GUID_FAILURE = "[FILTERS] GET BY GUID FAILURE",

  SET_FILTER_GUID = "[FILTERS] SET GUID",

  SAVE_TEMP_FILTER_REQUEST = "[FILTERS] SAVE TEMP REQUEST",
  SAVE_TEMP_FILTER_SUCCESS = "[FILTERS] SAVE TEMP SUCCESS",
  SAVE_TEMP_FILTER_FAILURE = "[FILTERS] SAVE TEMP FAILURE",

  UPDATE_FILTER_RESULT = "[FILTERS] UPDATE FILTER RESULT",
  SET_CHECKED_FILTER_FIELD = "[FILTERS] SET CHECKED FILTER FIELD",

  ADD_FILTERS_GROUP = "[FILTERS] ADD FILTERS GROUP",
  DISABLE_FILTERS_GROUP = "[FILTERS] DISABLE FILTERS GROUP",
  ENABLE_FILTERS_GROUP = "[FILTERS] ENABLE FILTERS GROUP",
  RENAME_FILTERS_GROUP = "[FILTERS] RENAME FILTERS GROUP",
  DELETE_FILTERS_GROUP = "[FILTERS] DELETE FILTERS GROUP",

  SET_REFINERS = "[FILTERS] SET REFINERS",
  RESET_ALL_REFINERS = "[FILTERS] RESET ALL REFINERS",
}

export interface ISetInfoPanelFiltersAction {
  type: FiltersActionsTypes.SET_INFO_PANEL_FILTERS;
  infoPanelFilters: IFilter[];
}

export function setInfoPanelFilters(infoPanelFilters: IFilter[]): ISetInfoPanelFiltersAction {
  return {
    type: FiltersActionsTypes.SET_INFO_PANEL_FILTERS,
    infoPanelFilters,
  };
}

export interface ISetFiltersLibraryAction {
  type: FiltersActionsTypes.SET_FILTERS_LIBRARY;
  filtersLibrary: LibraryName;
}

export function setFiltersLibrary(filtersLibrary: LibraryName): ISetFiltersLibraryAction {
  return {
    type: FiltersActionsTypes.SET_FILTERS_LIBRARY,
    filtersLibrary,
  };
}

export interface ISetFiltersAction {
  type: FiltersActionsTypes.SET_FILTERS;
  selectedFilterGroups: IFiltersGroup[];
}

export function setFilters(selectedFilterGroups: IFiltersGroup[]): ISetFiltersAction {
  return {
    type: FiltersActionsTypes.SET_FILTERS,
    selectedFilterGroups,
  };
}

export interface IUpdateFilterResultAction {
  type: FiltersActionsTypes.UPDATE_FILTER_RESULT;
  filterGroupResultList: IFilterGroupResult[];
}

export function updateFilterResult(filterGroupResultList: IFilterGroupResult[]): IUpdateFilterResultAction {
  return {
    type: FiltersActionsTypes.UPDATE_FILTER_RESULT,
    filterGroupResultList,
  };
}

export interface ISetCheckedFilterFieldAction {
  type: FiltersActionsTypes.SET_CHECKED_FILTER_FIELD;
  groupIndex: number;
  field?: string;
}

export function setCheckedFilterField(groupIndex: number, field?: string): ISetCheckedFilterFieldAction {
  return {
    type: FiltersActionsTypes.SET_CHECKED_FILTER_FIELD,
    groupIndex,
    field,
  };
}

export interface ISetFilterAction {
  type: FiltersActionsTypes.SET_FILTER;
  key: IFilterKeys;
  value: ISelectedFilterValue;
  groupIndex: number;
}

export function setFilter(key: IFilterKeys, value: ISelectedFilterValue, index: number): ISetFilterAction {
  return {
    type: FiltersActionsTypes.SET_FILTER,
    key,
    value,
    groupIndex: index,
  };
}

export interface IResetFiltersAction {
  type: FiltersActionsTypes.RESET_FILTERS;
  isResetAll?: boolean;
}

export function resetFilters(isResetAll?: boolean): IResetFiltersAction {
  return {
    type: FiltersActionsTypes.RESET_FILTERS,
    isResetAll,
  };
}

export type IIsPageKey = Extract<keyof IFiltersState, "isSearchPage" | "isSubscriptionsPage" | "isRolesRegistryPage">;

export interface ISetIsPageToggleAction {
  type: FiltersActionsTypes.SET_IS_PAGE_TOGGLE;
  isPageKey: IIsPageKey;
  toggle: boolean;
}

export function setIsPageToggle(isPageKey: IIsPageKey, toggle: boolean): ISetIsPageToggleAction {
  return {
    type: FiltersActionsTypes.SET_IS_PAGE_TOGGLE,
    isPageKey,
    toggle,
  };
}

export interface ISetActivePresetAction {
  type: FiltersActionsTypes.SET_ACTIVE_PRESET;
  activePreset: IFavoriteFilter;
}

export function setActivePreset(activePreset: IFavoriteFilter): ISetActivePresetAction {
  return {
    type: FiltersActionsTypes.SET_ACTIVE_PRESET,
    activePreset,
  };
}

export interface IResetActivePresetAction {
  type: FiltersActionsTypes.RESET_ACTIVE_PRESET;
  isResetAll?: boolean;
}

export function resetActivePreset(isResetAll?: boolean): IResetActivePresetAction {
  return {
    type: FiltersActionsTypes.RESET_ACTIVE_PRESET,
    isResetAll,
  };
}

export interface IToggleDocumentViewReset {
  type: FiltersActionsTypes.TOGGLE_DOCUMENT_VIEW_RESET;
}

export function toggleDocumentViewReset() {
  return {
    type: FiltersActionsTypes.TOGGLE_DOCUMENT_VIEW_RESET,
  };
}

export interface IGetFilterByGuidRequestAction {
  type: FiltersActionsTypes.GET_FILTER_BY_GUID_REQUEST;
  guid: string;
  cb?: (value: IFavoriteFilter) => void;
}

export function getFilterByGuidRequest(
  guid: string,
  cb?: (value: IFavoriteFilter) => void,
): IGetFilterByGuidRequestAction {
  return {
    type: FiltersActionsTypes.GET_FILTER_BY_GUID_REQUEST,
    guid,
    cb,
  };
}

export interface IGetFilterByGuidSuccessAction {
  type: FiltersActionsTypes.GET_FILTER_BY_GUID_SUCCESS;
  selectedFilterGroups: IFiltersGroup[];
}

export function getFilterByGuidSuccess(filter: IFavoriteFilter): IGetFilterByGuidSuccessAction {
  return {
    type: FiltersActionsTypes.GET_FILTER_BY_GUID_SUCCESS,
    selectedFilterGroups: filter.filtersGroups,
  };
}

export interface IGetFilterByGuidFailureAction {
  type: FiltersActionsTypes.GET_FILTER_BY_GUID_FAILURE;
  error: string;
}

export function getFilterByGuidFailure(error): IGetFilterByGuidFailureAction {
  return {
    type: FiltersActionsTypes.GET_FILTER_BY_GUID_FAILURE,
    error: error.message,
  };
}

export interface ISetFilterGuidAction {
  type: FiltersActionsTypes.SET_FILTER_GUID;
  guid: string;
}

export function setFilterGuid(guid: string): ISetFilterGuidAction {
  return {
    type: FiltersActionsTypes.SET_FILTER_GUID,
    guid,
  };
}

export interface ISaveTempFilterRequestAction {
  type: FiltersActionsTypes.SAVE_TEMP_FILTER_REQUEST;
  filter: IFavoriteFilter;
  cb?: IVoidCbWithParam;
}

export function saveTempFilterRequest(filter: IFavoriteFilter, cb?: IVoidCbWithParam): ISaveTempFilterRequestAction {
  return {
    type: FiltersActionsTypes.SAVE_TEMP_FILTER_REQUEST,
    filter,
    cb,
  };
}

export interface ISaveTempFilterSuccessAction {
  type: FiltersActionsTypes.SAVE_TEMP_FILTER_SUCCESS;
  guid: string;
}

export function saveTempFilterSuccess(guid: string): ISaveTempFilterSuccessAction {
  return {
    type: FiltersActionsTypes.SAVE_TEMP_FILTER_SUCCESS,
    guid,
  };
}

export interface ISaveTempFilterFailureAction {
  type: FiltersActionsTypes.SAVE_TEMP_FILTER_FAILURE;
  error: string;
}

export function saveTempFilterFailure(error: any): ISaveTempFilterFailureAction {
  return {
    type: FiltersActionsTypes.SAVE_TEMP_FILTER_FAILURE,
    error: error.message,
  };
}

export interface IAddFiltersGroupAction {
  type: FiltersActionsTypes.ADD_FILTERS_GROUP;
  group: IFiltersGroup;
}

export function addFiltersGroup(group: IFiltersGroup): IAddFiltersGroupAction {
  return {
    type: FiltersActionsTypes.ADD_FILTERS_GROUP,
    group,
  };
}

export interface IDisableFiltersGroupAction {
  type: FiltersActionsTypes.DISABLE_FILTERS_GROUP;
  groupIndex: number;
}

export function disableFiltersGroup(index: number): IDisableFiltersGroupAction {
  return {
    type: FiltersActionsTypes.DISABLE_FILTERS_GROUP,
    groupIndex: index,
  };
}

export interface IEnableFiltersGroupAction {
  type: FiltersActionsTypes.ENABLE_FILTERS_GROUP;
  groupIndex: number;
}

export function enableFiltersGroup(index: number): IEnableFiltersGroupAction {
  return {
    type: FiltersActionsTypes.ENABLE_FILTERS_GROUP,
    groupIndex: index,
  };
}

export interface IRenameFiltersGroupAction {
  type: FiltersActionsTypes.RENAME_FILTERS_GROUP;
  name: string;
  groupIndex: number;
}

export function renameFiltersGroup(name: string, index: number): IRenameFiltersGroupAction {
  return {
    type: FiltersActionsTypes.RENAME_FILTERS_GROUP,
    name,
    groupIndex: index,
  };
}

export interface IDeleteFiltersGroupAction {
  type: FiltersActionsTypes.DELETE_FILTERS_GROUP;
  groupIndex: number;
}

export function deleteFiltersGroup(index: number): IDeleteFiltersGroupAction {
  return {
    type: FiltersActionsTypes.DELETE_FILTERS_GROUP,
    groupIndex: index,
  };
}

export interface ISetRefinersAction {
  type: FiltersActionsTypes.SET_REFINERS;
  payload: IRefinementItem[];
}

export function setRefiners(refiners: IRefinementItem[]): ISetRefinersAction {
  return {
    type: FiltersActionsTypes.SET_REFINERS,
    payload: refiners,
  };
}

export interface IResetAllRefinersAction {
  type: FiltersActionsTypes.RESET_ALL_REFINERS;
}

export function resetAllRefiners(): IResetAllRefinersAction {
  return {
    type: FiltersActionsTypes.RESET_ALL_REFINERS,
  };
}

export type IFiltersActionsTypes =
  | ISetInfoPanelFiltersAction
  | ISetFiltersLibraryAction
  | ISetFiltersAction
  | ISetFilterAction
  | IResetFiltersAction
  | ISetIsPageToggleAction
  | ISetActivePresetAction
  | IResetActivePresetAction
  | IToggleDocumentViewReset
  | IGetFilterByGuidRequestAction
  | IGetFilterByGuidSuccessAction
  | IGetFilterByGuidFailureAction
  | ISetFilterGuidAction
  | ISaveTempFilterRequestAction
  | ISaveTempFilterSuccessAction
  | ISaveTempFilterFailureAction
  | IUpdateFilterResultAction
  | IAddFiltersGroupAction
  | IDisableFiltersGroupAction
  | IEnableFiltersGroupAction
  | IRenameFiltersGroupAction
  | IDeleteFiltersGroupAction
  | ISetCheckedFilterFieldAction
  | ISetRefinersAction
  | IResetAllRefinersAction;
