import { call, race, select, take } from "redux-saga/effects";

import { RegulationsService } from "SP/documents/regulations/regulations.service";
import { IRefinementItem } from "SP/search/search.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { DocumentsActionsTypes, IAllDocuments } from "Store/actions/documents.actions";
import { StandardsIconsActionTypes } from "Store/actions/standards-icons.actions";
import { TermsActionsTypes } from "Store/actions/terms.actions";
import { IRootReducerState } from "Store/reducers";
import { IFiltersGroup } from "Store/reducers/filters.reducer";
import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

import { mapPropertyFiltersToRefinementFilters } from "./utils.helpers";

const regulationsService = new RegulationsService();

const termsSelectors = {
  allTerms: (state: IRootReducerState): ISPTermObject[] => state.terms.allTerms,
  isTermsLoaded: (state: IRootReducerState): boolean => state.terms.isTermsLoaded,
};

const documentsSelectors = {
  allDocuments: (state: IRootReducerState): IAllDocuments => state.documents.allDocuments,
  regulations: (state: IRootReducerState): IAttachedFile[] => state.documents.allDocuments[LibraryName.regulations],
  isDocumentsLoaded: (state: IRootReducerState): boolean => state.documents.isDocumentsLoaded,
};

const iconsSelectors = {
  isLoaded: (state: IRootReducerState): boolean => state.standardsIcons.isIconsLoaded,
  icons: (state: IRootReducerState): IStandardIcon[] => state.standardsIcons.icons,
};

const filtersSelector = {
  refiners: (state: IRootReducerState): IRefinementItem[] => state.filters.refiners[state.filters.filtersLibrary],
};

export function* getAllTerms() {
  try {
    const isTermsLoaded = yield select(termsSelectors.isTermsLoaded);
    let allTerms = yield select(termsSelectors.allTerms);

    if (!isTermsLoaded) {
      const { success } = yield race({
        success: take(TermsActionsTypes.GET_TERMS_SUCCESS),
        failure: take(TermsActionsTypes.GET_TERMS_FAILURE),
      });
      allTerms = success?.payload?.allTerms || [];
    }

    return allTerms;
  } catch (error) {
    throw error;
  }
}

export function* getAllDocuments() {
  try {
    const isDocumentsLoaded = yield select(documentsSelectors.isDocumentsLoaded);
    let allDocuments = yield select(documentsSelectors.allDocuments);

    if (!isDocumentsLoaded) {
      const { success } = yield race({
        success: take(DocumentsActionsTypes.GET_ALL_DOCUMENTS_SUCCESS),
        failure: take(DocumentsActionsTypes.GET_ALL_DOCUMENTS_FAILURE),
      });
      allDocuments = success?.payload || {};
    }

    return allDocuments;
  } catch (error) {
    throw error;
  }
}

export function* getRegulations() {
  try {
    let regulations: IAttachedFile[] = yield select(documentsSelectors.regulations);

    if (!regulations.length) {
      regulations = yield call([regulationsService, "getAll"]);
    }

    return regulations;
  } catch (error) {
    throw error;
  }
}

export function* getStandardsIcons() {
  try {
    const isIconsLoaded = yield select(iconsSelectors.isLoaded);
    let icons = yield select(iconsSelectors.icons);

    if (!isIconsLoaded) {
      const { success } = yield race({
        success: take(StandardsIconsActionTypes.GET_ICONS_SUCCESS),
        failure: take(StandardsIconsActionTypes.GET_ICONS_FAILURE),
      });

      icons = success?.payload || [];
    }

    return icons;
  } catch (e) {
    throw e;
  }
}

export function* getRefinementFiltersGroups(propertyFilters: IFiltersGroup[]) {
  const refiners: IRefinementItem[] = yield select(filtersSelector.refiners);
  return mapPropertyFiltersToRefinementFilters(propertyFilters, refiners);
}
