import { fetchClientFactory } from "./msal";

type IHeader = { key: string; value: string };
interface IGraphGetParams {
  url: string;
  headers?: IHeader[];
  readAs?: keyof Omit<Body, "body" | "bodyUsed">;
  resource?: string;
  body?: any;
  method?: string;
}

export const graphConfig = {
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
  getDrivesIdEndpoint: (siteId: string) => `https://graph.microsoft.com/v1.0/sites/${siteId}/drives?$select=name,id`,
  getThumbnailUrlEndpoint: (siteId: string, driveId: string, filePath: string) =>
    `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root:/${filePath}:/thumbnails/0/large`,
};

const ERROR_STATUSES = [401, 403, 404, 423];

export async function requestWithAuth<T>({
  url,
  headers = [],
  readAs = "json",
  method = "GET",
  resource,
  body,
}: IGraphGetParams): Promise<T> {
  try {
    const accessToken = await fetchClientFactory.getToken(resource);
    if (!accessToken) {
      throw new Error("Access token is missing!");
    }

    const headersCollector = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headersCollector.append("Authorization", bearer);
    headers.forEach(({ key, value }) => {
      headersCollector.append(key, value);
    });

    const options = {
      method,
      headers: headersCollector,
      body,
    };

    const response = await fetch(url, options);
    if (ERROR_STATUSES.includes(response.status)) {
      throw new Error(`${response.status} - ${response.statusText}: ${response.url}`);
    }

    return response[readAs]();
  } catch (error) {
    console.info(error);
    return null;
  }
}
