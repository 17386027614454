import React from "react";

import { ReactComponent as cardViewIcon } from "@App/assets/cards_view.svg";
import { ReactComponent as listViewIcon } from "@App/assets/list_view.svg";
import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { ReactComponent as checkIcon } from "@epam/assets/icons/common/notification-done-18.svg";
import { FlexRow, FlexSpacer, IconContainer, LinkButton, PickerInput, Text } from "@epam/loveship";
import { DataRowProps } from "@epam/uui";
import { PickerFooterProps, PickerTogglerProps } from "@epam/uui-components";

import { SearchTabType } from "Components/search/search.interfaces";
import { SubscriptionsTabType } from "Components/subscriptions/subscriptions.interfaces";
import { SubscribeItemTypes } from "Helpers/subscribeHelper";
import { getServerRelativeUrl } from "Helpers/utils";
import { ICheckIsSubscribed } from "Hooks/useUsers";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { exportCertificatesToExcel } from "SP/exportToExcel/exportCertificates";
import { exportDocumentsToExcel } from "SP/exportToExcel/exportDocuments";
import { exportSearchResultsToExcel } from "SP/exportToExcel/exportSearchResults";
import { IUnique } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { ISearchItem } from "SP/search/search.types";
import { FullPageComponentTypes, IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";

import {
  IDataPicker,
  IGenerateShowFilterItemsParams,
  IPickerInputProps,
  IToolbarAction,
  Tabs,
  ViewModes,
} from "./documents-toolbar.interface";
import { RolesRegistryFooter } from "./roles-registry-footer";
import { ShowPickerFooter } from "./show-picker-footer";

const regulationPageTypes = [
  FullPageComponentTypes.RegulationsByDocumentType,
  FullPageComponentTypes.Regulations,
  FullPageComponentTypes.Templates,
];

export function isWithinRegulationTypes(pageType: FullPageComponentTypes, activeTab: string) {
  return regulationPageTypes.includes(pageType) && activeTab !== Tabs.certificates;
}

export function isRegulationsAndTemplates(pageType: FullPageComponentTypes, activeTab: string) {
  return pageType === FullPageComponentTypes.RegulationsByDocumentType && activeTab !== Tabs.certificates;
}

export function isCertificates(pageType: FullPageComponentTypes, activeTab: string) {
  return pageType === FullPageComponentTypes.Certificates || activeTab === Tabs.certificates;
}

export function isRolesRegistry(pageType: FullPageComponentTypes) {
  return pageType === FullPageComponentTypes.RolesRegistry;
}

export function isNotRolesRegistry(pageType: FullPageComponentTypes) {
  return pageType !== FullPageComponentTypes.RolesRegistry;
}

export function getSelectedData<T extends IUnique>(allData: T[], selectedRows: string[]) {
  return selectedRows.length > 0 ? allData.filter((doc) => selectedRows.includes(doc.UniqueId)) : allData;
}

export function getDocumentType(isRolesRegistry: boolean, isCertificates: boolean): SubscribeItemTypes {
  if (isRolesRegistry) {
    return SubscribeItemTypes.role;
  } else if (isCertificates) {
    return SubscribeItemTypes.certificate;
  }

  return SubscribeItemTypes.document;
}

export function exportItemsToExcel(
  activeTab: SearchTabType | SubscriptionsTabType,
  items: ISearchItem[],
  selectedRows: string[],
  title: string,
) {
  const itemsToExport = getSelectedData(items, selectedRows);
  const fileName = `${title} - ${activeTab}`;

  if (!itemsToExport.length) return;
  switch (activeTab) {
    case SearchTabType.All:
    case SearchTabType.Pages:
      exportSearchResultsToExcel(itemsToExport, fileName);
      break;
    case SearchTabType.Documents:
      exportDocumentsToExcel(itemsToExport as IRegulation[], fileName);
      break;
    case SearchTabType.Certificates:
      exportCertificatesToExcel(itemsToExport as ICertificate[], fileName);
      break;
  }
}

export function filterToolbarActions(actions: IToolbarAction[], selectedRows: string[]) {
  return actions.filter((action) => selectedRows.length > 0 || action.id === 3);
}

export function isThereUnsubscribedDocument(
  allData: IAttachedFile[],
  selectedRows: string[],
  checkIsSubscribed: ICheckIsSubscribed,
  isRoles: boolean,
) {
  const selectedData = getSelectedData(allData, selectedRows);

  return selectedData.some((document) => !checkIsSubscribed(document.sourceLibrary, document.Id));
}

function getUniqueRoleGroups(roles: IRole[]): string[] {
  return [...new Set(roles.filter((item) => item.Group).map((item) => item.Group))];
}

export const corporateGovernanceId = "/EPAM Regulations/Corporate Governance Policies";
export const codeOfEthicalConductId = "/EPAM Regulations/Code of Ethical Conduct";

export function getShowFilterDefaultItems(): Record<string, IDataPicker> {
  const ServerRelativeUrl = getServerRelativeUrl();

  return {
    all: { id: "", label: "All", parentId: null },
    allRoleGroups: { id: "", label: "All Groups", parentId: null },
    regulations: {
      id: `${ServerRelativeUrl}/EPAM Regulations`,
      label: "Regulations",
      parentId: null,
    },
    templates: {
      id: `${ServerRelativeUrl}/EPAM Templates`,
      label: "Templates and Examples",
      parentId: null,
    },
    certificate: {
      id: "Certificate",
      label: "Certificate",
      additionalLabel: `Certificate is an official document stating that company's management system has been assessed and certified as meeting the requirements of international or regional standard certificate refers to`,
    },
    certificateSummary: {
      id: "Certification Summary",
      label: "Certification Summary",
      additionalLabel: `Certification Summary is an internal document that provides a list of all EPAM Certifications until date. Certification Summaries, categorized as Audit Certifications by Locations and Audit Certifications by Standards`,
    },
    corporateGovernance: {
      id: corporateGovernanceId,
      label: "Corporate Governance",
    },
  };
}

export function generateShowFilterItems({
  pageType,
  activeTab,
  allRoles,
  documentTypeDescriptions,
}: IGenerateShowFilterItemsParams): IDataPicker[] {
  const showFilterItems = getShowFilterDefaultItems();

  if (isRegulationsAndTemplates(pageType, activeTab)) {
    return [
      showFilterItems.all,
      showFilterItems.regulations,
      showFilterItems.templates,
      ...documentTypeDescriptions[LibraryName.regulations].map((item) => ({
        id: item.ServerRelativeUrl,
        label: item.Title,
        additionalLabel: item.Description,
        parentId: showFilterItems.regulations.id,
      })),
      ...documentTypeDescriptions[LibraryName.templates].map((item) => ({
        id: item.ServerRelativeUrl,
        label: item.Title,
        additionalLabel: item.Description,
        parentId: showFilterItems.templates.id,
      })),
    ];
  } else if (pageType === FullPageComponentTypes.Templates) {
    return [
      showFilterItems.all,
      ...documentTypeDescriptions[LibraryName.templates].map((item) => ({
        id: item.ServerRelativeUrl,
        label: item.Title,
        additionalLabel: item.Description,
      })),
    ];
  } else if (isCertificates(pageType, activeTab)) {
    return [showFilterItems.all, showFilterItems.certificate, showFilterItems.certificateSummary];
  } else if (isRolesRegistry(pageType)) {
    return [
      showFilterItems.allRoleGroups,
      ...getUniqueRoleGroups(allRoles)
        .sort()
        .map((item) => ({
          id: item,
          label: item,
        })),
    ];
  }

  return [];
}

export function generateShowFilterModalInfo(
  pageType: FullPageComponentTypes,
  activeTab: string,
  filterItems: IDataPicker[],
) {
  if (isRegulationsAndTemplates(pageType, activeTab)) {
    return {
      modalTitle: `${LibraryName.regulations} and Templates: Document Types`,
      leftCol: filterItems.slice(3, 9),
      rightCol: filterItems.slice(9, 11),
      templates: filterItems.slice(11),
    };
  } else if (pageType === FullPageComponentTypes.Templates) {
    return {
      modalTitle: `${LibraryName.templates}: Document Types`,
      templates: filterItems.slice(1),
    };
  } else if (isCertificates(pageType, activeTab)) {
    return {
      modalTitle: `${LibraryName.certificates}: Document Types`,
      leftCol: filterItems.slice(1, 2),
      rightCol: filterItems.slice(2),
    };
  }

  return {
    modalTitle: "Unknown: Document Types",
  };
}

export const renderPickerInput = (props: IPickerInputProps) => (
  <PickerInput
    dataSource={props.dataSource}
    selectionMode="single"
    value={props.value}
    valueType="id"
    onValueChange={props.onValueChange}
    getName={(item) => item.label}
    minBodyWidth={props.minBodyWidth}
    disableClear
    renderToggler={props.renderToggler}
    renderRow={props.renderRow}
    renderFooter={props.renderFooter}
  />
);

export const renderListPickerToggler = (
  props: PickerTogglerProps<IDataPicker, string>,
  cn: (b, e?, m?) => string,
  isTabs: boolean,
) => {
  const selectedValue = props.selection[0]?.value;
  return (
    <div
      className={cn("picker-toggler", { "view-picker": true, "tabs-present": isTabs, isOpen: props.isOpen }, [
        "flex justify-between items-center cursor-pointer",
      ])}
      data-testid="list-picker-toggler"
      onClick={props.onClick}
      ref={props.ref}
    >
      <LinkButton
        caption={selectedValue.label}
        icon={selectedValue.label === ViewModes.list ? listViewIcon : cardViewIcon}
        iconPosition="left"
        size="36"
        fontSize="14"
        lineHeight="18"
        color="white"
      />
      <IconContainer cx={cn("picker-toggler-chevron")} icon={props.isOpen ? chevronUp : chevronDown} />
    </div>
  );
};

export const renderListPickerRow = (rowProps: DataRowProps<IDataPicker, string>, cn: (b, e?, m?) => string) => (
  <FlexRow
    key={rowProps.rowKey}
    cx={cn("picker-row", ["flex items-center"])}
    onClick={() => rowProps.onSelect(rowProps)}
  >
    <IconContainer icon={rowProps.value.icon} color="night600" />
    <Text cx={cn("picker-row-text")} size="18" color="night900" fontSize="14" lineHeight="18">
      {rowProps.value.label}
    </Text>
    <FlexSpacer />
    {rowProps.isSelected && <IconContainer icon={checkIcon} color="sky" />}
  </FlexRow>
);

export const renderShowPickerToggler = (
  props: PickerTogglerProps<{ id: string; label: string }, string>,
  cn: (b, e?, m?) => string,
) => (
  <div
    className={cn("picker-toggler", { "show-picker": true, isOpen: props.isOpen }, [
      "flex justify-start items-center cursor-pointer",
    ])}
    onClick={props.onClick}
    ref={props.ref}
    data-testid="show-picker-toggler"
  >
    <Text size="36" color="white" font="sans-semibold" fontSize="16" lineHeight="24">
      <span className={cn("picker-toggler-prefix")}>Show:</span>
      {props.selection[0]?.value?.label || props.placeholder}
    </Text>
    <IconContainer cx={cn("picker-toggler-chevron")} icon={props.isOpen ? chevronUp : chevronDown} />
  </div>
);

export const renderRolesRegistryFooter = (cn: (b, e?, m?) => string) => <RolesRegistryFooter cn={cn} />;

export const renderShowPickerFooter = (
  pickerProps: PickerFooterProps<IDataPicker, string>,
  cn: (b, e?, m?) => string,
  pageType: FullPageComponentTypes,
  activeTab?: string,
) => <ShowPickerFooter pickerProps={pickerProps} cn={cn} pageType={pageType} activeTab={activeTab} />;
