import { useMemo } from "react";

import { isFriendlyUrl } from "Helpers/utils";

export const useFriendlyURLPathname = () => {
  const pathname = useMemo(() => {
    if (isFriendlyUrl(location.href)) {
      return location.pathname.replace(/^\//, "");
    }
    return decodeURI(location.pathname);
  }, [location.pathname]);

  return { pathname };
};
