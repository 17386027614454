import { IList, sp } from "@pnp/sp/presets/all";

import { rolesRegistryListRequestInfo } from "SP/constants";

import { IRoleDTO } from "./rolesRegistry.types";

export class RolesRepository {
  private rolesRegistryList: IList = sp.web.lists.getByTitle("Roles Registry");
  public async getAll(): Promise<IRoleDTO[]> {
    return await this.rolesRegistryList.items
      .select(...rolesRegistryListRequestInfo.selectArray)
      .expand(...rolesRegistryListRequestInfo.expandArray)
      .getAll(5000);
  }
  public async getById(id: number): Promise<IRoleDTO> {
    return await this.rolesRegistryList.items
      .getById(id)
      .select(...rolesRegistryListRequestInfo.selectArray)
      .expand(...rolesRegistryListRequestInfo.expandArray)
      .get();
  }
}
