import _isEmpty from "lodash/isEmpty";

import { IRefiner, ISearchResult } from "@pnp/sp/presets/all";

import highlight from "Helpers/highlight";
import { getPlainText, getXmlTagContent, removeDuplicates } from "Helpers/utils";
import { documentsFieldsToSearch, rolesFieldsToSearch, searchSpecialSymbols } from "SP/constants";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import {
  IGlobalSearchProps,
  IListSearchProps,
  ISearchRepositoryResult,
  SearchRepository,
} from "SP/search/search.repository";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { IRefinementFiltersGroup } from "Store/reducers/filters.reducer";
import { SearchTypes } from "Store/reducers/search.reducer";

import {
  IHitHighlightedAll,
  IHitHighlightedExact,
  IListIds,
  IListSearchParams,
  IRefinementItem,
  ISearchInfo,
  ISearchItem,
} from "./search.types";

const MIN_WORD_LENGTH = 3;

interface ISearchRequestInfo {
  requestTime: Date;
  searchResult: ISearchResult[];
}

export interface ISearchServiceResult {
  searchItems: ISearchItem[];
  refiners: IRefinementItem[];
  totalRows: number;
}

export interface IExecuteSearchProps {
  QueryText: string;
  searchType: SearchTypes;
  regulations: IRegulation[];
  templates: IRegulation[];
  certificates: ICertificate[];
  roles: IRole[];
  library: LibraryName;
  rowsLimit: number;
  loadRefiners: boolean;
}

const emptySearchResult: ISearchServiceResult = {
  searchItems: [],
  refiners: [],
  totalRows: 0,
};

export class SearchService {
  private regulations: IRegulation[];
  private templates: IRegulation[];
  private certificates: ICertificate[];
  private roles: IRole[];
  private listIds: IListIds;
  private memorizedSearchResult: ISearchServiceResult = emptySearchResult;
  private lastRequestTime: Date;
  private searchRequestsInLists: Partial<Record<LibraryName, ISearchRequestInfo>> = {};

  public async executeSearch(props: IExecuteSearchProps): Promise<ISearchServiceResult> {
    return this.executeSearchWithFilters(props, []);
  }

  public async executeSearchWithFilters(
    props: IExecuteSearchProps,
    filters: IRefinementFiltersGroup[],
  ): Promise<ISearchServiceResult> {
    const { regulations, templates, certificates, roles, QueryText, searchType, library, rowsLimit } = props;
    this.regulations = regulations;
    this.templates = templates;
    this.certificates = certificates;
    this.roles = roles;

    if (!this.listIds) {
      this.listIds = await SearchRepository.getListsId();
    }

    const _getGetListId = (listIds: IListIds) => (library: LibraryName) => SearchService.getListId(library, listIds);
    const _getListId = _getGetListId(this.listIds);

    const documentsListsIds = [
      _getListId(LibraryName.regulations),
      _getListId(LibraryName.templates),
      _getListId(LibraryName.certificates),
    ];

    const searchProps: IGlobalSearchProps = {
      QueryText: process.env.NODE_ENV === "test" ? QueryText : SearchService.getFormattedQuery(QueryText, searchType),
      listsIds: documentsListsIds,
      pagesId: _getListId(LibraryName.pages),
      rolesId: _getListId(LibraryName.roles),
      rolesFields: rolesFieldsToSearch,
      documentsFields: documentsFieldsToSearch,
      filters,
      rowsLimit,
      loadRefiners: props.loadRefiners,
    };

    if (this.checkIfEmptyQuery(searchProps.QueryText, searchType)) {
      return { searchItems: [], refiners: [], totalRows: 0 };
    }

    const currentRequestTime = new Date();
    this.lastRequestTime = currentRequestTime;
    const globalResults = await this.executeGlobalSearch(searchProps, searchType, library);

    if (this.lastRequestTime > currentRequestTime) {
      return this.memorizedSearchResult;
    }

    const refiners: IRefinementItem[] = globalResults.Refiners.map((x) => this.mapRefiner(x));

    const filteredResults = this.clearSearchResults(globalResults.SearchResults);
    const searchItems = filteredResults.map((result) => this.mapSearchItems(QueryText, result, searchType));

    const result: ISearchServiceResult = {
      searchItems: this.clearSearchItems(searchItems),
      refiners,
      totalRows: globalResults.TotalRows,
    };
    this.memorizedSearchResult = result;

    return result;
  }

  private mapRefiner(refiner: IRefiner): IRefinementItem {
    let entries = this.mapRefinementEntries(refiner.Entries).filter(
      (x) => !x.Value.endsWith(".jpg") && !x.Value.endsWith(".PDF") && !x.Value.endsWith(".qxd"),
    );
    entries = removeDuplicates(entries, (x) => x.Value.trim());

    return {
      Name: refiner.Name,
      Entries: entries,
    };
  }

  private mapRefinementEntries(entries: IRefiner["Entries"]) {
    return entries.map((x) => ({ Value: x.RefinementValue, Token: x.RefinementToken }));
  }

  private checkIfEmptyQuery(query: string, searchType: SearchTypes) {
    let text = query;

    if (searchType === SearchTypes.exact) {
      text = SearchService.removeSpecialSymbols(text);
    }

    return text.trim().length === 0;
  }

  static removeSpecialSymbols(source: string) {
    const orExpression = searchSpecialSymbols.map((x) => `\\${x}`).join("|");
    const regex = new RegExp(`(${orExpression})`, "g");

    return source.replaceAll(regex, " ");
  }

  static getFormattedQuery(source: string, searchType: SearchTypes) {
    if (searchType === SearchTypes.all) {
      const withoutSpecialSymbols = this.removeSpecialSymbols(source);

      return SearchService.removeShortWords(withoutSpecialSymbols);
    }

    return source.replaceAll(/(\s")|(")/g, " ");
  }

  static getQueriesAll(queryText: string) {
    return SearchRepository.splitQuery(SearchService.getFormattedQuery(queryText, SearchTypes.all))
      .filter((x) => x.length >= MIN_WORD_LENGTH)
      .map((x) => x.toLocaleLowerCase())
      .filter((x) => x.length > 0);
  }

  static removeShortWords(queryText: string) {
    return SearchRepository.splitQuery(queryText)
      .filter((x) => x.length >= MIN_WORD_LENGTH)
      .join(" ");
  }

  private executeGlobalSearch = (
    searchProps: IGlobalSearchProps,
    searchType: SearchTypes,
    library: LibraryName = LibraryName.all,
  ): Promise<ISearchRepositoryResult> => {
    const isExact = searchType === SearchTypes.exact;
    if (library === LibraryName.all) {
      if (isExact) {
        return SearchRepository.executeGlobalSearchExact(searchProps);
      }

      return SearchRepository.executeGlobalSearchAll(searchProps);
    }

    return this.helpGetListSearchResult(
      library,
      searchProps.QueryText,
      searchProps.filters,
      searchProps.rowsLimit,
      !isExact,
      searchProps.loadRefiners,
    );
  };

  private getUpdatedPrimaryResult = (result: ISearchResult, summaryResults: ISearchResult[]): ISearchResult => {
    const summaryItem = summaryResults.find((x) => x.Path == result.Path);

    if (summaryItem) {
      result = { ...result, HitHighlightedSummary: summaryItem.HitHighlightedSummary };
    }

    return result;
  };

  private mergeHighlightedSummary = (
    primaryResults: ISearchResult[],
    summaryResults: ISearchResult[],
  ): ISearchResult[] => {
    return primaryResults.map((x) => this.getUpdatedPrimaryResult(x, summaryResults));
  };

  private clearSearchResults = (PrimarySearchResults: ISearchResult[]) => {
    const withoutDuplicates = removeDuplicates(PrimarySearchResults, (x) => x?.OriginalPath);

    return withoutDuplicates.filter((item) => {
      return !_isEmpty(item.SPWebUrl);
    });
  };

  private clearSearchItems = (searchItems: ISearchItem[]) =>
    searchItems.filter((item) => {
      if (item.sourceLibrary === LibraryName.regulations || item.sourceLibrary === LibraryName.templates) {
        const document = item as IRegulation;

        if (!document.fileInfo?.Name) return false;
      }

      return true;
    });

  public async executeSearchInList(params: IListSearchParams) {
    const { QueryText, listItems, list } = params;

    if (!this.listIds) {
      this.listIds = await SearchRepository.getListsId();
    }

    const currentRequestTime = new Date();
    const searchResults = await this.getListSearchResults(list, QueryText);

    let searchResultItems = this.clearSearchResults(searchResults.SearchResults);

    if (this.searchRequestsInLists[list]?.requestTime > currentRequestTime) {
      searchResultItems = this.searchRequestsInLists[list].searchResult;
    } else {
      this.searchRequestsInLists[list] = { requestTime: currentRequestTime, searchResult: searchResultItems };
    }

    return searchResultItems.map((result) => this.mapSearchItemsForList(QueryText, result, listItems)).filter(Boolean);
  }

  private async getListSearchResults(list: LibraryName, QueryText: string): Promise<ISearchRepositoryResult> {
    return this.helpGetListSearchResult(list, QueryText, [], 500, true, false);
  }

  private async helpGetListSearchResult(
    list: LibraryName,
    QueryText: string,
    filters: IRefinementFiltersGroup[],
    rowsLimit: number,
    searchAll: boolean,
    loadRefiners: boolean,
  ): Promise<ISearchRepositoryResult> {
    const searchProps: IListSearchProps = {
      queryText: QueryText,
      listsIds: [],
      filters,
      rowsLimit,
      searchAll,
      loadRefiners,
    };

    if (list === LibraryName.regulationsAndTemplates) {
      searchProps.listsIds.push(SearchService.getListId(LibraryName.regulations, this.listIds));
      searchProps.listsIds.push(SearchService.getListId(LibraryName.templates, this.listIds));

      return await SearchRepository.executeDocumentsSearch(searchProps);
    }

    searchProps.listsIds.push(SearchService.getListId(list, this.listIds));

    if (list === LibraryName.regulations) {
      return await SearchRepository.executeDocumentsSearch(searchProps);
    }

    if (list === LibraryName.pages) {
      return await SearchRepository.executePagesSearch(searchProps);
    }

    if (list === LibraryName.templates || list === LibraryName.certificates) {
      return await SearchRepository.executeDocumentsSearch(searchProps);
    }

    return null;
  }

  private formatListIdQueryPart = async (list: LibraryName) => {
    this.listIds = await SearchRepository.getListsId();
    const reversedListIds = SearchService.getReversedListIds(this.listIds);

    if (list === LibraryName.regulationsAndTemplates) {
      return `ListId:${reversedListIds[LibraryName.regulations]} OR ListId:${reversedListIds[LibraryName.templates]}`;
    }
    return `ListId:${reversedListIds[list]}`;
  };

  static getListId = (list: LibraryName, listIds: IListIds) => {
    const reversedListIds = this.getReversedListIds(listIds);

    return reversedListIds[list];
  };

  private static getReversedListIds = (listIds: IListIds) => {
    const reversedListIds = {};
    Object.entries(listIds).forEach(([key, value]) => (reversedListIds[value] = key));

    return reversedListIds;
  };

  private findByUniqueId(listItems: IAttachedFile[], item: ISearchResult): IAttachedFile {
    const regex = /\{([\w-]+)\}/;
    const id = item.UniqueId.match(regex)[1];

    if (!id) return null;

    return listItems.find((x) => x.UniqueId.toLowerCase() == id.toLowerCase());
  }

  private mapSearchItemsForList = (queryText: string, item: ISearchResult, listItems: IAttachedFile[]) => {
    const type = this.listIds[item["ListId"]] as LibraryName;
    const itemUrl = new URL(item?.OriginalPath);
    const fileServerRelativeUrl = decodeURI(itemUrl.pathname);

    if (type === LibraryName.roles) {
      const roleId = new URLSearchParams(itemUrl.search).get("ID");
      const role = listItems.find((role) => role.Id === +roleId);

      if (!role) return null;

      return {
        ...role,
        HitHighlightedSummary: this.getHitHighlightedSummary(queryText, item.HitHighlightedSummary),
      };
    }

    const document =
      item.FileType === "xltx"
        ? this.findByUniqueId(listItems, item)
        : listItems.find(this.findCallback(fileServerRelativeUrl));

    if (!document) return null;

    return {
      ...document,
      HitHighlightedSummary: this.getHitHighlightedSummary(queryText, item.HitHighlightedSummary),
    };
  };

  private getItemUrl = (item: ISearchResult): URL => {
    let path = item.OriginalPath;
    if (item.FileType === "potx") {
      path = (item as any).LinkingUrl;
    }

    return new URL(path);
  };

  private mapSearchItems = (queryText: string, item: ISearchResult, searchType: SearchTypes): ISearchItem => {
    const type = this.listIds[item["ListId"]] as LibraryName;
    let document: IRegulation = undefined;
    let role: IRole = undefined;
    let certificate: ICertificate = undefined;
    const itemUrl = this.getItemUrl(item);
    const fileServerRelativeUrl = decodeURI(itemUrl.pathname);
    const textToHighlight = this.getQueryHighlightResult(type, item);
    let hitHighlighted = this.getHitHighlighted(queryText, textToHighlight, searchType);
    if (type === LibraryName.pages) {
      return {
        Title: item.Title,
        Url: item.Path,
        FriendlyUrl: this.formatFriendlyUrl(item["FriendlyURL"]),
        HitHighlightedAll: hitHighlighted.HitHighlightedAll,
        HitHighlightedExact: hitHighlighted.HitHighlightedExact,
        UniqueId: item.DocId.toString(),
        sourceLibrary: LibraryName.pages,
      };
    } else {
      switch (type) {
        case LibraryName.roles:
          const roleId = new URLSearchParams(itemUrl.search).get("ID");
          role = this.roles.find((r) => r.Id === +roleId);
          if (textToHighlight?.length === 0) {
            hitHighlighted = this.getHitHighlighted(queryText, role.ShortDescription, searchType);
          }

          return {
            ...role,
            HitHighlightedAll: hitHighlighted.HitHighlightedAll,
            HitHighlightedExact: hitHighlighted.HitHighlightedExact,
            sourceLibrary: LibraryName.roles,
          };
        case LibraryName.certificates:
          certificate = this.certificates.find(this.findCallback(fileServerRelativeUrl));
          return {
            ...certificate,
            HitHighlightedAll: hitHighlighted.HitHighlightedAll,
            HitHighlightedExact: hitHighlighted.HitHighlightedExact,
            sourceLibrary: LibraryName.certificates,
          };
        case LibraryName.regulations:
          document = this.regulations.find(this.findCallback(fileServerRelativeUrl));
          return {
            ...document,
            HitHighlightedAll: hitHighlighted.HitHighlightedAll,
            HitHighlightedExact: hitHighlighted.HitHighlightedExact,
            sourceLibrary: LibraryName.regulations,
          };
        case LibraryName.templates:
          document =
            item.FileType === "xltx"
              ? (this.findByUniqueId(this.templates, item) as IRegulation)
              : this.templates.find(this.findCallback(fileServerRelativeUrl));
          return {
            ...document,
            HitHighlightedAll: hitHighlighted.HitHighlightedAll,
            HitHighlightedExact: hitHighlighted.HitHighlightedExact,
            sourceLibrary: LibraryName.templates,
          };
      }
    }
  };

  private formatFriendlyUrl(furl: string) {
    return furl?.split("\n")[0];
  }

  private getQueryHighlightResult = (type: LibraryName, item: ISearchResult): string => {
    switch (type) {
      case LibraryName.pages:
        return getPlainText(item["PageTextContent"]);
      case LibraryName.regulations:
      case LibraryName.templates:
      case LibraryName.certificates:
        return getPlainText(getXmlTagContent(item.HitHighlightedProperties, "DocumentTextContent"));
      case LibraryName.roles:
        return item.HitHighlightedProperties
          ? getPlainText(getXmlTagContent(item.HitHighlightedProperties, "ShortdescriptioninOWSMTXT"))
          : "";
    }

    return "";
  };

  private getHitHighlighted = (
    queryText: string,
    queryResult: string,
    searchType: SearchTypes,
  ): Partial<ISearchInfo> => {
    if (searchType === SearchTypes.exact) {
      return { HitHighlightedExact: this.getHitHighlightedExact(queryText, queryResult), HitHighlightedAll: null };
    }

    return { HitHighlightedExact: null, HitHighlightedAll: this.getHitHighlightedAll(queryText, queryResult) };
  };

  private getHitHighlightedExact = (queryText: string, queryResult: string): IHitHighlightedExact => {
    return {
      text: SearchService.formatSpaceSymbols(this.removeNonHtmlTags(queryResult)),
      query: queryText,
    };
  };

  static formatSpaceSymbols(queryText: string) {
    return queryText.replaceAll(/( [\r\n]+)|([\r\n]+)|(\u8239)/g, " ").replaceAll(/\s+/g, " ");
  }

  private getHitHighlightedAll = (queryText: string, queryResult: string): IHitHighlightedAll => {
    return {
      queries: SearchService.getQueriesAll(queryText),
      text: this.removeNonHtmlTags(queryResult),
    };
  };

  private removeAllTags = (text: string) => {
    if (!text) return "";

    const replaceRegex = /<\/?[^<>]*>/g;

    return this.removeNonHtmlTags(text).replaceAll(replaceRegex, "");
  };

  private removeNonHtmlTags = (text: string) => {
    if (!text) return "";

    const replaceRegex = /<\/?c0>/g;

    return text.replaceAll(replaceRegex, "");
  };

  private getHitHighlightedSummary = (queryText: string, summary: string): IHitHighlightedAll => {
    if (!summary) return null;

    const matchRegex = /<c0>(.*?)<\/c0>/g;
    const replaceRegex =
      /(<\/?c0>|n&(.)t;|li\/?ul|\/?ulli|n\/ul|br\/?|span\/?|h[1-6](.*?)h[1-6]|p?&(.*?);|a class=\\(.*?)\\|\/?(li){2,}|a =\\|tools;|Hamp;|nbsp;|href|\\n|\/(.)l)/g;

    const queries =
      summary.match(matchRegex)?.map((t) => t.replace(replaceRegex, "").toLowerCase()) ||
      highlight.getQueries(queryText, summary, false);
    return {
      text: summary.replaceAll(replaceRegex, "").replaceAll("<ddd/>", "..."),
      queries: queries ? [...new Set(queries)] : [],
    };
  };

  private findCallback = (fileServerRelativeUrl: string) => (c: IAttachedFile) =>
    c.fileInfo.ServerRelativeUrl === decodeURIComponent(fileServerRelativeUrl);
}
