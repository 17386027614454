import React, { FC } from "react";

import { IconContainer } from "@epam/loveship";

import { loaderIcon } from "Components/icons";
import { withNaming } from "Helpers/bemClassname";

import "./loader.scss";

export const Loader: FC = () => {
  const cn = withNaming("loader");
  return (
    <div className={cn()}>
      <IconContainer cx={cn("icon")} icon={loaderIcon} />
    </div>
  );
};
