import produce from "immer";
import { Reducer } from "redux";

import { ISPTermObject } from "SP/SPTermStoreService";
import { ITermsActionsTypes, TermsActionsTypes } from "Store/actions/terms.actions";

export interface ITermsState {
  loading: boolean;
  isTermsLoaded: boolean;
  allTerms: ISPTermObject[];
  filteredTerms: ISPTermObject[];
}

const initialState: ITermsState = {
  loading: false,
  isTermsLoaded: false,
  allTerms: [],
  filteredTerms: [],
};

const termsReducer: Reducer<ITermsState, ITermsActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case TermsActionsTypes.GET_TERMS:
      draft.loading = true;
      break;
    case TermsActionsTypes.GET_TERMS_SUCCESS:
      draft.allTerms = action.payload.allTerms;
      draft.filteredTerms = action.payload.filteredTerms;
      draft.isTermsLoaded = true;
      draft.loading = false;
      break;
    case TermsActionsTypes.GET_TERMS_FAILURE:
      draft.isTermsLoaded = true;
      draft.loading = false;
      break;
  }
}, initialState);

export default termsReducer;
