import { generateOrderLetterByIndex } from "SP/sp.helpers";

import { InfoPanelConfigurationRepository } from "./infoPanelConfiguration.repository";
import {
  CertificatePropNames,
  CertificateProps,
  DocumentPropNames,
  DocumentProps,
  IInfoPanelConfiguration,
  IInfoPanelConfigurationDTO,
  RolePropNames,
  RoleProps,
} from "./infoPanelConfiguration.types";

export class InfoPanelConfigurationService {
  private defaultValues: IInfoPanelConfiguration = {
    Id: null,
    certificates: Object.keys(CertificateProps).map((propName, index) => ({
      prop: propName as CertificatePropNames,
      order: generateOrderLetterByIndex(index),
      visible: true,
    })),
    documents: Object.keys(DocumentProps).map((propName, index) => ({
      prop: propName as DocumentPropNames,
      order: generateOrderLetterByIndex(index),
      visible: true,
    })),
    roles: Object.keys(RoleProps).map((propName, index) => ({
      prop: propName as RolePropNames,
      order: generateOrderLetterByIndex(index),
      visible: true,
    })),
  };

  private repository = new InfoPanelConfigurationRepository();
  public async getInfoPanelConfiguration(): Promise<IInfoPanelConfiguration> {
    const configurationItems = await this.repository.getInfoPanelConfiguration();
    if (configurationItems.length > 0) {
      return this.mapConfiguration(configurationItems[0]);
    }
    return this.defaultValues;
  }
  public async updateInfoPanelConfiguration(conf: IInfoPanelConfiguration) {
    const updateData = this.reversMapConfiguration(conf);
    if (conf.Id) {
      this.repository.updateInfoPanelConfiguration(updateData, conf.Id);
    } else {
      this.repository.addInfoPanelConfiguration(updateData);
    }
  }

  private reversMapConfiguration = (conf: IInfoPanelConfiguration): Partial<IInfoPanelConfigurationDTO> => {
    const updateData: Partial<IInfoPanelConfigurationDTO> = {
      certificates: JSON.stringify(conf.certificates),
      documents: JSON.stringify(conf.documents),
      roles: JSON.stringify(conf.roles),
    };
    return updateData;
  };

  private mapConfiguration(conf: IInfoPanelConfigurationDTO): IInfoPanelConfiguration {
    return {
      Id: conf.Id,
      certificates: conf.certificates ? JSON.parse(conf.certificates) : this.defaultValues.certificates,
      documents: conf.documents ? JSON.parse(conf.documents) : this.defaultValues.documents,
      roles: conf.roles ? JSON.parse(conf.roles) : this.defaultValues.roles,
    };
  }
}
