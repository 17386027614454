import { ISPTermObject } from "SP/SPTermStoreService";

export enum TermsActionsTypes {
  GET_TERMS = "[TERMS] GET TERMS REQUEST",
  GET_TERMS_SUCCESS = "[TERMS] GET TERMS SUCCESS",
  GET_TERMS_FAILURE = "[TERMS] GET TERMS FAILURE",
}

export interface IGetTermsRequestAction {
  type: TermsActionsTypes.GET_TERMS;
  termSetName: string;
}

export function getTermsRequest(termSetName: string): IGetTermsRequestAction {
  return {
    type: TermsActionsTypes.GET_TERMS,
    termSetName,
  };
}

type TermsPayload = {
  allTerms: ISPTermObject[];
  filteredTerms: ISPTermObject[];
};

export interface IGetTermsSuccessAction {
  type: TermsActionsTypes.GET_TERMS_SUCCESS;
  payload: TermsPayload;
}

export function getTermsSuccess(payload: TermsPayload): IGetTermsSuccessAction {
  return {
    type: TermsActionsTypes.GET_TERMS_SUCCESS,
    payload,
  };
}

export interface IGetTermsFailureAction {
  type: TermsActionsTypes.GET_TERMS_FAILURE;
  error: string;
}

export function getTermsFailure(error): IGetTermsFailureAction {
  return {
    type: TermsActionsTypes.GET_TERMS_FAILURE,
    error: error.message,
  };
}

export type ITermsActionsTypes = IGetTermsRequestAction | IGetTermsSuccessAction | IGetTermsFailureAction;
