import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";

import { StandardsIconsService } from "SP/standardsIcons/standardsIcons.service";
import {
  getIconsFailure,
  getIconsSuccess,
  IGetIconsRequestAction,
  StandardsIconsActionTypes,
} from "Store/actions/standards-icons.actions";

const iconService = new StandardsIconsService();

export function* getIcons(action: IGetIconsRequestAction) {
  try {
    const icons = yield call([iconService, "getAll"]);

    yield put(getIconsSuccess(icons));
  } catch {
    yield put(getIconsFailure());
  }
}

export function* watchGetIcons() {
  yield takeEvery(StandardsIconsActionTypes.GET_ICONS_REQUEST, getIcons);
}

export function* standardsIconsSagas(): Iterator<ForkEffect> {
  yield fork(watchGetIcons);
}
