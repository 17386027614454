import React, { useEffect, useLayoutEffect, useState } from "react";

export interface IContentOverflowProps {
  contentRef: React.MutableRefObject<any>;
  maxHeight: number;
}

export const useContentOverflow = ({ contentRef, maxHeight }: IContentOverflowProps) => {
  const [isOverflow, setOverflow] = useState(!!maxHeight);

  useLayoutEffect(() => {
    if (maxHeight) {
      setOverflow(contentRef.current.offsetHeight > maxHeight);
    }
  }, []);

  useEffect(() => {
    if (window.ResizeObserver) {
      const observer = new ResizeObserver((entries) => {
        const isOverflowUpdated = entries[0].target.offsetHeight > maxHeight;

        if (isOverflow !== isOverflowUpdated) {
          setOverflow(isOverflowUpdated);
        }
      });

      observer.observe(contentRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isOverflow]);

  return { isOverflow };
};
