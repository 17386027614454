import printJS from "print-js";
import React, { FC, useMemo, useState } from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification.svg";
import { ReactComponent as printIcon } from "@App/assets/print.svg";
import { ReactComponent as linkIcon } from "@epam/assets/icons/common/content-link-18.svg";
import { ReactComponent as downloadIcon } from "@epam/assets/icons/common/file-download-18.svg";
import { ReactComponent as moreIcon } from "@epam/assets/icons/common/navigation-more_vert-18.svg";
import { ReactComponent as infoIcon } from "@epam/assets/icons/common/table-info-fill-18.svg";
import { Dropdown, DropdownMenuBody, IconButton, Tooltip } from "@epam/loveship";
import { sp } from "@pnp/sp/presets/all";

import { DownloadDropdownWithAnalytics } from "Components/download-dropdown-with-analytics";
import { DropdownButtonWithAnalytics } from "Components/dropdown-button-with-analytics";
import { loaderIcon } from "Components/icons";
import { CopyText, documentDownloadTag } from "Helpers/constants";
import { getNotificationSubscribeParams, handleSubscribeToggle, SubscribeItemTypes } from "Helpers/subscribeHelper";
import { copyTextToClipboard, getDocumentViewUrl, parseDownloadLink } from "Helpers/utils";
import { useUsersActions, useUsersMethods } from "Hooks/useUsers";
import { IVoidCb } from "SP/helpers.types";
import { ISubscribeToItemToggleParams } from "Store/actions/users.actions";

interface IDocumentActionsDropdownProps {
  serverRelativeUrl: string;
  viewUrl: string;
  subscribeParams: ISubscribeToItemToggleParams;
  fileType?: string;
  downloadUrl?: string;
  friendlyUrl: string;
  onOpenInfoPanel?: IVoidCb;
}

export const DocumentActionsDropdown: FC<IDocumentActionsDropdownProps> = ({
  subscribeParams,
  fileType,
  serverRelativeUrl,
  viewUrl,
  downloadUrl,
  friendlyUrl,
  onOpenInfoPanel,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [printWindowLoading, setPrintWindowLoading] = useState(false);

  const { subscriptionsLoading } = useUsersActions();
  const { subscribeToItemToggle } = useUsersMethods();

  const handlePrint = async () => {
    setPrintWindowLoading(true);
    const blob = await sp.web.getFileByServerRelativeUrl(serverRelativeUrl).getBlob();

    printJS({
      printable: URL.createObjectURL(blob),
      onLoadingEnd: () => setPrintWindowLoading(false),
    });
  };

  const handleSubscribe = (cb: IVoidCb) => {
    subscribeToItemToggle({ ...subscribeParams, cb });
  };

  const handleCopyUrlCb = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleCopyLink = () => {
    if (friendlyUrl) {
      copyTextToClipboard(`${window.location.origin}/${friendlyUrl}`, handleCopyUrlCb);
      return;
    }

    let copyUrl = getDocumentViewUrl(viewUrl, friendlyUrl);
    if (copyUrl.startsWith("/roles/")) {
      copyUrl = `${window.location.origin}${viewUrl}`;
    }
    copyTextToClipboard(copyUrl, handleCopyUrlCb);
  };

  const fileLink = useMemo(() => parseDownloadLink(downloadUrl), [downloadUrl]);

  return (
    <Dropdown
      renderTarget={(props) => (
        <IconButton
          rawProps={{ "data-testid": "actions-menu-button" }}
          cx="dropdown__more-icon"
          icon={moreIcon}
          color="night600"
          {...props}
        />
      )}
      renderBody={() => (
        <DropdownMenuBody rawProps={{ "data-testid": "actions-menu-content" }} cx="dropdown" color="white">
          {onOpenInfoPanel && (
            <DropdownButtonWithAnalytics caption="Get Information" icon={infoIcon} onClick={onOpenInfoPanel} />
          )}
          {downloadUrl && (
            <DownloadDropdownWithAnalytics
              caption="Download"
              cx={documentDownloadTag}
              icon={downloadIcon}
              href={downloadUrl}
              fileLink={fileLink}
              target="_blank"
            />
          )}
          {fileType === "pdf" && (
            <DropdownButtonWithAnalytics
              caption="Print"
              icon={printWindowLoading ? loaderIcon : printIcon}
              onClick={handlePrint}
              isDisabled={printWindowLoading}
            />
          )}
          <DropdownButtonWithAnalytics
            caption={subscribeParams.isSubscribed ? "Unsubscribe" : "Subscribe"}
            icon={subscriptionsLoading ? loaderIcon : notificationIcon}
            onClick={handleSubscribeToggle(
              getNotificationSubscribeParams({
                isSubscribed: subscribeParams.isSubscribed,
                subscriptionsLoading,
                onSubscribe: handleSubscribe,
                type: subscribeParams.item as SubscribeItemTypes,
              }),
            )}
            isDisabled={subscriptionsLoading}
          />
          <Tooltip content={isCopied ? CopyText : ""} placement="bottom">
            <DropdownButtonWithAnalytics caption="Copy Link" icon={linkIcon} onClick={handleCopyLink} />
          </Tooltip>
        </DropdownMenuBody>
      )}
      closeOnMouseLeave="boundary"
      placement="bottom-start"
    />
  );
};
