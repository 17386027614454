import React, { Fragment } from "react";

import { ISelectedFilterValue, ISubarea, ITimePeriod } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData } from "./documents-filter.hooks";
import { IFilterKeys, IMinAndMaxDate } from "./documents-filter.interface";
import { DocumentsFilterDatePicker } from "./documents-filter-date-picker";
import { DocumentsFilterPickerInput } from "./documents-filter-picker-input";
import { DocumentsFilterSubscriptionsSwitcher } from "./documents-filter-subscriptions-switcher";

export interface IDocumentsGroupsFiltersProps {
  selectedFilters: Partial<Record<IFilterKeys, ISelectedFilterValue>>;
  groupIndex: number;
  resetToggleKey: React.MutableRefObject<boolean>;
}

export const DocumentsFilterGroupFilters: React.FC<IDocumentsGroupsFiltersProps> = ({
  selectedFilters,
  groupIndex,
  resetToggleKey,
}) => {
  const { isNotSubscriptionsPage, filtersLibrary, infoPanelFilters } = useFiltersData();
  return (
    <Fragment>
      {isNotSubscriptionsPage && (
        <DocumentsFilterSubscriptionsSwitcher
          groupIndex={groupIndex}
          key={`${filtersLibrary}_${resetToggleKey.current}`}
          defaultValue={selectedFilters && (selectedFilters.showSubscriptionsOnly as boolean)}
        />
      )}
      {infoPanelFilters.map(({ key, data, caption, component, excludeFilterInvolveIds }, i) => {
        let picker = (
          <DocumentsFilterDatePicker
            field={key}
            caption={caption}
            defaultData={selectedFilters && (selectedFilters[key] as ITimePeriod)}
            data={data as IMinAndMaxDate}
            groupIndex={groupIndex}
          />
        );

        if (component === "picker-input") {
          picker = (
            <DocumentsFilterPickerInput
              field={key}
              caption={caption}
              defaultData={selectedFilters && (selectedFilters[key] as ISubarea)}
              data={data as string[]}
              excludeFilterInvolveIds={excludeFilterInvolveIds}
              groupIndex={groupIndex}
            />
          );
        }

        return (
          <Fragment key={`${filtersLibrary}_${key}_${resetToggleKey.current}`}>
            {picker}
            {i !== infoPanelFilters.length - 1 && <div className={cn("picker-divider", ["divider"])} />}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
