import { LogError } from "Helpers/utils";

import { ExpiringCache } from "./StorageCache.types";

export const CACHE_ERROR_MESSAGE = "Cache error";

export abstract class StorageCache {
  public static Cache(key: string, value: string, target: Storage = sessionStorage) {
    target.setItem(key, value);
  }

  public static Restore(key: string, target: Storage = sessionStorage) {
    return target.getItem(key);
  }

  public static CacheExpiring(key: string, value: string, expiersInMinutes: number, target: Storage = sessionStorage) {
    const result: ExpiringCache = {
      value,
      expiry: new Date().getTime() + expiersInMinutes * 60000,
    };

    StorageCache.Cache(key, JSON.stringify(result), target);
  }

  public static RestoreExpiring(key: string, target: Storage = sessionStorage) {
    let result: string = null;
    const value = StorageCache.Restore(key, target);

    if (value) {
      try {
        const expiringCacheInfo: ExpiringCache = JSON.parse(value);

        if (new Date().getTime() <= expiringCacheInfo.expiry) {
          result = expiringCacheInfo.value;
        } else {
          StorageCache.Remove(key, target);
        }
      } catch (e) {
        LogError(CACHE_ERROR_MESSAGE, e);
      }
    }

    return result;
  }

  public static Remove(key: string, target: Storage = sessionStorage) {
    target.removeItem(key);
  }
}
