import { FilterInvolve } from "Components/documents-filter/documents-filter.interface";
import {
  FilterValueType,
  IRefinementFilter,
  IRefinementFiltersGroup,
  ISubarea,
  ITimePeriod,
} from "Store/reducers/filters.reducer";

type RefinementFilterType = ITimePeriod | ISubarea;

export class FqlService {
  private static helpCreateFqlFromSubarea(subarea: ISubarea) {
    const { items } = subarea;
    if (items.length === 1) return items[0];

    if (subarea.filterInvolve === FilterInvolve.includeAny || subarea.filterInvolve === FilterInvolve.exclude) {
      return `or(${items.join(",")})`;
    }

    return `and(${items.join(",")})`;
  }

  private static createFqlFromSubarea(subarea: ISubarea) {
    if (subarea.filterInvolve === FilterInvolve.exclude) {
      return `not(${this.helpCreateFqlFromSubarea(subarea)})`;
    }

    return this.helpCreateFqlFromSubarea(subarea);
  }

  private static createFqlFromMinMaxDate(minMaxDate: ITimePeriod) {
    return `range(${minMaxDate.fromDate || "min"}, ${minMaxDate.toDate || "max"})`;
  }

  private static createFqlFromFilterValues(filterValue: RefinementFilterType) {
    if (filterValue.type === FilterValueType.subarea) {
      return this.createFqlFromSubarea(filterValue);
    }

    if (filterValue.type === FilterValueType.timePeriod) {
      return this.createFqlFromMinMaxDate(filterValue);
    }
  }

  private static createFqlFromFilters(filters: IRefinementFilter) {
    const filtersFqls = [];

    for (const filterKey of Object.keys(filters)) {
      const filterFql = `${filterKey}:${this.createFqlFromFilterValues(filters[filterKey])}`;
      filtersFqls.push(filterFql);
    }

    if (filtersFqls.length === 0) return null;

    if (filtersFqls.length === 1) return filtersFqls[0];

    return `and(${filtersFqls.join(",")})`;
  }

  static createFqlFromFiltersGroups(filtersGroups: IRefinementFiltersGroup[]) {
    const groupsFqls = [];

    for (const group of filtersGroups) {
      const groupFql = this.createFqlFromFilters(group.filters);

      if (groupFql) {
        groupsFqls.push(groupFql);
      }
    }

    if (groupsFqls.length === 0) return null;

    if (groupsFqls.length === 1) return groupsFqls[0];

    return `or(${groupsFqls.join(",")})`;
  }
}
