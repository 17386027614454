import { createDateString } from "@App/libs/dayjs";

import { getDocumentViewUrl } from "Helpers/utils";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { CertificateField } from "SP/fields";

import {
  createHyperLink,
  ExportFileName,
  exportToExcel,
  formatExcelWorkBook,
  formatRowsAndColumns,
  IManuallyAlignedColumn,
  ITableLink,
} from "./helpers";

export interface IExportedCertificate {
  [CertificateField.EPAMDocumentID]: string;
  [CertificateField.Standard]: string;
  [CertificateField.Name]: ITableLink;
  [CertificateField.LocationCountry]: string;
  [CertificateField.LocationCity]: string;
  [CertificateField.ValidFrom]: string;
  [CertificateField.ValidUntil]: string;
  [CertificateField.IssueDate]: string;
  [CertificateField.CertificationBody]: string;
  [CertificateField.LegalEntities]: string;
  [CertificateField.ScopeLimitation]: string;
  [CertificateField.CertificateArtifactType]: string;
  [CertificateField.CertificateArea]: string;
  [CertificateField.RegistrationNumber]: string;
}

const manuallyAlignedColumns: { [column: string]: IManuallyAlignedColumn } = {
  [CertificateField.Standard]: { width: 25, wrapText: true },
  [CertificateField.LocationCountry]: { width: 20, wrapText: true },
  [CertificateField.LocationCity]: { width: 20, wrapText: true },
  [CertificateField.LegalEntities]: { width: 50, wrapText: true },
  [CertificateField.ScopeLimitation]: { width: 75, wrapText: true },
};

export function exportCertificatesToExcel(
  certificates: ICertificate[],
  fileName: string = ExportFileName.certificates,
): void {
  const items: IExportedCertificate[] = [];
  for (const item of certificates) {
    items.push({
      [CertificateField.EPAMDocumentID]: item.EPAMDocumentID,
      [CertificateField.Standard]: item.Standard?.join("; "),
      [CertificateField.Name]: createHyperLink(item.fileInfo.Name, getDocumentViewUrl(item.viewUrl, item?.FriendlyUrl)),
      [CertificateField.LocationCountry]: item.LocationCountry?.join("; "),
      [CertificateField.LocationCity]: item.LocationCity?.join(", "),
      [CertificateField.ValidFrom]: createDateString(item.ValidFrom),
      [CertificateField.ValidUntil]: createDateString(item.ValidUntil),
      [CertificateField.IssueDate]: createDateString(item.IssueDate),
      [CertificateField.CertificationBody]: item.CertificationBody,
      [CertificateField.LegalEntities]: item.LegalEntities,
      [CertificateField.ScopeLimitation]: item.ScopeLimitation,
      [CertificateField.CertificateArtifactType]: item.CertificateArtifactType,
      [CertificateField.CertificateArea]: item.CertificateArea,
      [CertificateField.RegistrationNumber]: item.RegistrationNumber,
    });
  }
  const { rows, columns } = formatRowsAndColumns(items);
  const workbook = formatExcelWorkBook(rows, columns, fileName, manuallyAlignedColumns);
  exportToExcel(workbook, `${fileName}.xlsx`);
}
