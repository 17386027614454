import React, { FC, useRef } from "react";

import { Checkbox, Spinner, VirtualList } from "@epam/loveship";
import { VirtualList as VirtualListType } from "@epam/uui-components";

import { RoleDocumentCard } from "Components/documents-card/documents-card-role-document-card";
import { useDocumentActions } from "Components/documents-table/documents-table.hooks";
import { IDocumentsTableProps, ISort } from "Components/documents-table/documents-table.interface";
import { ResultsNotFound } from "Components/results-not-found/results-not-found";
import { withNaming } from "Helpers/bemClassname";
import { getSubscribeParams, handleAllCheckboxLabel } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { useVirtualList } from "Hooks/useVirtualList";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { LongDocumentCard } from "./documents-card-long-document-card";
import { SortSelect } from "./documents-card-sort-select";

import "./documents-card.scss";

export const DocumentsCard: FC<IDocumentsTableProps> = ({
  isCertificates,
  isRolesRegistry,
  isOverWall,
  isDataLoaded,
  data,
  columns,
  sort,
  indeterminate,
  selectedRows,
  onSortChange,
  onCheckToggle,
  onToggleAll,
}) => {
  const cn = withNaming("documents-card");
  const virtualListRef = useRef<VirtualListType>(null);
  const { virtualState, visibleItems, setVirtualState, resetVirtualState } = useVirtualList<IAttachedFile>(data, 4);

  const { checkIsSubscribed } = useUsersData();
  const { onFileNameClick, handleOpenDocumentInfo } = useDocumentActions();

  const itemName = isRolesRegistry ? "role" : "document";

  function getCommonProps(card) {
    return {
      card,
      checked: selectedRows.includes(card.UniqueId),
      onCheckToggle: onCheckToggle(card.UniqueId),
      onOpenInfoPanel: handleOpenDocumentInfo(card),
      subscribeParams: getSubscribeParams({
        item: itemName,
        document: card,
        checkIsSubscribed,
      }),
    };
  }

  const visibleRows = visibleItems.map((card) =>
    isRolesRegistry ? (
      <RoleDocumentCard key={card.UniqueId} {...getCommonProps(card)} />
    ) : (
      <LongDocumentCard key={card.UniqueId} onFileNameClick={onFileNameClick(card)} {...getCommonProps(card)} />
    ),
  );

  const handleSort = (newSort: ISort) => {
    resetVirtualState();
    onSortChange(newSort);
    virtualListRef.current?.scrollBars?.scrollToTop();
  };

  return (
    <div className={cn("", { "over-wall": isOverWall, certificates: isCertificates })}>
      <div className={cn("head", ["relative flex justify-between"])}>
        <Checkbox
          cx={cn("checkbox", { all: true }, ["flex items-center checkbox-list"])}
          size="18"
          label={handleAllCheckboxLabel(selectedRows?.length, data?.length, itemName)}
          value={!!selectedRows.length}
          indeterminate={indeterminate}
          onValueChange={onToggleAll}
        />
        <SortSelect cn={cn} columns={columns} sort={sort} onSort={handleSort} />
      </div>
      {isDataLoaded && data.length > 0 && (
        <VirtualList
          ref={virtualListRef}
          cx={cn("virtual-list-body", ["flex flex-col"])}
          rows={visibleRows}
          value={virtualState}
          onValueChange={setVirtualState}
          rowsCount={data.length}
        />
      )}
      {isDataLoaded && data.length === 0 && <ResultsNotFound isSearchQueryPresent={true} />}
      {!isDataLoaded && (
        <div className={cn("virtual-list-body")}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
