import React, { Fragment, useMemo } from "react";

import { useSearch } from "Hooks/useSearch";
import { ISelectedFilterValue, ISubarea, ITimePeriod } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData } from "./documents-filter.hooks";
import { IFilterKeys, IMinAndMaxDate } from "./documents-filter.interface";
import { DocumentsFilterDatePicker } from "./documents-filter-date-picker";
import { DocumentsRefinerFilterPickerInput } from "./documents-refiner-filter-picker-input";
import { getPanelFiltersToRender } from "./filters/filters.helpers";

export interface IDocumentsRefinementFiltersProps {
  selectedFilters: Partial<Record<IFilterKeys, ISelectedFilterValue>>;
  groupIndex: number;
  resetToggleKey: React.MutableRefObject<boolean>;
}

export const DocumentsRefinementFilters: React.FC<IDocumentsRefinementFiltersProps> = ({
  selectedFilters,
  groupIndex,
  resetToggleKey,
}) => {
  const { filtersLibrary, refiners, infoPanelFilters, filterGroupResults } = useFiltersData();
  const { getRefinedGlobalSearchResults } = useSearch();

  const panelFiltersToRender = useMemo(() => {
    if (filterGroupResults.length <= groupIndex) {
      return [];
    }

    return getPanelFiltersToRender(refiners, infoPanelFilters, filterGroupResults, groupIndex);
  }, [refiners, infoPanelFilters, filterGroupResults, groupIndex]);

  return (
    <Fragment>
      {panelFiltersToRender.map(({ key, caption, component, data, excludeFilterInvolveIds }, i) => {
        let picker = null;

        if (component === "date-picker") {
          picker = (
            <DocumentsFilterDatePicker
              field={key}
              caption={caption}
              defaultData={selectedFilters && (selectedFilters[key] as ITimePeriod)}
              data={data as IMinAndMaxDate}
              groupIndex={groupIndex}
              onFilterChanged={getRefinedGlobalSearchResults}
            />
          );
        } else {
          picker = (
            <DocumentsRefinerFilterPickerInput
              field={key}
              caption={caption}
              defaultData={selectedFilters && (selectedFilters[key] as ISubarea)}
              data={data as string[]}
              excludeFilterInvolveIds={excludeFilterInvolveIds}
              groupIndex={groupIndex}
            />
          );
        }

        return (
          <Fragment key={`${filtersLibrary}_${key}_${resetToggleKey.current}`}>
            {picker}
            {i !== panelFiltersToRender.length - 1 && <div className={cn("picker-divider", ["divider"])} />}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
