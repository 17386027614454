import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

import { StandardsIconsRepository } from "./standardsIcons.repository";
import { IStandardIconDTO } from "./standardsIcons.types";

export class StandardsIconsService {
  private repository = new StandardsIconsRepository();

  private mapStandardIcon(iconDto: IStandardIconDTO): IStandardIcon {
    return {
      standard: iconDto.Title,
      icon: iconDto.IconLink.Url,
    };
  }

  public async getAll(): Promise<IStandardIcon[]> {
    const iconsDto = await this.repository.getAllIcons();

    return iconsDto.map(this.mapStandardIcon);
  }
}
