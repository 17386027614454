import { ClientNotFoundError } from "@App/errors/ClientNotFoundError";
import { IItemUpdateResult } from "@pnp/sp/presets/all";

import { DefaultRegistrationNumber } from "Helpers/constants";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { formatDownloadUrl, formatFileDirRef, formatLocationCity, formatViewUrl } from "SP/sp.helpers";
import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

import { CertificatesRepository } from "./certificates.repository";
import { ICertificate, ICertificateDTO } from "./certificates.types";

export class CertificatesService {
  repository = new CertificatesRepository();
  public mapCertificates = (doc: ICertificateDTO, standardIcons: IStandardIcon[]): ICertificate => {
    return {
      Author: { email: doc.Author.EMail, id: doc.Author.Id, name: doc.Author.Title },
      CertificateArtifactType: doc.CertificateArtifactType,
      CertificationBody: doc.CertificationBody,
      Created: new Date(doc.Created),
      EPAMDocumentID: doc.EPAMDocumentID_x0020_,
      Editor: { email: doc.Editor.EMail, id: doc.Editor.Id, name: doc.Editor.Title },
      Id: doc.Id,
      IssueDate: doc.issueDate && new Date(doc.issueDate),
      LegalEntities: doc.LegalEntities,
      LocationCity: doc.LocationCity && formatLocationCity(doc.LocationCity),
      LocationCountry: doc.LocationCountry,
      Modified: new Date(doc.Modified),
      Responsible: doc.Responsible,
      ScopeLimitation: doc.ScopeLimitation,
      Standard: doc.Standart,
      Title: doc.Title,
      ValidFrom: doc.ValidFrom && new Date(doc.ValidFrom),
      ValidUntil: doc.ValidUntil && new Date(doc.ValidUntil),
      WorkStorage: doc.WorkStorage,
      CertificateArea: doc.CertificateArea,
      viewUrl: formatViewUrl(doc),
      downloadUrl: formatDownloadUrl(doc),
      fileInfo: {
        Length: doc.File.Length,
        Name: doc.File.Name,
        ServerRelativeUrl: doc.File.ServerRelativeUrl,
        FileType: doc.File_x0020_Type,
        UniqueId: doc.File.UniqueId,
      },
      iconUrl: this.getCertificateIconUrl(doc, standardIcons),
      sourceLibrary: LibraryName.certificates,
      UniqueId: doc.File.UniqueId,
      publishedVersion: doc.publishedVersion,
      FileDirRef: formatFileDirRef(doc),
      FriendlyUrl: doc.FriendlyURL,
      RegistrationNumber: doc.RegistrationNumber ?? DefaultRegistrationNumber,
    };
  };
  private getCertificateIconUrl(doc: ICertificateDTO, icons: IStandardIcon[]): string {
    let icon = "GeneralCertificate.PNG";
    const certificationKey = doc.CertificateArtifactType;
    if (certificationKey === "Certification Summary") {
      icon = "IC_CERTSUMMARY.PNG";
    } else if (doc.Standart && doc.Standart.length > 0 && icons.some((x) => x.standard === doc.Standart[0])) {
      icon = icons.find((x) => x.standard === doc.Standart[0]).icon;
      return icon;
    }
    return `${process.env.REACT_APP_BASE_URL}/SiteCollectionImages/Certificate Icons/${icon}`;
  }

  public isValidCertificate(cert: ICertificateDTO): boolean {
    return true;
  }

  public async getAll(standardIcons: IStandardIcon[]): Promise<ICertificate[]> {
    const allDocs = await this.repository.getAll();
    const mappedDocs = allDocs.map((doc) => this.mapCertificates(doc, standardIcons));
    return mappedDocs;
  }
  public async getById(id: number, standardIcons: IStandardIcon[]): Promise<ICertificate> {
    const doc = await this.repository.getById(id);
    return this.mapCertificates(doc, standardIcons);
  }

  public async getByServerRelativeUrl(url: string, standardIcons: IStandardIcon[]): Promise<ICertificate> {
    const doc = await this.repository.getByServerRelativeUrl(url);

    return this.mapCertificates(doc, standardIcons);
  }

  public async getByFriendlyUrl(friendlyURL: string, standardIcons: IStandardIcon[]): Promise<ICertificate> {
    const docs = await this.repository.getByFriendlyUrl(friendlyURL);

    if (docs.length == 0) {
      throw new ClientNotFoundError(`Certificate ${friendlyURL} not found`);
    }

    return this.mapCertificates(docs[0], standardIcons);
  }
  public async updateDocument(id: number, data: Partial<ICertificateDTO>): Promise<IItemUpdateResult> {
    return await this.repository.updateDocument(id, data);
  }
}
