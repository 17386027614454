import * as React from "react";

import { interactions, msalInstance } from "@App/libs/msal";

import { Loader } from "Components/loader";
import { initPosthog } from "Helpers/posthogAnalytics";
import { logStringifiedError } from "Helpers/utils";
import { PnPFetchClient, scopes } from "Libs/pnp";
import { configurePnP } from "SP/configure";

import { configureElasticAPM } from "../elasticAPM";

interface IState {
  hasError: boolean;
  errorMessage: string;
  authenticated: boolean;
}

export function withAuth<TOriginalProps>(
  WrappedComponent: React.FC<TOriginalProps>,
): React.ComponentClass<TOriginalProps> {
  return class Auth extends React.Component<TOriginalProps, IState> {
    private callbackId: string;

    constructor(props: TOriginalProps) {
      super(props);

      this.state = {
        hasError: false,
        errorMessage: null,
        authenticated: false,
      };
    }

    componentDidMount() {
      this.callbackId = msalInstance.addEventCallback((message) => {
        interactions.updateState(message, interactions.MsalProviderActionType.EVENT);
      });

      msalInstance.initialize().then(() => {
        const originalUri = msalInstance.getTargetOriginUriAfterLogin();

        msalInstance.redirectUri = originalUri;

        this.handleLogin();
      });
    }

    componentWillUnmount(): void {
      if (this.callbackId) {
        msalInstance.removeEventCallback(this.callbackId);
      }
    }

    handleLogin() {
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
              return msalInstance.loginRedirect();
            } else {
              return msalInstance
                .acquireTokenSilent({
                  scopes,
                  account: accounts[0],
                })
                .then(() => {
                  this.handleAuthenticated();
                })
                .catch((e) => {
                  logStringifiedError("Authentication error: Error when executing acquireTokenSilent. ", e);
                  PnPFetchClient.clearMsalStorageItems();
                  return msalInstance.loginRedirect();
                });
            }
          } else {
            this.handleAuthenticated();
          }
        })
        .catch((error) => {
          logStringifiedError("Error when executing handleRedirectPromise. ", error);
          this.handleError(error);
        })
        .finally(() => {
          /*
           * If handleRedirectPromise returns a cached promise the necessary events may not be fired
           * This is a fallback to prevent inProgress from getting stuck in 'startup'
           */
          interactions.updateState(null, interactions.MsalProviderActionType.UNBLOCK_INPROGRESS);
        });
    }

    handleAuthenticated() {
      configureElasticAPM();
      configurePnP();
      initPosthog();

      this.setState({
        authenticated: true,
      });
    }

    handleError(error) {
      this.setState({
        hasError: true,
        errorMessage: error.statusText ? error.statusText.toString() : "Failed to authenticate the current user.",
      });
    }

    render(): JSX.Element {
      if (this.state.authenticated) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.state.hasError) {
        return (
          <div className="app">
            <div className="app__container flex items-center justify-center">
              <code>{this.state.errorMessage}</code>
            </div>
          </div>
        );
      }

      return <Loader />;
    }
  };
}
