import dayjs from "dayjs";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { FilterInvolve } from "Components/documents-filter/documents-filter.interface";
import { getFieldValues, getFilterPanelKeys } from "Components/documents-filter/filters/filters.helpers";
import { ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { FilterType, isNotEmpty, isQueryWithinText } from "Helpers/utils";
import { ICheckIsSubscribed } from "Hooks/useUsers";
import { getDateWithoutTime } from "Libs/dayjs";
import { IHyperLinkField } from "SP/helpers.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import {
  FilterValueType,
  IFiltersGroup,
  ISelectedFilter,
  ISelectedFilterValue,
  ISubarea,
  ITimePeriod,
} from "Store/reducers/filters.reducer";

function handleToolbarFilters(listItem: IAttachedFile, toolbarFilterValue: ISelectedFilterValue) {
  return (toolbarField: string) => {
    let sortingType: FilterType;

    if (listItem.sourceLibrary === LibraryName.roles) {
      sortingType = "equal";
    }

    const fieldValue = _get(listItem, toolbarField, "");

    if (Array.isArray(fieldValue)) {
      return (fieldValue as IHyperLinkField[]).some((item) =>
        isQueryWithinText(item.Description, toolbarFilterValue as string, sortingType),
      );
    }

    return isQueryWithinText(fieldValue, toolbarFilterValue as string, sortingType);
  };
}

const fieldsWithChild = ["OrganizationalUnit", "Regulations"];

function checkSubareaFilter(listItem: IAttachedFile, subarea: ISubarea, filterPanelKey: string) {
  const { items, filterInvolve } = subarea;
  const childKey = fieldsWithChild.includes(filterPanelKey) ? "Description" : undefined;
  const listItemValues = getFieldValues(filterPanelKey as keyof IAttachedFile, listItem, childKey);

  if (filterInvolve === FilterInvolve.includeExact) {
    return items.every((filterValue) => listItemValues.includes(filterValue));
  } else if (filterInvolve === FilterInvolve.exclude) {
    return items.every((filterValue) => !listItemValues.includes(filterValue));
  }

  return items.some((filterValue) => filterInclude(listItemValues, filterValue));
}

function filterInclude(values: any[], filterValue: any) {
  if ((filterValue === "" && values.includes("")) || values.includes(null)) {
    return true;
  }

  return values.includes(filterValue);
}

function checkTimePeriodFilter(listItem: IAttachedFile, timePeriod: ITimePeriod, filterPanelKey: string) {
  const { fromDate, toDate } = timePeriod;
  const fieldValue = listItem[filterPanelKey];

  if (!fieldValue) {
    return false;
  }

  const listItemDate = getDateWithoutTime(fieldValue);

  if (fromDate && !toDate) {
    return listItemDate >= dayjs(fromDate).toDate();
  } else if (!fromDate && toDate) {
    return listItemDate <= dayjs(toDate).toDate();
  }

  return listItemDate >= dayjs(fromDate).toDate() && listItemDate <= dayjs(toDate).toDate();
}

export function checkFilter(
  listItem: IAttachedFile,
  selectedFilters: ISelectedFilter,
  checkIsSubscribed?: ICheckIsSubscribed,
) {
  let isValid = true;

  const showFilterFields = ["fileInfo.ServerRelativeUrl", "CertificateArtifactType", "Group"];
  const showFilter = _get(selectedFilters, ToolbarKeys.show, "");
  const showSubscriptionsOnly = _get(selectedFilters, "showSubscriptionsOnly", false);
  const filterPanelKeys = getFilterPanelKeys(selectedFilters).filter((k) => k !== "showSubscriptionsOnly");

  if (showFilter) {
    isValid = showFilterFields.some(handleToolbarFilters(listItem, showFilter)) && isValid;
  }

  if (!isValid) return false;

  if (checkIsSubscribed && showSubscriptionsOnly) {
    isValid = checkIsSubscribed(listItem.sourceLibrary, listItem.Id);
  }

  if (!isValid) return false;

  if (!selectedFilters || _isEmpty(selectedFilters)) return false;

  for (const filterPanelKey of filterPanelKeys) {
    const selectedFilter = selectedFilters[filterPanelKey] as ISubarea | ITimePeriod;

    if (!selectedFilter) continue;

    if (selectedFilter.type === FilterValueType.subarea && isNotEmpty(selectedFilter.items)) {
      isValid = checkSubareaFilter(listItem, selectedFilter, filterPanelKey) && isValid;
    } else if (
      selectedFilter.type === FilterValueType.timePeriod &&
      (selectedFilter.fromDate || selectedFilter.toDate)
    ) {
      isValid = checkTimePeriodFilter(listItem, selectedFilter, filterPanelKey) && isValid;
    }
  }

  return isValid;
}

export function filterDataList(filtersGroups: IFiltersGroup[], checkIsSubscribed?: ICheckIsSubscribed) {
  return (listItem: IAttachedFile) => {
    return filtersGroups.some(
      (selectedGroup) =>
        selectedGroup.enabled &&
        (_isEmpty(selectedGroup.filters) || checkFilter(listItem, selectedGroup.filters, checkIsSubscribed)),
    );
  };
}
