import React from "react";

import { IViewThumbnailProps, withDocumentThumbnail } from "@App/hoc/withDocumentThumbnail";

import { Tooltip as CustomTooltip } from "Components/tooltip";

export interface IThumbnailImageWithTooltipProps {
  isCertificate: boolean;
}

export const ThumbnailImageWithTooltip = withDocumentThumbnail<IThumbnailImageWithTooltipProps>(
  ({ isCertificate, thumbnailUrl }: IThumbnailImageWithTooltipProps & IViewThumbnailProps) => (
    <CustomTooltip
      cx="certificate-icon-tooltip"
      trigger="hover"
      placement="right"
      content={() => <img src={thumbnailUrl} alt="document icon" />}
    >
      <img
        src={thumbnailUrl}
        alt="preview"
        width={isCertificate ? "120" : "134"}
        height={isCertificate ? "102" : "183"}
      />
    </CustomTooltip>
  ),
);
