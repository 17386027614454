import React, { FC } from "react";

import { VirtualList } from "@epam/loveship";

import { CardWrapper } from "Components/card-wrapper";
import { ISubscriptionItem, ISubscriptionsListProps } from "Components/subscriptions/subscriptions.interfaces";
import { SubscriptionsCard } from "Components/subscriptions/subscriptions-card";
import { useVirtualList } from "Hooks/useVirtualList";

export const SubscriptionsList: FC<ISubscriptionsListProps> = ({ cn, items, selectedRows, onCheckRowCallback }) => {
  const { virtualState, visibleItems, setVirtualState } = useVirtualList<ISubscriptionItem>(items, 8);

  const visibleRows = visibleItems.map((item, index) => (
    <CardWrapper
      key={item.UniqueId ?? index}
      isCheck={selectedRows.includes(item.UniqueId)}
      onCheck={onCheckRowCallback(item.UniqueId)}
      row
    >
      <SubscriptionsCard cn={cn} item={item} />
    </CardWrapper>
  ));

  return (
    <VirtualList
      cx={cn("virtual-list-body")}
      rawProps={{ "data-testid": "virtual-list-body" }}
      rows={visibleRows}
      value={virtualState}
      onValueChange={setVirtualState}
      rowsCount={items?.length}
    />
  );
};
