import { requestWithAuth } from "Libs/graph";
import { fetchClientFactory } from "Libs/msal";

export interface ReportDTO {
  id: string;
  embedUrl: string;
  datasetId: string;
}

export type EmbeddedReport = ReportDTO & {
  accessToken: string;
};

export const resource = "https://analysis.windows.net/powerbi/api";

export class PowerBIService {
  public static async getEmbeddedReport(reportId: string): Promise<EmbeddedReport> {
    const report: ReportDTO = await requestWithAuth({
      url: `https://api.powerbi.com/v1.0/myorg/reports/${reportId}`,
      resource,
    });

    const accessToken = await PowerBIService.getAadToken();

    return { ...report, accessToken };
  }

  public static async getAadToken(): Promise<string> {
    const accessToken = await fetchClientFactory.getToken(resource);
    return accessToken;
  }
}
