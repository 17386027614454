import React, { FC, Fragment } from "react";

import { IPathKeys } from "Helpers/sitePageUpdater";
import { isLastItem } from "Helpers/utils";
import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import { useSitePagesActions } from "Hooks/useSitePages";
import { IWebPart } from "SP/sitePages/sitePages.types";

import { AddWebPartButton } from "./web-parts-add-web-part";
import { ISelectDetailProps, WebPart } from "./web-parts-web-part";

export type ISectionDataColumn = "webParts" | "left" | "center" | "right";

export interface IWebPartsProps {
  webParts: IWebPart[];
  sectionIndex: number;
  column: ISectionDataColumn;
}

export interface IWebPartItemProps {
  webPart: IWebPart;
  pathKeys: IPathKeys;
  selectDetailProps: ISelectDetailProps;
}

export const WebParts: FC<IWebPartsProps> = ({ webParts, sectionIndex, column }) => {
  const { isSitePageInEditMode } = useSitePagesActions();

  const { pathname: sitePageUrl } = useFriendlyURLPathname();

  const commonProps = {
    sitePageUrl,
    sectionIndex,
    column,
  };

  if (!webParts?.length && isSitePageInEditMode) {
    return <AddWebPartButton {...commonProps} position="prev" webPartIndex={0} />;
  }

  return (
    <>
      {webParts.map((webPart, webPartIndex) => (
        <Fragment key={`${sitePageUrl}_${webPartIndex}`}>
          {isSitePageInEditMode && <AddWebPartButton {...commonProps} position="prev" webPartIndex={webPartIndex} />}
          <WebPart
            webPart={webPart}
            selectDetailProps={{ sectionIndex, webPartIndex, column }}
            pathKeys={[sitePageUrl, "pageInfo", "sections", sectionIndex, "data", column, webPartIndex]}
          />
          {isSitePageInEditMode && isLastItem(webParts, webPartIndex) && (
            <AddWebPartButton {...commonProps} position="next" webPartIndex={webPartIndex} />
          )}
        </Fragment>
      ))}
    </>
  );
};
