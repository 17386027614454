import React, { FC, useMemo } from "react";

import { Checkbox, FlexRow, FlexSpacer, Text } from "@epam/loveship";

import { DocumentsFilter } from "Components/documents-filter";
import { renderPickerInput } from "Components/documents-toolbar/documents-toolbar.helpers";
import { LinkButtonWithAnalytics } from "Components/link-button-with-analytics";
import { ISearchToolbarProps } from "Components/search/search.interfaces";
import { ActionIcons } from "Helpers/constants";
import { useSearch } from "Hooks/useSearch";

import { getLimitsOptions, renderLimitPickerToggler } from "./search.helpers";

export const SearchToolbar: FC<ISearchToolbarProps> = ({
  cn,
  selectedAll,
  hasSelected,
  checkboxLabel,
  indeterminate,
  exportCallback,
  onToggleAll,
}) => {
  const { rowsLimit, setRowsLimit, getRefinedGlobalSearchResults } = useSearch();

  const limitsDataSource = useMemo(() => getLimitsOptions(), []);

  const handleRowsChanged = (value: number) => {
    setRowsLimit(value);
    getRefinedGlobalSearchResults();
  };

  return (
    <FlexRow cx={cn("toolbar")}>
      <Checkbox
        cx={cn("toolbar-checkbox")}
        value={hasSelected || selectedAll}
        onValueChange={onToggleAll}
        indeterminate={indeterminate}
      />
      <Text cx={cn("toolbar-text")}>{checkboxLabel}</Text>
      <FlexSpacer />
      {renderPickerInput({
        dataSource: limitsDataSource,
        value: rowsLimit.toString(),
        onValueChange: handleRowsChanged,
        renderToggler: (props) => renderLimitPickerToggler(props, cn, rowsLimit.toString()),
        minBodyWidth: 100,
      })}
      <LinkButtonWithAnalytics
        cx="toolbar-button"
        iconPosition="left"
        size="none"
        fontSize="14"
        lineHeight="18"
        color="white"
        caption={"Export"}
        icon={ActionIcons.export}
        onClick={exportCallback}
      />
      <div className="toolbar-divider"></div>
      <DocumentsFilter />
    </FlexRow>
  );
};
