import React, { FC, useContext } from "react";

import { DocumentsViewContext } from "@App/context/DocumentsViewContext";
import { createDateString } from "@App/libs/dayjs";
import { Checkbox, Tooltip } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import {
  renderLabelLink,
  renderLabelText,
  renderLink,
  renderSubscribed,
  renderText,
} from "Components/documents-table/documents-table.helpers";
import { IRowProps } from "Components/documents-table/documents-table.interface";
import { FoundContent } from "Components/found-content";
import { SharePointImage } from "Components/sharepoint-image";
import { ThumbnailImageWithTooltip } from "Components/thumbnail-image-with-tooltip";
import { Tooltip as CustomTooltip } from "Components/tooltip";
import { withNaming } from "Helpers/bemClassname";
import { FileIcons } from "Helpers/constants";
import highlight from "Helpers/highlight";
import { getDocumentDownloadUrl } from "Helpers/utils";
import { CertificateField, DocumentField } from "SP/fields";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";

interface LongDocumentCardProps extends IRowProps {
  card: IAttachedFile;
  checked: boolean;
  onCheckToggle: (v: boolean) => void;
}

const cn = withNaming("documents-card");

function isTallCard(sourceLibrary: LibraryName, isSearchValueExist: boolean): boolean {
  return sourceLibrary === LibraryName.templates && isSearchValueExist;
}

export const LongDocumentCard: FC<LongDocumentCardProps> = ({
  card,
  checked,
  subscribeParams,
  onCheckToggle,
  onFileNameClick,
  onOpenInfoPanel,
}) => {
  const { searchValue } = useContext(DocumentsViewContext);
  const isSearchValueExist = !!searchValue;
  const isCertificate = card.sourceLibrary === LibraryName.certificates;

  const renderRegulationTemplateFirstRow = () => (
    <div className={cn("details-row", ["flex flex-col"])}>
      {renderLink({
        cx: "self-start hover-underline",
        font: "sans-semibold",
        fontSize: "18",
        lineHeight: "24",
        caption: highlight.peek(searchValue, card.Title),
        href: card.PublishedPlace?.Url,
        target: "_blank",
      })}
      <div className="flex">
        {renderLink({
          cx: cn("file-icon", ["hover-underline"]),
          fontSize: "12",
          icon: FileIcons[card.fileInfo.FileType] || FileIcons.default,
          caption: highlight.peek(searchValue, card.fileInfo.Name),
          onClick: onFileNameClick,
        })}
        <span className="dot" style={{ margin: "0 6px" }} />
        {renderText(card.SubjectDoc, { fontSize: "12", color: "night600" })}
        {subscribeParams.isSubscribed && renderSubscribed(cn, card.sourceLibrary)}
      </div>
    </div>
  );

  const renderRegulationTemplateSecondRow = () => (
    <div className={cn("details-row", ["flex"])}>
      {renderLabelText({ cn, label: "Document ID", caption: card?.EPAMDocumentID, modifier: "max-content" })}
      {renderLabelLink({
        cn,
        label: DocumentField.OrganizationalUnit,
        caption: card?.OrganizationalUnit?.Description,
        modifier: "max-content",
        linkProps: {
          caption: card?.OrganizationalUnit?.Description,
          href: card?.OrganizationalUnit?.Url,
          target: "_blank",
        },
      })}
      {renderLabelText({
        cn,
        label: DocumentField.ApprovalDate,
        modifier: "max-content",
        caption: createDateString(card?.ApprovalDate),
      })}
      {renderLabelText({
        cn,
        label: DocumentField.LocationCountry,
        modifier: "max-content",
        caption: card?.LocationCountry?.join(", "),
      })}
      {renderLabelText({ cn, label: "Document Area", caption: card?.DocumentArea?.join(", "), cutCx: "one-line" })}
    </div>
  );

  const renderCertificateFirstRow = () => (
    <div className={cn("details-row", ["flex items-center"])}>
      <CustomTooltip
        cx="certificate-icon-tooltip"
        trigger="hover"
        placement="bottom"
        content={() => <SharePointImage src={card?.iconUrl} alt="certificate icon" width="96" height="96" />}
      >
        <div>
          <SharePointImage
            className="certificate-icon"
            src={card?.iconUrl}
            alt="certificate icon"
            width="36"
            height="36"
          />
        </div>
      </CustomTooltip>
      <div className="certificate-info">
        <Tooltip content={card.Title} placement="bottom">
          {renderLink({
            cx: "file-title hover-underline",
            caption: highlight.peek(searchValue, card.Title),
            fontSize: "18",
            lineHeight: "24",
            onClick: onFileNameClick,
          })}
        </Tooltip>
        <div className="flex">
          {renderText(card.CertificateArtifactType, {
            cx: "one-line",
            font: "sans",
            fontSize: "12",
            color: "night600",
          })}
          {subscribeParams.isSubscribed && renderSubscribed(cn, card.sourceLibrary)}
        </div>
      </div>
    </div>
  );

  const renderCertificateSecondRow = () => (
    <div className={cn("details-row", ["flex"])}>
      {renderLabelText({ cn, label: CertificateField.CertificationBody, caption: card?.CertificationBody })}
      {renderLabelText({
        cn,
        label: CertificateField.Standard,
        caption: card?.Standard?.join(", "),
        cutCx: "one-line",
      })}
      {renderLabelText({ cn, label: CertificateField.LocationCountry, caption: card?.LocationCountry?.join(", ") })}
      {renderLabelText({
        cn,
        label: CertificateField.LocationCity,
        caption: card?.LocationCity?.join(", "),
        cutCx: "one-line",
      })}
      {renderLabelText({ cn, label: CertificateField.IssueDate, caption: createDateString(card?.IssueDate) })}
      {renderLabelText({ cn, label: CertificateField.ValidFrom, caption: createDateString(card?.ValidFrom) })}
      {renderLabelText({ cn, label: CertificateField.ValidUntil, caption: createDateString(card?.ValidUntil) })}
    </div>
  );

  return (
    <div
      className={cn(
        "row",
        {
          isCertificate,
          isSelected: checked,
          isTallCard: isTallCard(card.sourceLibrary, isSearchValueExist),
        },
        ["relative flex items-center"],
      )}
      data-testid="card"
    >
      <Checkbox
        cx={cn("checkbox", ["checkbox-list"])}
        size="18"
        value={checked}
        rawProps={{ "data-testid": "card-checkbox" }}
        onValueChange={onCheckToggle}
      />
      <div className={cn("content", ["flex shadow"])}>
        <div className={cn("preview", ["flex items-center"])}>
          <ThumbnailImageWithTooltip isCertificate={isCertificate} documentUrl={card.fileInfo.ServerRelativeUrl} />
        </div>
        <div className={cn("details", ["relative flex flex-col"])}>
          {isCertificate ? renderCertificateFirstRow() : renderRegulationTemplateFirstRow()}
          {isCertificate ? renderCertificateSecondRow() : renderRegulationTemplateSecondRow()}
          {card.BriefDescription && (
            <div className={cn("details-row")}>
              {renderLabelText({
                cn,
                label: "Description",
                caption: highlight.peek(searchValue, card.BriefDescription) as string,
                cutCx: "two-lines",
              })}
            </div>
          )}
          {isSearchValueExist && (
            <div className={cn("details-row")}>
              <FoundContent isCard hitHighlightedAll={card.HitHighlightedAll} />
            </div>
          )}
          <DocumentActionsDropdown
            fileType={card.fileInfo.FileType}
            serverRelativeUrl={card.fileInfo.ServerRelativeUrl}
            viewUrl={card.viewUrl}
            downloadUrl={getDocumentDownloadUrl(card.viewUrl, card.FriendlyUrl)}
            friendlyUrl={card.FriendlyUrl}
            subscribeParams={subscribeParams}
            onOpenInfoPanel={onOpenInfoPanel}
          />
        </div>
      </div>
    </div>
  );
};
