import React, { FC } from "react";

import { AddTooltipButton } from "Components/add-tooltip-button";
import { LayoutSelect } from "Components/layout-select";
import { ITooltipContentProps } from "Components/tooltip/tooltip";
import { SitePageUpdaterKeys } from "Helpers/sitePageUpdater";
import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import { useSitePagesActions, useSitePagesMethods } from "Hooks/useSitePages";
import { DetailTypes } from "Store/reducers/site-pages.reducer";

import "./sections-add-section.scss";

export type ISectionPosition = "prev" | "next";

interface IAddSectionButtonProps {
  position: ISectionPosition;
  sectionIndex: number;
  hideBefore?: boolean;
}

type IAddSectionContentProps = IAddSectionButtonProps & ITooltipContentProps;

const layoutData = {
  1: { webParts: [] },
  2: { left: [], right: [] },
  3: { left: [], center: [], right: [] },
};

const AddSectionContent: FC<IAddSectionContentProps> = ({ onTooltipClose, position, sectionIndex }) => {
  const { pathname: sitePageUrl } = useFriendlyURLPathname();
  const { updateSitePage } = useSitePagesMethods();
  const { selectSitePageDetail } = useSitePagesActions();

  const handleLayoutChange = (layout: string, columnsCount: number) => (e) => {
    const newSectionIndex = position === "next" ? sectionIndex + 1 : sectionIndex;

    const pathKeys = [sitePageUrl, "pageInfo", "sections", newSectionIndex];
    updateSitePage(pathKeys, {
      updaterKey: SitePageUpdaterKeys.ADD_SECTION,
      type: layout,
      data: layoutData[columnsCount],
    });
    selectSitePageDetail({ type: DetailTypes.section, sectionIndex: newSectionIndex });
    onTooltipClose(e);
  };

  return <LayoutSelect showTitle className="add-section-content" onChange={handleLayoutChange} selectedLayout={null} />;
};

export const AddSectionButton: FC<IAddSectionButtonProps> = ({ position, sectionIndex, hideBefore }) => {
  return (
    <AddTooltipButton
      caption="Add Section"
      hideBefore={hideBefore}
      content={(props) => <AddSectionContent {...props} position={position} sectionIndex={sectionIndex} />}
    />
  );
};
