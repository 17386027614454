import React from "react";

import { SeoHead } from "Components/seo-head";

export const withSeoHead =
  (WrappedComponent: React.FC, pageName: string): React.FC =>
  (props) => {
    return (
      <>
        <SeoHead page={pageName} />
        <WrappedComponent {...props} />
      </>
    );
  };
