import { IPageInfo, LibraryName, SectionTypes, WebPartTypes } from "./sitePages/sitePages.types";

const fileInfoSelect = [
  "File/Name",
  "File/Length",
  "File/ServerRelativeUrl",
  "File/UniqueId",
  "ContentType/Name",
  "  FileDirRef",
];
const mainExpandArray = ["Author", "Editor", "File", "ContentType"];
const regulationsSelectArray = [
  "Id",
  "Title",
  "Created",
  "Author/Id",
  "Author/Title",
  "Author/EMail",
  "Editor/Id",
  "Editor/Title",
  "Editor/EMail",
  "Modified",
  "Editor",
  "EPAMDocumentID_x0020_",
  "LastApproverName",
  "LastApprovalDate",
  "Document_x0020_Approver_x0020__x0028_position_x0029_",
  "Document_x0020_Owner_x0020__x0028_position_x0029_",
  "Subarea",
  "OrganizationalUnit",
  "WorkStorage",
  "LocationCountry",
  "DocumentArea",
  "SubjectDoc",
  "PageName",
  "ServerRedirectedEmbedUrl",
  "File_x0020_Type",
  "OData__Category",
  "RequiredReviewDate",
  "publishedVersion",
  "Review_x0020_Period",
  "Standards",
  "Company",
  "FriendlyURL",
].concat(fileInfoSelect);
/**
 * Object with necessary info for request to EPAM Regulations list
 */
export const regulationsListRequestInfo = {
  selectArray: regulationsSelectArray,
  expandArray: mainExpandArray,
};
/**
 * Object with necessary info for request to EPAM Templates list
 */
export const templatesListRequestInfo = {
  selectArray: regulationsSelectArray.concat("BriefDescription"),
  expandArray: mainExpandArray,
};
/**
 * Object with necessary info for request to EPAM Certificates list
 */
export const certificatesListRequestInfo = {
  selectArray: [
    "Id",
    "Title",
    "Created",
    "Author/Id",
    "Author/Title",
    "Author/EMail",
    "Editor/Id",
    "Editor/Title",
    "Editor/EMail",
    "Modified",
    "EPAMDocumentID_x0020_",
    "CertificateArtifactType",
    "CertificationBody",
    "issueDate",
    "LegalEntities",
    "LocationCity",
    "LocationCountry",
    "Responsible",
    "ScopeLimitation",
    "Standart",
    "ValidFrom",
    "ValidUntil",
    "WorkStorage",
    "CertificateArea",
    "ServerRedirectedEmbedUrl",
    "File_x0020_Type",
    "publishedVersion",
    "FriendlyURL",
    "RegistrationNumber",
  ].concat(fileInfoSelect),
  expandArray: mainExpandArray,
};
/**
 * Object with necessary info for request to Site Pages list
 */
export const sitePagesListRequestInfo = {
  selectArray: [
    "Id",
    "Title",
    "Created",
    "pageInfo",
    "Author/Id",
    "Author/Title",
    "Author/EMail",
    "Editor/Id",
    "Editor/Title",
    "Editor/EMail",
    "Modified",
    "parentNavigationTermSets",
    "CheckoutUser/Id",
    "CheckoutUser/Title",
    "CheckoutUser/EMail",
    "File_x0020_Type",
    "redirectToPage",
    "FriendlyURL",
  ].concat(fileInfoSelect),
  expandArray: mainExpandArray.concat("CheckoutUser"),
};

export const sitePageVersionsListRequestInfo = {
  selectArray: ["Title", "pageInfo", "Editor", "Modified", "VersionLabel", "OData__x005f_CheckinComment"],
};

/**
 * Object with necessary info for request to Roles Registry list
 */
export const rolesRegistryListRequestInfo = {
  selectArray: [
    "Id",
    "Title",
    "Created",
    "Roles_x0020_Category",
    "Group",
    "Short_x0020_description_x0020_in",
    "Status",
    "Published_x0020_date",
    "Approvedby",
    "DocumentArea",
    "Author/Id",
    "Author/Title",
    "Author/EMail",
    "Editor/Id",
    "Editor/Title",
    "Editor/EMail",
    "Modified",
    "Regulations/Title",
    "Regulations/Url",
    "DocumentsId",
  ],
  expandArray: ["Author", "Editor", "Regulations"],
};

export const standardsIconsRequestInfo = {
  selectArray: ["Title", "IconLink"],
};

export const PublishedRegulationsColumns = {
  EpamDocumentId: "EPAMDocumentID_x0020_",
};

export const publishedRegulationsRequestInfo = {
  selectArray: ["Id", PublishedRegulationsColumns.EpamDocumentId],
  expandArray: [],
};

export const filterQueryNotFolder = `FSObjType eq 0`;
export const listSubscriptionKeys = {
  [LibraryName.regulations]: "regulationsId",
  [LibraryName.templates]: "templatesId",
  [LibraryName.certificates]: "certificatesId",
  [LibraryName.roles]: "rolesId",
};
export const allLists = [
  LibraryName.certificates,
  LibraryName.regulations,
  LibraryName.templates,
  LibraryName.roles,
  LibraryName.pages,
];

/**
 * Fields for search in roles registry
 */
export const rolesFieldsToSearch = ["Regulations", "Title", "ShortdescriptioninOWSMTXT"];

/**
 * Fields for search in Certificates, Regulations and Templates
 */
export const documentsFieldsToSearch = ["Title", "FileName", "DocumentTextContent"];

/**
 * Fields for search in Certificates, Regulations and Templates
 */
export const pagesFieldsToSearch = ["Title", "PageTextContent"];

/**
 * Text to exclude from search
 */
export const searchTextsToExclude = [
  `This document contains privileged and/or confidential information and may not be
disclosed, distributed or reproduced without the prior written permission of EPAM®.`,
];
/**
 * Symbols that needed special processing for search and highlight
 */
export const searchSpecialSymbols = [
  "*",
  ".",
  ",",
  "&",
  "<",
  ">",
  "–",
  "—",
  "(",
  ")",
  '"',
  "“",
  "”",
  "/",
  "№",
  "%",
  "!",
  "[",
  "]",
  ":",
  "_",
];
/**
 * Apostrophe needs additional processing for All words option
 */
export const apostropheRegex = /[\u2019']/;
/**
 * Empty table for RTE
 */
export const emptyTable = `<table style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<td style="width: 50%; vertical-align: top;">&nbsp;</td>
<td style="width: 50%; vertical-align: top;">&nbsp;</td>
</tr>
</tbody>
</table>`;
/**
 * Page Info for new child page
 */
export const childPageInfo: IPageInfo = {
  showSubscribeButton: false,
  attachedFiles: { library: LibraryName.regulations, items: [] },
  sections: [
    {
      type: SectionTypes.OneColumnLayout,
      data: {
        webParts: [
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: { accordionTitle: "Overview", surroundedBy: "accordion", markup: "" },
          },
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: { accordionTitle: "Related Artifacts", surroundedBy: "accordion", markup: emptyTable },
          },
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: { accordionTitle: "Additional Information", surroundedBy: "accordion", markup: emptyTable },
          },
        ],
      },
    },
  ],
};
/**
 * Page Info for new parent page
 * TBD
 */
export const parentPageInfo: IPageInfo = {
  showSubscribeButton: false,
  attachedFiles: { library: LibraryName.regulations, items: [] },
  sections: [
    {
      type: SectionTypes.TwoColumnsLayout,
      data: {
        left: [],
        right: [],
      },
    },
  ],
};
/**
 * Link for oauth2 logout service
 */
export const logoutLink = `https://login.windows.net/common/oauth2/logout`;
