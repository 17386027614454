import {
  IGetSubscriptionsDataParams,
  ISubscriptionItem,
  SubscriptionsTabType,
} from "Components/subscriptions/subscriptions.interfaces";
import { ITab } from "Components/tabs-slider/tabs-slider";
import { SubscribeItemTypes } from "Helpers/subscribeHelper";
import { listSubscriptionKeys } from "SP/constants";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { ISubscriptions } from "SP/subscriptions/subscriptions.types";
import { IAllDocuments } from "Store/actions/documents.actions";

export const subscriptionsTabs: ITab[] = Object.keys(SubscriptionsTabType).map((tab) => ({
  caption: SubscriptionsTabType[tab],
  key: SubscriptionsTabType[tab],
}));

export const isDownloadButtonPresent = (tab: SubscriptionsTabType) => {
  return tab === SubscriptionsTabType.Documents || tab === SubscriptionsTabType.Certificates;
};

export const getItemNameByTab = (tab: SubscriptionsTabType) => {
  if (tab === SubscriptionsTabType.Certificates) {
    return SubscribeItemTypes.certificate;
  } else if (tab === SubscriptionsTabType.Documents) {
    return SubscribeItemTypes.document;
  }
  return SubscribeItemTypes.item;
};

const getLibrarySubscriptions = (subscriptions: ISubscriptions, libraryData: ISubscriptionItem[], field: string) => {
  return libraryData.filter((item) => subscriptions?.[field].includes(item.Id));
};

const getDocumentSubscriptions = (subscriptions: ISubscriptions, allDocuments: IAllDocuments) => {
  const regulations = getLibrarySubscriptions(
    subscriptions,
    allDocuments[LibraryName.regulations],
    listSubscriptionKeys[LibraryName.regulations],
  );

  const templates = getLibrarySubscriptions(
    subscriptions,
    allDocuments[LibraryName.templates],
    listSubscriptionKeys[LibraryName.templates],
  );

  return [...regulations, ...templates];
};

const getCertificateSubscriptions = (subscriptions: ISubscriptions, allDocuments: IAllDocuments) => {
  return getLibrarySubscriptions(
    subscriptions,
    allDocuments[LibraryName.certificates],
    listSubscriptionKeys[LibraryName.certificates],
  );
};

export const getSubscriptionsData = (params: IGetSubscriptionsDataParams) => {
  const { currentUser, allDocuments, activeTab } = params;
  const subscriptions = currentUser?.subscriptions;

  if (activeTab === SubscriptionsTabType.Documents) {
    return getDocumentSubscriptions(subscriptions, allDocuments);
  } else if (activeTab === SubscriptionsTabType.Certificates) {
    return getCertificateSubscriptions(subscriptions, allDocuments);
  }

  return [
    ...getDocumentSubscriptions(subscriptions, allDocuments),
    ...getCertificateSubscriptions(subscriptions, allDocuments),
  ]
    .map((x) => (x.Title ? x : { ...x, Title: "" }))
    .sort((a, b) => a.Title.localeCompare(b.Title));
};
