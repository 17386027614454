import React, { FC, useRef } from "react";

import { ReactComponent as tableSortAscIcon } from "@epam/assets/icons/common/table-sort_asc-18.svg";
import { ReactComponent as tableSortDescIcon } from "@epam/assets/icons/common/table-sort_desc-18.svg";
import { ReactComponent as tableSortIcon } from "@epam/assets/icons/common/table-swap-18.svg";
import { IconButton } from "@epam/loveship";

import { updateObject } from "Helpers/utils";
import { useResize } from "Hooks/useResize";

import { ITableColumnProps } from "./documents-table.interface";

const getIcon = (isSortable?: boolean, sortASC?: boolean) => {
  if (!isSortable) return;

  let icon = tableSortIcon;
  let fillColor: "night500" | "night600" = "night500";

  if (sortASC !== undefined) {
    icon = sortASC ? tableSortAscIcon : tableSortDescIcon;
    fillColor = "night600";
  }

  return { icon, fillColor };
};

const getASCNextValue = (sortASC: boolean) => {
  return sortASC === undefined ? false : !sortASC;
};

export const TableColumn: FC<ITableColumnProps> = ({
  cn,
  sortASC,
  column,
  columnsConfig,
  onColumnsConfigSet,
  onSort,
}) => {
  const sortIcon = getIcon(column.isSortable, sortASC);
  const cellRef = useRef();
  const [isResizing, onResizeStart] = useResize({
    ref: cellRef,
    onUpdate: onColumnsConfigUpdate,
  });

  function onColumnsConfigUpdate(newWidth) {
    const newColumnsConfig = { ...columnsConfig };
    newColumnsConfig[column.key] = updateObject(newColumnsConfig[column.key], {
      width: newWidth < column.minWidth ? column.minWidth : newWidth,
    });

    onColumnsConfigSet(newColumnsConfig);
  }

  return (
    <div
      className={cn("column", { isResizing, isSortable: column.isSortable }, ["flex"])}
      style={{ minWidth: column.minWidth ? `${column.minWidth}px` : 0, flexBasis: `${column.width}px` }}
      ref={cellRef}
      {...(sortIcon && {
        onClick: () => onSort({ field: column.key, asc: getASCNextValue(sortASC) }),
      })}
    >
      <span className={cn("column-caption")}>{column.caption}</span>
      {sortIcon && (
        <IconButton
          cx={cn("column-icon", { active: sortASC !== undefined })}
          icon={sortIcon.icon}
          color={sortIcon.fillColor}
        />
      )}
      {column.key !== "actions" && <div className={cn("column-resize")} onMouseDown={onResizeStart} />}
    </div>
  );
};
