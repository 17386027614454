import React, { FC, Fragment } from "react";

import { ReactComponent as chevronRight } from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import { LinkButton } from "@epam/loveship";
import { IconContainer } from "@epam/uui-components";

import { withNaming } from "Helpers/bemClassname";
import { getLinkProps, isLastItem } from "Helpers/utils";

import { IBreadcrumbsProps } from "./breadcrumbs.types";

import "./breadcrumbs.scss";

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ items = [], onClick, isLink, fontSize, lineHeight }) => {
  const cn = withNaming("breadcrumbs");

  if (!items.length) return null;

  return (
    <div data-testid="breadcrumbs" className={cn("", ["flex items-center"])}>
      {items.map((item, index) => (
        <Fragment key={item.title}>
          <LinkButton
            cx={cn("", { link: !!item.value })}
            color="white"
            size="none"
            fontSize={fontSize}
            lineHeight={lineHeight}
            caption={item.title}
            {...(isLink && item.value && { ...getLinkProps(item.value) })}
            {...(onClick && { onClick: onClick(item) })}
          />
          {!isLastItem(items, index) && <IconContainer cx={cn("chevron-right")} icon={chevronRight} />}
        </Fragment>
      ))}
    </div>
  );
};
