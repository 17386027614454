import React, { FC } from "react";

import { Layout } from "Components/layout";
import { Subscriptions } from "Components/subscriptions";

export const SubscriptionsPage: FC = () => {
  return (
    <Layout>
      <Subscriptions />
    </Layout>
  );
};
