import React from "react";

import { svc } from "@App/services";
import { ReactComponent as addIcon } from "@epam/assets/icons/common/action-add-18.svg";
import { ReactComponent as deleteIcon } from "@epam/assets/icons/common/action-delete-12.svg";
import { ReactComponent as editIcon } from "@epam/assets/icons/common/content-edit-12.svg";
import { ReactComponent as togglerChevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as togglerChevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { ReactComponent as checkIcon } from "@epam/assets/icons/common/notification-done-18.svg";
import { FlexRow, FlexSpacer, IconButton, IconContainer, LinkButton, Text, Tooltip } from "@epam/loveship";
import { DataRowProps } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import { cn } from "Components/documents-filter/documents-filter";
import { PresetsActionTypes } from "Components/documents-filter/documents-filter.interface";
import { DocumentsFilterPresetsModal } from "Components/documents-filter/documents-filter-presets-modal";
import { getFilterPanelKeys, getFilterPanelKeysGroups } from "Components/documents-filter/filters/filters.helpers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IFiltersGroup, ISelectedFilter } from "Store/reducers/filters.reducer";

export function calculateSelectedFiltersCount(selectedFilters: ISelectedFilter, isNeedAllFilters = false) {
  if (!selectedFilters) return 0;

  return getFilterPanelKeys(selectedFilters, isNeedAllFilters).length;
}

export function calculateSelectedFiltersGroupCount(groups: IFiltersGroup[]) {
  if (!groups) return 0;

  return getFilterPanelKeysGroups(groups).reduce((sum, x) => sum + x.length, 0);
}

export function calculateSelectedFiltersValuesCount(selectedFilters: ISelectedFilter) {
  if (!selectedFilters) return 0;

  return Object.values(selectedFilters).reduce(
    (accumulator, currentFilter) => accumulator + (currentFilter["items"] ? currentFilter["items"].length : 1),
    0,
  );
}

export const renderPresetsPickerToggler = (
  props: PickerTogglerProps<IFavoriteFilter, unknown>,
  activePresetTitle: string,
) => (
  <div
    className={cn("picker-toggler", { isOpen: props.isOpen }, ["flex justify-between items-center cut-text"])}
    ref={props.ref}
    onClick={props.onClick}
  >
    <Text size="18" color="night900" fontSize="14" lineHeight="18">
      <span className={cn("picker-toggler-prefix")}>Preset:&nbsp;</span>
      {activePresetTitle || "None"}
    </Text>
    <IconButton cx={cn("picker-toggler-chevron")} icon={props.isOpen ? togglerChevronUp : togglerChevronDown} />
  </div>
);

export const renderPresetsPickerRow = (
  rowProps: DataRowProps<IFavoriteFilter, unknown>,
  onResetComponentsState: () => void,
) => (
  <Tooltip content={rowProps.value.title} placement="left">
    <FlexRow
      key={rowProps.rowKey}
      cx={cn("picker-row", { isSelected: rowProps.isSelected }, ["flex"])}
      onClick={() => rowProps.onSelect(rowProps)}
    >
      {rowProps.isSelected && <IconContainer icon={checkIcon} color="sky" />}
      <Text cx={cn("picker-row-text")} size="18" color="night900" fontSize="14" lineHeight="24">
        {rowProps.value.title}
      </Text>
      <FlexSpacer />
      <IconButton
        cx={cn("picker-row-action")}
        icon={editIcon}
        onClick={() =>
          svc.uuiModals.show((modalProps) => (
            <DocumentsFilterPresetsModal
              modalProps={modalProps}
              modalType={PresetsActionTypes.rename}
              activePreset={rowProps.value}
              onResetComponentsState={onResetComponentsState}
            />
          ))
        }
      />
      <IconButton
        cx={cn("picker-row-action")}
        icon={deleteIcon}
        onClick={() =>
          svc.uuiModals.show((modalProps) => (
            <DocumentsFilterPresetsModal
              modalProps={modalProps}
              modalType={PresetsActionTypes.delete}
              activePreset={rowProps.value}
              onResetComponentsState={onResetComponentsState}
            />
          ))
        }
      />
    </FlexRow>
  </Tooltip>
);

export const renderPresetsPickerFooter = (onResetComponentsState: () => void) => (
  <FlexRow cx={cn("picker-row-footer", ["cursor-pointer"])}>
    <LinkButton
      caption="New Preset"
      color="night800"
      font="sans"
      fontSize="14"
      lineHeight="24"
      icon={addIcon}
      onClick={() =>
        svc.uuiModals.show((modalProps) => (
          <DocumentsFilterPresetsModal
            modalProps={modalProps}
            modalType={PresetsActionTypes.createEmpty}
            onResetComponentsState={onResetComponentsState}
          />
        ))
      }
    />
  </FlexRow>
);
