import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";

import { DrivesService } from "SP/drives/drives.service";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { DrivesActionsTypes, IGetListDriveIdAction, setListDriveId } from "Store/actions/drives.actions";

const drivesService = new DrivesService(process.env.REACT_APP_GRAPH_SITE_ID);

export function* getListDriveId(action: IGetListDriveIdAction) {
  try {
    const listTitle = action.list as LibraryName;
    const driveId = yield call([drivesService, "getDriveIdByListTitle"], listTitle);

    yield put(setListDriveId(listTitle, driveId));
  } catch (e) {
    throw e;
  }
}

export function* watchGetListDriveId() {
  yield takeEvery(DrivesActionsTypes.GET_LIST_DRIVE_ID, getListDriveId);
}

export default function* drivesSagas(): Iterator<ForkEffect> {
  yield fork(watchGetListDriveId);
}
