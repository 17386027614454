import React, { FC } from "react";

import { useSearch } from "Hooks/useSearch";

import { IDocumentsFilterPickerInputProps } from "./documents-filter.interface";
import { DocumentsFilterPickerInput } from "./documents-filter-picker-input";

export const DocumentsRefinerFilterPickerInput: FC<IDocumentsFilterPickerInputProps> = (props) => {
  const { getRefinedGlobalSearchResults } = useSearch();

  return <DocumentsFilterPickerInput {...props} onFilterChanged={getRefinedGlobalSearchResults} isValueActive={true} />;
};
