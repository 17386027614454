import _omit from "lodash/omit";

import { SectionTypes } from "SP/sitePages/sitePages.types";

export enum SitePageUpdaterKeys {
  DELETE = "DELETE",
  ADD_WEB_PART = "ADD_WEB_PART",
  ADD_SECTION = "ADD_SECTION",
}

export type IPathKeys = (string | number)[];

export function updateLayout(data, value, prevValue) {
  switch (value) {
    case SectionTypes.OneColumnLayout:
      let newData = [...data.left, ...data.right];
      if (prevValue === SectionTypes.ThreeColumnsLayout) {
        newData = [...data.left, ...data.center, ...data.right];
      }

      data.webParts = newData;
      data.left = [];
      data.right = [];
      data.center = [];
      break;
    case SectionTypes.ThreeColumnsLayout:
      if (prevValue === SectionTypes.OneColumnLayout) {
        data.left = data.webParts;
        data.right = [];
      }

      data.center = [];
      data.webParts = [];
      break;
    default:
      if (prevValue === SectionTypes.OneColumnLayout) {
        data.left = data.webParts;
        data.right = [];
      } else if (prevValue === SectionTypes.ThreeColumnsLayout) {
        data.right = [...data.center, ...data.right];
      }

      data.webParts = [];
      data.center = [];
      break;
  }
}

export function setSitePageValue(object, pathKeys: IPathKeys, value): boolean {
  if (pathKeys.length > 1) {
    if (!object.hasOwnProperty(pathKeys[0]) || typeof object[pathKeys[0]] !== "object") {
      throw new Error("Error in setSitePageValue, you add incorrect path!");
    }

    return setSitePageValue(object[pathKeys[0]], pathKeys.slice(1), value);
  } else {
    const prevValue = object[pathKeys[0]];

    // Add new webPart in site page
    if (Array.isArray(object) && SitePageUpdaterKeys[value.updaterKey]) {
      const addIndex = pathKeys[0] as number;
      object.splice(addIndex, 0, _omit(value, ["updaterKey"]));
      return true;
    }

    // Delete webPart from site page
    if (Array.isArray(object) && value === SitePageUpdaterKeys.DELETE) {
      const deleteIndex = pathKeys[0] as number;
      object.splice(deleteIndex, 1);
      return true;
    }

    object[pathKeys[0]] = value;

    // Update layout data according to the type
    if (SectionTypes[value]) {
      updateLayout(object["data"], value, prevValue);
    }

    return true;
  }
}
