import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

import { LinkButton } from "@epam/loveship";

export interface IRolesRegistryFooterProps {
  cn: (b, e?, m?) => string;
}

export const RolesRegistryFooter = ({ cn }: IRolesRegistryFooterProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <div
        data-html={true}
        data-tip="More information about Custom Roles can be found <a style='color: rgb(48, 182, 221)' href='https://kb.epam.com/x/UQGbTg' target='_blank'>here</a>"
      >
        <LinkButton
          cx={cn("modal-button", ["flex justify-center"])}
          caption={"Project Custom Roles".toUpperCase()}
          fontSize="14"
          lineHeight="24"
          onClick={() => {
            window.open("https://projects.epam.com/projects-administration/custom-roles", "_blank", "noopener");
          }}
        />
      </div>
    </>
  );
};
