import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FlexCell, FlexRow, Spinner } from "@epam/loveship";
import { ArrayDataSource } from "@epam/uui";

import { useFiltersData, useFiltersMethods } from "Components/documents-filter/documents-filter.hooks";
import { exportItemsToExcel, renderPickerInput } from "Components/documents-toolbar/documents-toolbar.helpers";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { ResultsNotFound } from "Components/results-not-found/results-not-found";
import {
  getFiltersByActiveTab,
  isVisibleOnTab,
  renderSearchPickerToggler,
  tabs,
} from "Components/search/search.helpers";
import { SearchTabType } from "Components/search/search.interfaces";
import { SearchResultList } from "Components/search/search-result-list";
import { SearchToolbar } from "Components/search/search-toolbar";
import { TabsSlider } from "Components/tabs-slider";
import { ValidatedSearchInput } from "Components/validated-search-input/validated-search-input";
import { withNaming } from "Helpers/bemClassname";
import highlight from "Helpers/highlight";
import { handleAllCheckboxLabel } from "Helpers/utils";
import useQuery from "Hooks/useQuery";
import { useRowsCheck } from "Hooks/useRowsCheck";
import { useSearch } from "Hooks/useSearch";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { SearchService } from "SP/search/search.service";
import { ISelectedFilter } from "Store/reducers/filters.reducer";
import { SearchTypes } from "Store/reducers/search.reducer";

import { filterByFolders, filterSearchItem } from "./search-filter";

import "./search.scss";

export const MIN_SEARCH_QUERY_LENGTH = 3;
const MAX_SEARCH_QUERY_ALL = 100;
const MAX_SEARCH_QUERY_EXACT = 100;

export const Search: FC = () => {
  const cn = withNaming("search");
  const cnDropdown = withNaming("documents-toolbar");
  const { hasParams, query, setQuery } = useQuery();

  const {
    loading,
    searchType,
    isGlobalSearchResultsLoaded,
    globalSearchResults,
    getGlobalSearchResults,
    resetGlobalSearchResults,
    setSearchType,
  } = useSearch();
  const [searchValue, setSearchValue] = useState<string>(query.searchQuery as string);
  const [showNotYet, setShowNotYet] = useState(true);
  const [activeTab, setActiveTab] = useState<SearchTabType>(
    (query.activeTab as SearchTabType) || SearchTabType.Documents,
  );
  const [shouldGetRefinedResults, setShouldGetRefinedResults] = useState(false);
  const { setInfoPanel } = useInfoPanel();
  const { setFilterGroupsResults, resetRefiners } = useFiltersMethods();

  const { allSelectedFilters, refiners } = useFiltersData();
  const { setInfoPanelFilters, setFiltersLibrary, setFilters, resetFilters, setIsPageToggle, getFilterByGuid } =
    useFiltersMethods();

  const { getRefinedGlobalSearchResults, rowsLimit } = useSearch();

  const getMaxSearchLength = useCallback(
    (searchType: SearchTypes) => (searchType === SearchTypes.all ? MAX_SEARCH_QUERY_ALL : MAX_SEARCH_QUERY_EXACT),
    [],
  );

  const maxSearchLength = useMemo(() => {
    return getMaxSearchLength(searchType);
  }, [searchType]);

  const getValidSearchValue = useCallback((value: string, searchType: SearchTypes) => {
    return value?.substring(0, getMaxSearchLength(searchType));
  }, []);

  const setValidatedSearchValue = useCallback(
    (value: string) => {
      const validValue = getValidSearchValue(value, searchType);
      updateQuery({ searchQuery: validValue });
      setSearchValue(validValue);
      if (validValue.length < 3) {
        setShowNotYet(true);
        resetGlobalSearchResults();
        resetRefiners();
      }
    },
    [searchType, activeTab],
  );

  const searchLengthInfo = useMemo(() => {
    return `${searchValue?.length ?? 0}/${maxSearchLength}`;
  }, [searchValue, maxSearchLength]);

  const { items, filtersLibrary, infoPanelFilters } = useMemo(() => {
    const filteredItemsByTab = globalSearchResults.filter((item) => isVisibleOnTab(activeTab, item));
    const filteredItemsByFolder = filteredItemsByTab.filter((x) => filterByFolders(x));
    const filteredItemsByOccurrences = filteredItemsByFolder.filter((x) => filterSearchItem(x, searchType));
    const { filtersLibrary, infoPanelFilters } = getFiltersByActiveTab(activeTab, filteredItemsByTab);
    setFilterGroupsResults(filteredItemsByOccurrences, allSelectedFilters[filtersLibrary]);

    if (loading || searchValue?.length < MIN_SEARCH_QUERY_LENGTH) {
      return {
        items: [],
        filtersLibrary: filtersLibrary,
        infoPanelFilters: infoPanelFilters,
      };
    }

    setShowNotYet(false);

    return {
      items: filteredItemsByOccurrences,
      filtersLibrary,
      infoPanelFilters,
    };
  }, [
    activeTab,
    JSON.stringify(globalSearchResults),
    JSON.stringify(allSelectedFilters),
    searchType,
    loading,
    searchValue,
  ]);

  const dataSource = useMemo(
    () =>
      new ArrayDataSource({
        items: [
          { id: SearchTypes.all, label: SearchTypes.all, onClick: () => setSearchType(SearchTypes.all) },
          { id: SearchTypes.exact, label: SearchTypes.exact, onClick: () => setSearchType(SearchTypes.exact) },
        ],
      }),
    [],
  );

  const { resetRows, selectedRows, indeterminate, onCheckToggle, onToggleAll, clearAll } = useRowsCheck(items);
  const isResultsPresent = items.length > 0;

  const updateQuery = (value: ISelectedFilter) => {
    const obj = { activeTab, searchQuery: searchValue, searchType, ...value };
    setQuery(obj);
  };

  const exportResultsCallback = () => {
    exportItemsToExcel(activeTab, items, selectedRows, searchValue);
  };

  const handleSearch = useCallback((newSearchValue: string) => {
    resetRows();
    resetFilters(true);
    setSearchValue(newSearchValue);
    if (newSearchValue.length >= MIN_SEARCH_QUERY_LENGTH) {
      getGlobalSearchResults(SearchService.formatSpaceSymbols(newSearchValue));
    }
  }, []);

  const handleTabChange = (newActiveTab: string) => () => {
    resetRows();
    setActiveTab(newActiveTab as SearchTabType);
    updateQuery({ activeTab: newActiveTab });
  };

  const handleSetFilters = (newFilter: IFavoriteFilter) => {
    if (newFilter?.filtersGroups) {
      setFilters(newFilter.filtersGroups);
      setShouldGetRefinedResults(true);
    }
  };

  useEffect(() => {
    setFiltersLibrary(filtersLibrary);

    if (searchValue?.length >= MIN_SEARCH_QUERY_LENGTH) {
      getRefinedGlobalSearchResults();
    }
  }, [filtersLibrary]);

  useEffect(() => {
    if (shouldGetRefinedResults && refiners?.length > 0) {
      getRefinedGlobalSearchResults();
      setShouldGetRefinedResults(false);
    }
  }, [shouldGetRefinedResults, refiners]);

  useEffect(() => {
    setInfoPanelFilters(infoPanelFilters);
  }, [JSON.stringify(infoPanelFilters)]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      resetRows();
    }
  }, [allSelectedFilters[filtersLibrary]]);

  useEffect(() => {
    clearAll();
  }, [rowsLimit]);

  useEffect(() => {
    setIsPageToggle("isSearchPage", true);

    if (query.searchType) {
      setSearchType(query.searchType as SearchTypes);
    }

    if (query.searchQuery && (query.searchQuery as string).length >= MIN_SEARCH_QUERY_LENGTH) {
      getGlobalSearchResults(query.searchQuery as string);
    }

    if (hasParams && query.filterGuid) {
      getFilterByGuid(query.filterGuid as string, handleSetFilters);
    }

    return () => {
      resetGlobalSearchResults();
      resetRefiners();
      resetFilters(true);
      setFiltersLibrary(null);
      setInfoPanel(null);
      setIsPageToggle("isSearchPage", false);
      highlight.clear();
      setSearchType(SearchTypes.all);
    };
  }, []);

  const handleSearchTypeChanged = (newSearchType: SearchTypes) => {
    if (searchType !== newSearchType) {
      resetGlobalSearchResults();
      resetRefiners();
      updateQuery({ searchType: newSearchType });
      setSearchType(newSearchType);
      handleSearch(getValidSearchValue(searchValue, newSearchType));
    }
  };

  return (
    <FlexCell cx={cn("")} rawProps={{ "data-testid": "search-page-content" }}>
      <div className={cn("container")}>
        <FlexRow>
          {renderPickerInput({
            dataSource: dataSource,
            value: searchType,
            minBodyWidth: 170,
            onValueChange: handleSearchTypeChanged,
            renderToggler: (props) => renderSearchPickerToggler(props, cnDropdown),
          })}
          <ValidatedSearchInput
            cx={cn("input")}
            icon={null}
            autoFocus
            value={searchValue}
            changeValue={setValidatedSearchValue}
            executeSearch={handleSearch}
            maxLength={maxSearchLength}
            placeholder="Search"
            inputMode="search"
            debounceDelay={process.env.UNIT_TEST ? 0 : 1000}
          />
        </FlexRow>
      </div>
      <div className={cn("length-info")}>{searchLengthInfo}</div>
      <TabsSlider borderBottom tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
      <SearchToolbar
        cn={cn}
        selectedAll={selectedRows?.length === items.length && !loading}
        hasSelected={!!selectedRows?.length && selectedRows?.length !== items.length}
        indeterminate={indeterminate}
        onToggleAll={onToggleAll}
        exportCallback={exportResultsCallback}
        checkboxLabel={handleAllCheckboxLabel(selectedRows?.length, items?.length, "search result")}
      />
      {loading && <Spinner cx={cn("spinner")} color={"sky"} />}
      {isGlobalSearchResultsLoaded && !isResultsPresent && (
        <ResultsNotFound
          isSearchQueryPresent={searchValue?.length >= MIN_SEARCH_QUERY_LENGTH && !showNotYet}
          isExactPhrase={searchType === SearchTypes.exact}
        />
      )}
      {isGlobalSearchResultsLoaded && isResultsPresent && (
        <div style={{ paddingTop: "17px" }}>
          <SearchResultList
            cn={cn}
            query={searchValue}
            activeTab={activeTab}
            selectedRows={selectedRows}
            items={items}
            onCheckRowCallback={onCheckToggle}
          />
        </div>
      )}
    </FlexCell>
  );
};
