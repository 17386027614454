export abstract class BlobCache {
  static cacheName: "ecl-blob";

  public static async get(key: string): Promise<Blob> {
    const cache = await caches.open(BlobCache.cacheName);

    const response = await cache.match(key);

    return await response.blob();
  }

  public static async put(key: string, value: any): Promise<void> {
    const cache = await caches.open(this.cacheName);

    await cache.put(key, new Response(value));
  }

  public static async getKeys(): Promise<string[]> {
    const cache = await caches.open(this.cacheName);

    const keys = await cache.keys();

    return keys.map((x) => x.url);
  }
}
