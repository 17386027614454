import { CertificateField } from "SP/fields";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { FilterInvolve, IFilter } from "../documents-filter.interface";
import { getFieldUniqueValues, getMinAndMaxDates } from "./filters.helpers";

export const getCertificateFilters = (documents: IAttachedFile[]): IFilter[] => {
  return [
    {
      key: "Title",
      caption: CertificateField.Title,
      component: "picker-input",
      data: getFieldUniqueValues("Title", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "ValidFrom",
      caption: CertificateField.ValidFrom,
      component: "date-picker",
      data: getMinAndMaxDates("ValidFrom", documents),
    },
    {
      key: "ValidUntil",
      caption: CertificateField.ValidUntil,
      component: "date-picker",
      data: getMinAndMaxDates("ValidUntil", documents),
    },
    {
      key: "Standard",
      caption: CertificateField.Standard,
      component: "picker-input",
      data: getFieldUniqueValues("Standard", documents),
    },
    {
      key: "LocationCity",
      caption: CertificateField.LocationCity,
      component: "picker-input",
      data: getFieldUniqueValues("LocationCity", documents),
    },
    {
      key: "LocationCountry",
      caption: CertificateField.LocationCountry,
      component: "picker-input",
      data: getFieldUniqueValues("LocationCountry", documents),
    },
    {
      key: "IssueDate",
      caption: CertificateField.IssueDate,
      component: "date-picker",
      data: getMinAndMaxDates("IssueDate", documents),
    },
    {
      key: "CertificationBody",
      caption: CertificateField.CertificationBody,
      component: "picker-input",
      data: getFieldUniqueValues("CertificationBody", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
  ];
};
