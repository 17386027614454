import React, { FC } from "react";
import { createPortal } from "react-dom";

import { Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import "./widen-notification.scss";

export const WidenNotification: FC = () => {
  const cn = withNaming("widen-notification");

  return createPortal(
    <div className={cn("", ["flex-col"])}>
      <div className={cn("header")}>Widen your browser window</div>
      <Text cx={cn("content", ["p-0"])} font="sans-semibold" fontSize="16" lineHeight="24" color="white">
        Editing is not available in a narrow browser window. Make the window wider to continue editing
      </Text>
    </div>,
    document.getElementById("root"),
  );
};
