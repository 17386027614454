import React from "react";

import { DropdownMenuBody, DropdownMenuButton, MainMenuIcon } from "@epam/loveship";
import { Icon } from "@epam/uui";
import { Dropdown } from "@epam/uui-components";

import { getLinkProps } from "Helpers/utils";
import { withButtonAnalytics } from "Helpers/withButtonAnalytics";

const IconWithAnalytics = withButtonAnalytics(MainMenuIcon);

export interface ILink {
  caption: string;
  icon?: Icon;
  width?: number;
  link?: string;
  isLinkActive?: boolean;
}

export interface IButtonWithLinksProps {
  icon: Icon;
  links: ILink[];
  linkModifier: (link: string) => string;
  caption: string;
}

export const ButtonWithLinks = ({ icon, links, linkModifier, caption }: IButtonWithLinksProps) => (
  <Dropdown
    renderTarget={(props) => (
      <IconWithAnalytics caption={caption} isDropdown icon={icon} priority={10} estimatedWidth={48} {...props} />
    )}
    renderBody={() => (
      <DropdownMenuBody cx="dropdown dropdown--no-icons" color="white">
        {links?.map(({ caption, link }) => (
          <DropdownMenuButton key={caption} caption={caption} {...getLinkProps(linkModifier(link))} />
        ))}
      </DropdownMenuBody>
    )}
    closeOnMouseLeave="boundary"
    placement="bottom-start"
  />
);
