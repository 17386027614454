import React, { useEffect, useRef } from "react";

export interface IDocumentDownloadProps {
  downloadUrl: string;
  documentName: string;
}

export const DocumentDownload = ({ downloadUrl, documentName }: IDocumentDownloadProps) => {
  const linkRef = useRef(null);

  useEffect(() => {
    if (linkRef?.current) {
      (linkRef?.current as HTMLElement).click();
    }
  }, [linkRef]);

  return (
    <p style={{ textAlign: "center" }} data-testid="document-download">
      If the download doesn&apos;t start automatically, click{" "}
      <a href={downloadUrl} download={documentName} ref={linkRef}>
        here
      </a>{" "}
      to download manually.
    </p>
  );
};
