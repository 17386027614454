import { analyticsHelper } from "./analyticsHelper";
import { IAnalyticsService } from "./AnalyticsService";

type IWindow = Window &
  typeof globalThis & {
    zaraz: { track: (eventName: string, properties?: any) => void };
  };

export class ZarazAnalytics implements IAnalyticsService {
  provideSearchTerm(searchTerm: string, jobTitle: string, searchType: string): void {
    (window as IWindow).zaraz.track("search", {
      searchTerm,
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      searchType,
    });
  }

  provideFilter(filterKey: string, jobTitle: string) {
    (window as IWindow).zaraz.track("filter_panel", {
      filterKey,
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      pageLink: window.location.href,
    });
  }

  providePageView(jobTitle: string) {
    (window as IWindow).zaraz.track("ecl_page_view", {
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideLinkClick(link: string, jobTitle: string) {
    (window as IWindow).zaraz.track("ecl_link_click", {
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      linkValue: link,
    });
  }

  provideButtonClick(caption: string, jobTitle: string) {
    (window as IWindow).zaraz.track("ecl_button_click", {
      buttonName: caption,
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideDownload(jobTitle: string, documentLink: string) {
    (window as IWindow).zaraz.track("ecl_download", {
      documentLink: documentLink,
      documentName: analyticsHelper.getDocumentName(documentLink),
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideSessionStart(jobTitle: string) {
    (window as IWindow).zaraz.track("ecl_session_start", {
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideDocumentView(jobTitle: string, documentLink: string) {
    (window as IWindow).zaraz.track("ecl_document_view", {
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      documentLink,
      documentName: analyticsHelper.getDocumentName(documentLink),
    });
  }
}
