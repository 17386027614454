import { IItemUpdateResult, IList, sp } from "@pnp/sp/presets/all";

import { getFormattedFriendlyUrl } from "SP/sp.helpers";

import { filterQueryNotFolder, templatesListRequestInfo } from "../../constants";
import { IRegulationDTO } from "../regulations/regulations.types";

export class TemplatesRepository {
  private templatesList: IList = sp.web.lists.getByTitle("EPAM Templates");
  public async getAll(): Promise<IRegulationDTO[]> {
    return await this.templatesList.items
      .select(...templatesListRequestInfo.selectArray)
      .expand(...templatesListRequestInfo.expandArray)
      .filter(filterQueryNotFolder)
      .getAll(5000);
  }
  public async getById(id: number): Promise<IRegulationDTO> {
    return await this.templatesList.items
      .getById(id)
      .select(...templatesListRequestInfo.selectArray)
      .expand(...templatesListRequestInfo.expandArray)
      .get();
  }

  public async getByServerRelativeUrl(url: string): Promise<IRegulationDTO> {
    const file = await sp.web.getFileByServerRelativeUrl(url).getItem();
    const item: IRegulationDTO = await file
      .select(...templatesListRequestInfo.selectArray)
      .expand(...templatesListRequestInfo.expandArray)
      .get();

    return item;
  }

  public async getByFriendlyUrl(friendlyUrl: string): Promise<IRegulationDTO[]> {
    const formattedUrl = getFormattedFriendlyUrl(friendlyUrl);

    return await this.templatesList.items
      .filter(`FriendlyURL eq '${formattedUrl}'`)
      .select(...templatesListRequestInfo.selectArray)
      .expand(...templatesListRequestInfo.expandArray)
      .get();
  }

  public async updateDocument(id: number, data: Partial<IRegulationDTO>): Promise<IItemUpdateResult> {
    return await this.templatesList.items.getById(id).update(data);
  }
}
