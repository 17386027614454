import { IInfoPanelConfiguration } from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { IVisited } from "SP/recent/recent.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { InfoPanel } from "Store/reducers/general.reducer";

export enum GeneralActionsTypes {
  GET_RECENT_ITEMS_REQUEST = "[GENERAL] GET RECENT ITEMS REQUEST",
  GET_RECENT_ITEMS_SUCCESS = "[GENERAL] GET RECENT ITEMS SUCCESS",
  GET_RECENT_ITEMS_FAILURE = "[GENERAL] GET RECENT ITEMS FAILURE",

  GET_ALL_ROLES_REQUEST = "[GENERAL] GET ALL ROLES REQUEST",
  GET_ALL_ROLES_SUCCESS = "[GENERAL] GET ALL ROLES SUCCESS",
  GET_ALL_ROLES_FAILURE = "[GENERAL] GET ALL ROLES FAILURE",

  GET_ROLE_BY_ID_REQUEST = "[GENERAL] GET ROLE BY ID REQUEST",
  GET_ROLE_BY_ID_SUCCESS = "[GENERAL] GET ROLE BY ID SUCCESS",
  GET_ROLE_BY_ID_FAILURE = "[GENERAL] GET ROLE BY ID FAILURE",

  RESET_ROLE = "[GENERAL] RESET ROLE",

  ADD_RECENT_ITEM = "[GENERAL] ADD RECENT ITEM",
  ADD_RECENT_ITEM_DOCUMENT = "[GENERAL] ADD RECENT ITEM DOCUMENT",
  SET_MENU_NAVIGATION_TOGGLE = "[GENERAL] SET MENU NAVIGATION TOGGLE",
  SET_INFO_PANEL = "[GENERAL] SET INFO PANEL",

  GET_INFO_PANEL_CONFIG_REQUEST = "[GENERAL] GET INFO PANEL CONFIG REQUEST",
  GET_INFO_PANEL_CONFIG_SUCCESS = "[GENERAL] GET INFO PANEL CONFIG SUCCESS",
  GET_INFO_PANEL_CONFIG_FAILURE = "[GENERAL] GET INFO PANEL CONFIG FAILURE",

  UPDATE_INFO_PANEL_CONFIG_REQUEST = "[GENERAL] UPDATE INFO PANEL CONFIG REQUEST",
  UPDATE_INFO_PANEL_CONFIG_SUCCESS = "[GENERAL] UPDATE INFO PANEL CONFIG SUCCESS",
  UPDATE_INFO_PANEL_CONFIG_FAILURE = "[GENERAL] UPDATE INFO PANEL CONFIG FAILURE",
}

// GET RECENT ITEMS START
export interface IGetRecentItemsRequestAction {
  type: GeneralActionsTypes.GET_RECENT_ITEMS_REQUEST;
}

export function getRecentItems(): IGetRecentItemsRequestAction {
  return {
    type: GeneralActionsTypes.GET_RECENT_ITEMS_REQUEST,
  };
}

export interface IGetRecentItemsSuccessAction {
  type: GeneralActionsTypes.GET_RECENT_ITEMS_SUCCESS;
  payload: IVisited[];
}

export function getRecentItemsSuccess(payload: IVisited[]): IGetRecentItemsSuccessAction {
  return {
    type: GeneralActionsTypes.GET_RECENT_ITEMS_SUCCESS,
    payload,
  };
}

export interface IGetRecentItemsFailureAction {
  type: GeneralActionsTypes.GET_RECENT_ITEMS_FAILURE;
  error: string;
}

export function getRecentItemsFailure(error): IGetRecentItemsFailureAction {
  return {
    type: GeneralActionsTypes.GET_RECENT_ITEMS_FAILURE,
    error: error.message,
  };
}
// GET RECENT ITEMS END

// GET ALL ROLES START
export interface IGetAllRolesRequestAction {
  type: GeneralActionsTypes.GET_ALL_ROLES_REQUEST;
}

export function getAllRoles(): IGetAllRolesRequestAction {
  return {
    type: GeneralActionsTypes.GET_ALL_ROLES_REQUEST,
  };
}

export interface IGetAllRolesSuccessAction {
  type: GeneralActionsTypes.GET_ALL_ROLES_SUCCESS;
  payload: IRole[];
}

export function getAllRolesSuccess(payload: IRole[]): IGetAllRolesSuccessAction {
  return {
    type: GeneralActionsTypes.GET_ALL_ROLES_SUCCESS,
    payload,
  };
}

export interface IGetAllRolesFailureAction {
  type: GeneralActionsTypes.GET_ALL_ROLES_FAILURE;
  error: string;
}

export function getAllRolesFailure(error): IGetAllRolesFailureAction {
  return {
    type: GeneralActionsTypes.GET_ALL_ROLES_FAILURE,
    error: error.message,
  };
}
// GET ALL ROLES END

// GET ROLE BY ID START
export interface IGetRoleByIdRequestAction {
  type: GeneralActionsTypes.GET_ROLE_BY_ID_REQUEST;
  roleId: number;
}

export function getRoleById(roleId: number): IGetRoleByIdRequestAction {
  return {
    type: GeneralActionsTypes.GET_ROLE_BY_ID_REQUEST,
    roleId,
  };
}

export interface IGetRoleByIdSuccessAction {
  type: GeneralActionsTypes.GET_ROLE_BY_ID_SUCCESS;
  payload: IRole;
}

export function getRoleByIdSuccess(role: IRole): IGetRoleByIdSuccessAction {
  return {
    type: GeneralActionsTypes.GET_ROLE_BY_ID_SUCCESS,
    payload: role,
  };
}

export interface IGetRoleByIdFailureAction {
  type: GeneralActionsTypes.GET_ROLE_BY_ID_FAILURE;
  error: string;
}

export function getRoleByIdFailure(error): IGetRoleByIdFailureAction {
  return {
    type: GeneralActionsTypes.GET_ROLE_BY_ID_FAILURE,
    error: error.message,
  };
}

export interface IResetRole {
  type: GeneralActionsTypes.RESET_ROLE;
}

export function resetRole(): IResetRole {
  return {
    type: GeneralActionsTypes.RESET_ROLE,
  };
}
// GET ROLE BY ID END

export interface IAddRecentItemAction {
  type: GeneralActionsTypes.ADD_RECENT_ITEM;
  item: IVisited;
}

export function addRecentItem(item: IVisited): IAddRecentItemAction {
  return {
    type: GeneralActionsTypes.ADD_RECENT_ITEM,
    item,
  };
}

export interface IAddRecentItemDocumentAction {
  type: GeneralActionsTypes.ADD_RECENT_ITEM_DOCUMENT;
  attachedFile: IAttachedFile;
}

export function addRecentItemDocument(attachedFile): IAddRecentItemDocumentAction {
  return {
    type: GeneralActionsTypes.ADD_RECENT_ITEM_DOCUMENT,
    attachedFile,
  };
}

export interface ISetMenuNavigationToggleAction {
  type: GeneralActionsTypes.SET_MENU_NAVIGATION_TOGGLE;
  toggle: boolean;
}

export function setMenuNavigationToggle(toggle: boolean): ISetMenuNavigationToggleAction {
  return {
    type: GeneralActionsTypes.SET_MENU_NAVIGATION_TOGGLE,
    toggle,
  };
}

export interface ISetInfoPanel {
  type: GeneralActionsTypes.SET_INFO_PANEL;
  infoPanel: InfoPanel;
}

export function setInfoPanel(infoPanel: InfoPanel): ISetInfoPanel {
  return {
    type: GeneralActionsTypes.SET_INFO_PANEL,
    infoPanel,
  };
}

// GET INFO PANEL CONFIG START
export interface IGetInfoPanelConfigRequestAction {
  type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_REQUEST;
}

export function getInfoPanelConfig(): IGetInfoPanelConfigRequestAction {
  return {
    type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_REQUEST,
  };
}

export interface IGetInfoPanelConfigSuccessAction {
  type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_SUCCESS;
  infoPanelConfig: IInfoPanelConfiguration;
}

export function getInfoPanelConfigSuccess(infoPanelConfig: IInfoPanelConfiguration): IGetInfoPanelConfigSuccessAction {
  return {
    type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_SUCCESS,
    infoPanelConfig,
  };
}

export interface IGetInfoPanelConfigFailureAction {
  type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_FAILURE;
  error: string;
}

export function getInfoPanelConfigFailure(error): IGetInfoPanelConfigFailureAction {
  return {
    type: GeneralActionsTypes.GET_INFO_PANEL_CONFIG_FAILURE,
    error: error.message,
  };
}
// GET INFO PANEL CONFIG END

// UPDATE INFO PANEL CONFIG START
export interface IUpdateInfoPanelConfigRequestAction {
  type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_REQUEST;
  infoPanelConfig: IInfoPanelConfiguration;
}

export function updateInfoPanelConfig(infoPanelConfig: IInfoPanelConfiguration): IUpdateInfoPanelConfigRequestAction {
  return {
    type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_REQUEST,
    infoPanelConfig,
  };
}

export interface IUpdateInfoPanelConfigSuccessAction {
  type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_SUCCESS;
  infoPanelConfig: IInfoPanelConfiguration;
}

export function updateInfoPanelConfigSuccess(
  infoPanelConfig: IInfoPanelConfiguration,
): IUpdateInfoPanelConfigSuccessAction {
  return {
    type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_SUCCESS,
    infoPanelConfig,
  };
}

export interface IUpdateInfoPanelConfigFailureAction {
  type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_FAILURE;
  error: string;
}

export function updateInfoPanelConfigFailure(error): IUpdateInfoPanelConfigFailureAction {
  return {
    type: GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_FAILURE,
    error: error.message,
  };
}
// UPDATE INFO PANEL CONFIG END

export type IGeneralActionsTypes =
  | IGetRecentItemsRequestAction
  | IGetRecentItemsSuccessAction
  | IGetRecentItemsFailureAction
  | IGetAllRolesRequestAction
  | IGetAllRolesSuccessAction
  | IGetAllRolesFailureAction
  | IGetRoleByIdRequestAction
  | IGetRoleByIdSuccessAction
  | IGetRoleByIdFailureAction
  | IAddRecentItemAction
  | IAddRecentItemDocumentAction
  | ISetMenuNavigationToggleAction
  | IResetRole
  | ISetInfoPanel
  | IGetInfoPanelConfigRequestAction
  | IGetInfoPanelConfigSuccessAction
  | IGetInfoPanelConfigFailureAction
  | IUpdateInfoPanelConfigRequestAction
  | IUpdateInfoPanelConfigSuccessAction
  | IUpdateInfoPanelConfigFailureAction;
