import React, { FC, Fragment } from "react";
import { createPortal } from "react-dom";

import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { IconButton, ScrollBars } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import { useInfoPanel } from "./info-panel.hooks";

import "./info-panel.scss";

export const InfoPanel: FC = () => {
  const cn = withNaming("info-panel");
  const { infoPanel, setInfoPanel } = useInfoPanel();

  const Scroll = infoPanel?.hasScroll ? ScrollBars : Fragment;

  return createPortal(
    <div className={cn("", { isShow: !!infoPanel }, ["shadow"])}>
      <IconButton cx={cn("close")} icon={closeIcon} onClick={() => setInfoPanel(null)} />
      <Scroll>{infoPanel?.component || null}</Scroll>
    </div>,
    document.getElementById("root"),
  );
};
