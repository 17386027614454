import React from "react";

import { ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { ISubarea, ITimePeriod } from "Store/reducers/filters.reducer";

export enum FilterInvolve {
  includeAny = "include_any",
  includeExact = "include_exact",
  exclude = "exclude",
}

export type IMinAndMaxDate = {
  minDate: Date;
  maxDate: Date;
};

export type IDates = Omit<ITimePeriod, "type">;

export interface IFilter {
  key: keyof IAttachedFile;
  caption: string;
  component: "picker-input" | "date-picker";
  data: string[] | IMinAndMaxDate;
  excludeFilterInvolveIds?: FilterInvolve[];
}

export type IShowSubscriptionsOnlyKey = "showSubscriptionsOnly";

export type IFilterGuidKey = "filterGuid";

export type IFilterKeys = keyof IAttachedFile | ToolbarKeys | IShowSubscriptionsOnlyKey | IFilterGuidKey;

export interface IDocumentsFilterPickerInputProps {
  field: keyof IAttachedFile;
  caption: string;
  data: string[];
  defaultData: ISubarea;
  excludeFilterInvolveIds?: FilterInvolve[];
  groupIndex: number;
  onFilterChanged?: () => void;
  isValueActive?: boolean;
}

export interface IDocumentsFilterDatePickerProps
  extends Omit<IDocumentsFilterPickerInputProps, "data" | "defaultData"> {
  data: IMinAndMaxDate;
  defaultData: ITimePeriod;
  groupIndex: number;
  onFilterChanged?: () => void;
}

export interface IDocumentsFilterTogglerProps {
  caption: string | React.ReactNode;
  isPickerInput?: boolean;
  defaultOpen?: boolean;
}

export enum PresetsActionTypes {
  "create" = "create",
  "createEmpty" = "createEmpty",
  "rename" = "rename",
  "delete" = "delete",
}
