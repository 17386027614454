import { IRefiner, ISearchQuery, ISearchResult, SearchResults, sp, Web } from "@pnp/sp/presets/all";

import { refinerNameToPropertyName } from "Helpers/constants";
import { getSiteInfo } from "SP/configure";
import { allLists, apostropheRegex } from "SP/constants";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { IRefinementFiltersGroup } from "Store/reducers/filters.reducer";

import { FqlService } from "./fql.service";
import { KqlService } from "./kql.service";
import { IListIds } from "./search.types";

export interface IGlobalSearchProps {
  QueryText: string;
  listsIds: string[];
  pagesId: string;
  rolesId: string;
  rolesFields: string[];
  documentsFields: string[];
  filters: IRefinementFiltersGroup[];
  rowsLimit: number;
  loadRefiners: boolean;
}

export interface IListSearchProps {
  queryText: string;
  listsIds: string[];
  filters: IRefinementFiltersGroup[];
  rowsLimit: number;
  searchAll: boolean;
  loadRefiners: boolean;
}

export interface ISearchRepositoryResult {
  SearchResults: ISearchResult[];
  Refiners: IRefiner[];
  TotalRows: number;
}

const RefinersString = Object.keys(refinerNameToPropertyName)
  .map((x) => `${x}(filter=1500/0/*,sort=name/ascending)`)
  .join(",");

export class SearchRepository {
  public static async executeSearch(QueryText: string, additionalRestrictions: string = null): Promise<SearchResults> {
    const query = this.addRestrictions(`"${QueryText}*"`, additionalRestrictions);

    return this.helpExecuteSearch(`${query}`);
  }

  public static async executeGlobalSearchExact(props: IGlobalSearchProps): Promise<ISearchRepositoryResult> {
    const formattedQuery = KqlService.formatQueryExact(props.QueryText);
    const query = KqlService.createGlobalQuery({ ...props, QueryText: formattedQuery });

    return this.executeRankingSearchExact(query, props.QueryText, props.filters, props.rowsLimit, props.loadRefiners);
  }

  public static async executeGlobalSearchAll(props: IGlobalSearchProps): Promise<ISearchRepositoryResult> {
    const formattedQuery = KqlService.formatQueryAll(props.QueryText);

    const QueryText = `(${formattedQuery})`;
    const query = KqlService.createGlobalQuery({ ...props, QueryText });

    return this.executeRankingSearchAll(query, props.QueryText, props.filters, props.rowsLimit, props.loadRefiners);
  }

  private static shouldAddStar(query: string) {
    return query.search(apostropheRegex) != query.length - 2;
  }

  public static executeDocumentsSearch(props: IListSearchProps): Promise<ISearchRepositoryResult> {
    return this.helpExecuteListSearch(props, (query, listIds) => KqlService.createDocumentsQuery(query, listIds));
  }

  public static executePagesSearch(props: IListSearchProps): Promise<ISearchRepositoryResult> {
    return this.helpExecuteListSearch(props, (query, lisIds) => KqlService.createPagesQuery(query, lisIds[0]));
  }

  private static helpExecuteListSearch(
    props: IListSearchProps,
    createQuery: (sourceQuery: string, listIds: string[]) => string,
  ): Promise<ISearchRepositoryResult> {
    const { searchAll, queryText, listsIds, filters, rowsLimit } = props;
    const orQuery = searchAll ? KqlService.formatQueryAll(queryText) : KqlService.formatQueryExact(queryText);
    const query = createQuery(orQuery, listsIds);

    if (searchAll) {
      return this.executeRankingSearchAll(query, queryText, filters, rowsLimit, props.loadRefiners);
    }

    return this.executeRankingSearchExact(query, queryText, filters, rowsLimit, props.loadRefiners);
  }

  private static excludeTextsQuery(textsToExclude: string[]): string {
    return textsToExclude.map((x) => `NOT "${x}"`).join(" AND ");
  }

  private static addRestrictions(QueryText: string, additionalRestrictions: string): string {
    const restrictionsQuery = ` AND (${additionalRestrictions})`;

    return `(${QueryText})${additionalRestrictions ? restrictionsQuery : ""}`;
  }

  public static async getListsId(): Promise<IListIds> {
    const listIds: IListIds = {};
    const batch = sp.createBatch();
    const Url = process.env.REACT_APP_BASE_URL;
    const web = Web(Url);

    for (const item of allLists) {
      web.lists
        .getByTitle(item)
        .usingCaching()
        .inBatch(batch)
        .get()
        .then((list) => {
          listIds[list.Id] = list.Title as LibraryName;
        });
    }

    await batch.execute();

    return listIds;
  }

  public static async getHighlightedSummary(QueryText: string): Promise<SearchResults> {
    const orQuery = SearchRepository.splitQuery(QueryText)
      .map((query) => `${query}*`)
      .join(" OR ");
    const formattedQuery = `${orQuery}`;

    return this.helpGetHighlightedSummary(formattedQuery);
  }

  public static async helpGetHighlightedSummary(QueryText: string): Promise<SearchResults> {
    const { Id, webId } = await getSiteInfo();
    const searchResults = await sp.search(<ISearchQuery>{
      Querytext: `SiteId:${Id} WebId:${webId} ${QueryText}`,
      RowLimit: 500,
      EnableInterleaving: true,
      SelectProperties: ["HitHighlightedSummary", "Title", "Path"],
    });

    return searchResults;
  }

  public static splitQuery(query: string): string[] {
    return KqlService.splitQuery(query);
  }

  private static async executeRankingSearchAll(
    QueryText: string,
    originalText: string,
    filters: IRefinementFiltersGroup[],
    rowsLimit: number,
    loadRefiners: boolean,
  ) {
    const queryExact = KqlService.formatQueryExact(originalText);
    const queryAll = KqlService.formatQueryAll(originalText);

    const { Id, webId } = await getSiteInfo();
    const searchResults = await this.executeSearchParallelPaging(
      `((SiteId:${Id} WebId:${webId} (${QueryText})) XRANK(cb=500) Title:${queryExact}) XRANK(cb=100) Title:${queryAll}`,
      filters,
      rowsLimit,
      loadRefiners,
    );

    return searchResults;
  }

  private static async executeRankingSearchExact(
    QueryText: string,
    originalText: string,
    filters: IRefinementFiltersGroup[],
    rowsLimit: number,
    loadRefiners: boolean,
  ) {
    const queryExact = KqlService.formatQueryExact(originalText);

    const { Id, webId } = await getSiteInfo();
    const searchResults = await this.executeSearchParallelPaging(
      `(SiteId:${Id} WebId:${webId} (${QueryText})) XRANK(cb=100) Title:${queryExact}`,
      filters,
      rowsLimit,
      loadRefiners,
    );

    return searchResults;
  }

  private static async helpExecuteSearch(QueryText: string): Promise<SearchResults> {
    const { Id, webId } = await getSiteInfo();
    const searchResults = await this.executeSpSearch(`SiteId:${Id} WebId:${webId} ${QueryText}`);

    return searchResults;
  }

  private static async executeSpSearch(
    QueryText: string,
    refinementFilters = "",
    rowLimit = 500,
    StartRow = 0,
    loadRefiners = true,
  ): Promise<SearchResults> {
    const searchResults = await sp.search(<ISearchQuery>{
      Querytext: QueryText,
      RowLimit: rowLimit,
      EnableInterleaving: true,
      HitHighlightedProperties: ["ShortdescriptioninOWSMTXT", "DocumentTextContent"],
      TrimDuplicates: false,
      SelectProperties: ["Path", "ListId", "LinkingUrl", "FileType", "FriendlyURL", "SPWebUrl", "PageTextContent"],
      StartRow: StartRow,
      RefinementFilters: refinementFilters ? [refinementFilters] : [],
      Refiners: loadRefiners ? RefinersString : null,
    });

    return searchResults;
  }

  private static async executeSearchParallelPaging(
    QueryText: string,
    filters: IRefinementFiltersGroup[],
    rowsLimit = 500,
    loadRefiners = true,
  ): Promise<ISearchRepositoryResult> {
    const globalResult = [];
    let startRow = 0;

    const parallelDegree = 1;
    const promises = [];

    const filtersString = FqlService.createFqlFromFiltersGroups(filters);

    for (let i = 0; i < parallelDegree; i++) {
      promises.push(this.executeSpSearch(QueryText, filtersString, rowsLimit, startRow, loadRefiners));
      startRow += rowsLimit;
    }

    const results = await Promise.all(promises);

    results.forEach((result) => {
      globalResult.push(...result.PrimarySearchResults);
    });

    return {
      SearchResults: globalResult,
      Refiners: results[0].RawSearchResults.PrimaryQueryResult.RefinementResults?.Refiners || [],
      TotalRows: results[0].RawSearchResults.PrimaryQueryResult.RelevantResults.TotalRows,
    };
  }
}
