import dayjs from "dayjs";
import React, { FC, useCallback, useEffect, useState } from "react";

import { createDateString } from "@App/libs/dayjs";
import { ReactComponent as togglerChevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as dateChevronDown } from "@epam/assets/icons/common/navigation-chevron-down-24.svg";
import { ReactComponent as togglerChevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { ReactComponent as dateChevronUp } from "@epam/assets/icons/common/navigation-chevron-up-24.svg";
import {
  DatePicker,
  IconButton,
  IconContainer,
  LinkButton,
  PickerInput,
  SearchInput,
  Spinner,
  Text,
  Tooltip,
} from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { FileIcons, RoleCategoryIconColor } from "Helpers/constants";
import { getDocumentViewUrl, getRecentItemUrl } from "Helpers/utils";
import { useDocumentsData } from "Hooks/useDocuments";
import { RECENT_FORMAT, useRecentItemsActions, useRecentItemsData, useRecentItemsMethods } from "Hooks/useRecentItems";
import { useUsersData } from "Hooks/useUsers";
import { ISpDocument } from "SP/documents/documents.types";
import { IVisited } from "SP/recent/recent.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

import "./recent-items.scss";

const cn = withNaming("recent-items");

const groupNames = {
  [dayjs().format(RECENT_FORMAT)]: "Today",
  [dayjs().subtract(1, "d").format(RECENT_FORMAT)]: "Yesterday",
};

const handleFilterCondition = (day: dayjs.Dayjs, dates: Set<string>) => {
  if (!dates) return true;

  return dates.has(day.format(RECENT_FORMAT));
};

const handleFilterGroupedRecentItems = (groupedRecentItems, searchQuery) => (group) => {
  return groupedRecentItems[group].some(handleFilterItems(searchQuery));
};

const handleFilterItems = (searchQuery) => (item) => {
  return item.title?.toLowerCase().includes(searchQuery?.toLowerCase());
};

const DatePickerInput = ({ value, onClick, isOpen }) => {
  return (
    <div
      className={cn("date-picker-input", { isOpen }, ["flex justify-between align-center cursor-pointer"])}
      onClick={onClick}
    >
      <Text cx="p-0" color="night900" fontSize="16" lineHeight="24">
        {createDateString(value, "MMMM DD, YYYY", "History")}
      </Text>
      <IconButton cx={cn("date-picker-input-icon")} icon={isOpen ? dateChevronUp : dateChevronDown} />
    </div>
  );
};

export const RecentItems: FC = () => {
  const { loading } = useRecentItemsData();
  const {
    filterDate,
    tabs,
    activeTab,
    onTabChange,
    recentItemsDates,
    setCurrentScrollableDate,
    groupedRecentItems,
    onDateChange,
    groupsRef,
  } = useRecentItemsActions();
  const { getRecentItems, addRecentItem } = useRecentItemsMethods();
  const [searchValue, setSearchValue] = useState("");
  const { currentUser } = useUsersData();

  const { allDocuments } = useDocumentsData();

  const tabsDataSource = useArrayDataSource(
    {
      items: tabs.map((tab) => ({
        id: tab,
        tabName: tab,
      })),
    },
    [tabs],
  );

  useEffect(() => {
    getRecentItems();
  }, []);

  const renderPickerToggler = useCallback(
    (props: PickerTogglerProps<any>) => {
      return (
        <div
          className={cn("picker-toggler", { isOpen: props.isOpen }, ["flex justify-between items-center"])}
          ref={props.ref}
          onClick={props.onClick}
        >
          <Text size="18" color="night900" fontSize="14" lineHeight="18">
            <span className={cn("picker-toggler-prefix")}>Show:&nbsp;</span>
            {activeTab}
          </Text>
          <IconButton cx={cn("picker-toggler-chevron")} icon={props.isOpen ? togglerChevronUp : togglerChevronDown} />
        </div>
      );
    },
    [activeTab],
  );

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setCurrentScrollableDate(scrollTop);
  };

  const handleVisitedClicked = (visited: IVisited) => {
    let newVisited = visited;

    if (Object.keys(allDocuments).includes(visited.list)) {
      const documents = allDocuments[visited.list] as ISpDocument[];

      const document = documents.find((x) => x.Id == visited.Id);

      newVisited = { ...visited, url: getDocumentViewUrl(document.viewUrl, document.FriendlyUrl) };
    }

    addRecentItem(newVisited);
    analyticsHelper.analyticsService?.provideLinkClick(getRecentItemUrl(visited.url), currentUser?.jobTitle);
  };

  return (
    <div className={cn("", ["flex flex-col"])} data-testid="recent-items-menu">
      <div className={cn("header", ["flex align-center"])}>
        <DatePicker
          cx={cn("date-picker")}
          format="YYYY-MM-DD"
          value={filterDate}
          filter={(day) => handleFilterCondition(day, recentItemsDates)}
          onValueChange={onDateChange}
          renderTarget={({ onClick, isOpen }) => (
            <DatePickerInput value={filterDate} onClick={onClick} isOpen={isOpen} />
          )}
        />
      </div>
      {loading && (
        <div className={cn("body", { loading: true }, ["flex flex-col"])}>
          <Spinner color="sky" />
        </div>
      )}
      {!loading && (
        <>
          <div className={cn("filters", { search: true })}>
            <SearchInput
              cx={cn("search")}
              value={searchValue}
              onValueChange={setSearchValue}
              placeholder="Search by Name"
            />
            <PickerInput
              dataSource={tabsDataSource}
              selectionMode="single"
              value={activeTab}
              valueType="id"
              onValueChange={(tabKey) => onTabChange(tabKey as string)}
              getName={(item) => item.tabName}
              minBodyWidth={100}
              disableClear
              placeholder="Show"
              renderToggler={renderPickerToggler}
            />
          </div>
          <div className="divider" />
          <div className={cn("body", ["flex flex-col"])} onScroll={handleScroll}>
            {Object.keys(groupedRecentItems)
              .filter(handleFilterGroupedRecentItems(groupedRecentItems, searchValue))
              .map((group) => {
                const groupName = groupNames[group] || dayjs(group, RECENT_FORMAT).format("dddd, MMMM DD");
                return (
                  <div
                    key={group}
                    className={cn("group")}
                    ref={(ref) => (groupsRef.current[group] = ref)}
                    data-testid="history-group"
                  >
                    <Text
                      cx={cn("group-name", ["flex justify-between items-center p-0"])}
                      color="night600"
                      font="sans-semibold"
                      fontSize="14"
                      lineHeight="18"
                    >
                      {groupName}
                      {groupName !== "Today" && <span className={cn("group-name-line")} />}
                    </Text>
                    {groupedRecentItems[group].filter(handleFilterItems(searchValue)).map((item) => (
                      <div
                        key={item.lastVisited + ""}
                        className={cn("group-item", ["flex justify-between items-start"])}
                        data-testid="group-item"
                      >
                        <div className={cn("group-item-title", ["flex flex-wrap justify-start items-start"])}>
                          <IconContainer
                            cx={cn("group-item-icon")}
                            icon={
                              item.list === LibraryName.certificates
                                ? FileIcons.certificate
                                : FileIcons[item.extension] || FileIcons.default
                            }
                            color={item.extension === "role" ? RoleCategoryIconColor[item.category] : null}
                          />
                          <LinkButton
                            cx={cn("group-item-link", ["hover-underline"])}
                            href={getRecentItemUrl(item.url)}
                            target="_blank"
                            caption={item.title}
                            onClick={() => handleVisitedClicked(item)}
                          />
                          {item.fileName &&
                            (item.list === LibraryName.regulations || item.list === LibraryName.templates) && (
                              <Tooltip cx={"single-line-tooltip"} content={item.fileName} placement="bottom">
                                <Text
                                  cx={cn("group-item-filename", ["cut-text"])}
                                  color="night600"
                                  fontSize="12"
                                  lineHeight="18"
                                >
                                  {item.fileName}
                                </Text>
                              </Tooltip>
                            )}
                        </div>
                        <Text cx={cn("group-item-time", ["p-0"])} color="night600" fontSize="12" lineHeight="18">
                          {dayjs(item.lastVisited).format("HH:mm")}
                        </Text>
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};
