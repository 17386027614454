import React, { HTMLAttributes } from "react";

import highlight from "./highlight";

interface TimingProps<T> extends HTMLAttributes<T> {
  elementtiming: string;
}

interface IHighlightWithTimingArgs {
  elementtiming: string;
  query: string;
  value: string;
  isExactPhrase?: boolean;
}

export const TimingDiv: React.FC<TimingProps<HTMLDivElement>> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

export const TimingSpan: React.FC<TimingProps<HTMLSpanElement>> = ({ children, ...props }) => (
  <span {...props}>{children}</span>
);

export const highlightWithTiming = ({ elementtiming, query, value, isExactPhrase }: IHighlightWithTimingArgs) => (
  <TimingSpan elementtiming={elementtiming}>{highlight.peek(query, value, isExactPhrase)}</TimingSpan>
);
