import _isEqual from "lodash/isEqual";
import React from "react";

import { svc } from "@App/services";
import { ReactComponent as MoreIcon } from "@epam/assets/icons/common/navigation-more_vert-18.svg";
import { DropdownMenuBody, DropdownMenuButton, FlexRow, IconButton, Tooltip } from "@epam/loveship";
import { Dropdown } from "@epam/uui-components";

import { useUsersMethods } from "Hooks/useUsers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IFiltersGroup } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData, useFiltersMethods } from "./documents-filter.hooks";
import { DocumentsFilterModal } from "./documents-filter-modal";

export interface IFilterGroupHeaderProps {
  group: IFiltersGroup;
  groupIndex: number;
  onToggleEnable: () => void;
}

export const DocumentsFilterGroupHeader: React.FC<IFilterGroupHeaderProps> = ({
  group,
  groupIndex,
  onToggleEnable,
}) => {
  const { renameFiltersGroup, deleteFiltersGroup } = useFiltersMethods();
  const { activePresetFilters, selectedFiltersGroups } = useFiltersData();
  const { saveFavoriteFilter } = useUsersMethods();

  return (
    <div className={cn("group-header")}>
      <FlexRow>
        {!group.enabled && "Disabled: "}
        <Tooltip trigger="hover" content={group.name} placement="left">
          <div className={cn("group-name", { disable: !group.enabled })}>{group.name}</div>
        </Tooltip>
      </FlexRow>
      <Dropdown
        renderTarget={(props) => <IconButton icon={MoreIcon} {...props} />}
        renderBody={() => (
          <DropdownMenuBody cx="dropdown dropdown--no-icons">
            <DropdownMenuButton caption={group.enabled ? "Disable" : "Enable"} onClick={onToggleEnable} />
            <DropdownMenuButton
              caption="Rename"
              onClick={() =>
                svc.uuiModals.show((modalProps) => (
                  <DocumentsFilterModal
                    modalProps={modalProps}
                    title={`Rename Filter Group "${group.name}"`}
                    label="New Name"
                    submitName="Rename"
                    text={group.name}
                    groupIndex={groupIndex}
                    submitAction={(x) =>
                      new Promise((resolve) => {
                        renameFiltersGroup(x, groupIndex);

                        if (
                          !activePresetFilters ||
                          !_isEqual(activePresetFilters.filtersGroups, selectedFiltersGroups)
                        ) {
                          resolve();
                          return;
                        }

                        const newFilters: IFavoriteFilter = {
                          ...activePresetFilters,
                          filtersGroups: activePresetFilters.filtersGroups.map((f, i) =>
                            i === groupIndex ? { ...f, name: x } : f,
                          ),
                        };

                        saveFavoriteFilter(newFilters, () => resolve());
                      })
                    }
                  />
                ))
              }
            />
            <DropdownMenuButton
              caption="Delete"
              onClick={() =>
                svc.uuiModals.show((modalProps) => (
                  <DocumentsFilterModal
                    modalProps={modalProps}
                    title={`Delete "${group.name}"`}
                    label="Name"
                    submitName="Delete"
                    submitAction={() =>
                      new Promise((resolve) => {
                        deleteFiltersGroup(groupIndex);
                        resolve();
                      })
                    }
                    isDeleteType
                  />
                ))
              }
            />
          </DropdownMenuBody>
        )}
        closeOnMouseLeave="boundary"
        placement="bottom-start"
      />
    </div>
  );
};
