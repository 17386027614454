import { useDispatch, useSelector } from "react-redux";

import { LibraryName } from "SP/sitePages/sitePages.types";
import { getListDriveId } from "Store/actions/drives.actions";
import { IRootReducerState } from "Store/reducers";

export const useDrivesData = () => {
  const drives = useSelector<IRootReducerState, Partial<Record<LibraryName, string>>>(
    (state) => state.drives.listsDriverId,
  );

  return {
    drives,
  };
};

export const useDrivesMethods = () => {
  const dispatch = useDispatch();

  const handleGetLibraryDriveId = (library: LibraryName) => dispatch(getListDriveId(library));

  return {
    getLibraryDriveId: handleGetLibraryDriveId,
  };
};
