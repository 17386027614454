import React from "react";

import { ReactComponent as downloadIcon } from "@epam/assets/icons/common/file-download-18.svg";
import { Button, FlexRow, Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import "./document-preview.scss";

const cn = withNaming("document-preview");

export interface IDocumentPreviewProps {
  objectUrl: string;
  documentName: string;
}

export const DocumentPreview = ({ objectUrl, documentName }: IDocumentPreviewProps) => {
  return (
    <>
      <FlexRow cx={cn("header")}>
        <Text cx={cn("name")}>{documentName}</Text>
        <a href={objectUrl} download={documentName} style={{ textDecoration: "none" }}>
          <Button caption={"Download document"} cx={cn("download")} icon={downloadIcon} />
        </a>
      </FlexRow>

      <iframe src={objectUrl} className={cn("content")} data-testid="document-preview"></iframe>
    </>
  );
};
