import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Loader } from "Components/loader";
import { performRedirectToRelativeUrl } from "Helpers/utils";
import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import { useSitePagesData, useSitePagesMethods } from "Hooks/useSitePages";
import { DynamicSitePage } from "Pages/DynamicSitePage";

export const OldUrlSitePage: FC<RouteComponentProps> = () => {
  const { getSitePageByUrl } = useSitePagesMethods();
  const { pathname: sitePageUrl } = useFriendlyURLPathname();
  const { sitePage } = useSitePagesData(sitePageUrl);

  useEffect(() => {
    getSitePageByUrl(sitePageUrl);
  }, [sitePageUrl]);

  useEffect(() => {
    performRedirectToRelativeUrl(sitePage?.friendlyUrl, location.search);
  }, [sitePage?.friendlyUrl, sitePageUrl]);

  return sitePage ? <DynamicSitePage pageUrl={sitePageUrl} /> : <Loader />;
};
