import { IItemUpdateResult, IList, sp } from "@pnp/sp/presets/all";

import { getFormattedFriendlyUrl } from "SP/sp.helpers";

import { certificatesListRequestInfo, filterQueryNotFolder } from "../../constants";
import { ICertificateDTO } from "./certificates.types";

export class CertificatesRepository {
  private certificatesList: IList = sp.web.lists.getByTitle("EPAM Certificates");
  public async getAll(): Promise<ICertificateDTO[]> {
    return await this.certificatesList.items
      .select(...certificatesListRequestInfo.selectArray)
      .expand(...certificatesListRequestInfo.expandArray)
      .filter(filterQueryNotFolder)
      .getAll(5000);
  }
  public async getById(id: number): Promise<ICertificateDTO> {
    return await this.certificatesList.items
      .getById(id)
      .select(...certificatesListRequestInfo.selectArray)
      .expand(...certificatesListRequestInfo.expandArray)
      .get();
  }

  public async getByServerRelativeUrl(url: string): Promise<ICertificateDTO> {
    const file = await sp.web.getFileByServerRelativeUrl(url).getItem();
    const item: ICertificateDTO = await file
      .select(...certificatesListRequestInfo.selectArray)
      .expand(...certificatesListRequestInfo.expandArray)
      .get();

    return item;
  }

  public async getByFriendlyUrl(friendlyURL: string): Promise<ICertificateDTO[]> {
    const formattedUrl = getFormattedFriendlyUrl(friendlyURL);

    return await this.certificatesList.items
      .filter(`FriendlyURL eq '${formattedUrl}'`)
      .select(...certificatesListRequestInfo.selectArray)
      .expand(...certificatesListRequestInfo.expandArray)
      .get();
  }

  public async updateDocument(id: number, data: Partial<ICertificateDTO>): Promise<IItemUpdateResult> {
    return await this.certificatesList.items.getById(id).update(data);
  }
}
