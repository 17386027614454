import React, { FC, useRef } from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification_18dp.svg";
import { ReactComponent as linkIcon } from "@epam/assets/icons/common/content-link-24.svg";
import { ReactComponent as checkIcon } from "@epam/assets/icons/common/notification-done-18.svg";
import { Button, IconButton, Tooltip } from "@epam/loveship";
import { FlexSpacer, Tooltip as TooltipType } from "@epam/uui-components";

import { loaderIcon } from "Components/icons";
import { withNaming } from "Helpers/bemClassname";
import { CopyText } from "Helpers/constants";
import { handleSubscribeToggle, ISubscribe } from "Helpers/subscribeHelper";
import { TimingDiv } from "Helpers/timingElements";
import { useCopyText } from "Hooks/useCopyText";

import "./page-title.scss";

interface IPageTitleProps {
  title: string;
  subscribe?: ISubscribe;
  defaultCopyUrl?: string;
}

export const PageTitle: FC<IPageTitleProps> = ({ title, subscribe, defaultCopyUrl }) => {
  const cn = withNaming("page-title");
  const tooltipRef = useRef<TooltipType>(null);
  const { isCopied, handleCopyUrl } = useCopyText({ ref: tooltipRef, content: defaultCopyUrl || window.location.href });

  return (
    <div className={cn("", ["flex items-center"])} data-testid="page-title">
      <TimingDiv className={cn("text")} elementtiming="page-title">
        {title}
      </TimingDiv>
      <Tooltip ref={tooltipRef} content={isCopied ? CopyText : "Copy the link to this page"} placement="bottom">
        <IconButton cx={cn("link-icon")} color="white" icon={linkIcon} onClick={handleCopyUrl} />
      </Tooltip>
      {subscribe && (
        <>
          <FlexSpacer />
          <Tooltip cx={cn("subscribe-tooltip")} content={subscribe.tooltipText} placement="bottom">
            <Button
              cx={cn("subscribe-button", { subscribed: subscribe.isSubscribed, disabled: subscribe.isLoading })}
              icon={subscribe.isLoading ? loaderIcon : subscribe.isSubscribed ? checkIcon : notificationIcon}
              fill={subscribe.isSubscribed ? "white" : "none"}
              color={subscribe.isSubscribed ? "sky" : "white"}
              caption={subscribe.isSubscribed ? "You’re subscribed" : "Subscribe"}
              isDisabled={subscribe.isLoading}
              onClick={handleSubscribeToggle(subscribe)}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};
