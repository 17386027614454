import React, { FC } from "react";

import { ReactComponent as userIcon } from "@epam/assets/icons/common/social-person-18.svg";
import { IconContainer, Tooltip } from "@epam/loveship";

import { renderText } from "Components/documents-table/documents-table.helpers";
import { withNaming } from "Helpers/bemClassname";
import { RoleCategoryIconColor } from "Helpers/constants";
import highlight from "Helpers/highlight";

import "./role-category.scss";

enum RoleCategoryTooltip {
  "Defined in EPAM processes" = "Roles that are defined in EPAM processes and can be assigned to project team members additionally",
  "Assigned on the projects" = "Roles that are assigned on the projects and aligned with the Project Resource Plan",
}

interface RoleCategoryProps {
  text: string;
  searchValue?: string;
  isRolePage?: boolean;
}

export const RoleCategory: FC<RoleCategoryProps> = ({ text, searchValue, isRolePage }) => {
  const iconColor = RoleCategoryIconColor[text];
  const cn = withNaming("role-category");

  return (
    <Tooltip content={RoleCategoryTooltip[text]} placement="bottom">
      <div className="flex">
        {iconColor && <IconContainer cx={cn("icon")} icon={userIcon} color={iconColor} />}
        {renderText(
          searchValue ? highlight.peek(searchValue, text) : text,
          !isRolePage && {
            fontSize: "12",
            color: "night600",
          },
        )}
      </div>
    </Tooltip>
  );
};
