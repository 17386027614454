import React, { FC } from "react";

import { Text } from "@epam/loveship";

import { MAX_ROWS_TO_SHOW } from "Components/show-curtain-search/show-curtain-search";
import { Tooltip as CustomTooltip } from "Components/tooltip";
import { withNaming } from "Helpers/bemClassname";
import { searchSpecialSymbols } from "SP/constants";
import { IHitHighlightedAll, IHitHighlightedExact } from "SP/search/search.types";

import "./found-content.scss";

const ROW_CHARACTERS_LENGTH = 300;

interface IFoundContentProps {
  hitHighlightedAll: IHitHighlightedAll;
  isCard?: boolean;
}

function isLetter(symbol: string) {
  return !!symbol.match(/[a-zA-Z0-9]/);
}

function isStartOfWord(text: string, index: number): boolean {
  if (index === 0) return isLetter(text[index]);

  return !isLetter(text[index - 1]) && isLetter(text[index]);
}

function isEndOfWord(text: string, index: number): boolean {
  if (index === text.length - 1) return isLetter(text[index]);

  return isLetter(text[index]) && !isLetter(text[index + 1]);
}

function getOccurrences(text: string, phrase: string): number[] {
  if (!text || !phrase) return [];

  const phraseLength = phrase.length;

  text = text.toLowerCase();
  phrase = phrase.toLocaleLowerCase();

  let startIndex = 0;
  const result = [];
  let index = text.indexOf(phrase);
  while (index !== -1) {
    if (isSeparatePhrase(text, phrase, index)) {
      result.push(index);
    }

    startIndex = index + phraseLength;
    index = text.indexOf(phrase, startIndex);
  }

  return result;
}

function getFirstOccurrence(text: string, phrase: string, checkOccurrence: (index: number) => boolean): number {
  text = text.toLowerCase();
  phrase = phrase.toLocaleLowerCase();

  let index = text.indexOf(phrase);

  while (index !== -1) {
    if (checkOccurrence(index)) {
      return index;
    }

    index = text.indexOf(phrase, index + 1);
  }

  return -1;
}

export function getFirstExactOccurrence(text: string, phrase: string): number {
  return getFirstOccurrence(text, phrase, (index) => isSeparatePhrase(text, phrase, index));
}

export function getFirstAllOccurrence(hitHighlighted: IHitHighlightedAll): number {
  const { text, queries } = hitHighlighted;

  let result = -1;
  for (const query of queries) {
    if (result === -1) {
      result = getFirstStartWithOccurrence(text, query);
    }
  }

  return result;
}

function getFirstStartWithOccurrence(text: string, phrase: string): number {
  return getFirstOccurrence(text, phrase, (index) => isStartOfWord(text, index));
}

function isSeparatePhrase(text: string, phrase: string, index: number) {
  const indexLast = index + phrase.length - 1;
  return (
    (!isLetter(text[index]) || isStartOfWord(text, index)) &&
    (isEndOfWord(text, indexLast) || !isLetter(text[indexLast]))
  );
}

export const getTextToHighlightExact = (hitHighlighted: IHitHighlightedExact) => {
  const index = getFirstExactOccurrence(hitHighlighted.text, hitHighlighted.query);

  if (index === -1) return "";

  return hitHighlighted.text.substring(index);
};

export const getTextToHighlightAll = (hitHighlighted: IHitHighlightedAll) => {
  const index = getFirstAllOccurrence(hitHighlighted);

  if (index === -1) return "";

  const length = (window.innerWidth / 1920) * ROW_CHARACTERS_LENGTH * (MAX_ROWS_TO_SHOW + 1);
  return hitHighlighted.text.substring(index, index + length);
};

export const checkHighlightExact = (hitHighlighted: IHitHighlightedExact) => {
  const { text, query } = hitHighlighted;

  const occurrences = getOccurrences(text, query);

  return occurrences.length > 0;
};

export const renderHighlightedExact = (hitHighlightedProperties: IHitHighlightedExact) => {
  const { text, query } = hitHighlightedProperties;

  const occurrences = getOccurrences(text, query);

  const results = [];
  const phraseLength = query.length;
  let startIndex = 0;
  for (const index of occurrences) {
    results.push(text.substring(startIndex, index));
    results.push(text.substring(index, index + phraseLength));

    startIndex = index + phraseLength;
  }

  results.push(text.substring(startIndex, text.length));

  return results.map((x, i) => {
    if (i % 2 == 0) {
      return x;
    }

    return (
      <>
        {" "}
        <span className="highlight">{x}</span>
        {i == results.length - 1 && " "}
      </>
    );
  });
};

export const getUniqueOccurrencesAll = (hitHighlightedAll: IHitHighlightedAll): Set<string> => {
  const { text, queries } = hitHighlightedAll;

  const wordsToHighlight = new Set<string>();

  text?.split(/\s/).forEach((word) => {
    const matchedWord = word.match(/[\w']+/);

    if (!matchedWord) return;

    const wordToFind = matchedWord[0].toLocaleLowerCase();
    const index = queries.findIndex((query) => wordToFind.startsWith(query));

    if (index !== -1) {
      wordsToHighlight.add(queries[index]);
    }
  });

  return wordsToHighlight;
};

export const checkOccurrencesAll = (hitHighlightedAll: IHitHighlightedAll) => {
  return checkHighlightAll(hitHighlightedAll);
};

export const checkHighlightAll = (hitHighlightedAll: IHitHighlightedAll) => {
  return helpCheckWordsAny(hitHighlightedAll, isWordStartsWithQuery);
};

const helpCheckWordsAny = (
  hitHighlightedAll: IHitHighlightedAll,
  checkWord: (queries: string[], word: RegExpMatchArray) => boolean,
) => {
  const { text, queries } = hitHighlightedAll;

  if (!text || !queries) return false;

  if (queries.length === 0) return true;

  const regex = getWordsSeparationRegex();
  const matches = text.matchAll(regex);

  return [...matches].some((word) => {
    return checkWord(queries, word);
  });
};

const getWordsSeparationRegex = () => {
  const delimiters = [...searchSpecialSymbols, "s"];
  const wordPart = delimiters.map((x) => `^\\${x}`);
  const delimitersPart = delimiters.map((x) => `\\${x}`);

  return new RegExp(`([${wordPart}]+)([${delimitersPart}]*)`, "g");
};

export const renderHighlightedAll = (hitHighlightedAll: IHitHighlightedAll) => {
  const { text, queries } = hitHighlightedAll;

  const regex = getWordsSeparationRegex();
  const matches = text.matchAll(regex);

  return [...matches].map((match) => {
    if (isWordStartsWithQuery(queries, match)) {
      return (
        <>
          <span className="highlight">{match[1]}</span>
          {match[2]}
        </>
      );
    }

    return `${match[1]}${match[2]}`;
  });
};

const isWordStartsWithQuery = (queries: string[], word: RegExpMatchArray) => {
  if (!word) return;

  const wordText = word[0].toLowerCase();
  return queries.some((query) => wordText.startsWith(query));
};

export const FoundContent: FC<IFoundContentProps> = ({ hitHighlightedAll, isCard }) => {
  const cn = withNaming("found-content");

  if (!hitHighlightedAll) {
    return null;
  }

  const content = (
    <>
      <Text cx={cn("suffix", ["p-0"])} color="night600" fontSize="12" lineHeight="18">
        Found:
      </Text>
      <Text cx={cn("content", ["p-0 one-line"])} color="night900" fontSize="12" lineHeight="18">
        {renderHighlightedAll(hitHighlightedAll)}
      </Text>
    </>
  );

  return (
    <CustomTooltip cx={cn("tooltip", ["shadow"])} trigger="hover" placement="bottom-start" content={() => content}>
      <div className={cn("", { isCard }, ["flex"])}>{content}</div>
    </CustomTooltip>
  );
};
