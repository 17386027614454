import React, { useEffect } from "react";

import { useAnalytics } from "Hooks/useAnalytics";
import { useUsersData } from "Hooks/useUsers";

import { analyticsHelper } from "./analyticsHelper";

export interface ISessionStartAnalyticsProps {
  pageName: string;
}

export function withSessionStartAnalytics<WrappedProps>(
  Component: React.ComponentType<WrappedProps>,
  pageName: string,
) {
  return (props: WrappedProps) => {
    const { currentUser } = useUsersData();
    const { sessionStartSent, setSessionStartSent } = useAnalytics();

    useEffect(() => {
      if (currentUser && !sessionStartSent) {
        analyticsHelper.analyticsService.provideSessionStart(currentUser?.jobTitle, pageName);
        setSessionStartSent(true);
      }
    }, [currentUser]);

    return <Component {...props} />;
  };
}
