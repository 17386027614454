import React, { FC, useState } from "react";

import { Switch } from "@epam/loveship";

import { useFiltersActions, useFiltersMethods } from "Components/documents-filter/documents-filter.hooks";

import { cn } from "./documents-filter";

export const DocumentsFilterSubscriptionsSwitcher: FC<{ defaultValue: boolean; groupIndex: number }> = ({
  defaultValue,
  groupIndex,
}) => {
  const [showSubscriptionsOnly, setShowSubscriptionsOnly] = useState<boolean>(defaultValue);
  const { setFilter } = useFiltersActions();
  const { setCheckedFilterField } = useFiltersMethods();

  const handleShowSubscriptionsOnly = () => {
    const newShowSubscriptionsOnly = !showSubscriptionsOnly;
    setShowSubscriptionsOnly(newShowSubscriptionsOnly);
    setFilter("showSubscriptionsOnly", newShowSubscriptionsOnly, groupIndex, false);
    setCheckedFilterField(groupIndex, newShowSubscriptionsOnly ? "showSubscriptionsOnly" : "");
  };

  return (
    <>
      <Switch
        cx={cn("panel-switcher", ["justify-between"])}
        label="Subscriptions only"
        value={showSubscriptionsOnly}
        onValueChange={handleShowSubscriptionsOnly}
        size="18"
      />
      <div className={cn("picker-divider", ["divider"])} />
    </>
  );
};
