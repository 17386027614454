import { IItemUpdateResult, IList, sp } from "@pnp/sp/presets/all";

import { PublishedRegulationsColumns, publishedRegulationsRequestInfo } from "SP/constants";

import { IRegulationDTO } from "../regulations/regulations.types";

export class PublishedRegulationRepository {
  private publishedRegulations: IList = sp.web.lists.getByTitle("Published EPAM Regulations");

  public async getByEpamDocumentId(documentId: string): Promise<IRegulationDTO[]> {
    return await this.publishedRegulations.items
      .filter(`${PublishedRegulationsColumns.EpamDocumentId} eq '${documentId}'`)
      .select(...publishedRegulationsRequestInfo.selectArray)
      .expand(...publishedRegulationsRequestInfo.expandArray)
      .get();
  }

  public async updateDocument(id: number, data: Partial<IRegulationDTO>): Promise<IItemUpdateResult> {
    return await this.publishedRegulations.items.getById(id).update(data);
  }
}
