import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

export enum StandardsIconsActionTypes {
  GET_ICONS_REQUEST = "[STANDARDS ICONS] GET ICONS REQUEST",
  GET_ICONS_SUCCESS = "[STANDARDS ICONS] GET ICONS SUCCESS",
  GET_ICONS_FAILURE = "[STANDARDS ICONS] GET ICONS FAILURE",
}

export interface IGetIconsRequestAction {
  type: StandardsIconsActionTypes.GET_ICONS_REQUEST;
}

export function getIconsRequest(): IGetIconsRequestAction {
  return {
    type: StandardsIconsActionTypes.GET_ICONS_REQUEST,
  };
}

export interface IGetIconsSuccessAction {
  type: StandardsIconsActionTypes.GET_ICONS_SUCCESS;
  payload: IStandardIcon[];
}

export function getIconsSuccess(icons: IStandardIcon[]): IGetIconsSuccessAction {
  return {
    type: StandardsIconsActionTypes.GET_ICONS_SUCCESS,
    payload: icons,
  };
}

export interface IGetIconsFailureAction {
  type: StandardsIconsActionTypes.GET_ICONS_FAILURE;
}

export function getIconsFailure(): IGetIconsFailureAction {
  return {
    type: StandardsIconsActionTypes.GET_ICONS_FAILURE,
  };
}

export type IStandardsIconsActionsTypes = IGetIconsRequestAction | IGetIconsSuccessAction | IGetIconsFailureAction;
