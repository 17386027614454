import React from "react";

import { ReactComponent as recentIcon } from "@epam/assets/icons/common/action-history-24.svg";
import { MainMenuIcon, Tooltip } from "@epam/loveship";

import { IButtonAnalyticsProps, withButtonAnalytics } from "Helpers/withButtonAnalytics";

export const HistoryButton: React.FC<IButtonAnalyticsProps> = ({ caption, onClick }: IButtonAnalyticsProps) => (
  <Tooltip content={caption}>
    <MainMenuIcon icon={recentIcon} priority={10} estimatedWidth={48} onClick={onClick} />
  </Tooltip>
);

export const HistoryButtonWithAnalytics = withButtonAnalytics(HistoryButton);
