import isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";

import { ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { propertyNameToRefinerName } from "Helpers/constants";
import { IHyperLinkField } from "SP/helpers.types";
import { IRefinementItem } from "SP/search/search.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { IFilterGroupResult, IFiltersGroup, ISelectedFilter } from "Store/reducers/filters.reducer";

import { FilterInvolve, IFilter, IMinAndMaxDate } from "../documents-filter.interface";

export const toolbarFilters = Object.keys(ToolbarKeys);

export function getFilterPanelKeys(selectedFilters: ISelectedFilter, isNeedAllFilters = false) {
  if (!selectedFilters) return [];

  return Object.keys(selectedFilters).filter((filterKey) => isNeedAllFilters || !toolbarFilters.includes(filterKey));
}

export function getFilterPanelKeysGroups(filterGroups: IFiltersGroup[], isNeedAllFilters = false) {
  const result = [];

  for (const group of filterGroups) {
    if (group.enabled) {
      const keys = getFilterPanelKeys(group.filters, isNeedAllFilters);
      result.push(keys);
    }
  }

  return result;
}

export function getMinAndMaxDates(key: keyof IAttachedFile, documents: IAttachedFile[]): IMinAndMaxDate {
  let minDate: Date;
  let maxDate: Date;

  documents.forEach((document) => {
    const date = document[key] as Date;
    if (!date) return;

    if (!minDate || date < minDate) {
      minDate = date;
    }

    if (!maxDate || date > maxDate) {
      maxDate = date;
    }
  });

  return { minDate, maxDate };
}

export function getFieldValues(key: keyof IAttachedFile, document: IAttachedFile, childKey?: keyof IHyperLinkField) {
  const field = document[key];

  if (!field || isEmpty(field)) return [""];

  if (Array.isArray(field)) {
    return field.map((fieldItem) => {
      if (_isObject(fieldItem) && childKey) {
        return fieldItem[childKey]?.trim();
      }

      return fieldItem;
    });
  }

  if (_isObject(field) && childKey) {
    return [field[childKey]?.trim()];
  }

  return [field];
}

export function getFieldUniqueValues(
  key: keyof IAttachedFile,
  documents: IAttachedFile[],
  childKey?: keyof IHyperLinkField,
): string[] {
  let allValues = [];

  documents.forEach((document) => {
    const documentValues = getFieldValues(key, document, childKey);
    allValues = allValues.concat(documentValues);
  });

  return [...new Set(allValues)].sort((a, b) => a.localeCompare(b));
}

export const filterInvolveItems = [
  {
    id: FilterInvolve.includeAny,
    label: "Including any of",
  },
  {
    id: FilterInvolve.includeExact,
    label: "Including exactly",
  },
  {
    id: FilterInvolve.exclude,
    label: "Excluding",
  },
];

export const getPanelFiltersToRender = (
  refiners: IRefinementItem[],
  infoPanelFilters: IFilter[],
  filterGroupResults: IFilterGroupResult[],
  groupIndex: number,
): IFilter[] => {
  const result = [];

  const handleInputPicker = (filter: IFilter, refinerIndex: number) => {
    const refiner = refiners[refinerIndex];
    result.push({ ...filter, data: refiner.Entries.map((x) => x.Value) });
  };

  const handleDatePicker = (filter: IFilter) => {
    const showFilter = filterGroupResults[groupIndex].result.some((x) => Boolean(x[filter.key]));
    if (showFilter) {
      result.push(filter);
    }
  };

  infoPanelFilters.forEach((filter) => {
    const refinerName = propertyNameToRefinerName[filter.key];

    const refinerIndex = refiners.findIndex((x) => x.Name === refinerName);

    if (refinerIndex === -1) return;

    if (filter.component === "date-picker") {
      handleDatePicker(filter);
      return;
    }

    handleInputPicker(filter, refinerIndex);
  });

  return result;
};
