import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox, PickerInput, SearchInput, Text, VirtualList } from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import {
  useFiltersActions,
  useFiltersData,
  useFiltersMethods,
} from "Components/documents-filter/documents-filter.hooks";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { handleCheckboxSelection, hasFilterValueInItem, isQueryWithinText } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { useVirtualList } from "Hooks/useVirtualList";
import { FilterValueType, IFilterGroupResult } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { IDocumentsFilterPickerInputProps } from "./documents-filter.interface";
import { DocumentsFilterToggler } from "./documents-filter-toggler";
import { filterInvolveItems } from "./filters/filters.helpers";

export const DocumentsFilterPickerInput: FC<IDocumentsFilterPickerInputProps> = ({
  field,
  caption,
  defaultData,
  data,
  excludeFilterInvolveIds,
  groupIndex,
  onFilterChanged,
  isValueActive,
}) => {
  const { filterGroupResults, selectedFiltersGroups } = useFiltersData();
  const { setCheckedFilterField } = useFiltersMethods();

  const defaultIsFilterValueActive = (filterItemValue) => {
    if (
      !selectedFiltersGroups[groupIndex]?.filters?.showSubscriptionsOnly &&
      filterGroupResults[groupIndex]?.lastChangedFilterField === "showSubscriptionsOnly"
    ) {
      return true;
    }

    if (filterGroupResults[groupIndex]) {
      return (storedFilteredResult || filterGroupResults[groupIndex]).result.some((resultItem) =>
        hasFilterValueInItem(resultItem, field, filterItemValue),
      );
    }
  };

  const isFilterValueActive = (filterItemValue) => {
    if (isValueActive !== undefined) {
      return isValueActive;
    }

    return defaultIsFilterValueActive(filterItemValue);
  };

  const [storedData, setStoredData] = useState(data);
  const [search, setSearch] = useState<string>("");
  const [storedFilteredResult, setStoredFilteredResult] = useState<IFilterGroupResult>(null);
  const [filterInvolve, setFilterInvolve] = useState(defaultData?.filterInvolve || filterInvolveItems[0].id);
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultData?.items || []);
  const { setFilter } = useFiltersActions();
  const { virtualState, visibleItems, setVirtualState, resetVirtualState } = useVirtualList<string>(storedData, 9);
  const [storedActiveItems, setActiveItems] = useState<string[]>(data.filter(isFilterValueActive));

  const { currentUser } = useUsersData();

  useEffect(() => {
    setActiveItems(data.filter(isFilterValueActive));
  }, [data]);

  const filterInvolveDataSource = useArrayDataSource(
    {
      items: filterInvolveItems.filter((filterInvolveItem) => !excludeFilterInvolveIds?.includes(filterInvolveItem.id)),
    },
    [],
  );

  useEffect(() => {
    setSelectedItems(defaultData?.items || []);
  }, [defaultData]);

  const updateSortedStoredData = () => {
    resetVirtualState();
    const storedItems = [...storedActiveItems, ...data.filter((item) => !storedActiveItems.includes(item))];
    setStoredData(storedItems);
  };

  const handleSearchChange = (value: string) => {
    const sortedItems = [...storedActiveItems, ...data.filter((item) => !storedActiveItems.includes(item))];
    const filteredData = sortedItems.filter((item) => isQueryWithinText(item, value, "includes"));

    resetVirtualState();
    setSearch(value);
    setStoredData(filteredData);
  };

  const updateSearch = useCallback(() => {
    if (search.length > 0) {
      handleSearchChange(search);
    }
  }, [search]);

  const handleFilterInvolveChange = (newFilterInvolve) => {
    setFilter(
      field,
      {
        type: FilterValueType.subarea,
        filterInvolve: newFilterInvolve,
        items: selectedItems,
      },
      groupIndex,
    );
    setFilterInvolve(newFilterInvolve);

    onFilterChanged?.();
  };

  const handleFilterItemsChange = (items: string[]) => {
    if (field !== filterGroupResults[groupIndex]?.lastChangedFilterField) {
      setCheckedFilterField(groupIndex, field);
    }

    setFilter(
      field,
      {
        type: FilterValueType.subarea,
        filterInvolve,
        items,
      },
      groupIndex,
    );
    analyticsHelper.analyticsService?.provideFilter(field, currentUser?.jobTitle);

    if (items.length === 0 && field === filterGroupResults[groupIndex]?.lastChangedFilterField) {
      setCheckedFilterField(groupIndex, "");
    }

    onFilterChanged?.();
  };

  const handleSelectAllClick = (newCbValue) => {
    const isAllSelectedItemsActive = selectedItems.every((item) => storedActiveItems.includes(item));
    const items = newCbValue && isAllSelectedItemsActive ? storedActiveItems : [];
    if (field !== filterGroupResults[groupIndex].lastChangedFilterField && isAllSelectedItemsActive) {
      setCheckedFilterField(groupIndex, field);
    }
    setFilter(
      field,
      {
        type: FilterValueType.subarea,
        filterInvolve,
        items,
      },
      groupIndex,
    );
    setSelectedItems(items);

    if (items.length === 0 && field === filterGroupResults[groupIndex].lastChangedFilterField) {
      setCheckedFilterField(groupIndex, "");
    }

    onFilterChanged?.();
  };

  const isCurrentFilterGroupResultChanged = () => {
    const isCurrentField = field === filterGroupResults[groupIndex]?.lastChangedFilterField;
    const isResultChanged =
      !storedFilteredResult || filterGroupResults[groupIndex]?.result.length != storedFilteredResult.result.length;

    return isCurrentField && isResultChanged;
  };

  const onClearSection = () => {
    setFilter(
      field,
      {
        type: FilterValueType.subarea,
        filterInvolve,
        items: [],
      },
      groupIndex,
    );
    setSelectedItems([]);

    if (field === filterGroupResults[groupIndex].lastChangedFilterField) {
      setCheckedFilterField(groupIndex, "");
    }

    onFilterChanged?.();
  };

  useEffect(() => {
    if (!_isEqual(storedData, data)) {
      setStoredData(data);
      updateSearch();
    }
  }, [data]);

  useEffect(() => {
    if (!isCurrentFilterGroupResultChanged() || _isEmpty(selectedFiltersGroups[groupIndex].filters)) {
      setStoredFilteredResult(filterGroupResults[groupIndex]);
    }
  }, [filterGroupResults[groupIndex]?.lastChangedFilterField, filterGroupResults[groupIndex]?.result]);

  useEffect(() => {
    if (field !== storedFilteredResult?.lastChangedFilterField || _isEmpty(selectedFiltersGroups[groupIndex].filters)) {
      setActiveItems(data.filter(isFilterValueActive));
    }
  }, [storedFilteredResult]);

  useEffect(() => {
    updateSortedStoredData();
    updateSearch();
  }, [storedActiveItems]);

  useEffect(() => {
    setStoredFilteredResult(filterGroupResults[groupIndex]);
    setActiveItems(data.filter(isFilterValueActive));
    setCheckedFilterField(groupIndex, "");
  }, [selectedFiltersGroups[groupIndex].filters.show]);

  const visibleRows = useMemo(() => {
    const rows = [];
    if (virtualState.topIndex === 0) {
      rows.push(
        <div key={`selectAll_${field}`} className={cn("picker-input-item", ["flex items-center cursor-pointer"])}>
          <Checkbox
            cx={cn("picker-input-checkbox", [cn("select-all-picker-input-checkbox")])}
            size="18"
            value={storedActiveItems.length === selectedItems.length}
            onValueChange={handleSelectAllClick}
            label={"Select All"}
          />
        </div>,
      );
    }

    rows.push(
      ...visibleItems.map((item) => (
        <div key={item} className={cn("picker-input-item", ["flex items-center cursor-pointer"])}>
          <Checkbox
            cx={!storedActiveItems.includes(item) ? cn("disabled-picker-input-checkbox") : cn("picker-input-checkbox")}
            size="18"
            isDisabled={!storedActiveItems.includes(item)}
            value={selectedItems.includes(item)}
            onValueChange={handleCheckboxSelection<string>({
              item,
              items: selectedItems,
              setter: setSelectedItems,
              cb: handleFilterItemsChange,
            })}
            label={item.length === 0 ? "Empty" : item}
            rawProps={{ "data-testid": "filter-checkbox" }}
          />
        </div>
      )),
    );
    return rows;
  }, [visibleItems, virtualState]);

  const renderToggler = useCallback(
    (props: PickerTogglerProps<any>) => {
      const selectedItem = props.selection[0]?.value;

      const handleTogglerClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onClick(event);
      };

      const captionText = `${caption}${selectedItems.length > 0 ? "," : ""}`;

      return (
        <div className={cn("picker-input-involve-picker", ["flex justify-between items-center"])} ref={props.ref}>
          <Text
            cx={cn("picker-input-involve-picker-caption", ["one-line"])}
            size="none"
            color="night900"
            font="sans-semibold"
            fontSize="14"
            lineHeight="18"
          >
            {captionText}
          </Text>
          {selectedItems.length > 0 && (
            <Text
              cx={cn("picker-input-involve-picker-toggler", { open: props.isOpen }, ["one-line"])}
              size="none"
              color="night600"
              fontSize="12"
              lineHeight="18"
              onClick={handleTogglerClick}
              rawProps={{ "data-testid": "involve-picker-toggler" }}
            >
              {selectedItem ? props.getName(selectedItem).toLowerCase() : props.placeholder}
              {` ${selectedItems.length} items`}
            </Text>
          )}
        </div>
      );
    },
    [selectedItems],
  );

  return (
    <div className={cn("picker-input-wrapper")}>
      <DocumentsFilterToggler
        isPickerInput
        defaultOpen={!!defaultData}
        caption={
          <PickerInput
            selectionMode="single"
            valueType="id"
            minBodyWidth={210}
            getName={(item) => item.label}
            dataSource={filterInvolveDataSource}
            value={filterInvolve}
            onValueChange={handleFilterInvolveChange}
            renderToggler={renderToggler}
          />
        }
      >
        <div className={cn("picker-content")}>
          <div className={cn("clear-section")}>
            <Text cx={"text"} onClick={onClearSection}>
              Clear
            </Text>
          </div>
          {data.length > 10 && (
            <SearchInput
              cx={cn("picker-input-search")}
              placeholder="Search"
              value={search}
              onValueChange={handleSearchChange}
            />
          )}
          <VirtualList
            cx={cn("picker-input-list")}
            rows={visibleRows}
            value={virtualState}
            onValueChange={setVirtualState}
            rowsCount={storedData.length}
          />
        </div>
      </DocumentsFilterToggler>
    </div>
  );
};
