import { renderHighlightedAll, renderHighlightedExact } from "Components/found-content/found-content";
import { SearchService } from "SP/search/search.service";

export const regExpToReplace = /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g;

export default (function () {
  const cache = new Map<string, string | JSX.Element | JSX.Element[] | (string | JSX.Element)[]>();

  function peek(query: string, value: string, exactPhrase = false) {
    if (!value) return "";
    if (!query) return value;

    const key = `${query}_${value}_${exactPhrase ? "Exact" : "Any"}`;
    if (cache.has(key)) {
      return cache.get(key);
    }
    const queries = SearchService.getQueriesAll(query);

    const highlightedResult = exactPhrase
      ? renderHighlightedExact({ query, text: value })
      : renderHighlightedAll({ queries, text: value });

    cache.set(key, highlightedResult);

    return highlightedResult;
  }

  function getQueries(query: string, value: string, exactPhrase) {
    if (!value) return [""];
    if (!query) return [value];

    const formattedQuery = query.toLowerCase().trim().replace(regExpToReplace, "\\$&");

    const queries = exactPhrase ? [formattedQuery] : formattedQuery.split(/\s/);

    return queries;
  }

  function clear() {
    cache.clear();
  }

  return {
    clear,
    peek,
    getQueries,
  };
})();
