import React, { FC } from "react";

import { FlexRow, FlexSpacer, Text } from "@epam/loveship";

import { ButtonWithAnalytics } from "Components/button-with-analytics/button-with-analytics";
import { PageCard } from "Components/page-card";
import { withNaming } from "Helpers/bemClassname";
import { Links, PageCards } from "Helpers/constants";

import "./landing-page.scss";

export const LandingPage: FC = () => {
  const cn = withNaming("landing-page");

  return (
    <div className={cn()}>
      <div className={cn("header")}>EPAM Ethics & Compliance Program</div>
      <FlexRow alignItems="stretch">
        <ButtonWithAnalytics
          cx={cn("button", ["landing-btn shadow"])}
          caption={"EPAM Code\nof Ethical Conduct"}
          href={Links.epamCodeOfEthicalConduct}
          target="_blank"
        />
        <FlexSpacer />
        <PageCard card={PageCards.LandingPage.globalPolicies} />
      </FlexRow>
      <Text cx={cn("text-links")} color="night900" fontSize="12" lineHeight="18">
        Publicly available on&nbsp;
        <a className={cn("link")} href={Links.epam} target="_blank" rel="noreferrer">
          epam.com
        </a>
        &nbsp;and&nbsp;
        <a className={cn("link")} href={Links.investors} target="_blank" rel="noreferrer">
          investors.epam.com
        </a>
      </Text>
      <div className={cn("text-title")}>EPAM Compliance Assurance Resources</div>
      <PageCard card={PageCards.LandingPage.processGovernance} />
      <FlexRow alignItems="stretch">
        <PageCard card={PageCards.LandingPage.certificatesAndAudits} />
        <FlexSpacer />
        <PageCard card={PageCards.LandingPage.complianceAssurance} />
      </FlexRow>
    </div>
  );
};
