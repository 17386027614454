import fs from "file-saver";
import JSZip from "jszip";

import { IEmailProperties, IFolderInfo, sp, Web } from "@pnp/sp/presets/all";

import { FoldersToIgnore } from "Helpers/constants";
import { getServerOriginUrl, getServerRelativeUrl, replacePageUrlPrefix } from "Helpers/utils";

import { ISpDocumentDTO } from "./documents/documents.types";
import { IRegulation, IRegulationDTO } from "./documents/regulations/regulations.types";
import { IFoldersInfo, IHyperLinkField, ILibraryFolder } from "./helpers.types";
import { IAttachedFile, LibraryName } from "./sitePages/sitePages.types";

export async function sendEmail(
  subject: string,
  body: string,
  TOUsers: string[],
  CCUsers?: string[],
  BCCUsers?: string[],
): Promise<void> {
  const emailProps: IEmailProperties = {
    To: TOUsers,
    CC: CCUsers,
    BCC: BCCUsers,
    Subject: subject,
    Body: body,
    AdditionalHeaders: { "content-type": "text/html" },
  };
  await sp.utility.sendEmail(emailProps);
}

export function formatDownloadUrl(doc: ISpDocumentDTO): string {
  return `${process.env.REACT_APP_BASE_URL}/_layouts/download.aspx?SourceUrl=${doc.File.ServerRelativeUrl}`;
}
export function formatViewUrl(doc: ISpDocumentDTO): string {
  return encodeURI(`${getServerOriginUrl()}${doc.File.ServerRelativeUrl}?web=1`);
}
export function formatFolder(folder: IFolderInfo): ILibraryFolder {
  return {
    Name: folder.Name,
    ServerRelativeUrl: folder.ServerRelativeUrl,
    Folders: folder["Folders"],
  };
}
/**
 * Exporting files in zip archive
 * @param docs array of files
 * @param archiveName name of zip archive
 */
export async function createZipWithFiles(docs: IAttachedFile[], archiveName: string) {
  const zip = new JSZip();

  await Promise.all(
    docs.map((doc) =>
      sp.web
        .getFileByServerRelativeUrl(doc.fileInfo.ServerRelativeUrl)
        .getBlob()
        .then((file) => zip.file(doc.fileInfo.Name, file)),
    ),
  );
  const zipBlob = await zip.generateAsync({ type: "blob" });
  fs.saveAs(zipBlob, `${archiveName}.zip`);
}

export async function getAllDocumentTypeDescriptions(): Promise<IFoldersInfo> {
  const Url = process.env.REACT_APP_BASE_URL;
  const web = Web(Url);
  const batch = sp.createBatch();
  const libraries: LibraryName[] = [LibraryName.regulations, LibraryName.templates];
  const foldersInfo: IFoldersInfo = {
    [LibraryName.regulations]: [],
    [LibraryName.templates]: [],
    templatesDescription: undefined,
  };

  for (const library of libraries) {
    web.lists
      .getByTitle(library)
      .items.inBatch(batch)
      .select("Title", "folderDescription", "Folder/ServerRelativeUrl")
      .filter(`ContentType eq 'ECL Folder'`)
      .orderBy("Title")
      .expand("Folder")
      .get()
      .then((folders) =>
        folders.map((folder) => {
          foldersInfo[library].push({
            Title: folder["Title"],
            Description: folder["folderDescription"],
            ServerRelativeUrl: folder["Folder"]["ServerRelativeUrl"],
          });
        }),
      );
  }

  web.lists
    .getByTitle(LibraryName.templates)
    .select("Description")
    .inBatch(batch)
    .get()
    .then((list) => {
      foldersInfo.templatesDescription = list.Description;
    });

  await batch.execute();

  return foldersInfo;
}

export function formatLocationCity(cities: string) {
  return cities.split(",").map((city) => city.trim());
}

export function mapDocuments(doc: IRegulationDTO, list: LibraryName.regulations | LibraryName.templates): IRegulation {
  if (doc.PageName?.Url) {
    doc.PageName.Url = replacePageUrlPrefix(doc.PageName.Url);
  }

  return {
    ApprovalDate: doc.LastApprovalDate && new Date(doc.LastApprovalDate),
    ApproverName: doc.LastApproverName,
    ApproverPosition: doc.Document_x0020_Approver_x0020__x0028_position_x0029_,
    Author: { email: doc.Author.EMail, id: doc.Author.Id, name: doc.Author.Title },
    BriefDescription: doc.BriefDescription,
    Created: new Date(doc.Created),
    DocumentArea: doc.DocumentArea,
    DocumentOwnerPosition: doc.Document_x0020_Owner_x0020__x0028_position_x0029_,
    EPAMDocumentID: doc.EPAMDocumentID_x0020_,
    Editor: { email: doc.Editor.EMail, id: doc.Editor.Id, name: doc.Editor.Title },
    Id: doc.Id,
    LocationCountry: doc.LocationCountry,
    Modified: new Date(doc.Modified),
    OrganizationalUnit: doc.OrganizationalUnit && {
      Description: doc.OrganizationalUnit.Description?.trim(),
      Url: doc.OrganizationalUnit.Url,
    },
    PublishedPlace: doc.PageName,
    Subarea: doc.Subarea,
    SubjectDoc: doc.SubjectDoc,
    Title: doc.Title,
    WorkStorage: doc.WorkStorage,
    Category: doc.OData__Category,
    viewUrl: formatViewUrl(doc),
    downloadUrl: formatDownloadUrl(doc),
    fileInfo: {
      Length: doc.File.Length,
      Name: doc.File.Name,
      ServerRelativeUrl: doc.File.ServerRelativeUrl,
      FileType: doc.File_x0020_Type,
      UniqueId: doc.File.UniqueId,
    },
    ContentType: { Name: doc.ContentType.Name },
    sourceLibrary: list,
    UniqueId: doc.File.UniqueId,
    publishedVersion: doc.publishedVersion,
    FileDirRef: formatFileDirRef(doc),
    Company: doc.Company,
    RequiredReviewDate: new Date(doc.RequiredReviewDate),
    ReviewPeriod: doc.Review_x0020_Period,
    Standards: doc.Standards,
    FriendlyUrl: doc.FriendlyURL,
  };
}

export function formatFileDirRef(doc: ISpDocumentDTO): IHyperLinkField {
  const ServerRelativeUrl = getServerRelativeUrl();
  const ServerOriginUrl = getServerOriginUrl();

  return {
    Url: `${ServerOriginUrl}${doc.FileDirRef}`,
    Description: doc.FileDirRef?.replace(ServerRelativeUrl, ""),
  };
}

export function getShortText(text: string): string {
  return text?.length < 255 ? text : text?.slice(0, 255)?.concat("...");
}

export function generateOrderLetterByIndex(index: number) {
  // Lower case alphabet character code is start from 97
  return String.fromCharCode(97 + index);
}

export async function getDocumentBlob(documentUrl: string, mimeType: string) {
  const blob = await sp.web.getFileByServerRelativeUrl(documentUrl).getBlob();
  const windowBlob = new window.Blob([blob], {
    type: mimeType,
  });

  return windowBlob;
}

export function filterRegulationByFolder(item: IRegulation): boolean {
  return !FoldersToIgnore.some((folder) =>
    (item as IRegulation).fileInfo?.ServerRelativeUrl?.startsWith(`${getServerRelativeUrl()}${folder}`),
  );
}

export function getFormattedFriendlyUrl(url: string) {
  return url.replaceAll("'", "''");
}

export async function getDownloadedDocumentUrl(documentUrl: string, mimeType: string) {
  const relativeUrl = new URL(documentUrl).pathname;

  const windowBlob = await getDocumentBlob(relativeUrl, mimeType);

  return URL.createObjectURL(windowBlob);
}

export async function getImageBlob(imageSrc: string): Promise<Blob> {
  return sp.web.getFileByUrl(imageSrc).getBlob();
}
