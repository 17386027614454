import _get from "lodash/get";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { LabeledInput } from "@epam/loveship";

import { LayoutSelect } from "Components/layout-select";
import { withNaming } from "Helpers/bemClassname";
import { useSitePagesActions, useSitePagesMethods } from "Hooks/useSitePages";
import { IRootReducerState } from "Store/reducers";

import { IPageDetailsProps } from "./page-details.interface";
import { PageDetailsHeader } from "./page-details-header";

export const PageDetailsSection: FC<IPageDetailsProps> = ({ sitePageUrl }) => {
  const cn = withNaming("page-details");
  const [layout, setLayout] = useState("");
  const {
    selectedDetailInfo: { sectionIndex },
  } = useSitePagesActions();
  const { updateSitePage } = useSitePagesMethods();

  const pathKeys = ["pageInfo", "sections", sectionIndex, "type"];
  const sectionLayout = useSelector<IRootReducerState, string>((state) =>
    _get(state.sitePages.sitePages[sitePageUrl], pathKeys.join("."), null),
  );

  const handleLayoutChange = (type: string) => () => {
    setLayout(type);
    updateSitePage([sitePageUrl, ...pathKeys], type);
  };

  useEffect(() => {
    if (sectionLayout) {
      setLayout(sectionLayout);
    }
  }, []);

  return (
    <div className={cn("wrapper")}>
      <PageDetailsHeader title="Section" sitePageUrl={sitePageUrl} />
      <div className={cn("row", { card: true })}>
        <LabeledInput isRequired size="none" label="Layout">
          <LayoutSelect selectedLayout={layout} onChange={handleLayoutChange} />
        </LabeledInput>
      </div>
    </div>
  );
};
