import React, { FC, useRef } from "react";

import { Checkbox, Spinner, VirtualList } from "@epam/loveship";
import { VirtualList as VirtualListType } from "@epam/uui-components";

import { ResultsNotFound } from "Components/results-not-found/results-not-found";
import { withNaming } from "Helpers/bemClassname";
import { getSubscribeParams } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { useVirtualList } from "Hooks/useVirtualList";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { useColumns, useDocumentActions } from "./documents-table.hooks";
import { IDocumentsTableProps, ISort } from "./documents-table.interface";
import { TableColumn } from "./documents-table-column";
import { TableRow } from "./documents-table-row";

import "./documents-table.scss";

const cn = withNaming("documents-table");

const addASCProp = (sort: ISort, sortFieldName: string) => {
  return sort?.field === sortFieldName && { sortASC: sort.asc };
};

export const DocumentsTable: FC<IDocumentsTableProps> = ({
  isCertificates,
  isOverWall,
  isDataLoaded,
  data,
  columns,
  sort,
  indeterminate,
  selectedRows,
  onSortChange,
  onCheckToggle,
  onToggleAll,
}) => {
  const virtualListRef = useRef<VirtualListType>(null);
  const { virtualState, visibleItems, setVirtualState, resetVirtualState } = useVirtualList<IAttachedFile>(data, 12);

  const { checkIsSubscribed } = useUsersData();
  const { onFileNameClick, handleOpenDocumentInfo } = useDocumentActions();
  const { columnsList, columnsConfig, setColumnsConfig } = useColumns(columns);

  const visibleRows = visibleItems.map((row) => (
    <TableRow
      key={row.UniqueId}
      cn={cn}
      row={row}
      columns={columnsList}
      subscribeParams={getSubscribeParams({
        item: "document",
        document: row,
        checkIsSubscribed,
      })}
      checked={selectedRows.includes(row.UniqueId)}
      onCheckToggle={onCheckToggle(row.UniqueId)}
      onFileNameClick={onFileNameClick(row)}
      onOpenInfoPanel={handleOpenDocumentInfo(row)}
    />
  ));

  const handleSort = (newSort: ISort) => {
    resetVirtualState();
    onSortChange(newSort);
    virtualListRef.current?.scrollBars?.scrollToTop();
  };

  return (
    <div className={cn("", { "over-wall": isOverWall, certificates: isCertificates })}>
      <div className={cn("head", ["flex"])}>
        <Checkbox
          cx={cn("checkbox", { all: true }, ["checkbox-list"])}
          size="18"
          value={!!selectedRows.length}
          indeterminate={indeterminate}
          rawProps={{ "data-testid": "table-all-checkbox" }}
          onValueChange={onToggleAll}
        />
        <div className={cn("cells", ["flex"])}>
          {columnsList.map((column) => (
            <TableColumn
              cn={cn}
              key={column.key}
              onSort={handleSort}
              column={column}
              columnsConfig={columnsConfig}
              onColumnsConfigSet={setColumnsConfig}
              {...addASCProp(sort, column.key)}
            />
          ))}
        </div>
      </div>
      {isDataLoaded && data.length > 0 && (
        <VirtualList
          ref={virtualListRef}
          cx={cn("virtual-list-body")}
          rows={visibleRows}
          value={virtualState}
          onValueChange={setVirtualState}
          rowsCount={data.length}
        />
      )}
      {isDataLoaded && data.length === 0 && <ResultsNotFound isSearchQueryPresent={true} />}
      {!isDataLoaded && (
        <div className={cn("virtual-list-body")}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
