import { MutableRefObject, useEffect, useRef, useState } from "react";

import { copyTextToClipboard, getServerOriginUrl } from "Helpers/utils";

interface ICopyTextParams {
  ref: MutableRefObject<any>;
  content: string;
  isFileName?: boolean;
}

const encodeCopyUrl = (copyUrl: string) => {
  const url = new URL(window.location.href);
  const path: string = new URLSearchParams(url.search).get("path");

  copyUrl = decodeURI(copyUrl);

  if (!copyUrl.includes(url.origin)) {
    copyUrl = `${getServerOriginUrl()}${copyUrl}`;
  }

  if (path && !copyUrl.includes(`?path=${path}`)) {
    copyUrl = `${copyUrl}?path=${path}`;
  }

  return encodeURI(copyUrl);
};

export const useCopyText = ({ ref, content, isFileName }: ICopyTextParams) => {
  const [isCopied, setIsCopied] = useState(false);
  const timeOutId = useRef(null);

  const handleCopyTextCb = (e) => () => {
    if (timeOutId.current) {
      clearTimeout(timeOutId.current);
    }

    setIsCopied(true);

    timeOutId.current = setTimeout(() => {
      setIsCopied(false);

      if (ref.current?.state.isOpen) {
        ref.current.mouseClickHandler(e);
      }
    }, 2000);
  };

  const handleCopyUrl = (e) => {
    const copyText = isFileName ? content : encodeCopyUrl(content);
    copyTextToClipboard(copyText, handleCopyTextCb(e));
  };

  const copyUrlWithGuid = (e, guid: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("filterGuid", guid);
    const copyText = encodeCopyUrl(url.href);
    copyTextToClipboard(copyText, handleCopyTextCb(e));
  };

  useEffect(
    () => () => {
      if (timeOutId.current) {
        clearTimeout(timeOutId.current);
      }
    },
    [],
  );

  return {
    isCopied,
    handleCopyUrl,
    copyUrlWithGuid,
  };
};
