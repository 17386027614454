import dayjs from "dayjs";
import _has from "lodash/has";
import _isEqual from "lodash/isEqual";
import _maxBy from "lodash/maxBy";
import _minBy from "lodash/minBy";
import React, { FC, useEffect, useState } from "react";

import { DatePicker } from "@epam/loveship";

import { useFiltersActions, useFiltersData } from "Components/documents-filter/documents-filter.hooks";
import { createDateString } from "Libs/dayjs";
import { FilterValueType, ITimePeriod } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { IDates, IDocumentsFilterDatePickerProps, IMinAndMaxDate } from "./documents-filter.interface";
import { DocumentsFilterToggler } from "./documents-filter-toggler";

export const dateFormat = "DD/MM/YYYY";
const defaultValueFormat = "YYYY-MM-DD";

const handleFilterDates = (key: keyof IDates, day: dayjs.Dayjs, data: IMinAndMaxDate, { fromDate, toDate }: IDates) => {
  const date = day.toDate();
  date.setHours(0, 0, 0, 0);

  const baseCondition = date >= data.minDate && date <= data.maxDate;

  if (key === "fromDate" && toDate) {
    return baseCondition && date <= dayjs(toDate).toDate();
  } else if (key === "toDate" && fromDate) {
    return baseCondition && date >= dayjs(fromDate).toDate();
  }

  return baseCondition;
};

const handleDefaultData = ({ minDate, maxDate }: IMinAndMaxDate, defaultData: ITimePeriod) => ({
  fromDate: createDateString(minDate, defaultValueFormat, defaultData?.fromDate),
  toDate: createDateString(maxDate, defaultValueFormat, defaultData?.toDate),
});

export const DocumentsFilterDatePicker: FC<IDocumentsFilterDatePickerProps> = ({
  field,
  caption,
  defaultData,
  data,
  groupIndex,
  onFilterChanged,
}) => {
  const [dates, setDates] = useState<IDates>(handleDefaultData(data, defaultData));
  const [isDatesDisabled, setIsDatesDisabled] = useState<boolean>(false);
  const { filterGroupResults } = useFiltersData();

  const { setFilter } = useFiltersActions();

  const handleDateChange = (key: keyof IDates) => (newDate: string) => {
    setDates((oldDates) => {
      const newDates = { ...oldDates, [key]: newDate };

      setFilter(field, { type: FilterValueType.timePeriod, ...newDates }, groupIndex);
      return newDates;
    });

    onFilterChanged?.();
  };

  useEffect(() => {
    const newDates = handleDefaultData(data, defaultData);

    if (!_isEqual(dates, newDates)) {
      setDates(newDates);
    }
  }, [data.minDate, data.maxDate]);

  useEffect(() => {
    if (filterGroupResults[groupIndex]?.lastChangedFilterField != field) {
      if (_has(filterGroupResults[groupIndex]?.result[0], field)) {
        const minDate = _minBy(filterGroupResults[groupIndex].result, field)?.[field] || data.minDate;
        const maxDate = _maxBy(filterGroupResults[groupIndex].result, field)?.[field] || data.maxDate;
        const newDates = {
          fromDate: createDateString(minDate, defaultValueFormat),
          toDate: createDateString(maxDate, defaultValueFormat),
        };
        setDates(newDates);
        setIsDatesDisabled(false);
      } else if (filterGroupResults[groupIndex]?.result.length === 0) {
        const newDates = {
          fromDate: createDateString(data.minDate, defaultValueFormat),
          toDate: createDateString(data.maxDate, defaultValueFormat),
        };
        setDates(newDates);
        setIsDatesDisabled(true);
      }
    }
  }, [filterGroupResults]);

  return (
    <div className={cn("date-picker-wrapper")}>
      <DocumentsFilterToggler caption={caption} defaultOpen={!!defaultData}>
        <DatePicker
          cx={cn("date-picker-input", { "from-date": true })}
          format={dateFormat}
          value={dates.fromDate}
          filter={(day) => handleFilterDates("fromDate", day, data, dates)}
          onValueChange={handleDateChange("fromDate")}
          mode="inline"
          isDisabled={isDatesDisabled}
        />
        <DatePicker
          cx={cn("date-picker-input", { "to-date": true })}
          format={dateFormat}
          value={dates.toDate}
          filter={(day) => handleFilterDates("toDate", day, data, dates)}
          onValueChange={handleDateChange("toDate")}
          mode="inline"
          isDisabled={isDatesDisabled}
        />
      </DocumentsFilterToggler>
    </div>
  );
};
