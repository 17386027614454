import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { FlexRow, LinkButton, Panel, Text } from "@epam/loveship";

import { RichTextEditor } from "Components/rich-text-editor";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { handleClick, isInternalUrl } from "Helpers/utils";
import { useSitePagesActions } from "Hooks/useSitePages";
import { useUsersData } from "Hooks/useUsers";
import { IParentRichTextEditorWebPart } from "SP/sitePages/sitePages.types";
import { DetailTypes } from "Store/reducers/site-pages.reducer";

import { IWebPartItemProps } from "../web-parts";
import { isCardSelected } from "../web-parts-card/web-parts-card";

import "./web-parts-parent.scss";

interface IWebPartsParentProps extends IWebPartItemProps {
  data: IParentRichTextEditorWebPart;
}

export const WebPartsParent: FC<IWebPartsParentProps> = ({ data, webPart, pathKeys, selectDetailProps }) => {
  const cn = withNaming("web-parts-card");
  const { push } = useHistory();
  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } = useSitePagesActions();
  const isTitleHasLink = !!data.webPartTitleLink;
  const { currentUser } = useUsersData();

  const handleSelectDetail = () => {
    if (isSitePageInEditMode) {
      selectSitePageDetail({ type: DetailTypes.parentCard, ...selectDetailProps });
    }
  };

  const handleTitleLinkClick = (link: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    analyticsHelper.analyticsService?.provideLinkClick(link, currentUser?.jobTitle);
    if (isInternalUrl(link)) {
      event.preventDefault();
      handleClick(link, event, push);
    } else {
      window.open(link);
    }
  };

  return (
    <Panel
      cx={cn(
        "",
        { selected: isCardSelected(selectedDetailInfo, selectDetailProps), "edit-mode": isSitePageInEditMode },
        ["shadow"],
      )}
      background="white"
      onClick={handleSelectDetail}
    >
      <FlexRow cx={cn("title-wrapper")}>
        <div
          className={cn("title-text-wrapper", ["flex justify-start items-start"])}
          style={{ background: data.webPartLinkColor }}
        >
          {isTitleHasLink ? (
            <LinkButton
              cx={cn("link-text")}
              caption={data.webPartTitle}
              size="none"
              fontSize="18"
              lineHeight="24"
              color="sky"
              onClick={handleTitleLinkClick(data.webPartTitleLink)}
            />
          ) : (
            <Text cx={cn("title-text")} color="night900" font="sans-semibold" fontSize="18" lineHeight="24">
              {data.webPartTitle}
            </Text>
          )}
        </div>
      </FlexRow>
      <RichTextEditor
        cx={cn("slate-editor")}
        isEditMode={isSitePageInEditMode}
        data={data}
        pathKeys={pathKeys}
        webPart={webPart}
      />
    </Panel>
  );
};
