import React, { FC } from "react";

import { LinkButton, Text } from "@epam/loveship";

import { replacePageUrlPrefix } from "Helpers/utils";

import "./document-text.scss";

interface IDocumentTextProps {
  caption: string;
  isItem?: boolean;
  href?: string;
  isCut?: boolean;
}

export const DocumentText: FC<IDocumentTextProps> = ({ caption, isItem, href, isCut }) => (
  <>
    {href ? (
      <LinkButton
        cx="document-text--link"
        caption={caption}
        size="none"
        font="sans"
        fontSize="14"
        lineHeight="18"
        color="sky"
        href={replacePageUrlPrefix(href)}
        target="_blank"
      />
    ) : (
      <Text
        cx={`document-text${isCut ? " cut-text" : ""}`}
        lineHeight="18"
        fontSize={isItem ? "14" : "12"}
        font="sans"
        color={isItem ? "night900" : "night600"}
      >
        {caption}
      </Text>
    )}
  </>
);
