import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";

import { FavoriteFiltersService } from "SP/favoriteFilters/favoriteFilters.service";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import {
  FiltersActionsTypes,
  getFilterByGuidFailure,
  getFilterByGuidSuccess,
  IGetFilterByGuidRequestAction,
  ISaveTempFilterRequestAction,
  saveTempFilterFailure,
  saveTempFilterSuccess,
} from "Store/actions/filters.actions";

const favoriteFiltersService = new FavoriteFiltersService();

export function* getFilterByGuid(action: IGetFilterByGuidRequestAction) {
  try {
    const filter: IFavoriteFilter = yield call([favoriteFiltersService, "getFilterByGuid"], action.guid);

    yield put(getFilterByGuidSuccess(filter));

    action.cb?.(filter);
  } catch (e) {
    yield put(getFilterByGuidFailure(e));
  }
}

export function* saveTempFilter(action: ISaveTempFilterRequestAction) {
  try {
    const guid: string = yield call([favoriteFiltersService, "saveTempFilter"], action.filter);

    yield put(saveTempFilterSuccess(guid));
    action.cb?.(guid);
  } catch (e) {
    yield put(saveTempFilterFailure(e));
  }
}

export function* watchGetFilterByGuid() {
  yield takeEvery(FiltersActionsTypes.GET_FILTER_BY_GUID_REQUEST, getFilterByGuid);
}

export function* watchSaveTempFilter() {
  yield takeEvery(FiltersActionsTypes.SAVE_TEMP_FILTER_REQUEST, saveTempFilter);
}

export default function* filterSagas(): Iterator<ForkEffect> {
  yield fork(watchGetFilterByGuid);
  yield fork(watchSaveTempFilter);
}
