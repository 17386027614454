import React, { FC, MouseEvent, useRef, useState } from "react";

import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { IconContainer } from "@epam/loveship";

import { renderText } from "Components/documents-table/documents-table.helpers";

import { cn } from "./documents-filter";
import { IDocumentsFilterTogglerProps } from "./documents-filter.interface";

export const DocumentsFilterToggler: FC<IDocumentsFilterTogglerProps> = ({
  caption,
  children,
  isPickerInput,
  defaultOpen,
}) => {
  const [toggle, setToggle] = useState(defaultOpen);
  const togglerRef = useRef(null);

  const onToggle = (event: MouseEvent<HTMLDivElement>) => {
    if (togglerRef.current.contains?.(event.target)) {
      setToggle((t) => !t);
    }
  };

  return (
    <>
      <div
        className={cn("toggler", ["flex justify-between items-center cursor-pointer"])}
        ref={togglerRef}
        onClick={onToggle}
        data-testid="filter-toggler"
      >
        {typeof caption === "string"
          ? renderText(caption, { cx: "one-line", font: "sans-semibold", color: "night900" })
          : caption}
        <IconContainer icon={toggle ? chevronUp : chevronDown} />
      </div>
      {toggle && <div className={cn("toggler-content", { "picker-input": isPickerInput }, ["flex"])}>{children}</div>}
    </>
  );
};
