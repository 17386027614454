import { useDispatch, useSelector } from "react-redux";

import { setInfoPanel } from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";
import { InfoPanel } from "Store/reducers/general.reducer";

export const useInfoPanel = () => {
  const dispatch = useDispatch();
  const infoPanel = useSelector<IRootReducerState, InfoPanel>((state) => state.general.infoPanel);

  const handleSetInfoPanel = (infoPanel: InfoPanel) => {
    return dispatch(setInfoPanel(infoPanel));
  };

  return {
    infoPanel,
    setInfoPanel: handleSetInfoPanel,
  };
};
