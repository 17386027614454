import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { withSeoHead } from "Components/seo-head";
import {
  FriendlyCertificatePrefix,
  FriendlyPagePrefix,
  FriendlyRegulationPrefix,
  FriendlyTemplatePrefix,
} from "Helpers/constants";
import { withSessionStartAnalytics } from "Helpers/withSessionStartAnalytics";
import { FriendlyUrlDocumentPage } from "Pages/FriendlyUrlDocumentPage";
import { FriendlyUrlSitePage } from "Pages/FriendlyUrlSitePage";
import { HomePage } from "Pages/HomePage";
import { NotFound } from "Pages/NotFound";
import { OldUrlDocumentPage } from "Pages/OldUrlDocumentPage";
import { OldUrlSitePage } from "Pages/OldUrlSitePage";
import { SearchPage } from "Pages/SearchPage";
import { SubscriptionsPage } from "Pages/SubscriptionsPage";

export interface IRoute {
  path: string;
  pageName: string;
  props: {
    component: React.FC<RouteComponentProps>;
    exact?: boolean;
    routes?: IRoute[];
  };
}

interface IRoutes {
  HOME: IRoute;
  SEARCH: IRoute;
  DYNAMIC_SITE_PAGE: IRoute;
  DYNAMIC_PAGE: IRoute;
  ROLE: IRoute;
  SUBSCRIPTIONS: IRoute;
  DOCUMENT: IRoute;
  REGULATION: IRoute;
  TEMPLATE: IRoute;
  CERTIFICATE: IRoute;
}

const Routes: IRoutes = {
  HOME: {
    path: "/",
    pageName: "Home",
    props: {
      component: HomePage,
      exact: true,
    },
  },
  SEARCH: {
    path: "/search",
    pageName: "Search",
    props: {
      component: withSessionStartAnalytics(withSeoHead(SearchPage, "Search"), "Search"),
      exact: true,
    },
  },
  ROLE: {
    path: "/roles/:id",
    pageName: "Role",
    props: {
      component: NotFound,
    },
  },
  DOCUMENT: {
    path: "/document",
    pageName: "Document",
    props: {
      component: OldUrlDocumentPage,
    },
  },
  SUBSCRIPTIONS: {
    path: "/subscriptions",
    pageName: "My Subscriptions",
    props: {
      component: withSessionStartAnalytics(withSeoHead(SubscriptionsPage, "My Subscriptions"), "My Subscriptions"),
    },
  },
  REGULATION: {
    path: `${FriendlyRegulationPrefix}/:regulationUrl`,
    pageName: "Regulation",
    props: {
      component: FriendlyUrlDocumentPage,
    },
  },
  TEMPLATE: {
    path: `${FriendlyTemplatePrefix}/:templateUrl`,
    pageName: "Template",
    props: {
      component: FriendlyUrlDocumentPage,
    },
  },
  CERTIFICATE: {
    path: `${FriendlyCertificatePrefix}/:certificateUrl`,
    pageName: "Certificate",
    props: {
      component: FriendlyUrlDocumentPage,
    },
  },
  DYNAMIC_PAGE: {
    path: `${FriendlyPagePrefix}/:pageUrl`,
    pageName: "Dynamic Page",
    props: {
      component: FriendlyUrlSitePage,
    },
  },
  DYNAMIC_SITE_PAGE: {
    path: "/*",
    pageName: "Dynamic Site Page",
    props: {
      component: OldUrlSitePage,
    },
  },
};

export default Routes;
