import { MutableRefObject, useCallback, useEffect, useState } from "react";

interface IUseResizeHookParams {
  ref: MutableRefObject<HTMLElement>;
  onUpdate: (n: number) => void;
}

type IUseResize = [boolean, (e: React.MouseEvent) => void];

export const useResize = ({ ref, onUpdate }: IUseResizeHookParams): IUseResize => {
  const [isResizing, setIsResizing] = useState(false);

  const onResizeStart = useCallback((e: React.MouseEvent) => {
    setIsResizing(true);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onResizeEnd = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      function onResize(e: MouseEvent) {
        e.preventDefault();

        const cellNode: HTMLElement = ref.current as any;
        const cellRect = cellNode.getBoundingClientRect();
        const newWidth = e.clientX - cellRect.left;

        return onUpdate(newWidth);
      }

      document.addEventListener("mousemove", onResize);
      document.addEventListener("mouseup", onResizeEnd);

      return () => {
        document.removeEventListener("mousemove", onResize);
        document.removeEventListener("mouseup", onResizeEnd);
      };
    }
  }, [isResizing]);

  return [isResizing, onResizeStart];
};
