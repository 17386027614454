import { ClassNameFormatter } from "@bem-react/classname";

import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IVoidCb } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { IAllDocuments } from "Store/actions/documents.actions";
import { IUserWithPermsAndSubscriptions } from "Store/actions/users.actions";

export enum SubscriptionsTabType {
  All = "All",
  Documents = "Documents",
  Certificates = "Certificates",
}

export type ISubscriptionItem = IRegulation | ICertificate | IRole;

export interface ISubscriptionsToolbarProps {
  cn: ClassNameFormatter;
  hasSelected: boolean;
  indeterminate: boolean;
  checkboxLabel: string;
  onToggleAll: IVoidCb;
  exportCallback: IVoidCb;
  items: IAttachedFile[];
  selectedRows: string[];
  activeTab: SubscriptionsTabType;
}

export interface ISubscriptionsListProps {
  cn: ClassNameFormatter;
  items: ISubscriptionItem[];
  activeTab: SubscriptionsTabType;
  selectedRows: string[];
  onCheckRowCallback: (uniqueId: string) => (isChecked: boolean) => void;
}

export interface SubscriptionsCardProps {
  cn: ClassNameFormatter;
  item: ISubscriptionItem;
}

export type IGetSubscriptionsDataParams = {
  currentUser: IUserWithPermsAndSubscriptions;
  allDocuments: IAllDocuments;
  activeTab: SubscriptionsTabType;
};
