import { IAnalyticsService } from "./AnalyticsService";
import { CompositeAnalytics } from "./compositeAnalytics";
import { GoogleAnalytics } from "./googleAnalytics";
import { PosthogAnalytics } from "./posthogAnalytics";
import { ZarazAnalytics } from "./zarazAnalytics";

export const analyticsHelper = {
  _analyticsService: null,

  get analyticsService(): IAnalyticsService {
    function createAnalyticsService() {
      if ((window as any).zaraz) {
        return new CompositeAnalytics(new PosthogAnalytics(), new ZarazAnalytics());
      }

      return new CompositeAnalytics(new PosthogAnalytics(), new GoogleAnalytics());
    }

    if (!this._analyticsService) {
      this._analyticsService = createAnalyticsService();
    }

    return this._analyticsService;
  },

  getDocumentName: (link: string) => {
    if (!document) return undefined;

    const fragments = link.split("?")[0].split("/");

    return fragments[fragments.length - 1];
  },

  getUserTitle: (jobTitle: string) => jobTitle || "Empty",
};
