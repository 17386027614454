import produce from "immer";
import { Reducer } from "redux";

import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";

import { IUsersActionsTypes, IUserWithPermsAndSubscriptions, UsersActionsTypes } from "../actions/users.actions";

export interface IUsersState {
  currentUser: IUserWithPermsAndSubscriptions;
  subscriptionsLoading: boolean;
  favoriteFilters: IFavoriteFilter[];
  presetSaveErrorText: string;
}

const initialState: IUsersState = {
  currentUser: null,
  subscriptionsLoading: false,
  favoriteFilters: [],
  presetSaveErrorText: "",
};

const usersReducer: Reducer<IUsersState, IUsersActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case UsersActionsTypes.GET_CURRENT_USER_SUCCESS:
      draft.currentUser = action.payload;
      break;

    case UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_REQUEST:
      draft.subscriptionsLoading = true;
      break;
    case UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_SUCCESS:
      draft.currentUser.subscriptions = action.payload;
      draft.subscriptionsLoading = false;
      break;
    case UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_FAILURE:
      draft.subscriptionsLoading = false;
      break;

    case UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_SUCCESS:
      draft.favoriteFilters = action.payload;
      break;
    case UsersActionsTypes.SAVE_FAVORITE_FILTER_REQUEST:
      draft.presetSaveErrorText = "";
      break;
    case UsersActionsTypes.SAVE_FAVORITE_FILTER_SUCCESS:
      draft.presetSaveErrorText = "";
      break;
    case UsersActionsTypes.SAVE_FAVORITE_FILTER_FAILURE:
      draft.presetSaveErrorText = action.error;
      break;
  }
}, initialState);

export default usersReducer;
