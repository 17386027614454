import _sortBy from "lodash/sortBy";
import React, { FC, useMemo, useState } from "react";

import { svc } from "@App/services";
import { Button, Checkbox, DropMarker, FlexSpacer } from "@epam/loveship";
import { DndActor, DropParams, getOrderBetween, IModal } from "@epam/uui";
import { DragHandle } from "@epam/uui-components";

import { Modal } from "Components/modal";
import { withNaming } from "Helpers/bemClassname";

import "./fields-configuration-modal.scss";

export type IField = {
  prop: string;
  label: string;
  order: string;
  visible: boolean;
};

interface IFieldsConfigurationModalProps {
  modalProps: IModal<string>;
  title: string;
  fields: IField[];
  onSave: (fields: IField[]) => void;
}

export const FieldsConfigurationModal: FC<IFieldsConfigurationModalProps> = ({ modalProps, title, fields, onSave }) => {
  const cn = withNaming("fields-configuration-modal");
  const [stateFields, setStateFields] = useState<IField[]>(fields);
  const sortedFields = useMemo(() => _sortBy(stateFields, ["order"]), [stateFields]);

  const handleUpdateField = (field: IField) => {
    setStateFields(stateFields.map((i) => (i.prop === field.prop ? field : i)));
  };

  const handleDrop = (prevField: IField, nextField: IField) => (params: DropParams<IField, IField>) => {
    const { srcData, dstData, position } = params;

    handleUpdateField({
      ...srcData,
      order:
        position === "bottom"
          ? getOrderBetween(dstData.order, nextField?.order)
          : getOrderBetween(prevField?.order, dstData.order),
    });
  };

  const handleVisibleToggle = (field: IField) => (visible: boolean) => {
    handleUpdateField({ ...field, visible });
  };

  const handleApply = () => {
    onSave(stateFields);

    if (process.env.UNIT_TEST) {
      svc.uuiModals.closeAll();
    } else {
      modalProps.abort();
    }
  };

  const renderDndField = (field: IField, prevField: IField, nextField: IField) => {
    return (
      <DndActor
        key={field.prop}
        srcData={field}
        dstData={field}
        canAcceptDrop={() => ({ top: true, bottom: true })}
        onDrop={handleDrop(prevField, nextField)}
        render={(props) => {
          return (
            <div {...props.eventHandlers} className={cn("drag-element", { isDragGhost: props.isDragGhost })}>
              <div className={cn("dnd-item", { isDraggedOut: props.isDraggedOut }, ["flex items-center"])}>
                <DragHandle cx={cn("drag-handle")} />
                <Checkbox
                  rawProps={{ "data-testid": `config-field-checkbox-${field.prop}` }}
                  key={field.prop}
                  label={field.label}
                  value={field.visible}
                  onValueChange={handleVisibleToggle(field)}
                />
              </div>
              <DropMarker {...props} />
            </div>
          );
        }}
      />
    );
  };

  return (
    <Modal
      modalProps={modalProps}
      title={title}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn" color="grass" caption="Apply" onClick={handleApply} />
        </>
      }
    >
      {sortedFields.map((field, index) => {
        const prevItem = index ? sortedFields[index - 1] : null;
        const nextItem = index === fields.length - 1 ? null : sortedFields[index + 1];

        return renderDndField(field, prevItem, nextItem);
      })}
    </Modal>
  );
};
