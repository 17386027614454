import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import { analyticsHelper } from "Helpers/analyticsHelper";
import { RolesService } from "SP/rolesRegistry/rolesRegistry.service";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IExecuteSearchProps, ISearchServiceResult, SearchService } from "SP/search/search.service";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { resetAllRefiners, setRefiners } from "Store/actions/filters.actions";
import { getAllRolesSuccess } from "Store/actions/general.actions";
import {
  getGlobalSearchResultsFailure,
  getGlobalSearchResultsSuccess,
  getListSearchResultsFailure,
  getListSearchResultsSuccess,
  IGlobalSearchResultsRequestAction,
  IListSearchResultsRequestAction,
  refinementSearchFailure,
  refinementSearchSuccess,
  SearchActionsTypes,
  setSearchQuery,
} from "Store/actions/search.actions";
import { IRootReducerState } from "Store/reducers";
import { getAllDocuments, getRefinementFiltersGroups } from "Store/sagas/helpers/utils.sagas";

const searchService = new SearchService();
const rolesService = new RolesService();

const searchSelectors = {
  allRoles: (state: IRootReducerState) => state.general.allRoles,
  allDocuments: (state: IRootReducerState) => state.documents.allDocuments,
  searchType: (state: IRootReducerState) => state.search.searchType,
  searchQuery: (state: IRootReducerState) => state.search.searchQuery,
  userTitle: (state: IRootReducerState) => state.users.currentUser?.jobTitle,
  filtersGroups: (state: IRootReducerState) => state.filters.selectedFilters[state.filters.filtersLibrary],
  filtersLibrary: (state: IRootReducerState) => state.filters.filtersLibrary,
  refiners: (state: IRootReducerState) => state.filters.refiners[state.filters.filtersLibrary],
  rowsLimit: (state: IRootReducerState) => state.search.rowsLimit,
};

export function* getGlobalSearchResults(action: IGlobalSearchResultsRequestAction) {
  try {
    const allDocuments = yield call(getAllDocuments);

    const searchType = yield select(searchSelectors.searchType);
    const userTitle = yield select(searchSelectors.userTitle);
    const filtersLibrary = yield select(searchSelectors.filtersLibrary);
    const rowsLimit = yield select(searchSelectors.rowsLimit);

    analyticsHelper.analyticsService?.provideSearchTerm(action.query, userTitle, searchType);

    const searchProps: IExecuteSearchProps = {
      QueryText: action.query,
      searchType,
      regulations: allDocuments[LibraryName.regulations],
      templates: allDocuments[LibraryName.templates],
      certificates: allDocuments[LibraryName.certificates],
      roles: [],
      library: filtersLibrary,
      rowsLimit,
      loadRefiners: true,
    };

    const globalSearchResults: ISearchServiceResult = yield call([searchService, "executeSearch"], searchProps);

    yield put(setSearchQuery(action.query));
    yield put(resetAllRefiners());
    yield put(setRefiners(globalSearchResults.refiners));
    yield put(getGlobalSearchResultsSuccess(globalSearchResults));
  } catch (e) {
    yield put(getGlobalSearchResultsFailure(e));
    throw e;
  }
}

export function* getRefinedGlobalSearchResult() {
  try {
    const allDocuments = yield call(getAllDocuments);
    let allRoles: IRole[] = yield select(searchSelectors.allRoles);

    if (!allRoles?.length) {
      allRoles = yield call([rolesService, "getAll"], allDocuments[LibraryName.regulations]);
      yield put(getAllRolesSuccess(allRoles));
    }

    const searchQuery = yield select(searchSelectors.searchQuery);
    const filtersGroups = yield select(searchSelectors.filtersGroups);

    const refinementFiltersGroups = yield call(getRefinementFiltersGroups, filtersGroups);
    const filtersLibrary = yield select(searchSelectors.filtersLibrary);
    const rowsLimit = yield select(searchSelectors.rowsLimit);

    const searchType = yield select(searchSelectors.searchType);
    const currentRefiners = yield select(searchSelectors.refiners);
    const shouldLoadRefiners = currentRefiners?.length === 0;

    const searchProps: IExecuteSearchProps = {
      QueryText: searchQuery,
      searchType,
      regulations: allDocuments[LibraryName.regulations],
      templates: allDocuments[LibraryName.templates],
      certificates: allDocuments[LibraryName.certificates],
      roles: allRoles,
      library: filtersLibrary,
      rowsLimit,
      loadRefiners: shouldLoadRefiners,
    };

    const globalSearchResults: ISearchServiceResult = yield call(
      [searchService, "executeSearchWithFilters"],
      searchProps,
      refinementFiltersGroups,
    );

    if (shouldLoadRefiners) {
      yield put(setRefiners(globalSearchResults.refiners));
    }

    yield put(refinementSearchSuccess(globalSearchResults));
  } catch (e) {
    yield put(refinementSearchFailure(e));
    throw e;
  }
}

export function* getListSearchResults(action: IListSearchResultsRequestAction) {
  try {
    const listSearchResults: IAttachedFile[] = yield call([searchService, "executeSearchInList"], {
      QueryText: action.query,
      list: action.list,
      listItems: action.listItems,
    });

    const userTitle = yield select(searchSelectors.userTitle);

    analyticsHelper.analyticsService?.provideSearchTerm(action.query, userTitle, null);

    yield put(getListSearchResultsSuccess(action.list, listSearchResults));
  } catch (e) {
    yield put(getListSearchResultsFailure(e));
    throw e;
  }
}

export function* watchGetGlobalSearchResults() {
  yield takeEvery(SearchActionsTypes.GLOBAL_SEARCH_RESULTS_REQUEST, getGlobalSearchResults);
}

export function* watchGetListSearchResults() {
  yield takeEvery(SearchActionsTypes.LIST_SEARCH_RESULTS_REQUEST, getListSearchResults);
}

export function* watchGetRefinedGlobalSearchResults() {
  yield takeEvery(SearchActionsTypes.REFINEMENT_SEARCH_REQUEST, getRefinedGlobalSearchResult);
}

export default function* searchResultsSagas(): Iterator<ForkEffect> {
  yield fork(watchGetGlobalSearchResults);
  yield fork(watchGetListSearchResults);
  yield fork(watchGetRefinedGlobalSearchResults);
}
