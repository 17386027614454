import React, { FC, useEffect, useMemo, useState } from "react";

import { ReactComponent as cardViewIcon } from "@App/assets/cards_view.svg";
import { ReactComponent as listViewIcon } from "@App/assets/list_view.svg";
import { ReactComponent as powerBiIcon } from "@App/assets/power-bi.svg";
import { Button, Dropdown, DropdownMenuBody, FlexRow, FlexSpacer, LinkButton } from "@epam/loveship";
import { ArrayDataSource, IDropdownToggler } from "@epam/uui";

import { DocumentsFilter } from "Components/documents-filter";
import { DropdownButtonWithAnalytics } from "Components/dropdown-button-with-analytics";
import { LinkButtonWithAnalytics } from "Components/link-button-with-analytics";
import { Loader } from "Components/loader";
import { getToolbarActions } from "Components/search/search.helpers";
import { SearchMenuItem } from "Components/search-menu-item";
import { ITab, TabsSlider } from "Components/tabs-slider/tabs-slider";
import { withNaming } from "Helpers/bemClassname";
import { Links } from "Helpers/constants";
import { useDocumentsActions, useDocumentsData, useDocumentsMethods } from "Hooks/useDocuments";
import { useRolesData } from "Hooks/useRoles";
import { useUsersActions, useUsersData, useUsersMethods } from "Hooks/useUsers";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { exportCertificatesToExcel } from "SP/exportToExcel/exportCertificates";
import { exportDocumentsToExcel } from "SP/exportToExcel/exportDocuments";
import { exportRolesToExcel } from "SP/exportToExcel/exportRoles";
import { ExportFileName } from "SP/exportToExcel/helpers";
import { IVoidCb } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { FullPageComponentTypes } from "SP/sitePages/sitePages.types";

import {
  filterToolbarActions,
  generateShowFilterItems,
  getDocumentType,
  getSelectedData,
  isCertificates as isCertificatesChecker,
  isNotRolesRegistry,
  isRolesRegistry as isRolesRegistryChecker,
  isThereUnsubscribedDocument,
  isWithinRegulationTypes as isWithinRegulationTypesChecker,
  renderListPickerRow,
  renderListPickerToggler,
  renderPickerInput,
  renderRolesRegistryFooter,
  renderShowPickerFooter,
  renderShowPickerToggler,
} from "./documents-toolbar.helpers";
import { IDocumentsToolbarProps, Tabs, ViewModes } from "./documents-toolbar.interface";

import "./documents-toolbar.scss";

export const tabs: ITab[] = [
  {
    caption: "Regulations and Templates",
    key: Tabs.documents,
  },
  {
    caption: "Certificates",
    key: Tabs.certificates,
  },
];

const exportFileNames = {
  [FullPageComponentTypes.RegulationsByDocumentType]: ExportFileName.regulationsAndTemplates,
  [FullPageComponentTypes.Regulations]: ExportFileName.regulations,
  [FullPageComponentTypes.Templates]: ExportFileName.templates,
};

export const DocumentsToolbar: FC<IDocumentsToolbarProps> = ({
  isHasTabs,
  activeTab,
  viewMode,
  searchValue,
  showFilter,
  selectedRows,
  allData,
  pageType,
  onViewModeChange,
  onSearchValueChange,
  onShowFilterChange,
  onTabChange,
}) => {
  const cn = withNaming("documents-toolbar");

  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);

  const { downloadLoading, documentTypeDescriptions } = useDocumentsData();
  const { allRoles } = useRolesData();
  const { getDocumentTypeDescriptions } = useDocumentsMethods();
  const { onDownload } = useDocumentsActions();
  const { checkIsSubscribed } = useUsersData();
  const { subscriptionsLoading } = useUsersActions();
  const { subscribeToItemToggle } = useUsersMethods();
  const isAllSelected = selectedRows.length === allData.length;
  const isWithinRegulationTypes = isWithinRegulationTypesChecker(pageType, activeTab);
  const isCertificates = isCertificatesChecker(pageType, activeTab);
  const isRolesRegistry = isRolesRegistryChecker(pageType);
  const { currentUser } = useUsersData();

  const isNeedToSubscribe = isThereUnsubscribedDocument(allData, selectedRows, checkIsSubscribed, isRolesRegistry);

  const toolbarActions = getToolbarActions({
    items: allData,
    itemType: getDocumentType(isRolesRegistry, isCertificates),
    isDownloadButtonPresent: !isRolesRegistry,
    isNeedToSubscribe,
    isAllSelected,
    selectedRows,
    docType: pageType,
    subscriptionsLoading,
    handleSubscribe,
    onDownload,
    exportCallback: handleExport,
    userTitle: currentUser?.jobTitle,
  });

  const dataSources = useMemo(() => {
    return {
      toolbarActions: new ArrayDataSource({
        items: toolbarActions,
      }),
      listPickerDataSource: new ArrayDataSource({
        items: [
          { id: ViewModes.list, label: ViewModes.list, icon: listViewIcon },
          { id: ViewModes.card, label: ViewModes.card, icon: cardViewIcon },
        ],
      }),
      showPickerDataSource: new ArrayDataSource({
        items: generateShowFilterItems({ pageType, activeTab, allRoles, documentTypeDescriptions }),
      }),
    };
  }, [toolbarActions, documentTypeDescriptions]);

  const viewModePickerInput = renderPickerInput({
    dataSource: dataSources.listPickerDataSource,
    value: viewMode,
    minBodyWidth: 160,
    onValueChange: onViewModeChange,
    renderToggler: (props) => renderListPickerToggler(props, cn, isHasTabs),
    renderRow: (props) => renderListPickerRow(props, cn),
  });

  function handleExport() {
    const selectedData = getSelectedData(allData, selectedRows);

    if (isWithinRegulationTypes) {
      const fileName = exportFileNames[pageType];
      exportDocumentsToExcel(selectedData as IRegulation[], fileName, false);
    } else if (isCertificates) {
      exportCertificatesToExcel(selectedData as ICertificate[]);
    } else if (isRolesRegistry) {
      exportRolesToExcel(selectedData as IRole[]);
    }
  }

  function handleSubscribe(cb: IVoidCb) {
    const selectedData = getSelectedData(allData, selectedRows);

    subscribeToItemToggle({
      item: isRolesRegistry ? "role" : "document",
      isSubscribed: !isNeedToSubscribe,
      items: selectedData,
      cb,
    });
  }

  useEffect(() => {
    getDocumentTypeDescriptions();
  }, []);

  return (
    <div
      className={cn("", {
        "has-tabs": isHasTabs,
        "list-mode": viewMode === ViewModes.list,
      })}
    >
      {(downloadLoading || subscriptionsLoading) && <Loader />}
      {isHasTabs && (
        <div className={cn("tabs-wrapper", ["flex justify-between"])}>
          <TabsSlider activeTab={activeTab} tabs={tabs} onTabChange={onTabChange} />
          {viewModePickerInput}
        </div>
      )}
      <FlexRow cx={cn("actions-wrapper", { lined: !isHasTabs }, ["flex"])}>
        {pageType !== FullPageComponentTypes.Regulations &&
          renderPickerInput({
            dataSource: dataSources.showPickerDataSource,
            value: showFilter,
            minBodyWidth: 300,
            onValueChange: onShowFilterChange,
            renderToggler: (props) => renderShowPickerToggler(props, cn),
            ...(isNotRolesRegistry(pageType)
              ? {
                  renderFooter: (props) => renderShowPickerFooter(props, cn, pageType, activeTab),
                }
              : { renderFooter: (props) => renderRolesRegistryFooter(cn) }),
          })}
        {isCertificates && (
          <LinkButton
            cx="toolbar-button"
            iconPosition="left"
            size="none"
            fontSize="14"
            lineHeight="18"
            color="white"
            link={{ pathname: Links.certificatesBI }}
            icon={powerBiIcon}
            target="_blank"
            caption="EPAM Certificates Map"
          />
        )}
        <FlexSpacer />
        <FlexRow cx={cn("actions", ["flex justify-start align-center"])}>
          {isSearchExpanded ? (
            <Dropdown
              renderBody={() => (
                <DropdownMenuBody cx="dropdown" color="white">
                  {filterToolbarActions(toolbarActions, selectedRows).map((action) => (
                    <DropdownButtonWithAnalytics
                      key={action.id}
                      caption={action.caption}
                      icon={action.icon}
                      onClick={action.onClick}
                    />
                  ))}
                </DropdownMenuBody>
              )}
              renderTarget={(props: IDropdownToggler) => (
                <Button
                  size="36"
                  lineHeight="18"
                  fontSize="14"
                  cx={cn("actions-dropdown", { isOpen: props.isOpen })}
                  caption="Action"
                  {...props}
                />
              )}
            />
          ) : (
            <FlexRow cx={cn("actions-row")}>
              {filterToolbarActions(toolbarActions, selectedRows).map((action) => (
                <LinkButtonWithAnalytics
                  key={action.id}
                  cx="toolbar-button"
                  iconPosition="left"
                  size="none"
                  fontSize="14"
                  lineHeight="18"
                  color="white"
                  caption={action.caption}
                  icon={action.icon}
                  onClick={action.onClick}
                />
              ))}
            </FlexRow>
          )}
          <div className="toolbar-divider"></div>
          <FlexRow cx={cn("actions-row")}>
            <DocumentsFilter key={`${activeTab}_filter`} />
            <SearchMenuItem
              key={`${activeTab}_search`}
              expandCB={setIsSearchExpanded}
              placeholder="Search"
              value={searchValue}
              onValueChange={onSearchValueChange}
            />
          </FlexRow>
          {!isHasTabs && viewMode && viewModePickerInput}
        </FlexRow>
      </FlexRow>
    </div>
  );
};
