import React from "react";

import { Panel, Spinner } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import "./document-card-loader.scss";

const cn = withNaming("card-loader");

interface IDocumentsCardsLoaderProps {
  count: number;
  isNoSections: boolean;
}

export const DocumentsCardsLoader = ({ count, isNoSections }: IDocumentsCardsLoaderProps) => {
  return (
    <>
      {new Array(count).fill(
        <Panel cx={cn("", { isNoSections })} background="white" rawProps={{ "data-testid": "card-loader" }}>
          <Spinner />
        </Panel>,
      )}
    </>
  );
};
