import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { ApmRoute } from "@elastic/apm-rum-react";

import { Header } from "Components/header";
import { Navigation } from "Components/navigation";
import { SpecificPageWarningMessage } from "Components/specific-page-warning-message";
import { WarningMessage } from "Components/warning-message";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { useDocumentsMethods } from "Hooks/useDocuments";
import { useIconsActions } from "Hooks/useIcons";
import { useTermsMethods } from "Hooks/useTerms";
import { useUsersData, useUsersMethods } from "Hooks/useUsers";
import { msalInstance } from "Libs/msal";
import { NotFound } from "Pages/NotFound";

import Routes, { IRoute } from "./routes";

export const App = () => {
  const history = useHistory();
  const { getCurrentUser } = useUsersMethods();
  const { currentUser } = useUsersData();
  const { getTerms } = useTermsMethods();
  const { getAllDocuments } = useDocumentsMethods();
  const { getIcons } = useIconsActions();

  useEffect(() => {
    getCurrentUser();
    getIcons();
    getTerms("Navigation");
    getAllDocuments();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      analyticsHelper.analyticsService?.providePageView(currentUser?.jobTitle);
    }
  }, [location.pathname, currentUser]);

  useEffect(() => {
    if (msalInstance.redirectUri) {
      const path = new URL(msalInstance.redirectUri).pathname;
      msalInstance.redirectUri = null;

      history.push(path);
    }
  }, [location.pathname]);

  return (
    <>
      <Route component={Header} />
      {process.env.REACT_APP_GENERAL_WARNING_MESSAGE && (
        <WarningMessage message={process.env.REACT_APP_GENERAL_WARNING_MESSAGE} />
      )}
      {process.env.REACT_APP_SEARCH_WARNING_MESSAGE && (
        <SpecificPageWarningMessage message={process.env.REACT_APP_SEARCH_WARNING_MESSAGE} pagePath="/search" />
      )}
      <Navigation />
      <Switch>
        {Object.values(Routes).map((route: IRoute): React.ReactElement | undefined => {
          return <ApmRoute key={route.path} path={route.path} {...route.props} />;
        })}
        <ApmRoute component={NotFound} />
      </Switch>
    </>
  );
};
