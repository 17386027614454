import React from "react";

import { useUsersData } from "Hooks/useUsers";

import { analyticsHelper } from "./analyticsHelper";

export interface IButtonAnalyticsProps {
  caption?: string;
  onClick?: () => void;
}

export function withButtonAnalytics<WrappedProps>(
  Component: React.ComponentType<WrappedProps & IButtonAnalyticsProps>,
) {
  return (props: IButtonAnalyticsProps & WrappedProps) => {
    const { currentUser } = useUsersData();

    const onClick = () => {
      analyticsHelper.analyticsService?.provideButtonClick(props.caption, currentUser?.jobTitle);

      props.onClick?.();
    };

    return <Component {...props} onClick={onClick} />;
  };
}
