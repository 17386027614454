import React from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification.svg";
import { IconContainer, LinkButtonMods, Text, TextMods, Tooltip } from "@epam/loveship";
import { IHasCX } from "@epam/uui";
import { ButtonProps } from "@epam/uui-components";

import { getSubscribeTooltipText } from "Helpers/subscribeHelper";
import { LibraryName } from "SP/sitePages/sitePages.types";

import { DocumentsTableLink } from "./documents-table-link";

interface ILabelText {
  cn: (b, e?, m?) => string;
  label: string;
  caption: string;
  cutCx?: string;
  modifier?: string;
  linkProps?: ButtonProps & LinkButtonMods;
  additionalClasses?: string[];
}

export const renderText = (caption, props?: TextMods & IHasCX) => (
  <Text size="none" color="night900" fontSize="14" lineHeight="18" {...props}>
    {caption}
  </Text>
);

export const renderTextWithTooltip = (caption: string, props?: TextMods & IHasCX) => (
  <Tooltip content={caption} placement="bottom">
    {renderText(caption, props)}
  </Tooltip>
);

export const renderLabelLink = ({ cn, label, modifier, linkProps }: ILabelText) => (
  <div className={cn("details-label", { [modifier]: !!modifier }, ["flex flex-col hover-underline"])}>
    {renderText(label, { fontSize: "12", color: "night600" })}
    <DocumentsTableLink {...linkProps} />
  </div>
);

export const renderLinkWithTooltip = (caption: string, linkProps: ButtonProps & LinkButtonMods) => (
  <Tooltip content={caption} placement="bottom">
    <DocumentsTableLink {...linkProps} />
  </Tooltip>
);

export const renderSubscribed = (cn, library: LibraryName) => (
  <>
    <span className="dot" style={{ marginLeft: 6, marginRight: 8 }} />
    <IconContainer cx={cn("notification-icon")} icon={notificationIcon} color="night500" />
    <Tooltip placement="bottom" content={getSubscribeTooltipText(library)}>
      {renderText("Subscribed", { fontSize: "12", color: "night600" })}
    </Tooltip>
  </>
);

export const renderLink = (props: ButtonProps & LinkButtonMods) => {
  return <DocumentsTableLink {...props} />;
};

export const renderLabelText = ({ cn, caption, label, modifier, cutCx, additionalClasses = [] }: ILabelText) => (
  <div className={cn("details-label", { [modifier]: !!modifier }, [`flex flex-col`].concat(additionalClasses))}>
    {renderText(label, { fontSize: "12", color: "night600" })}
    {cutCx
      ? renderTextWithTooltip(caption, { fontSize: "14", color: "night900", cx: cutCx })
      : renderText(caption, { fontSize: "14", color: "night900" })}
  </div>
);
