import { Workbook } from "exceljs";

import { replacePageUrlPrefix } from "Helpers/utils";
import { RoleField } from "SP/fields";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";

import {
  autoWidth,
  createHyperLink,
  ExportFileName,
  exportToExcel,
  formatHyperlinks,
  IManuallyAlignedColumn,
  ITableLink,
} from "./helpers";

interface expRole {
  [RoleField.Title]: ITableLink | string;
  [RoleField.Group]: string;
  [RoleField.RoleCategory]: string;
  [RoleField.ShortDescription]: string;
  [RoleField.Regulations]: ITableLink | string;
  // [RoleField.ApprovedBy]: string;
  // [RoleField.PublishedDate]: string;
  [RoleField.DocumentArea]: string;
}

const manuallyAlignedColumns: { [column: string]: IManuallyAlignedColumn } = {
  [RoleField.ShortDescription]: { width: 75, wrapText: true, merge: true },
  [RoleField.Regulations]: { wrapText: true, merge: false },
  [RoleField.DocumentArea]: { width: 50, wrapText: true, merge: true },
};

export function exportRolesToExcel(roles: IRole[], fileName: string = ExportFileName.roles): void {
  const Url = window.location.origin;
  const rows: (string | ITableLink)[][] = new Array(roles.length + 1);
  const columns = Object.keys({
    [RoleField.Title]: "",
    [RoleField.Group]: "",
    [RoleField.RoleCategory]: "",
    [RoleField.ShortDescription]: "",
    [RoleField.Regulations]: "",
    // [RoleField.ApprovedBy]: "",
    // [RoleField.PublishedDate]: "",
    [RoleField.DocumentArea]: "",
  } as expRole);
  rows.push(columns);
  for (const role of roles) {
    const roleUrl = `${Url}${role.roleUrl}`;
    if (role.Regulations.length === 0) {
      const item: expRole = {
        [RoleField.Title]: createHyperLink(role.Title, roleUrl),
        [RoleField.Group]: role.Group,
        [RoleField.RoleCategory]: role.RoleCategory,
        [RoleField.ShortDescription]: role.ShortDescription,
        [RoleField.Regulations]: "",
        // [RoleField.ApprovedBy]: i > 0 ? "" : role.Approvedby ? role.Approvedby.join("\n") : "",
        // [RoleField.PublishedDate]: i > 0 ? "" : createDateString(role.PublishedDate),
        [RoleField.DocumentArea]: role.DocumentArea ? role.DocumentArea.join("\n") : "",
      };
      const row = [];
      for (const column of columns) row.push(item[column]);
      rows.push(row);
    } else {
      for (let i = 0; i < role.Regulations.length; i++) {
        const item: expRole = {
          [RoleField.Title]: i > 0 ? "" : createHyperLink(role.Title, roleUrl),
          [RoleField.Group]: i > 0 ? "" : role.Group,
          [RoleField.RoleCategory]: i > 0 ? "" : role.RoleCategory,
          [RoleField.ShortDescription]: i > 0 ? "" : role.ShortDescription,
          [RoleField.Regulations]: createHyperLink(
            role.Regulations[i].Description,
            replacePageUrlPrefix(role.Regulations[i].Url),
          ),
          // [RoleField.ApprovedBy]: i > 0 ? "" : role.Approvedby ? role.Approvedby.join("\n") : "",
          // [RoleField.PublishedDate]: i > 0 ? "" : createDateString(role.PublishedDate),
          [RoleField.DocumentArea]: i > 0 ? "" : role.DocumentArea ? role.DocumentArea.join("\n") : "",
        };
        const row = [];
        for (const column of columns) row.push(item[column]);
        rows.push(row);
      }
    }
  }
  const workbook = formatExcelWorkBook(rows, columns, fileName);
  exportToExcel(workbook, `${fileName}.xlsx`);
}

function formatExcelWorkBook(rows: (string | ITableLink)[][], columns: string[], fileName: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  const manuallyAlignedColumnNames = Object.keys(manuallyAlignedColumns);
  worksheet.addRows(rows);
  let startCellNumber: number;
  worksheet.eachRow((row, index) => {
    const cell = row.getCell(1);
    if (cell.isMerged) return;
    if (cell.text != "") {
      startCellNumber = +cell.row;
    }
    let nextColumnsIndex = index + 1;
    while (worksheet.getRow(nextColumnsIndex).getCell(1).text == "" && nextColumnsIndex < worksheet.rowCount) {
      nextColumnsIndex++;
    }
    if (
      index != 1 &&
      worksheet.getRow(nextColumnsIndex).getCell(5).text != "" &&
      worksheet.getRow(nextColumnsIndex).getCell(1).text == ""
    )
      nextColumnsIndex++;
    nextColumnsIndex -= 1;
    for (const column of worksheet.columns) {
      const columnName = columns[column.number - 1];
      if (manuallyAlignedColumnNames.includes(columnName) && !manuallyAlignedColumns[columnName].merge) continue;
      worksheet.mergeCells(`${column.letter}${startCellNumber}:${column.letter}${nextColumnsIndex}`);
    }
  });
  autoWidth(worksheet);
  for (const column in manuallyAlignedColumns) {
    const columnIndex = columns.indexOf(column) + 1;
    if (manuallyAlignedColumns[column].width) {
      worksheet.getColumn(columnIndex).width = manuallyAlignedColumns[column].width;
    }
    if (manuallyAlignedColumns[column].wrapText) {
      worksheet.getColumn(columnIndex).alignment = { wrapText: true };
    }
  }
  formatHyperlinks(worksheet);
  return workbook;
}
