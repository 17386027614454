import _cloneDeep from "lodash/cloneDeep";
import _forOwn from "lodash/forOwn";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import qs from "qs";

import { ButtonProps } from "@epam/uui-components";

import { IFilterKeys } from "Components/documents-filter/documents-filter.interface";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { CertificateField, DocumentField, RoleField } from "SP/fields";
import { IVoidCb } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { ISubscribeItem, ISubscribeToItemToggleParams } from "Store/actions/users.actions";
import { FilterValueType, IFiltersGroup, ISelectedFilterValue, ISubarea } from "Store/reducers/filters.reducer";
import { DetailTypes } from "Store/reducers/site-pages.reducer";

import { ErrorMessages, Extensions, FriendlyUrlPrefixes, validatePageTitle } from "./constants";
import { ENVS, getEnvironment } from "./environments";

export function parseJson(str: string) {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
}

export function updateObject<T, U>(obj1: T, obj2: U): T & U {
  return { ...obj1, ...obj2 };
}

export function removeUrlPrefix(url: string): string {
  if (!url) return "";

  const UrlPrefix = getUrlPrefix();
  const regexp = new RegExp(UrlPrefix, "i");
  return url.replace(regexp, "");
}

export function isTermHaveChild(term: ISPTermObject): boolean {
  return Array.isArray(term.terms) && term.terms.length > 0;
}

export function fallbackCopyTextToClipboard(text: string, cb: IVoidCb): void {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    successful && cb();
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
  textArea = null;
}

export function copyTextToClipboard(text: string, cb: IVoidCb): void {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, cb);
    return;
  }

  navigator.clipboard.writeText(text).then(cb, (err) => {
    console.error("Async: Could not copy text: ", err);
  });
}

export function getInternalUrl(url: string): string {
  if (!url) return;

  if (url.startsWith(window.origin)) {
    return url.replace(window.origin, "");
  }

  const Url = process.env.REACT_APP_BASE_URL;
  const appUrl = `${Url}/index.aspx`;

  if (url.includes(appUrl)) {
    return url.replace(appUrl, "");
  }

  return url.replace(`${Url}/SitePages`, "");
}

export function isInternalUrl(url: string): boolean {
  if (!url) return;

  const serverRelativeUrl = getServerRelativeUrl();
  return url.includes(serverRelativeUrl) || url.includes(location.origin);
}

export function isPageUrl(url: string): boolean {
  if (!url) return;

  const Url = process.env.REACT_APP_BASE_URL;

  const urlWithoutParameters = url.split("?")[0];

  return (
    urlWithoutParameters.endsWith(Extensions.Aspx) ||
    url.includes(`${Url}/index.aspx`) ||
    url.includes(`${Url}/SitePages`)
  );
}

export function isDocumentView(url: string): boolean {
  if (!url) return;

  return url.includes("/:b:/r") || url.includes("/:w:/r") || url.includes("/:u/r");
}

export function isExternalUrl(url: string): boolean {
  if (!url) return;

  return url.indexOf("http") === 0;
}

export function isLastItem<T>(items: T[], index: number): boolean {
  return items.length - 1 === index;
}

export function isSitePage(pathname: string): boolean {
  return pathname.indexOf(Extensions.Aspx) !== -1 || pathname.startsWith("/pages/");
}

export function isHomePage(pathname: string): boolean {
  return pathname == "/";
}

export function getLinkProps(url: string): ButtonProps {
  return isExternalUrl(url) ? { target: "_blank", href: url } : { link: { pathname: removeUrlPrefix(url) } };
}

export function handleSortByName(a, b, asc: boolean) {
  if (!asc) {
    return handleSortByName(b, a, true);
  }

  if (!a && b) {
    return -1;
  }

  if (a && !b) {
    return 1;
  }

  if (!a && !b) {
    return 0;
  }

  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export function handleSortByDate(a, b, asc: boolean) {
  if (!asc) {
    return handleSortByDate(b, a, true);
  }

  return +new Date(a) - +new Date(b);
}

export function transformFileName(fileName: string): string {
  const regex = /(?=[A-Z][a-z])/;

  return fileName.split(regex).join("\n");
}

type IGetSubscribeParams = {
  item: ISubscribeItem;
  document: Partial<IRegulation & ICertificate & IRole>;
  checkIsSubscribed: (l: string, d: number) => boolean;
};

export function getSubscribeParams(params: IGetSubscribeParams): ISubscribeToItemToggleParams {
  const { item, document, checkIsSubscribed } = params;

  return {
    item,
    isSubscribed: checkIsSubscribed(document.sourceLibrary, document.Id),
    items: [document],
  };
}

export function getFieldIfExist<T>(field: keyof T, obj: T) {
  return field in obj && obj[field];
}

export function getTitleErrorMessage(title) {
  if (!title || !validatePageTitle.test(title)) {
    return ErrorMessages.emptyOrHaveInvalidCharacters;
  } else if (title.length > 255) {
    return ErrorMessages.long;
  }

  return "";
}

export function getPresetErrorMessage(presetName: string, favoriteFilters: IFavoriteFilter[]) {
  const isPresetNameExists = favoriteFilters.some((filter) => filter.title === presetName);
  const commonErrorMessage = getTitleErrorMessage(presetName);

  if (commonErrorMessage) {
    return commonErrorMessage;
  } else if (isPresetNameExists) {
    return ErrorMessages.nameExists;
  }

  return "";
}

export function isDetailCard(type: DetailTypes) {
  return type === DetailTypes.card || type === DetailTypes.parentCard;
}

type ICheckboxSelectionParams<T> = {
  item: T;
  items: T[];
  setter: (value: React.SetStateAction<T[]>) => void;
  cb?: (items: T[]) => void;
};

export function handleCheckboxSelection<T>(params: ICheckboxSelectionParams<T>) {
  const { item, items, setter, cb } = params;

  return (value: boolean) => {
    if (value) {
      const newItems = [...items, item];
      setter(newItems);
      return cb?.(newItems);
    }

    setter((oldItems) => {
      const updatedOldItems = oldItems.filter((oldItem) => oldItem !== item);
      cb?.(updatedOldItems);
      return updatedOldItems;
    });
  };
}

export type FilterType = "equal" | "includes";

export function isQueryWithinText(text: string, query: string, filterType?: FilterType) {
  if (filterType === "includes") {
    return text?.toLowerCase().includes(query.toLowerCase());
  }

  if (filterType === "equal") {
    return text === query;
  }

  return text?.toLowerCase().startsWith(query.toLowerCase());
}

export function checkOpenLinkInNewTab(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  return event.ctrlKey || event.metaKey;
}

export function handleClick(
  href: string,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  push: {
    (path: string, state?: unknown): void;
  },
) {
  if (checkOpenLinkInNewTab(event)) window.open(href);
  else {
    if (href.toLowerCase().includes("siteassets")) {
      location.href = href;
    } else {
      push(getInternalUrl(href));
    }
  }
}

export const handleAllCheckboxLabel = (selectedRowsLength: number, dataLength: number, type: string): string => {
  if (!selectedRowsLength) {
    return `Select all ${type}s`;
  } else if (selectedRowsLength === dataLength) {
    return `All ${type}s are selected`;
  }

  return `${selectedRowsLength} ${type}(s) selected`;
};

export function isNotEmpty<T>(value: T): boolean {
  return !_isEmpty(value);
}

export const hasFilterValueInItem = (item, field, value) => {
  if (!item) return false;

  if (value === "" && (!item[field] || item[field].length == 0)) return true;

  return (
    item[field] === value ||
    (_isArray(item[field]) && item[field].some((x) => x == value || _get(x, "Description") == value)) ||
    _get(item[field], "Description") === value
  );
};

export const libraryNameToFields = (library: LibraryName) => {
  if (library === LibraryName.certificates) {
    return CertificateField;
  }

  if (library === LibraryName.roles) {
    return RoleField;
  }

  return DocumentField;
};

export function isEmptyFilterField(value: any) {
  return !value || value.length === 0;
}

export function isEqualFilters(
  firstFilter: Partial<Record<IFilterKeys, ISelectedFilterValue>>,
  secondFilter: Partial<Record<IFilterKeys, ISelectedFilterValue>>,
): boolean {
  const a = _cloneDeep(firstFilter);
  const b = _cloneDeep(secondFilter);

  for (const key in a) {
    if ((a[key] as any).type == FilterValueType.subarea) {
      (a[key] as ISubarea)?.items?.sort();
      (b[key] as ISubarea)?.items?.sort();
    }
  }

  return _isEqual(a, b);
}

export function isEqualFiltersGroups(firstGroups: IFiltersGroup[], secondGroups: IFiltersGroup[]): boolean {
  if (firstGroups.length !== secondGroups.length) return false;

  return firstGroups.every(
    (g, i) => isEqualFilters(g.filters, secondGroups[i].filters) && g.enabled === secondGroups[i].enabled,
  );
}

export function traverseObject(object: any, handleField: (name: string, value: any) => void) {
  _forOwn(object, (value, key) => {
    if (typeof value === "object") {
      traverseObject(value, handleField);
      return;
    }

    handleField(key, value);
  });
}

export function getPlainText(source: string): string {
  if (_isEmpty(source)) return source;

  return source
    .replaceAll("&quot;", '"')
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("&amp;nbsp;", "")
    .replaceAll("&amp;", "&")
    .replaceAll("&#39;", "'")
    .replaceAll("&#160;", " ")
    .replaceAll("&#226;", "â")
    .replaceAll("&ndash;", "–")
    .replaceAll("&mdash;", "—")
    .replaceAll("&ldquo;", "“")
    .replaceAll("&rdquo;", "”")
    .replaceAll("&frasl;", "/");
}

export function getEncodedText(source: string): string {
  if (_isEmpty(source)) return source;

  return source
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll("–", "&ndash;")
    .replaceAll("—", "&mdash;")
    .replaceAll("“", "&ldquo;")
    .replaceAll("”", "&rdquo;")
    .replaceAll("/", "&frasl;");
}

export function getXmlTagContent(source: string, tagName: string): string {
  if (_isEmpty(source)) return source;

  const matchRegex = new RegExp(`<${tagName}[^>]+>`);
  const pageInfoOpenTag = source.match(matchRegex)[0];
  const tagStart = source.indexOf(`<${tagName}`);
  const tagEnd = source.indexOf(`</${tagName}`);

  if (tagStart === -1 || tagEnd === -1) return "";

  return source.substring(tagStart + pageInfoOpenTag.length, tagEnd);
}

export function isSpecialSearchSymbol(symbol: string) {
  return symbol === "*";
}

export function reverseObjectKeyValue(object: Record<string, string>) {
  const newObject = {};
  Object.entries(object).forEach(([key, value]) => (newObject[value.toString()] = key));
  return newObject;
}

export function parseDownloadLink(downloadLink: string) {
  if (!downloadLink) {
    return "";
  }

  const url = new URL(downloadLink);
  const params = qs.parse(url.search, { ignoreQueryPrefix: true });

  const relativeUrl = params["SourceUrl"];

  return `${url.origin}${relativeUrl}`;
}

export function removeDuplicates<T>(source: T[], getKey: (value: T) => string) {
  const table = {};
  return source.filter((value) => {
    const key: string = getKey ? getKey(value) : (value as any);
    if (table[key]) {
      return false;
    } else {
      table[key] = true;
      return true;
    }
  });
}

export function performRedirectTo(url: string) {
  window.history.replaceState(null, "", url);
}

export function performRedirectToRelativeUrl(url: string, searchParams?: string) {
  if (!url) return;

  if (searchParams) {
    performRedirectTo(`${window.location.origin}/${url}${searchParams}`);
    return;
  }

  return performRedirectTo(`${window.location.origin}/${url}`);
}

export function getServerRelativeUrl(): string {
  const baseUrl = new URL(process.env.REACT_APP_BASE_URL);
  return baseUrl.pathname;
}

export function getServerUrl(): string {
  const baseUrl = new URL(process.env.REACT_APP_BASE_URL);
  return baseUrl.href;
}

export function getUrlPrefix(): string {
  return `${getServerRelativeUrl()}/SitePages`;
}

export function replacePageUrlPrefix(url: string): string {
  return url?.replace(/^(?:.*\/SitePages)/, window.location.origin);
}

export function replaceAllPageUrlPrefixes(text: string): string {
  return text.replaceAll(`${getServerOriginUrl()}${getUrlPrefix()}`, window.location.origin);
}

export function getServerOriginUrl(): string {
  const baseUrl = new URL(process.env.REACT_APP_BASE_URL);
  return baseUrl.origin;
}

export function getDocumentPageUrl(documentUrl: string) {
  return `${window.location.origin}/document?documentUrl=${documentUrl}`;
}

export function getDocumentViewUrl(viewUrl: string, friendlyUrl: string) {
  if (friendlyUrl) {
    return `${window.origin}/${friendlyUrl}`;
  }

  if (viewUrl.startsWith("/roles/")) {
    return `${window.location.origin}${viewUrl}`;
  }

  if (viewUrl?.startsWith(`${window.location.origin}/roles/`)) {
    return viewUrl;
  }

  return getDocumentPageUrl(viewUrl);
}

export function getDocumentDownloadUrl(viewUrl: string, friendlyUrl: string) {
  const documentViewUrl = getDocumentViewUrl(viewUrl, friendlyUrl);

  if (documentViewUrl.includes("?")) {
    return `${documentViewUrl}&forceDownload=true`;
  }
  return `${documentViewUrl}?forceDownload=true`;
}

export function getDocumentName(documentUrl: string) {
  const cleanUrl = documentUrl.split("?")[0];
  return cleanUrl.split("/").pop();
}

export function isFriendlyUrl(url: string) {
  return FriendlyUrlPrefixes.some((x) => url.startsWith(`${window.location.origin}${x}`));
}

export function getRecentItemUrl(url: string) {
  if (isFriendlyUrl(url)) {
    return url;
  }

  const pathname = new URL(url).pathname;
  if (isHomePage(pathname)) {
    return url;
  }

  if (url.toLocaleLowerCase().endsWith(Extensions.Aspx)) {
    return replacePageUrlPrefix(url);
  }

  return getDocumentViewUrl(url, null);
}

// log errors without toString method
export function logStringifiedError(description: string, e: any): void {
  try {
    console.log(description, JSON.stringify(e));
  } catch (error) {
    console.log("Error logging failed. Description: ", description, "Fail reason: ", error, "Error data: ", e);
  }
}

export function getFullFriendlyUrl(furl: string) {
  return `${window.origin}/${furl}`;
}

const documentRelativeUrlRegex = /\/sites\/[\w-]+\/(?<library>[\w\s]+)\/(?<path>[^\?]+)/;

export function parseDocumentRelativeUrl(url: string) {
  const match = url.match(documentRelativeUrlRegex);
  return { libraryName: match.groups?.library, filePath: match.groups?.path };
}

export function LogError(...params) {
  if (getEnvironment() !== ENVS.prod) {
    console.error(...params);
  }
}
