import React, { FC } from "react";

import { Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { SectionTypes } from "SP/sitePages/sitePages.types";

import "./layout-select.scss";

interface ILayoutSelectProps {
  onChange: (type: string, columnsCount: number) => (e) => void;
  selectedLayout: string;
  className?: string;
  showTitle?: boolean;
}

interface ILayoutOptionProps extends Pick<ILayoutSelectProps, "onChange" | "showTitle"> {
  type: string;
  columns: number;
  selected: boolean;
  title: string;
  cn: (b, e?, m?) => string;
}

const layoutOptions = [
  { type: SectionTypes.OneColumnLayout, title: "One Column", columns: 1 },
  { type: SectionTypes.TwoColumnsLayout, title: "Two Columns", columns: 2 },
  { type: SectionTypes.OneThirdLeftLayout, title: "One-third Left", columns: 2 },
  { type: SectionTypes.OneThirdRightLayout, title: "One-third Right", columns: 2 },
  { type: SectionTypes.ThreeColumnsLayout, title: "Three Columns", columns: 3 },
];

const LayoutOption: FC<ILayoutOptionProps> = ({ cn, type, title, showTitle, columns, selected, onChange }) => (
  <div
    className={cn("layout-option", { [type]: true, selected }, [
      "flex flex-col justify-center items-center cursor-pointer",
    ])}
    onClick={onChange(type, columns)}
    {...(!showTitle && { title })}
    data-testid="layout-option"
  >
    <div className={cn("columns", ["flex"])}>
      {[...Array(columns).keys()].map((column) => (
        <span className="column" key={column}></span>
      ))}
    </div>

    {showTitle && (
      <Text cx={cn("text")} size="none" color="night900" fontSize="14" lineHeight="18">
        {title}
      </Text>
    )}
  </div>
);

export const LayoutSelect: FC<ILayoutSelectProps> = ({ className, onChange, selectedLayout, showTitle }) => {
  const cn = withNaming(className || "layout-select");

  return (
    <div className={cn("", ["flex"])}>
      {layoutOptions.map(({ type, title, columns }) => (
        <LayoutOption
          key={type}
          cn={cn}
          type={type}
          title={title}
          columns={columns}
          showTitle={showTitle}
          selected={type === selectedLayout}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
