import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";

import { DocumentsViewContext } from "@App/context/DocumentsViewContext";
import { Checkbox, LinkButton } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { renderLabelText, renderSubscribed, renderText } from "Components/documents-table/documents-table.helpers";
import { IRowProps } from "Components/documents-table/documents-table.interface";
import { RoleCategory } from "Components/role-category/role-category";
import { ShowCurtain } from "Components/show-curtain";
import { withNaming } from "Helpers/bemClassname";
import highlight from "Helpers/highlight";
import { highlightWithTiming } from "Helpers/timingElements";
import { replacePageUrlPrefix } from "Helpers/utils";
import { IHyperLinkField } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";

interface RoleDocumentCardProps extends IRowProps {
  card: IRole;
  checked: boolean;
  onCheckToggle: (v: boolean) => void;
}

const cn = withNaming("documents-card");

export const RoleDocumentCard: FC<RoleDocumentCardProps> = ({
  card,
  checked,
  subscribeParams,
  onCheckToggle,
  onOpenInfoPanel,
}) => {
  const { searchValue } = useContext(DocumentsViewContext);

  const renderRegulationsList = (regs: IHyperLinkField[], isFullWidth: boolean) => (
    <div className={cn("details-label", ["flex flex-col"])}>
      {renderText("Regulations", { fontSize: "12", color: "night600" })}
      <ul className={cn("regulations", { isFullWidth })}>
        {regs.map((item) => (
          <li key={item.Url}>
            <LinkButton
              caption={highlight.peek(searchValue, item.Description)}
              font="sans"
              fontSize="14"
              lineHeight="18"
              href={replacePageUrlPrefix(item.Url)}
              target="_blank"
              cx={"hover-underline"}
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div
      className={cn("row", { isRole: true, isSelected: checked }, ["relative flex items-center"])}
      data-testid="card"
    >
      <Checkbox
        cx={cn("checkbox", ["checkbox-list"])}
        size="18"
        value={checked}
        rawProps={{ "data-testid": "card-checkbox" }}
        onValueChange={onCheckToggle}
      />
      <div className={cn("content", ["flex shadow"])}>
        <div className={cn("details", ["relative flex flex-col"])}>
          <ShowCurtain maxHeight={180}>
            <div className={cn("details-row", ["flex flex-col"])}>
              <Link className={cn("details-title")} to={card.roleUrl} target="_blank">
                {highlightWithTiming({ elementtiming: "role-name", query: searchValue, value: card.Title })}
              </Link>
              <div className="flex">
                <RoleCategory text={card.RoleCategory} searchValue={searchValue} />
                <span className="dot" style={{ margin: "0 6px" }} />
                {renderText(highlight.peek(searchValue, card.Group), { fontSize: "12", color: "night600" })}
                {subscribeParams.isSubscribed && renderSubscribed(cn, card.sourceLibrary)}
              </div>
            </div>
            <div className={cn("details-row", { isRoles: true }, ["flex"])}>
              {card.ShortDescription &&
                renderLabelText({
                  cn,
                  label: "Description",
                  caption: highlight.peek(searchValue, card.ShortDescription) as string,
                  additionalClasses: ["whitespace-pre-line"],
                })}
              {card.Regulations.length ? renderRegulationsList(card.Regulations, !card.ShortDescription) : null}
            </div>
          </ShowCurtain>
          <DocumentActionsDropdown
            serverRelativeUrl={card.roleUrl}
            viewUrl={card.roleUrl}
            friendlyUrl={null}
            subscribeParams={subscribeParams}
            onOpenInfoPanel={onOpenInfoPanel}
          />
        </div>
      </div>
    </div>
  );
};
