import React, { FC } from "react";

import { FlexCell } from "@epam/loveship";

import { DocumentCard } from "Components/documents/document-card";
import { useDocumentActions } from "Components/documents-table/documents-table.hooks";
import { withNaming } from "Helpers/bemClassname";
import { getSubscribeParams } from "Helpers/utils";
import { useSitePagesActions } from "Hooks/useSitePages";
import { useUsersData } from "Hooks/useUsers";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { DocumentsCardsLoader } from "./document-card-loader/document-card-loader";

import "./documents.scss";

interface IDocumentsProps {
  attachedFiles: IAttachedFile[];
  isNoSections: boolean;
  filesLoading: boolean;
  filesCount: number;
}

export const Documents: FC<IDocumentsProps> = ({ attachedFiles, isNoSections, filesLoading, filesCount }) => {
  const cn = withNaming("documents");
  const { isSitePageInEditMode } = useSitePagesActions();
  const { checkIsSubscribed } = useUsersData();
  const { handleOpenDocumentInfo } = useDocumentActions();

  if (!filesCount) return null;

  return (
    <FlexCell grow={1} cx={cn("", { ["edit-mode"]: isSitePageInEditMode, ["empty-sections"]: isNoSections })}>
      {filesLoading ? (
        <DocumentsCardsLoader count={filesCount} isNoSections={isNoSections} />
      ) : (
        attachedFiles.map((attachedFile) => (
          <DocumentCard
            key={attachedFile.Id}
            attachedFile={attachedFile}
            isSingle={attachedFiles.length === 1}
            isNoSections={isNoSections}
            subscribeParams={getSubscribeParams({
              item: "document",
              document: attachedFile,
              checkIsSubscribed,
            })}
            onOpenInfoPanel={handleOpenDocumentInfo(attachedFile)}
          />
        ))
      )}
    </FlexCell>
  );
};
