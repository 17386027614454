import produce from "immer";
import { Reducer } from "redux";

import { IFoldersInfo } from "SP/helpers.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

import { DocumentsActionsTypes, IAllDocuments, IDocumentsActionsTypes } from "../actions/documents.actions";

export interface IDocumentsState {
  allDocuments: IAllDocuments;
  loading: boolean;
  downloadLoading: boolean;
  isDocumentsLoaded: boolean;
  foldersInfo: IFoldersInfo;
  invalidItemsCount: number;
}

const initialState: IDocumentsState = {
  allDocuments: {
    [LibraryName.regulations]: [],
    [LibraryName.templates]: [],
    [LibraryName.certificates]: [],
  },
  loading: false,
  downloadLoading: false,
  isDocumentsLoaded: false,
  foldersInfo: {
    [LibraryName.regulations]: [],
    [LibraryName.templates]: [],
    templatesDescription: undefined,
  },
  invalidItemsCount: 0,
};

const documentsReducer: Reducer<IDocumentsState, IDocumentsActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case DocumentsActionsTypes.GET_ALL_DOCUMENTS_REQUEST:
      draft.loading = true;
      break;
    case DocumentsActionsTypes.GET_ALL_DOCUMENTS_SUCCESS:
      draft.allDocuments = action.payload;
      draft.loading = false;
      draft.isDocumentsLoaded = true;
      draft.invalidItemsCount = action.invalidItemsCount;
      break;
    case DocumentsActionsTypes.GET_ALL_DOCUMENTS_FAILURE:
      draft.loading = false;
      draft.isDocumentsLoaded = true;
      draft.invalidItemsCount = 0;
      break;
    case DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_SUCCESS:
      draft.foldersInfo = action.payload;
      break;

    case DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_REQUEST:
      draft.downloadLoading = true;
      break;
    case DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_SUCCESS:
    case DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_FAILURE:
      draft.downloadLoading = false;
      break;
  }
}, initialState);

export default documentsReducer;
