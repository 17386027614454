import React from "react";
import { Helmet } from "react-helmet";

interface ISeoHeadProps {
  page: string;
}

export const SeoHead: React.FC<ISeoHeadProps> = ({ page }) => (
  <Helmet>
    <title>ECL | {page}</title>
  </Helmet>
);
