import { IVisibleProp } from "SP/helpers.types";

export enum CommonPropNames {
  Author = "Author",
  Created = "Created",
  Editor = "Editor",
  Modified = "Modified",
}

export enum CommonDocumentPropNames {
  WorkStorage = "WorkStorage",
  publishedVersion = "publishedVersion",
  FileDirRef = "FileDirRef",
}

export enum CertificateUniquePropNames {
  EPAMDocumentID = "EPAMDocumentID",
  Standard = "Standard",
  LocationCountry = "LocationCountry",
  LocationCity = "LocationCity",
  ValidFrom = "ValidFrom",
  ValidUntil = "ValidUntil",
  IssueDate = "IssueDate",
  CertificationBody = "CertificationBody",
  LegalEntities = "LegalEntities",
  ScopeLimitation = "ScopeLimitation",
  CertificateArea = "CertificateArea",
  Responsible = "Responsible",
  RegistrationNumber = "RegistrationNumber",
}

export enum DocumentUniquePropNames {
  EPAMDocumentID = "EPAMDocumentID",
  LocationCountry = "LocationCountry",
  DocumentArea = "DocumentArea",
  ApprovalDate = "ApprovalDate",
  ApproverName = "ApproverName",
  ApproverPosition = "ApproverPosition",
  DocumentOwnerPosition = "DocumentOwnerPosition",
  OrganizationalUnit = "OrganizationalUnit",
  BriefDescription = "BriefDescription",
  PublishedPlace = "PublishedPlace",
  Category = "Category",
  RequiredReviewDate = "RequiredReviewDate",
  Company = "Company",
  ReviewPeriod = "ReviewPeriod",
  Standards = "Standards",
  Subarea = "Subarea",
}

export enum RoleUniquePropNames {
  Group = "Group",
  PublishedDate = "PublishedDate",
  Approvedby = "Approvedby",
  DocumentArea = "DocumentArea",
  ShortDescription = "ShortDescription",
  Status = "Status",
  Regulations = "Regulations",
  Documents = "Documents",
}

export type ILibraryPropNames = RolePropNames | CertificatePropNames | DocumentPropNames;

export type CertificatePropNames = CertificateUniquePropNames | CommonDocumentPropNames | CommonPropNames;

export type DocumentPropNames = DocumentUniquePropNames | CommonDocumentPropNames | CommonPropNames;

export type RolePropNames = RoleUniquePropNames | CommonPropNames;

export const CertificateProps = { ...CertificateUniquePropNames, ...CommonDocumentPropNames, ...CommonPropNames };

export const DocumentProps = { ...DocumentUniquePropNames, ...CommonDocumentPropNames, ...CommonPropNames };

export const RoleProps = { ...RoleUniquePropNames, ...CommonPropNames };

export interface IInfoPanelConfiguration {
  readonly Id: number;
  readonly certificates: IInfoPanelProp[];
  readonly documents: IInfoPanelProp[];
  readonly roles: IInfoPanelProp[];
}

export interface IInfoPanelProp extends IVisibleProp {
  prop: ILibraryPropNames;
  order: string;
}

export interface IInfoPanelConfigurationDTO {
  readonly Id: number;
  readonly certificates: string;
  readonly documents: string;
  readonly roles: string;
}
