import { IRegulation } from "SP/documents/regulations/regulations.types";
import { DocumentField } from "SP/fields";

import { exportToExcel, formatExcelWorkBook, formatItemsForExcelWorkBook, IManuallyAlignedColumn } from "./helpers";

const manuallyAlignedColumns: { [column: string]: IManuallyAlignedColumn } = {
  [DocumentField.DocumentArea]: { width: 25, wrapText: true },
  [DocumentField.ApproverPosition]: { width: 25, wrapText: true },
  [DocumentField.OrganizationalUnit]: { width: 25, wrapText: true },
  [DocumentField.Description]: { width: 50, wrapText: true },
  [DocumentField.PublishedPlace]: { width: 25, wrapText: true },
  [DocumentField.Category]: { width: 25, wrapText: true },
};

export function exportDocumentsToExcel(documents: IRegulation[], fileName: string, templatesInArray?: boolean): void {
  const { rows, columns } = formatItemsForExcelWorkBook(documents, templatesInArray);
  const workbook = formatExcelWorkBook(rows, columns, fileName, manuallyAlignedColumns);
  exportToExcel(workbook, `${fileName}.xlsx`);
}
