import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import { useSitePagesMethods } from "Hooks/useSitePages";
import { DynamicSitePage } from "Pages/DynamicSitePage";

export const FriendlyUrlSitePage: FC<RouteComponentProps> = () => {
  const { getSitePageByFriendlyUrl } = useSitePagesMethods();

  const { pathname: friendlyUrl } = useFriendlyURLPathname();

  useEffect(() => {
    getSitePageByFriendlyUrl(friendlyUrl);
  }, [friendlyUrl]);

  return <DynamicSitePage pageUrl={friendlyUrl} />;
};
