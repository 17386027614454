import { checkHighlightExact, checkOccurrencesAll } from "Components/found-content/found-content";
import { apostropheRegex, searchSpecialSymbols } from "SP/constants";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { ISearchItem, ISearchSitePage } from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { filterRegulationByFolder } from "SP/sp.helpers";
import { SearchTypes } from "Store/reducers/search.reducer";

export function filterByFolders(item: ISearchItem): boolean {
  if (item.sourceLibrary == LibraryName.templates) {
    return filterRegulationByFolder(item as IRegulation);
  }

  return true;
}

export function filterSearchItem(item: ISearchItem, searchType: SearchTypes): boolean {
  const searchResultText = getSearchResultText(item, searchType);
  const fieldsValues = getSearchableFieldsValues(item, searchType, searchResultText);

  if (fieldsValues.length > 0) {
    return checkSearchableFields(fieldsValues, item, searchType);
  }

  return true;
}

function getSearchableFieldsValues(item: ISearchItem, searchType: SearchTypes, searchResultText: string): string[] {
  if (item.sourceLibrary === LibraryName.pages) {
    return getPagesSearchableFields(item as ISearchSitePage, searchResultText);
  }

  if (searchType === SearchTypes.exact || item.HitHighlightedAll.queries.some(isContainsApostrophe)) {
    if (item.sourceLibrary === LibraryName.regulations || item.sourceLibrary === LibraryName.templates) {
      return getRegulationSearchableFields(item as IRegulation, searchResultText);
    }

    if (item.sourceLibrary === LibraryName.certificates) {
      return getCertificateSearchableFields(item as ICertificate, searchResultText);
    }

    if (item.sourceLibrary === LibraryName.roles) {
      return getRoleSearchableFields(item as IRole);
    }
  }

  return [];
}

function isContainsApostrophe(source: string) {
  return source.search(apostropheRegex) !== -1;
}

function isContainsSpecialSymbols(source: string) {
  return searchSpecialSymbols.some((x) => source.includes(x));
}

function getSearchResultText(item: ISearchItem, searchType: SearchTypes) {
  return searchType === SearchTypes.all ? item.HitHighlightedAll?.text : item.HitHighlightedExact?.text;
}

function checkSearchableFields(fieldsValues: string[], item: ISearchItem, searchType: SearchTypes) {
  const checkOccurrences = (value: string) => {
    if (searchType === SearchTypes.all) {
      return checkOccurrencesAll({ queries: item.HitHighlightedAll.queries, text: value });
    }

    return checkHighlightExact({ query: item.HitHighlightedExact.query, text: value });
  };

  let hasOccurrence = false;
  for (const field of fieldsValues) {
    if (!hasOccurrence && checkOccurrences(field)) {
      hasOccurrence = true;
    }
  }

  return hasOccurrence;
}

function getPagesSearchableFields(page: ISearchSitePage, searchResultText: string): string[] {
  return [page.Title, searchResultText];
}

function getRegulationSearchableFields(regulation: IRegulation, searchResultText: string): string[] {
  return [regulation.Title, regulation.fileInfo?.Name, searchResultText];
}

function getCertificateSearchableFields(certificate: ICertificate, searchResultText: string): string[] {
  return [certificate.Title, certificate.fileInfo?.Name, searchResultText];
}

function getRoleSearchableFields(role: IRole): string[] {
  return [role.Title, role.ShortDescription, role.Regulations.map((x) => x.Description).join(" ")];
}
