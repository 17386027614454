import { IAnalyticsService } from "./AnalyticsService";

export class CompositeAnalytics implements IAnalyticsService {
  analyticServices: IAnalyticsService[];

  constructor(...services: IAnalyticsService[]) {
    this.analyticServices = services;
  }
  provideDocumentView(jobTitle: string, documentLink: string) {
    this.analyticServices.forEach((x) => x.provideDocumentView(jobTitle, documentLink));
  }

  provideSearchTerm(searchTerm: string, jobTitle: string, searchType: string): void {
    this.analyticServices.forEach((x) => x.provideSearchTerm(searchTerm, jobTitle, searchType));
  }

  provideFilter(filterKey: string, jobTitle: string) {
    this.analyticServices.forEach((x) => x.provideFilter(filterKey, jobTitle));
  }

  providePageView(jobTitle: string) {
    this.analyticServices.forEach((x) => x.providePageView(jobTitle));
  }

  provideLinkClick(link: string, jobTitle: string) {
    this.analyticServices.forEach((x) => x.provideLinkClick(link, jobTitle));
  }

  provideButtonClick(caption: string, jobTitle: string) {
    this.analyticServices.forEach((x) => x.provideButtonClick(caption, jobTitle));
  }

  provideDownload(jobTitle: string, documentLink: string) {
    this.analyticServices.forEach((x) => x.provideDownload(jobTitle, documentLink));
  }

  provideSessionStart(jobTitle: string, pageTitle: string) {
    this.analyticServices.forEach((x) => x.provideSessionStart(jobTitle, pageTitle));
  }
}
