import { enableMapSet } from "immer";
import { combineReducers } from "redux";

import analyticsReducer, { IAnalyticsState } from "./analytics.reducer";
import documentsReducer, { IDocumentsState } from "./documents.reducer";
import { drivesReducer, IDrivesState } from "./drives.reducer";
import filtersReducer, { IFiltersState } from "./filters.reducer";
import generalReducer, { IGeneralState } from "./general.reducer";
import notificationsReducer, { INotificationsState } from "./notifications.reducer";
import searchReducer, { ISearchState } from "./search.reducer";
import sitePagesReducer, { ISitePagesState } from "./site-pages.reducer";
import standardsIconsReducer, { IStandardsIconsState } from "./standards-icons.reducer";
import termsReducer, { ITermsState } from "./terms.reducer";
import usersReducer, { IUsersState } from "./users.reducer";

enableMapSet();

export interface IRootReducerState {
  documents: IDocumentsState;
  general: IGeneralState;
  notifications: INotificationsState;
  users: IUsersState;
  terms: ITermsState;
  sitePages: ISitePagesState;
  search: ISearchState;
  filters: IFiltersState;
  standardsIcons: IStandardsIconsState;
  analytics: IAnalyticsState;
  drives: IDrivesState;
}

export const rootReducer = combineReducers<IRootReducerState>({
  documents: documentsReducer,
  general: generalReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  terms: termsReducer,
  sitePages: sitePagesReducer,
  search: searchReducer,
  filters: filtersReducer,
  standardsIcons: standardsIconsReducer,
  analytics: analyticsReducer,
  drives: drivesReducer,
});
