import { useLocation } from "react-router";

import { isHomePage, isSitePage } from "Helpers/utils";

import { useUsersData } from "./useUsers";

function isRibbonPresent(currentUser, pathname, isContentLoaded): boolean {
  return currentUser?.canEditSitePage && (isSitePage(pathname) || isHomePage(pathname)) && isContentLoaded;
}

export const useRibbon = (isContentLoaded = true) => {
  const { currentUser } = useUsersData();
  const { pathname } = useLocation();

  return isRibbonPresent(currentUser, pathname, isContentLoaded);
};
