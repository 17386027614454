import { TextSettings } from "@epam/loveship/helpers/textLayout";
import { Icon } from "@epam/uui/types/objects";

export type IIconPositionTypes = "left" | "right";

export enum RowTypes {
  LINKED_LIST = "linkedList",
  LABEL = "label",
  BUTTON = "button",
}

export enum IconPositionTypes {
  LEFT = "left",
  RIGHT = "right",
}

interface ILink {
  caption: string;
  icon?: Icon;
  link: string;
}

interface IRow {
  type: RowTypes;
  className: string;
  button?: ILink;
  linkedList?: ILink[];
  iconPosition?: IIconPositionTypes;
}

interface ICard {
  className: string;
  title?: string;
  leftRow: IRow[];
  rightRow?: IRow[];
}

export interface IPageTextProps extends TextSettings {
  className: string;
  caption: string;
}

export interface ILinkedListProps {
  className: string;
  linkedList: ILink[];
  iconPosition?: IIconPositionTypes;
  onLinkClicked?: (url: string, description: string) => void;
}

export interface IPageCardProps {
  card: ICard;
}
