import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export function getDateWithoutTime(date: Date): Date {
  return new Date(createDateString(date));
}

export function createDateString(date: Date, format = "DD-MMM-YYYY", defaultValue = ""): string {
  return date ? dayjs(date).tz("Europe/Minsk").format(format) : defaultValue;
}
