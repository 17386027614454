import _get from "lodash/get";
import { useState } from "react";
import { useSelector } from "react-redux";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { validLinkRegex } from "Helpers/constants";
import { IPathKeys } from "Helpers/sitePageUpdater";
import { useSitePagesActions, useSitePagesMethods } from "Hooks/useSitePages";
import { IWebPart } from "SP/sitePages/sitePages.types";
import { IRootReducerState } from "Store/reducers";

export enum CardKeys {
  "accordionTitle" = "accordionTitle",
  "surroundedBy" = "surroundedBy",
  "webPartTitle" = "webPartTitle",
  "webPartTitleLink" = "webPartTitleLink",
  "webPartLinkColor" = "webPartLinkColor",
  "displayDividers" = "displayDividers",
  "showCurtain" = "showCurtain",
}

export const isAccordion = (surroundedBy: string) => {
  return surroundedBy === "accordion";
};

export const getProperty = (pathKeys: IPathKeys, key: CardKeys) => {
  return [...pathKeys, key].join(".");
};

export const usePageDetailsActions = (sitePageUrl: string) => {
  const {
    selectedDetailInfo: { sectionIndex, column, webPartIndex },
    setSitePageInValidProperty,
  } = useSitePagesActions();
  const { updateSitePage } = useSitePagesMethods();
  const [title, setTitle] = useState("");

  const pathKeys = ["pageInfo", "sections", sectionIndex, "data", column, webPartIndex];

  const card = useSelector<IRootReducerState, IWebPart>((state) =>
    _get(state.sitePages.sitePages[sitePageUrl], pathKeys.join("."), null),
  );

  const handleDeleteCard = (key: CardKeys) => {
    const titleProperty = getProperty([...pathKeys, "data"], key);
    setSitePageInValidProperty(false, titleProperty);
  };

  const DELAY = process.env.UNIT_TEST ? 0 : 550;
  const handleUpdateSitePage = useDebouncedCallback((newValue: string | boolean, lastKey: string) => {
    const newPathKeys = [sitePageUrl, ...pathKeys, "data", lastKey];

    const accordionTitleProperty = getProperty(newPathKeys.slice(1, -1), CardKeys.accordionTitle);

    const webPartTitleProperty = getProperty(newPathKeys.slice(1, -1), CardKeys.webPartTitle);

    const linkProperty = getProperty(newPathKeys.slice(1, -1), CardKeys.webPartTitleLink);
    if (typeof newValue === "string") {
      switch (lastKey) {
        case CardKeys.accordionTitle:
          setSitePageInValidProperty(!newValue, accordionTitleProperty);
          break;
        case CardKeys.webPartTitle:
          setSitePageInValidProperty(!newValue, webPartTitleProperty);
          break;
        case CardKeys.surroundedBy:
          setSitePageInValidProperty(isAccordion(newValue) && !title, accordionTitleProperty);
          break;
        case CardKeys.webPartTitleLink:
          setSitePageInValidProperty(!validLinkRegex.test(newValue), linkProperty);
          break;
        case CardKeys.webPartLinkColor:
          setSitePageInValidProperty(!newValue, linkProperty);
          break;
      }
    }

    updateSitePage(newPathKeys, newValue);
  }, DELAY);

  return {
    card,
    title,
    onTitleChange: setTitle,
    handleDeleteCard,
    handleUpdateSitePage,
    isAccordion,
  };
};
