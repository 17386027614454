import { dateAdd, PnPClientStorage } from "@pnp/common";
import { IOrderedTermInfo, ITermGroupInfo, ITermSetInfo, sp } from "@pnp/sp/presets/all";

import { getSiteInfo } from "SP/configure";

const NAV_TERMS_SELECTOR = ["id", "labels", "localProperties", "childrenCount", "customSortOrder"];

/**
 * @interface
 * Generic Term Object (abstract interface)
 */
export interface ISPTermObject {
  name: string;
  guid: string;
  terms: ISPTermObject[];
  localCustomProperties: ILocalCustomProperties;
  parentNode?: ISPTermObject;
}

interface ILocalCustomProperties {
  _Sys_Nav_SimpleLinkUrl: string;
  _Sys_Nav_FriendlyUrlSegment: string;
  _Sys_Nav_TargetSiteId: string;
  _Sys_Nav_TargetTermSetId: string;
  _Sys_Nav_TargetUrl: string;
  _Sys_Nav_TargetUrlListId: string;
  _Sys_Nav_TargetUrlListItemId: string;
  _Sys_Nav_TargetUrlWebId: string;
  _Sys_Nav_ExcludedProviders: string;
  opened?: string;
}

interface ITermStore {
  terms: IOrderedTermInfo[];
  created: string;
}
interface ITermProp {
  key: string;
  value: string;
}

const yearDelay = 1;

/**
 * @class
 * Service implementation to manage term stores in SharePoint
 */
export class SPTermStoreService {
  /**
   * @function
   * Getting array of ordered terms in needed term store inside site collection term group
   */

  private sortNavTerms = (customSortOrder) => (a, b) => {
    const aIndex = customSortOrder?.indexOf(a.id);
    const bIndex = customSortOrder?.indexOf(b.id);
    return aIndex - bIndex;
  };

  public loadTermWithChildren = async (term, setQuery) => {
    if (!term.childrenCount) return term;

    const customSortOrder = term.customSortOrder?.[0]?.order;

    const children = await setQuery
      .getTermById(term.id)
      .children.select(...NAV_TERMS_SELECTOR)
      .get();

    if (children.length > 0) {
      term.children = await Promise.all(
        children.sort(this.sortNavTerms(customSortOrder)).map(async (childTerm) => {
          return await this.loadTermWithChildren(childTerm, setQuery);
        }),
      );
    }
    if (!term.children) {
      term.children = [];
    }

    return term;
  };

  public getTermsFromTermSet = async (termSetName: string): Promise<ISPTermObject[]> => {
    const { Id } = await getSiteInfo();
    const localStorageKey = `${Id}-siteNavigationTermTree`;
    const group = await this.getRootGroup();

    if (group) {
      const set = await this.getSetByName(group, termSetName);
      if (set) {
        const setQuery = sp.termStore.groups.getById(group.id).sets.getById(set.id);
        const store = new PnPClientStorage();
        this.checkIfTermTreeUpdated(set, localStorageKey, store);
        const childTree: ITermStore = await store.local.getOrPut(
          localStorageKey,
          async (): Promise<ITermStore> => {
            const rootTerms = await setQuery.children.select(...NAV_TERMS_SELECTOR).get();

            const terms = await Promise.all(
              rootTerms.map(async (term) => {
                return await this.loadTermWithChildren(term, setQuery);
              }),
            );

            return {
              terms,
              created: new Date().toISOString(),
            };
          },
          dateAdd(new Date(), "year", yearDelay),
        );
        return childTree.terms.map(this.formatTerm);
      }
    }
  };
  /**
   * This method checks if term tree has been updated by admin. If updated - executing reset of object in localStorage.
   * Admin can reset users cache in Term Store Management Tool by updating termTreeUpdated custom property in Navigation term set
   * @param set Navigation term set
   * @param localStorageKey key of stored object in localStorage
   * @param store PnPClientStorage object
   */
  private checkIfTermTreeUpdated(set: ITermSetInfo, localStorageKey: string, store: PnPClientStorage) {
    const setProperties: ITermProp[] = set.properties;
    const termTreeUpdatedProp = setProperties.find((prop) => prop.key === "termTreeUpdated");
    const localStorageValue: ITermStore = store.local.get(localStorageKey);
    if (this.isResetStore(termTreeUpdatedProp, localStorageValue)) {
      store.local.delete(localStorageKey);
    }
  }
  /**
   * This method returns true if property termTreeUpdated outdated or missing
   * @param termTreeUpdatedProp property termTreeUpdated value from Navigation term
   * @param localStorageValue cached value from localStorage
   * @returns boolean value that tells reset storage or note
   */
  private isResetStore(termTreeUpdatedProp: ITermProp, localStorageValue: ITermStore) {
    let needToResetStore = true;
    if (termTreeUpdatedProp && localStorageValue && localStorageValue.created) {
      needToResetStore = new Date(termTreeUpdatedProp.value) > new Date(localStorageValue.created);
    }
    return needToResetStore;
  }
  /**
   * Getting root site collection term group
   * @returns site collection root term group
   */
  private async getRootGroup(): Promise<ITermGroupInfo> {
    const { Id } = await getSiteInfo();
    const groups = await sp.termStore.groups
      .filter(`displayName eq 'Site Collection'`)
      .usingCaching({
        key: `${Id}-siteCollectionGroup`,
        storeName: "local",
        expiration: dateAdd(new Date(), "year", yearDelay),
      })
      .get();
    if (groups.length > 0) {
      return groups[0];
    }
  }
  /**
   * Getting term set inside term group by term set name
   * @param group root site collection term group
   * @param termSetName needed term store name
   * @returns needed term set inside term group
   */
  private async getSetByName(group: ITermGroupInfo, termSetName: string) {
    const sets = await sp.termStore.groups.getById(group.id).sets.select("localizedNames", "id", "properties").get();
    for (const set of sets) {
      const neededSet = set.localizedNames.some((name) => name.name == termSetName);
      if (neededSet) {
        return set;
      }
    }
  }
  /**
   * Formatting term to ISPTermObject
   * @param term current term
   * @returns formatted term
   */
  private formatTerm = (term: IOrderedTermInfo): ISPTermObject => {
    return {
      guid: term.id,
      localCustomProperties: this.formatLocalCustomProperties(term),
      name: term.defaultLabel || term.labels.find((i) => i.isDefault).name,
      terms: term.children?.map(this.formatTerm) || [],
    };
  };
  /**
   * Formatting local custom properties of term
   * @param term current term
   * @returns object with local custom properties of term
   */
  private formatLocalCustomProperties(term: IOrderedTermInfo) {
    const localCustomProperties = {};
    if (term.localProperties && term.localProperties.length > 0) {
      for (const prop of term.localProperties) {
        for (const p of prop.properties) {
          localCustomProperties[p.key] = p.value;
        }
      }
    }
    return localCustomProperties as ILocalCustomProperties;
  }
}
