import { getCertificateFilters } from "Components/documents-filter/filters/certificates-filters";
import { getRegulationTemplatesFilters } from "Components/documents-filter/filters/regulation-templates-filters";
import { getRolesFilters } from "Components/documents-filter/filters/roles-filters";
import { certificatesColumns } from "Components/documents-table/columns/certificates-columns";
import { regulationTemplatesColumns } from "Components/documents-table/columns/regulation-templates-columns";
import { rolesColumns } from "Components/documents-table/columns/roles-columns";
import { IColumn } from "Components/documents-table/documents-table.interface";
import { codeOfEthicalConductId, corporateGovernanceId } from "Components/documents-toolbar/documents-toolbar.helpers";
import { Tabs } from "Components/documents-toolbar/documents-toolbar.interface";
import { IGetViewDataFiltersParams, IGetViewDataParams } from "Components/documents-view/documents-view.interface";
import { getFieldIfExist } from "Helpers/utils";
import {
  FullPageComponentTypes,
  IDoubleListData,
  ISingleListColumn,
  ISingleListData,
  ISingleListFilterItem,
  LibraryName,
} from "SP/sitePages/sitePages.types";

function filterColumns(columns: ISingleListColumn[]) {
  return (column: IColumn) => column.key === "actions" || columns.some((col) => column.key === col.prop && col.visible);
}

function filterFilters(filters: ISingleListFilterItem[]) {
  return (filter) => filters.some((filterItem) => filter.key === filterItem.prop && filterItem.visible);
}

export function getViewDataFilters(params: IGetViewDataFiltersParams) {
  const { pageType, data, filterPaneItems, activeTab } = params;

  switch (pageType) {
    case FullPageComponentTypes.RegulationsByDocumentType: {
      if (activeTab === Tabs.documents) {
        return getRegulationTemplatesFilters(data).filter(filterFilters(filterPaneItems));
      }

      return getCertificateFilters(data).filter(filterFilters(filterPaneItems));
    }
    case FullPageComponentTypes.Certificates: {
      return getCertificateFilters(data).filter(filterFilters(filterPaneItems));
    }
    case FullPageComponentTypes.RolesRegistry: {
      return getRolesFilters(data).filter(filterFilters(filterPaneItems));
    }
    default:
      return getRegulationTemplatesFilters(data).filter(filterFilters(filterPaneItems));
  }
}

export function getViewData(params: IGetViewDataParams) {
  const { pageType, allDocuments, allRoles, listData, defaultShowFilter } = params;

  switch (pageType) {
    case FullPageComponentTypes.RegulationsByDocumentType: {
      const { documents, certificates } = listData as IDoubleListData;

      const documentsData = [...allDocuments[LibraryName.regulations], ...allDocuments[LibraryName.templates]];
      const certificatesData = allDocuments[LibraryName.certificates];

      return {
        data: {
          documents: documentsData,
          certificates: certificatesData,
        },
        columns: {
          documents: regulationTemplatesColumns.filter(filterColumns(documents.columns)),
          certificates: certificatesColumns.filter(filterColumns(certificates.columns)),
        },
        filterPaneItems: {
          documents: documents.filterPaneItems,
          certificates: certificates.filterPaneItems,
        },
        filtersLibrary: {
          documents: LibraryName.regulationsAndTemplates,
          certificates: LibraryName.certificates,
        },
      };
    }
    case FullPageComponentTypes.Regulations: {
      const regulations = listData as ISingleListData;
      let regulationsData = allDocuments[LibraryName.regulations];

      if (defaultShowFilter === corporateGovernanceId) {
        regulationsData = regulationsData.filter(
          (doc) =>
            doc.fileInfo.ServerRelativeUrl.includes(corporateGovernanceId) ||
            doc.fileInfo.ServerRelativeUrl.includes(codeOfEthicalConductId),
        );
      }

      return {
        data: regulationsData,
        columns: regulationTemplatesColumns.filter(filterColumns(regulations.columns)),
        filterPaneItems: regulations.filterPaneItems,
        filtersLibrary: LibraryName.regulations,
      };
    }
    case FullPageComponentTypes.Templates: {
      const templates = listData as ISingleListData;
      const templatesData = allDocuments[LibraryName.templates];

      return {
        data: templatesData,
        columns: regulationTemplatesColumns.filter(filterColumns(templates.columns)),
        filterPaneItems: templates.filterPaneItems,
        filtersLibrary: LibraryName.templates,
      };
    }
    case FullPageComponentTypes.Certificates: {
      const certificates = listData as ISingleListData;
      const certificatesData = allDocuments[LibraryName.certificates];

      return {
        data: certificatesData,
        columns: certificatesColumns.filter(filterColumns(certificates.columns)),
        filterPaneItems: certificates.filterPaneItems,
        filtersLibrary: LibraryName.certificates,
      };
    }
    case FullPageComponentTypes.RolesRegistry: {
      const roles = listData as ISingleListData;

      return {
        data: allRoles,
        columns: rolesColumns,
        filterPaneItems: roles.filterPaneItems,
        filtersLibrary: LibraryName.roles,
      };
    }
    default:
      return {
        data: null,
        columns: [],
        filters: [],
        filtersLibrary: null,
      };
  }
}

export function getStringDataByTab<T>(data, key: Tabs): T {
  return typeof data === "string" ? data : getFieldIfExist(key, data);
}

export function getArrayDataByTab<T>(data, key: Tabs): T[] {
  return Array.isArray(data) ? data : getFieldIfExist(key, data);
}
