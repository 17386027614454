import React, { useEffect, useState } from "react";

import { DocumentViewer } from "Components/document-viewer";
import { Loader } from "Components/loader";
import { FriendlyCertificatePrefix, FriendlyRegulationPrefix, FriendlyTemplatePrefix } from "Helpers/constants";
import handleError from "Helpers/errorHandler";
import { getServerOriginUrl } from "Helpers/utils";
import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import useQuery from "Hooks/useQuery";
import { CertificatesService } from "SP/documents/certificates/certificates.service";
import { RegulationsService } from "SP/documents/regulations/regulations.service";
import { TemplatesService } from "SP/documents/templates/templates.service";

export const FriendlyUrlDocumentPage = () => {
  const { pathname: friendlyUrl } = useFriendlyURLPathname();

  const { documentQuery } = useQuery();

  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData(friendlyUrl: string) {
      let fileService: RegulationsService | TemplatesService | CertificatesService;
      const path = `/${friendlyUrl}`;

      if (path.startsWith(FriendlyCertificatePrefix)) {
        fileService = new CertificatesService();
      } else if (path.startsWith(FriendlyRegulationPrefix)) {
        fileService = new RegulationsService();
      } else if (path.startsWith(FriendlyTemplatePrefix)) {
        fileService = new TemplatesService();
      }

      try {
        const document = await fileService.getByFriendlyUrl(friendlyUrl, []);
        setDocumentUrl(`${getServerOriginUrl()}${document.fileInfo?.ServerRelativeUrl}`);
        setDocumentName(document.fileInfo?.Name);
      } catch (e) {
        handleError(e);
      }
    }

    fetchData(friendlyUrl);
  }, [friendlyUrl]);

  return (
    <>
      {documentUrl ? (
        <DocumentViewer
          forceDownload={documentQuery.forceDownload}
          documentUrl={documentUrl}
          documentName={documentName}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};
