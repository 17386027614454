import { dateAdd } from "@pnp/common";
import type { ICachingOptions } from "@pnp/odata/caching";
import { sp } from "@pnp/sp";

import { msalInstance } from "Libs/msal";
import { PnPFetchClient } from "Libs/pnp";

import cache from "./cache";

export interface ISiteInfo {
  Id: string;
  webId: string;
}

function getCachingParams(key: "siteInfo" | "webInfo"): ICachingOptions {
  return {
    key: `${process.env.REACT_APP_BASE_URL}-${key}`,
    expiration: dateAdd(new Date(), "year", 1),
    storeName: "local",
  };
}

export async function getSiteInfo(): Promise<ISiteInfo> {
  try {
    let siteInfo = cache.get<ISiteInfo>(cache.Keys.siteInfo);

    if (siteInfo?.Id && siteInfo?.webId) {
      return siteInfo;
    }

    const siteRequest = sp.site.select("Id").usingCaching(getCachingParams("siteInfo")).get();
    const webRequest = sp.web.select("Id").usingCaching(getCachingParams("webInfo")).get();
    const [site, web] = await Promise.all([siteRequest, webRequest]);

    siteInfo = {
      Id: site.Id,
      webId: web.Id,
    };

    cache.add<ISiteInfo>(cache.Keys.siteInfo, siteInfo);

    return siteInfo;
  } catch (error) {
    console.info("Error when getting site data:", error);
  }
}

export function configurePnP(): void {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchClientFactory = () => {
    return new PnPFetchClient(msalInstance);
  };

  sp.setup({
    defaultCachingStore: "local",
    defaultCachingTimeoutSeconds: 60,
    sp: {
      fetchClientFactory,
      baseUrl,
      headers: {
        Accept: "application/json;odata=minimalmetadata",
        Pragma: "no-cache",
      },
    },
    globalCacheDisable: false,
    enableCacheExpiration: true,
    cacheExpirationIntervalMilliseconds: 1000,
  });
}
