export const tinyPlugins = [
  "advlist autolink lists link image",
  "charmap print preview anchor help",
  "searchreplace visualblocks code",
  "insertdatetime media table paste wordcount",
  "fullscreen",
];

export const tinyFontSizeFormats =
  "1pt 2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 32pt 34pt 36pt 38pt 40pt 44pt 48pt 52pt 56pt 60pt 72pt 96pt";

export const tinyToolbar =
  "undo redo | formatselect | bold italic | \
                     alignleft aligncenter alignright | \
                     bullist numlist outdent indent | help";

export const tinyFontFormats = `Andale Mono=andale mono,times;
                    Arial=arial,helvetica,sans-serif;
                    Arial Black=arial black,avant garde;
                    Baskerville=baskerville,palatino linotype,palatino,century schoolbook l,times new roman,serif;
                    Book Antiqua=book antiqua,palatino;
                    Cambria=cambria,hoefler text,liberation serif,times,times new roman,serif;
                    Comic Sans MS=comic sans ms,sans-serif;
                    Constantia=constantia,lucida bright,dejaVu serif,georgia,serif;
                    Consolas=consolas,andale mono,lucida console,lucida aans typewriter,monaco,courier new,monospace;
                    Courier New=courier new,courier;
                    Georgia=georgia,palatino;Gotham=gotham,helvetica neue,helvetica,arial,sans-serif;
                    Gill Sans=gill sans,gill sans mt,myriad pro,dejaVu sans condensed,helvetica,arial,sans-serif;
                    Source Sans Pro=Source Sans Pro;
                    Helvetica=helvetica;
                    Impact=impact,haettenschweiler,franklin gothic bold,arial black,sans-serif;
                    Lucida=lucida grande,lucida sans unicode,lucida sans,dejaVu sans,verdana,sans-serif;
                    Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif;
                    Symbol=symbol;
                    Tahoma=tahoma,arial,helvetica,sans-serif;
                    Terminal=terminal,monaco;
                    Times New Roman=times new roman,times;
                    Trebuchet MS=trebuchet ms,geneva;
                    Verdana=verdana,geneva;
                    Webdings=webdings;
                    Wingdings=wingdings,zapf dingbats;`;
