import {
  CheckinType,
  IBasePermissions,
  IFieldInfo,
  IItemUpdateResult,
  IList,
  PermissionKind,
  sp,
} from "@pnp/sp/presets/all";

import { filterQueryNotFolder, sitePagesListRequestInfo, sitePageVersionsListRequestInfo } from "SP/constants";
import { IItemTermInfo, ILibraryFolder } from "SP/helpers.types";
import { formatFolder, getFormattedFriendlyUrl } from "SP/sp.helpers";

import { ISitePageDTO, ISitePageVersionDTO } from "./sitePages.types";

export class SitePagesRepository {
  private sitePagesList: IList = sp.web.lists.getByTitle("Site Pages");
  public async getAll(): Promise<ISitePageDTO[]> {
    return await this.sitePagesList.items
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)
      .filter(filterQueryNotFolder)
      .getAll(5000);
  }
  public async getById(id: number): Promise<ISitePageDTO> {
    return await this.sitePagesList.items
      .getById(id)
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)
      .get();
  }

  public async getSitePageVersions(id: number): Promise<ISitePageVersionDTO[]> {
    const pageVersions = await this.sitePagesList.items
      .getById(id)
      .versions.select(...sitePageVersionsListRequestInfo.selectArray)
      .get<ISitePageVersionDTO[]>();
    return pageVersions;
  }

  public async getSitePageByServerRelativeUrl(url: string): Promise<ISitePageDTO> {
    const file = await sp.web.getFileByServerRelativeUrl(url).getItem();
    const item: ISitePageDTO = await file
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)
      .get();
    return item;
  }

  public async getSitePageByFriendlyUrl(friendlyUrl: string): Promise<ISitePageDTO[]> {
    const formattedUrl = getFormattedFriendlyUrl(friendlyUrl);

    return this.sitePagesList.items
      .filter(`FriendlyURL eq '${formattedUrl}'`)
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)
      .get();
  }

  public async checkOutSitePage(id: number): Promise<void> {
    await this.sitePagesList.items.getById(id).file.checkout();
  }
  public async checkInSitePage(id: number, comment?: string): Promise<void> {
    await this.sitePagesList.items.getById(id).file.checkin(comment, CheckinType.Major);
  }
  public async updatePage(id: number, data: Partial<ISitePageDTO>): Promise<IItemUpdateResult> {
    return await this.sitePagesList.items.getById(id).update(data);
  }
  public async discardCheckOutSitePage(id: number): Promise<void> {
    await this.sitePagesList.items.getById(id).file.undoCheckout();
  }
  public async getCurrentUserEffectivePermissions(): Promise<IBasePermissions> {
    return await this.sitePagesList.getCurrentUserEffectivePermissions();
  }
  public async checkIfUSerHasEditPermission(): Promise<boolean> {
    const perm = await this.sitePagesList.getCurrentUserEffectivePermissions();
    return this.sitePagesList.hasPermissions(perm, PermissionKind.EditListItems);
  }
  public getFolderHierarchy = async (maxDepth: number): Promise<ILibraryFolder[]> => {
    /**
     * Expand Query for folders structure of library. The count of elements determines the maximum nesting of folders
     */
    const expandQuery: string[] = Array(maxDepth).fill("Folders");
    const folders = await sp.web
      .getFolderByServerRelativeUrl(`SitePages`)
      .folders.expand(expandQuery.join(`/`))
      .select("Name", "ServerRelativeUrl")
      .filter(`Name ne 'Forms' and Name ne 'Hidden pages'`)
      .orderBy("TimeCreated")
      .get();
    return folders.map(formatFolder);
  };
  public async getParentNavigationTermSetsField(): Promise<IFieldInfo> {
    return this.sitePagesList.fields.getByInternalNameOrTitle("parentNavigationTermSets_0").usingCaching().get();
  }
  public formatTaxonomyUpdateString(terms: IItemTermInfo[]): string {
    let updateValue = "";
    terms.forEach((term) => (updateValue += `-1#${term.Label}|${term.TermGuid};`));
    return updateValue;
  }
  public async addNewPage(folderServerRelativeUrl: string, pageTitle: string): Promise<number> {
    pageTitle = this.clearPageName(pageTitle);
    const newPage = await sp.web.addClientsidePage(pageTitle, pageTitle, "Home");
    const pageItem = await newPage.getItem();
    const pageFile = await this.sitePagesList.items.getById(pageItem["ID"]).file.get();
    const newUrl = `${folderServerRelativeUrl}/${pageTitle}.aspx`;

    await sp.web.getFileByServerRelativePath(pageFile.ServerRelativeUrl).moveByPath(newUrl, true, false);

    return pageItem["ID"];
  }
  public async checkIfPageExist(folderServerRelativeUrl: string, pageTitle: string): Promise<boolean> {
    let pageExist = false;
    pageTitle = this.clearPageName(pageTitle);
    try {
      await sp.web.getFileByServerRelativeUrl(`${folderServerRelativeUrl}/${pageTitle}.aspx`).get();
      pageExist = true;
    } catch (error) {
      pageExist = false;
    }
    return pageExist;
  }

  private clearPageName = (pageTitle: string) => pageTitle.replaceAll(":", "").replaceAll("/", "").replaceAll("&", "");
}
