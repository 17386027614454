import React, { FC } from "react";
import { SwitchProps } from "react-router";

import mainLogo from "@App/assets/ecl_logo.svg";
import { ReactComponent as GlobalMenuIcon } from "@App/assets/global_menu.svg";
import { ReactComponent as glossaryIcon } from "@App/assets/glossary.svg";
import { ReactComponent as rulesIcon } from "@App/assets/rules.svg";
import Routes from "@App/routes";
import { ReactComponent as recentIcon } from "@epam/assets/icons/common/action-history-24.svg";
import { ReactComponent as settingsIcon } from "@epam/assets/icons/common/action-settings-24.svg";
import { ReactComponent as logoutIcon } from "@epam/assets/icons/common/navigation-logout-24.svg";
import { ReactComponent as userGuideIcon } from "@epam/assets/icons/common/notification-help-outline-24.svg";
import { ReactComponent as yammerIcon } from "@epam/assets/icons/common/social-network-yammer-24.svg";
import {
  BurgerButton,
  DropdownMenuBody,
  DropdownMenuButton,
  FlexSpacer,
  MainMenu,
  MainMenuAvatar,
  MainMenuButton,
} from "@epam/loveship";
import { Dropdown, MainMenuCustomElement } from "@epam/uui-components";

import { ButtonWithLinks, ILink } from "Components/button-with-links";
import { HistoryButtonWithAnalytics } from "Components/history-button";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { RecentItems } from "Components/recent-items";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { Links } from "Helpers/constants";
import { getLinkProps } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { logoutLink } from "SP/constants";

import "./header.scss";

const getLinks = (pathname: string): ILink[] => [
  {
    caption: "Home",
    link: Links.home,
    isLinkActive: ![Routes.SEARCH.path, Links.analytics, Links.eclNews].includes(pathname),
    width: 65,
  },
  {
    caption: "Search",
    link: Routes.SEARCH.path,
    width: 72,
  },
  {
    caption: "Analytics",
    link: Links.analytics,
    width: 87,
  },
  {
    caption: "ECL News",
    link: Links.eclNews,
    width: 91,
  },
];

const linksOther: ILink[] = [
  {
    caption: "EPAM Glossary",
    icon: glossaryIcon,
    link: Links.glossary,
  },
  {
    caption: "History",
    icon: recentIcon,
  },
  {
    caption: "User Guide",
    icon: userGuideIcon,
    link: Links.userGuide,
  },
  {
    caption: "Settings",
    icon: settingsIcon,
  },
  {
    caption: "ECL Publishing Rules",
    icon: rulesIcon,
    link: Links.eclPublishingRules,
  },
  {
    caption: "Yammer: Q&S Community",
    icon: yammerIcon,
    link: Links.yammer,
  },
  {
    caption: "Log out",
    icon: logoutIcon,
    link: logoutLink,
  },
];

export const userGuideLinks: ILink[] = [
  {
    caption: "User Guide",
    link: Links.userGuide,
  },
  {
    caption: "ECL Publishing Rules",
    link: Links.eclPublishingRules,
  },
  {
    caption: "Viva Engage: Q&S Community",
    link: Links.yammer,
  },
  {
    caption: "Regulations Sharing Rules",
    link: Links.allRightReservedLink,
  },
];

export const settingsLinks: ILink[] = [
  {
    caption: "Site Settings",
    link: Links.siteSettings,
  },
  {
    caption: "Site Content",
    link: Links.siteContent,
  },
  {
    caption: "Term Store Management Tool",
    link: Links.termStoreManagementTool,
  },
];

export const Header: FC<SwitchProps> = ({ location }) => {
  const cn = withNaming("header");
  const { currentUser } = useUsersData();
  const { infoPanel, setInfoPanel } = useInfoPanel();
  const menuLinks = getLinks(location.pathname);

  const handleOpenRecentItems = () => {
    if (infoPanel?.componentName === "RecentItems") {
      return setInfoPanel(null);
    }

    setInfoPanel({
      component: <RecentItems />,
      componentName: "RecentItems",
      hasScroll: false,
    });
  };

  const renderBurger = () => (
    <>
      {menuLinks.map(({ caption, link, isLinkActive }) => (
        <BurgerButton key={caption} caption={caption} isLinkActive={isLinkActive} {...getLinkProps(link)} />
      ))}
      <hr className="burger-group-header" />
      {linksOther.map(({ caption, icon, link }) => (
        <BurgerButton key={caption} caption={caption} icon={icon} {...getLinkProps(link)} />
      ))}
    </>
  );

  const onGlossaryClicked = () => {
    analyticsHelper.analyticsService?.provideButtonClick("Glossary", currentUser?.jobTitle);
  };

  return (
    <MainMenu cx={cn("wrapper")} appLogoUrl={mainLogo} logoLink={{ pathname: Links.home }} renderBurger={renderBurger}>
      {menuLinks.map(({ caption, link, width, isLinkActive }, index) => (
        <MainMenuButton
          cx={cn("link-btn", { isFirst: index === 0 })}
          key={caption}
          collapseToMore
          priority={3}
          caption={caption}
          isLinkActive={isLinkActive}
          estimatedWidth={width}
          {...getLinkProps(link)}
        />
      ))}
      <FlexSpacer priority={100500} />
      <MainMenuButton
        cx={cn("link-btn")}
        caption="EPAM Glossary"
        icon={glossaryIcon}
        href={Links.glossary}
        target="_blank"
        estimatedWidth={142}
        onClick={onGlossaryClicked}
      />
      <div className={cn("divider")} />
      <HistoryButtonWithAnalytics caption="History" onClick={handleOpenRecentItems} />
      <MainMenuCustomElement priority={10} estimatedWidth={48}>
        <ButtonWithLinks
          icon={userGuideIcon}
          links={userGuideLinks}
          linkModifier={(link) => link}
          caption={"Question mark"}
        />
        {currentUser?.canEditSitePage && (
          <ButtonWithLinks
            icon={settingsIcon}
            links={settingsLinks}
            linkModifier={(link) => `${process.env.REACT_APP_BASE_URL}${link}`}
            caption={"Settings"}
          />
        )}
      </MainMenuCustomElement>
      <MainMenuCustomElement priority={10} estimatedWidth={78}>
        <Dropdown
          renderTarget={(props) => <MainMenuAvatar avatarUrl={currentUser?.photo} isDropdown {...props} />}
          renderBody={() => (
            <DropdownMenuBody cx="dropdown dropdown--no-icons" color="white">
              <DropdownMenuButton caption="My Subscriptions" link={{ pathname: Routes.SUBSCRIPTIONS.path }} />
              <DropdownMenuButton caption="Log out" href={logoutLink} />
            </DropdownMenuBody>
          )}
          closeOnMouseLeave="boundary"
          placement="bottom-start"
        />
      </MainMenuCustomElement>
      <div id="global_menu_toggle" className={cn("global_menu")}>
        <GlobalMenuIcon />
      </div>
    </MainMenu>
  );
};
