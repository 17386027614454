import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as menuHideIcon } from "@epam/assets/icons/common/navigation-hide-24.svg";
import { ReactComponent as menuIcon } from "@epam/assets/icons/common/navigation-menu-24.svg";
import { IconButton, Text } from "@epam/loveship";

import { AccordionLink } from "Components/accordion-link";
import { withNaming } from "Helpers/bemClassname";
import { isExternalUrl, isSitePage, isTermHaveChild } from "Helpers/utils";
import { useMenuNavigationMethods } from "Hooks/useMenuNavigation";
import { useTermsData } from "Hooks/useTerms";
import { useUsersData } from "Hooks/useUsers";
import { ISPTermObject } from "SP/SPTermStoreService";

import "./navigation.scss";

interface ILocState {
  isMenu: boolean;
}

function getSitePageParentIds(term: ISPTermObject, pathname: string, ids: string) {
  if (
    term.localCustomProperties["_Sys_Nav_TargetUrl"] === pathname ||
    term.localCustomProperties["_Sys_Nav_SimpleLinkUrl"] === pathname
  ) {
    return ids;
  }
  if (isTermHaveChild(term)) {
    let result = "";
    for (const childTerm of term.terms) {
      result = getSitePageParentIds(childTerm, pathname, `${ids}.${childTerm.guid}`);
      if (result) break;
    }
    return result;
  }
  return "";
}

function getExpandedIds(terms: ISPTermObject[], pathname: string) {
  let result = "";
  for (const term of terms) {
    result = getSitePageParentIds(term, pathname, term.guid);
    if (result) break;
  }
  return result.split(".");
}

function isDefaultExpanded(term: ISPTermObject, expandedIds: string[]) {
  return expandedIds.includes(term.guid) || term.localCustomProperties["opened"] === "1";
}

export const Navigation: FC = () => {
  const cn = withNaming("navigation");
  const [selectedId, setSelectedId] = useState("");
  const [expandedIds, setExpandedIds] = useState(null);
  const [show, setShow] = useState(false);
  const { filteredTerms, loading } = useTermsData();
  const { setMenuNavigationToggle } = useMenuNavigationMethods();
  const { currentUser } = useUsersData();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleSelect = (id: string) => () => {
    setSelectedId(id);
  };

  const handleNavigationToggle = () => {
    const newShow = !show;
    setShow(newShow);
    setMenuNavigationToggle(newShow);
  };

  const updateNavigationTree = (pathname: string, isInitial?: boolean) => {
    const ids = getExpandedIds(filteredTerms, pathname);
    const selectedId = ids.pop();

    if (isInitial || !!selectedId) {
      setExpandedIds(ids);
      setSelectedId(selectedId);
    }
  };

  useEffect(() => {
    if (show && expandedIds === null && !!filteredTerms.length) {
      updateNavigationTree(pathname, true);

      const unListen = history.listen((location) => {
        const state = history.location.state as ILocState;

        if (!state?.isMenu || isExternalUrl(location.pathname) || !isSitePage(location.pathname)) {
          setSelectedId("");
        }

        updateNavigationTree(location.pathname);
      });

      return unListen;
    }
  }, [show, filteredTerms]);

  const renderAccordion = (terms: ISPTermObject[], level = 1) => {
    return terms.map((term) => {
      const isExpandable = isTermHaveChild(term);
      return (
        <AccordionLink
          key={term.guid}
          level={level}
          title={term.name}
          isExpandable={isExpandable}
          to={term.localCustomProperties["_Sys_Nav_SimpleLinkUrl"] || term.localCustomProperties["_Sys_Nav_TargetUrl"]}
          isSelected={selectedId === term.guid}
          onSelect={handleSelect(term.guid)}
          defaultExpanded={isDefaultExpanded(term, expandedIds)}
        >
          {isExpandable ? renderAccordion(term.terms, level + 1) : null}
        </AccordionLink>
      );
    });
  };

  if (pathname == "/document") {
    return null;
  }

  return (
    <div
      className={cn("", { isShow: show, "ribbon-present": currentUser?.canEditSitePage && isSitePage(pathname) })}
      data-testid="navigation"
    >
      <div className={cn("wrapper")}>
        {!show && currentUser && (
          <IconButton
            rawProps={{ "data-testid": "navigation-toggle" }}
            cx={cn("toggle", { show: true }, ["flex align-center justify-center"])}
            icon={menuIcon}
            onClick={handleNavigationToggle}
          />
        )}
        <div className={cn("content", ["shadow"])}>
          <div className={cn("content-header", ["flex align-center justify-between self-start"])}>
            <Text fontSize="16" lineHeight="24" cx={cn("content-header-text", ["p-0"])}>
              Navigation
            </Text>
            <IconButton
              rawProps={{ "data-testid": "navigation-toggle" }}
              cx={cn("toggle", { hide: true }, ["flex align-center justify-center"])}
              icon={menuHideIcon}
              onClick={handleNavigationToggle}
            />
          </div>
          <div className={cn("content-body")}>
            {loading || expandedIds === null ? (
              <Text cx={cn("content-body-loading")} font="sans-semibold" fontSize="14" lineHeight="18">
                Loading...
              </Text>
            ) : (
              renderAccordion(filteredTerms)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
