import { useDispatch, useSelector } from "react-redux";

import { setSessionStartSent } from "Store/actions/analytics.actions";
import { IRootReducerState } from "Store/reducers";

export const useAnalytics = () => {
  const sessionStartSent = useSelector<IRootReducerState, boolean>((state) => state.analytics.sessionStartSent);

  const dispatch = useDispatch();
  const handleSetSessionStartSent = (value: boolean) => dispatch(setSessionStartSent(value));

  return { sessionStartSent, setSessionStartSent: handleSetSessionStartSent };
};
