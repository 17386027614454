import { interactions } from "@App/libs/msal";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import type { IFetchOptions } from "@pnp/common";
import { BearerTokenFetchClient, isUrlAbsolute } from "@pnp/common";

import { logStringifiedError } from "Helpers/utils";

import { elasticLoggingFetchClientDecorator } from "./decorators";

export const scopes = ["profile", "User.Read", "Sites.Manage.All"];
const powerBiScopes = ["report.Read.All", "dataset.Read.All", "content.Create"];

const DEFAULT_RESOURCE = "graph";

@elasticLoggingFetchClientDecorator
export class PnPFetchClient extends BearerTokenFetchClient {
  constructor(private authContext: PublicClientApplication) {
    super(null);
  }

  public async fetch(url: string, options: IFetchOptions = {}): Promise<Response> {
    if (!isUrlAbsolute(url)) {
      throw new Error("You must supply absolute urls to PnPFetchClient.fetch.");
    }

    const token = await this.getToken(this.getResource(url));

    this.token = token;
    return super.fetch(url, options);
  }

  public async getToken(resource = DEFAULT_RESOURCE): Promise<string> {
    const request: SilentRequest = {
      scopes: [],
      account: this.getAccount(),
    };
    if (resource.includes("powerbi")) {
      request.scopes = powerBiScopes.map((scope) => `${resource}/${scope}`);
    } else if (resource === DEFAULT_RESOURCE) {
      request.scopes = scopes;
    } else if (resource.indexOf("sharepoint") !== -1) {
      request.scopes = [`${resource}/AllSites.FullControl`];
    } else if (resource.indexOf("graph") !== -1) {
      request.scopes = scopes.map((scope) => `${resource}/${scope}`);
    }

    let tokenResponse: Partial<AuthenticationResult> = {};
    try {
      tokenResponse = await this.authContext.acquireTokenSilent(request);
    } catch (error) {
      // check for any interactions
      if (
        error instanceof InteractionRequiredAuthError &&
        interactions.getInteractionStatus() !== InteractionStatus.None
      ) {
        logStringifiedError("Get token error, interaction is still in progress", error);
        tokenResponse.accessToken = await this.interactionInProgressHandler(resource);
      } else {
        // no interaction, invoke acquire token flow
        logStringifiedError("Get token error, will execute acquireTokenRedirect", error);
        PnPFetchClient.clearMsalStorageItems();
        await this.authContext.acquireTokenRedirect(request);
      }
    }

    return tokenResponse.accessToken;
  }

  static clearMsalStorageItems() {
    for (const key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key) && key.includes("login.windows.net")) {
        window.localStorage.removeItem(key);
      }
    }
  }

  public async interactionInProgressHandler(resource: string): Promise<string> {
    /*
     * "waitFor" method polls the interaction status via getInteractionStatus() from
     * the application state and resolves when it's equal to "None".
     */
    await this.waitFor(() => interactions.getInteractionStatus() === InteractionStatus.None);

    return await this.getToken(resource);
  }

  private async waitFor(conditionFn: () => boolean, interval = 1000) {
    while (!conditionFn()) {
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
  }

  private getAccount(): AccountInfo | null {
    const currentAccounts = this.authContext.getAllAccounts();
    if (currentAccounts === null) {
      console.info("No accounts detected");
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.info("Multiple accounts detected, need to add choose account code.");
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  private getResource(url: string): string {
    const parser = document.createElement("a") as HTMLAnchorElement;
    parser.href = url;
    return `${parser.protocol}//${parser.hostname}`;
  }
}
