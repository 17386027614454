import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory, useLocation } from "react-router-dom";

import { withAuth } from "@App/hoc/withAuth";
import { ErrorHandler, skinContext } from "@epam/loveship";
import { ContextProvider } from "@epam/uui";
import { Modals } from "@epam/uui-components";

import { InfoPanel } from "Components/info-panel";
import { Notifications } from "Components/notifications";
import store from "Store/store";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { svc } from "./services";

import "@epam/uui-components/styles.css";
import "@epam/loveship/styles.css";
import "SCSS/index.scss";

const UuiEnhancedApp = () => {
  const history = useHistory();

  return (
    <ContextProvider
      onInitCompleted={(context) => Object.assign(svc, context)}
      history={history}
      skinContext={skinContext}
    >
      <ErrorHandler>
        <App />
        <Notifications />
        <Modals />
        <InfoPanel />
      </ErrorHandler>
    </ContextProvider>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RoutedApp = withAuth(() => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Provider store={store}>
        <UuiEnhancedApp />
      </Provider>
    </BrowserRouter>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals(console.log);
