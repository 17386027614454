import { RoleField } from "SP/fields";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { FilterInvolve, IFilter } from "../documents-filter.interface";
import { getFieldUniqueValues, getMinAndMaxDates } from "./filters.helpers";

export const rolesDefaultFilters = ["Title", "RoleCategory", "Group"];

export const getRolesFilters = (roles: IAttachedFile[]): IFilter[] => {
  return [
    {
      key: "Title",
      caption: RoleField.Title,
      component: "picker-input",
      data: getFieldUniqueValues("Title", roles),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "RoleCategory",
      caption: RoleField.RoleCategory,
      component: "picker-input",
      data: getFieldUniqueValues("RoleCategory", roles),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "Group",
      caption: RoleField.Group,
      component: "picker-input",
      data: getFieldUniqueValues("Group", roles),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "Status",
      caption: RoleField.Status,
      component: "picker-input",
      data: getFieldUniqueValues("Status", roles),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "PublishedDate",
      caption: RoleField.PublishedDate,
      component: "date-picker",
      data: getMinAndMaxDates("PublishedDate", roles),
    },
    {
      key: "DocumentArea",
      caption: RoleField.DocumentArea,
      component: "picker-input",
      data: getFieldUniqueValues("DocumentArea", roles),
    },
    {
      key: "Regulations",
      caption: RoleField.Regulations,
      component: "picker-input",
      data: getFieldUniqueValues("Regulations", roles, "Description"),
    },
  ];
};
