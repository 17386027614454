import React, { useEffect, useMemo, useState } from "react";

import excelIcon from "@App/assets/file_excel.svg";
import visioIcon from "@App/assets/file_visio.svg";
import { Spinner } from "@epam/loveship";

import { parseDocumentRelativeUrl } from "Helpers/utils";
import { useDrivesData, useDrivesMethods } from "Hooks/useDrives";
import { DrivesService } from "SP/drives/drives.service";
import { LibraryName } from "SP/sitePages/sitePages.types";

export interface IViewThumbnailProps {
  thumbnailUrl: string;
}

export interface IDocumentThumbnailProps {
  documentUrl: string;
}

const drivesService = new DrivesService(process.env.REACT_APP_GRAPH_SITE_ID);

export function withDocumentThumbnail<WrappedProps>(
  Component: React.ComponentType<WrappedProps & IViewThumbnailProps>,
) {
  return (props: WrappedProps & IDocumentThumbnailProps) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const { drives } = useDrivesData();
    const { getLibraryDriveId } = useDrivesMethods();

    const { libraryName, filePath } = useMemo(() => parseDocumentRelativeUrl(props.documentUrl), [props.documentUrl]);

    useEffect(() => {
      async function fetchData() {
        if (!drives[libraryName]) {
          getLibraryDriveId(libraryName as LibraryName);
          return;
        }

        const driveId = drives[libraryName];

        try {
          const url = await drivesService.getThumbnailUrl(driveId, filePath);

          setImageUrl(url);
          setLoading(false);
        } catch (e) {
          console.log(e);
          return;
        }
      }

      if (props.documentUrl.endsWith(".vsd") || props.documentUrl.endsWith(".vsdx")) {
        setImageUrl(visioIcon);
        setLoading(false);
        return;
      }

      if (props.documentUrl.endsWith("xltm")) {
        setImageUrl(excelIcon);
        setLoading(false);
        return;
      }

      fetchData();
    }, [drives, libraryName, filePath]);

    return (
      <>
        {loading ? (
          <Spinner rawProps={{ "data-testid": "spinner" }} />
        ) : (
          <Component {...props} thumbnailUrl={imageUrl} data-testid="image" />
        )}
      </>
    );
  };
}
