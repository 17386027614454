import { TableColumnProperties, Workbook, Worksheet } from "exceljs";
import fs from "file-saver";

import { createDateString } from "@App/libs/dayjs";

import { getDocumentViewUrl } from "Helpers/utils";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { DocumentField } from "SP/fields";
import { LibraryName } from "SP/sitePages/sitePages.types";

import { IExportedCertificate } from "./exportCertificates";
import { IExportedSearchResult } from "./exportSearchResults";

interface IExportedItem {
  [DocumentField.EPAMDocumentID]: string;
  [DocumentField.Name]: ITableLink;
  [DocumentField.Title]: string;
  [DocumentField.SubjectDoc]: string;
  [DocumentField.LocationCountry]: string;
  [DocumentField.DocumentArea]: string;
  [DocumentField.ApprovalDate]: string;
  [DocumentField.ApproverName]: string;
  [DocumentField.ApproverPosition]: string;
  [DocumentField.DocumentOwnerPosition]: string;
  [DocumentField.Description]: string;
  [DocumentField.OrganizationalUnit]: ITableLink;
  [DocumentField.PublishedPlace]: ITableLink;
  [DocumentField.Category]: string;
}

/**
 * Interface for setting link in Excel table
 */
export interface ITableLink {
  text: string;
  hyperlink: string;
  tooltip: string;
}

/**
 * Interface for manually aligned column
 */
export interface IManuallyAlignedColumn {
  width?: number;
  wrapText?: boolean;
  merge?: boolean;
}

export enum ExportFileName {
  roles = "EPAM Role Registry Export",
  certificates = "EPAM Certificates Export",
  regulations = "EPAM Regulations Export",
  templates = "EPAM Templates Export",
  regulationsAndTemplates = "EPAM Regulations and Templates Export",
}

/**
 * Formatting Excel table and writing of it
 * @param data Items for Excel table
 */
export function exportToExcel(workbook: Workbook, fileName: string): void {
  fileName = clearTableName(fileName);
  workbook.xlsx.writeBuffer().then((fileData) => {
    const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    fs.saveAs(blob, fileName);
  });
}

/**
 * Auto Width columns
 * @param worksheet excelJs worksheet
 * @param minimalWidth minimal value of width
 */
export function autoWidth(worksheet: Worksheet, minimalWidth = 10): void {
  for (let i = 1; i < worksheet.columnCount + 1; i++) {
    let maxColumnLength = 0;
    worksheet.getColumn(i).eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value && !cell.isMerged ? cell.text.length : 0);
    });
    worksheet.getColumn(i).width = maxColumnLength + 1;
  }
}

/**
 * Format hyperlink style in table
 * @param worksheet excelJs worksheet
 */
export function formatHyperlinks(worksheet: Worksheet): void {
  worksheet.eachRow((row) => {
    row.eachCell((cell, colNumber) => {
      if (cell.value && cell.value["hyperlink"])
        row.getCell(colNumber).font = {
          underline: true,
          color: { argb: "FF0000FF" },
        };
    });
  });
}

export function createHyperLink(description: string, link: string): ITableLink {
  return { hyperlink: link, text: description, tooltip: description };
}

export function executeColumnsAlign(
  worksheet: Worksheet,
  columns: TableColumnProperties[],
  manuallyAlignedColumns: { [column: string]: IManuallyAlignedColumn },
): void {
  for (const column in manuallyAlignedColumns) {
    const columnIndex = columns.findIndex((col) => col.name === column) + 1;
    if (columnIndex > 0) {
      if (manuallyAlignedColumns[column].width) {
        worksheet.getColumn(columnIndex).width = manuallyAlignedColumns[column].width;
      }
      if (manuallyAlignedColumns[column].wrapText) {
        worksheet.getColumn(columnIndex).alignment = { wrapText: true };
      }
    }
  }
}

export function formatItemsForExcelWorkBook(docs: IRegulation[], templatesInArray: boolean) {
  const items: IExportedItem[] = [];

  if (templatesInArray === undefined) {
    templatesInArray = docs.some((item) => item.sourceLibrary === LibraryName.templates);
  }

  for (const item of docs) {
    const newItem = {
      [DocumentField.EPAMDocumentID]: item.EPAMDocumentID,
      [DocumentField.Name]: createHyperLink(item.fileInfo?.Name, getDocumentViewUrl(item.viewUrl, item.FriendlyUrl)),
      [DocumentField.Title]: item.Title,
      [DocumentField.SubjectDoc]: item.SubjectDoc,
      [DocumentField.LocationCountry]: item.LocationCountry && item.LocationCountry.join("; "),
      [DocumentField.DocumentArea]: item.DocumentArea && item.DocumentArea.join("; "),
      [DocumentField.ApprovalDate]: createDateString(item.ApprovalDate),
      [DocumentField.ApproverName]: item.ApproverName,
      [DocumentField.ApproverPosition]: item.ApproverPosition,
      [DocumentField.DocumentOwnerPosition]: item.DocumentOwnerPosition,
      [DocumentField.OrganizationalUnit]:
        item.OrganizationalUnit && createHyperLink(item.OrganizationalUnit.Description, item.OrganizationalUnit.Url),
    };

    if ("BriefDescription" in item && item.BriefDescription) {
      newItem[DocumentField.Description] = item.BriefDescription;
    } else if (templatesInArray) {
      newItem[DocumentField.Description] = "";
    }

    newItem[DocumentField.PublishedPlace] =
      item.PublishedPlace && createHyperLink(item.PublishedPlace.Description, item.PublishedPlace.Url);
    newItem[DocumentField.Category] = item.Category;
    items.push(newItem as IExportedItem);
  }

  return formatRowsAndColumns(items);
}

export function formatRowsAndColumns(items: IExportedItem[] | IExportedCertificate[] | IExportedSearchResult[]) {
  const rows: (string | ITableLink)[][] = new Array(items.length + 1);
  const cols = Object.keys(items[0]);
  const columns: TableColumnProperties[] = [];

  for (const item of items) {
    const row: string[] = [];
    for (const column of cols) {
      if (columns.length < cols.length) columns.push({ name: column, filterButton: true });
      row.push(item[column]);
    }
    rows.push(row);
  }

  return { rows: rows, columns: columns };
}

export function formatExcelWorkBook(
  rows: (string | ITableLink)[][],
  columns: TableColumnProperties[],
  worksheetName: string,
  manuallyAlignedColumns?: { [column: string]: IManuallyAlignedColumn },
) {
  const workbook = new Workbook();
  worksheetName = clearTableName(worksheetName);
  const worksheet = workbook.addWorksheet(worksheetName);

  worksheet.addTable({
    name: "ExportedItems",
    ref: "A1",
    headerRow: true,
    columns: columns,
    rows: rows,
  });
  autoWidth(worksheet);
  manuallyAlignedColumns && executeColumnsAlign(worksheet, columns, manuallyAlignedColumns);
  formatHyperlinks(worksheet);

  return workbook;
}

function clearTableName(fileName: string) {
  return fileName.replace(/[:?*\[\]<>]/g, "");
}
