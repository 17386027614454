import _difference from "lodash/difference";
import _map from "lodash/map";
import _noop from "lodash/noop";
import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { Button, Checkbox, LabeledInput, PickerInput, Text, TextInput } from "@epam/loveship";
import { ArrayDataSource, DataRowProps } from "@epam/uui";

import { withNaming } from "Helpers/bemClassname";
import { getDocumentOptions, getTermOptions } from "Helpers/optionsHelper";
import { getTitleErrorMessage, updateObject } from "Helpers/utils";
import { useDocumentsData } from "Hooks/useDocuments";
import { useFriendlyURLPathname } from "Hooks/useFriendlyURL";
import { useSitePagesActions, useSitePagesMethods } from "Hooks/useSitePages";
import { useTermsData } from "Hooks/useTerms";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { IPickerInputOption } from "SP/helpers.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { IAllDocuments } from "Store/actions/documents.actions";

import { IDetailsForm, IDetailsFormData, IPageDetailsMainProps } from "./page-details.interface";

function setFormValues(formData: IDetailsFormData): IDetailsForm {
  return {
    title: formData.title.value,
    associatedTerms: _map(formData.associatedTerms.value, "TermGuid"),
    library: formData.library.value,
    documents: formData.documents.value,
    attachToDocumentInPolicyWork: false,
    updateWhatsNewRecord: false,
  };
}

function orderDocumentsByUserSelect(newValue: number[] = [], oldValue: number[]): number[] {
  const diff = _difference(newValue, oldValue);
  return diff.length ? oldValue.concat(diff) : newValue;
}

const useDetailsForm = (
  formData: IDetailsFormData,
  allFormattedTerms: IPickerInputOption<ISPTermObject>[],
  allDocuments: IAllDocuments,
) => {
  const [formValue, setFormValue] = useState<IDetailsForm>(setFormValues(formData));
  const { pathname: sitePageUrl } = useFriendlyURLPathname();
  const { setSitePageInValidProperty } = useSitePagesActions();
  const { updateSitePage, updateSitePageFiles } = useSitePagesMethods();

  const onFormChange = (fieldKey: keyof IDetailsForm) => (newValue) => {
    const isLibrary = fieldKey === "library";

    if (fieldKey === "documents") {
      const oldValue = formValue[fieldKey];
      newValue = orderDocumentsByUserSelect(newValue, oldValue);
    }

    setFormValue(
      updateObject(formValue, {
        [fieldKey]: newValue,
        ...(isLibrary && { documents: [] }),
      }),
    );
    debouncedUpdate(fieldKey, newValue);
  };

  const DELAY = process.env.UNIT_TEST ? 0 : 550;
  const debouncedUpdate = useDebouncedCallback((fieldKey: keyof IDetailsForm, newValue) => {
    const pathKeys = formData[fieldKey]?.pathKeys;

    const handleEmptyValue = () => {
      updateSitePage(pathKeys, []);
    };

    switch (fieldKey) {
      case "title":
        setSitePageInValidProperty(getTitleErrorMessage(formValue.title) !== "", "Title");
        updateSitePage(pathKeys, newValue);
        break;
      case "associatedTerms":
        if (!newValue) return handleEmptyValue();

        const newTerms = allFormattedTerms
          .filter((term) => newValue.includes(term.id))
          .map((term) => ({
            TermGuid: term.id,
            Label: term.label,
          }));
        updateSitePage(pathKeys, newTerms);
        break;
      case "library":
        const documentsPathKeys = formData.documents.pathKeys;
        updateSitePage(pathKeys, newValue);
        updateSitePage(documentsPathKeys, []);
        break;
      case "documents":
        if (!newValue) return handleEmptyValue();
        const libraryFiles = allDocuments[formValue.library] as IRegulation[];
        const newFiles = libraryFiles
          .filter((doc) => newValue.includes(doc.Id))
          .sort((a, b) => newValue.indexOf(a.Id) - newValue.indexOf(b.Id));
        updateSitePage(pathKeys, newValue);
        updateSitePageFiles(newFiles, sitePageUrl);
        break;
      default:
        break;
    }
  }, DELAY);

  return { formValue, onFormChange };
};

export const PageDetailsMain: FC<IPageDetailsMainProps> = ({ sitePageUrl, formData }) => {
  const cn = withNaming("page-details");

  const { allTerms } = useTermsData();
  const { allDocuments } = useDocumentsData();
  const { attachSitePageToDocuments } = useSitePagesMethods();

  const dataSources = useMemo(() => {
    const allFormattedTerms = getTermOptions(allTerms);

    return {
      libraryDataSource: new ArrayDataSource({
        items: [
          { id: LibraryName.regulations, label: LibraryName.regulations },
          { id: LibraryName.templates, label: LibraryName.templates },
        ],
      }),
      documentsDataSource: {
        [LibraryName.regulations]: new ArrayDataSource({
          items: getDocumentOptions(allDocuments[LibraryName.regulations]),
        }),
        [LibraryName.templates]: new ArrayDataSource({
          items: getDocumentOptions(allDocuments[LibraryName.templates]),
        }),
      },
      associatedTermsDataSource: new ArrayDataSource({
        items: allFormattedTerms,
      }),
      allFormattedTerms,
    };
  }, [allTerms, allDocuments]);

  const { formValue, onFormChange } = useDetailsForm(formData, dataSources.allFormattedTerms, allDocuments);

  const isTitleInvalid = getTitleErrorMessage(formValue.title) !== "";

  const handleUpdateLink = () => {
    const params = {
      sitePageUrl,
      documents: formValue.documents,
      library: formValue.library,
      command: {
        attachToDocumentInPolicyWork: formValue.attachToDocumentInPolicyWork,
        updateWhatsNewRecord: formValue.updateWhatsNewRecord,
      },
    };
    attachSitePageToDocuments(params);
  };

  const renderDocumentsRow = useCallback((rowProps: DataRowProps<IPickerInputOption<ISPTermObject>, number>) => {
    const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      rowProps.onCheck(rowProps);
    };

    return (
      <div key={rowProps.rowKey} className={cn("documents-row", ["flex items-center"])} onClick={clickHandler}>
        <Checkbox
          cx={cn("documents-checkbox")}
          value={rowProps.isChecked}
          indeterminate={!rowProps.isChecked && rowProps.isChildrenChecked}
          onValueChange={_noop}
        />
        <div className={cn("documents-text")}>
          <Text size="18" color="night900" fontSize="14" lineHeight="18">
            {rowProps.value.label}
          </Text>
          <Text size="18" color="night600" fontSize="14" lineHeight="18">
            {rowProps.value.additionalLabel}
          </Text>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={cn("wrapper")}>
      <div className={cn("row")}>
        <LabeledInput
          isRequired
          size="none"
          label="Title"
          isInvalid={isTitleInvalid}
          validationMessage={getTitleErrorMessage(formValue.title)}
        >
          <TextInput
            placeholder="Title"
            value={formValue.title}
            onValueChange={onFormChange("title")}
            isInvalid={isTitleInvalid}
            rawProps={{ "data-testid": "title-input" }}
          />
        </LabeledInput>
      </div>
      <div className={cn("row")}>
        <LabeledInput size="none" label="Associated Terms" rawProps={{ "data-testid": "associated-terms" }}>
          <PickerInput
            disableClear
            valueType="id"
            selectionMode="multi"
            searchPosition="body"
            getName={(item) => item.label}
            value={formValue.associatedTerms}
            onValueChange={onFormChange("associatedTerms")}
            dataSource={dataSources.associatedTermsDataSource}
          />
        </LabeledInput>
      </div>
      <div className={cn("row", { section: true })}>
        <div className={cn("col")}>
          <Text cx="p-0" color="night800" font="sans-semibold" fontSize="18" lineHeight="24">
            Documents
          </Text>
          <LabeledInput size="none" label="Source Library" rawProps={{ "data-testid": "source-library" }}>
            <PickerInput
              selectionMode="single"
              valueType="id"
              getName={(item) => item.label}
              value={formValue.library}
              onValueChange={onFormChange("library")}
              dataSource={dataSources.libraryDataSource}
            />
          </LabeledInput>
        </div>
      </div>
      {formValue.library && (
        <div className={cn("row")}>
          <LabeledInput size="none" label="Documents" rawProps={{ "data-testid": "documents-input-box" }}>
            <PickerInput
              disableClear
              valueType="id"
              selectionMode="multi"
              searchPosition="body"
              getName={(item) => item.label}
              renderRow={renderDocumentsRow}
              getSearchFields={(item: IPickerInputOption<ISPTermObject>) => [item.label, item.additionalLabel]}
              value={formValue.documents}
              onValueChange={onFormChange("documents")}
              dataSource={dataSources.documentsDataSource[formValue.library]}
            />
          </LabeledInput>
        </div>
      )}
      {!!formValue.documents?.length && (
        <div className={cn("row", { section: true })}>
          <div className={cn("col")}>
            <Text cx={cn("link-text", ["p-0"])} color="night800" font="sans-semibold" fontSize="18" lineHeight="24">
              Page Link
            </Text>
            <Checkbox
              isDisabled
              value={true}
              size="18"
              cx={cn("link-checkbox")}
              label="Update link in ECL"
              onValueChange={_noop}
            />
            <Checkbox
              cx={cn("link-checkbox")}
              size="18"
              label="Update record in the RDS"
              value={formValue.attachToDocumentInPolicyWork}
              onValueChange={onFormChange("attachToDocumentInPolicyWork")}
            />
            <Checkbox
              cx={cn("link-checkbox")}
              size="18"
              label="Update record in the ECL News"
              value={formValue.updateWhatsNewRecord}
              onValueChange={onFormChange("updateWhatsNewRecord")}
            />
            <Button cx={cn("link-button")} caption="Update Link" onClick={handleUpdateLink} />
          </div>
        </div>
      )}
    </div>
  );
};
