import _cloneDeep from "lodash/cloneDeep";
import { call, fork, ForkEffect, put, takeLatest } from "redux-saga/effects";

import { isTermHaveChild, removeUrlPrefix, updateObject } from "Helpers/utils";
import { ISPTermObject, SPTermStoreService } from "SP/SPTermStoreService";
import {
  getTermsFailure,
  getTermsSuccess,
  IGetTermsRequestAction,
  TermsActionsTypes,
} from "Store/actions/terms.actions";

const termsService = new SPTermStoreService();

export function filterOnlyVisibleTerms(terms: ISPTermObject[]): ISPTermObject[] {
  return terms.filter((term) => {
    if (isTermHaveChild(term)) term.terms = filterOnlyVisibleTerms(term.terms);
    return !term.localCustomProperties.hasOwnProperty("_Sys_Nav_ExcludedProviders");
  });
}

export function modifyVisibleTerms(terms: ISPTermObject[]): ISPTermObject[] {
  const urlKey = "_Sys_Nav_TargetUrl";

  return terms.map((term) => {
    if (isTermHaveChild(term)) {
      term.terms = modifyVisibleTerms(term.terms);
    }

    return updateObject(term, {
      localCustomProperties: updateObject(term.localCustomProperties, {
        [urlKey]: removeUrlPrefix(term.localCustomProperties[urlKey]),
      }),
    });
  });
}

export function* getTerms(action: IGetTermsRequestAction) {
  try {
    const allTerms: ISPTermObject[] = yield call([termsService, "getTermsFromTermSet"], action.termSetName);
    const filteredTerms = filterOnlyVisibleTerms(_cloneDeep(allTerms));

    yield put(
      getTermsSuccess({
        allTerms,
        filteredTerms: modifyVisibleTerms(filteredTerms),
      }),
    );
  } catch (e) {
    yield put(getTermsFailure(e));
    throw e;
  }
}

export function* watchGetTerms() {
  yield takeLatest(TermsActionsTypes.GET_TERMS, getTerms);
}

export default function* termsSagas(): Iterator<ForkEffect> {
  yield fork(watchGetTerms);
}
