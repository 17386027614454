import React, { useState } from "react";

import { Button, FlexSpacer, LabeledInput, TextInput } from "@epam/loveship";
import { IModal } from "@epam/uui";

import { renderText } from "Components/documents-table/documents-table.helpers";
import { loaderIcon } from "Components/icons";
import { Modal } from "Components/modal";
import { getTitleErrorMessage } from "Helpers/utils";

import { cn } from "./documents-filter";
import { useFiltersData } from "./documents-filter.hooks";

export interface IDocumentFilterModalProps {
  modalProps: IModal<string>;
  title: string;
  submitName: string;
  submitAction: (value: string) => Promise<void>;
  text?: string;
  label?: string;
  errorMessage?: string;
  isInvalidInput?: boolean;
  isDeleteType?: boolean;
  groupIndex?: number;
  onChange?: (value: string) => void;
}

export const DocumentsFilterModal: React.FC<IDocumentFilterModalProps> = ({
  modalProps,
  submitName,
  submitAction,
  title,
  text,
  label,
  isDeleteType,
  groupIndex,
  onChange,
}) => {
  const [name, setName] = useState(text || "");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(getTitleErrorMessage(name));
  const { selectedFiltersGroups } = useFiltersData();

  const onInputChange = (value: string) => {
    let groupNameErrorMessage = getTitleErrorMessage(value);

    if (!groupNameErrorMessage && isDifferentGroupHasSameName(value)) {
      groupNameErrorMessage = "Filter group with this name already exists";
    }

    setErrorMessage(groupNameErrorMessage);
    setName(value);
    onChange?.(value);
  };

  const isDifferentGroupHasSameName = (groupName) => {
    return selectedFiltersGroups.some((group, index) => groupIndex !== index && group.name === groupName);
  };
  const onSubmitClick = async () => {
    setLoading(true);
    await submitAction(name);
    setLoading(false);
    modalProps.abort();
  };

  return (
    <Modal
      className={cn("modal")}
      modalProps={modalProps}
      width={580}
      title={title}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn btn--grey" color="night600" fill="white" caption="Cancel" onClick={modalProps.abort} />
          <Button
            cx={`btn${isDeleteType ? " btn--modal-fire" : ""}`}
            color={isDeleteType ? "fire" : "grass"}
            caption={submitName}
            icon={loading ? loaderIcon : null}
            onClick={onSubmitClick}
            iconPosition="right"
            isDisabled={!!errorMessage && submitName !== "Delete"}
          />
        </>
      }
    >
      <div className={cn("modal-body", ["flex"])}>
        {isDeleteType ? (
          renderText("You won’t be able to restore it")
        ) : (
          <LabeledInput
            cx={cn("label")}
            label={label}
            validationMessage={errorMessage}
            isInvalid={!!errorMessage}
            isRequired
          >
            <TextInput value={name} onValueChange={onInputChange} placeholder="Enter name" isInvalid={!!errorMessage} />
          </LabeledInput>
        )}
      </div>
    </Modal>
  );
};
