import React, { FC, Fragment } from "react";

import { ScrollBars, Text } from "@epam/loveship";

import { Loader } from "Components/loader";
import { WidenNotification } from "Components/widen-notification";
import { withNaming } from "Helpers/bemClassname";
import { Links } from "Helpers/constants";
import { useMenuNavigationData } from "Hooks/useMenuNavigation";
import { useRibbon } from "Hooks/useRibbon";
import { useSitePagesActions } from "Hooks/useSitePages";

interface ILayoutProps {
  loading?: boolean;
  isContentLoaded?: boolean;
  isLandingPage?: boolean;
}

export const Layout: FC<ILayoutProps> = ({ children, loading, isContentLoaded, isLandingPage }) => {
  const cn = withNaming("app");
  const { menuNavigationToggle } = useMenuNavigationData();
  const { isSitePageInEditMode, isSitePageInVersionHistoryMode } = useSitePagesActions();
  const isRibbonPresent = useRibbon(isContentLoaded);
  const now = new Date();
  const appVersion = process.env.REACT_APP_VERSION;

  const Scroll = isSitePageInEditMode ? ScrollBars : Fragment;

  return (
    <div
      className={cn("", {
        "ribbon-present": isRibbonPresent,
        "edit-mode": isSitePageInEditMode || isSitePageInVersionHistoryMode,
        "landing-page": isLandingPage,
      })}
    >
      {isSitePageInEditMode && <WidenNotification />}
      <Scroll {...(isSitePageInEditMode && { cx: cn("scroll") })}>
        <div className={cn("wall")} />
        {loading && <Loader />}
        <div
          className={cn("container", {
            "menu-open": menuNavigationToggle,
            "edit-mode": isSitePageInEditMode || isSitePageInVersionHistoryMode,
          })}
        >
          {children}
        </div>
        <div className={cn("footer")}>
          <Text color="night500" size="18" fontSize="12" lineHeight="18">
            Ethics & Compliance v. {appVersion} © 1993–{now.getFullYear()} EPAM Systems.&nbsp;
            <a className={cn("footer__link")} href={Links.allRightReservedLink} target="_blank" rel="noreferrer">
              All Rights Reserved
            </a>
          </Text>
        </div>
      </Scroll>
    </div>
  );
};
