import React, { FC, useContext } from "react";

import { DocumentsViewContext } from "@App/context/DocumentsViewContext";
import { Checkbox } from "@epam/loveship";

import { ITableRow } from "./documents-table.interface";

export const TableRow: FC<ITableRow> = ({
  cn,
  columns,
  checked,
  subscribeParams,
  row,
  onCheckToggle,
  onFileNameClick,
  onOpenInfoPanel,
}) => {
  const { searchValue } = useContext(DocumentsViewContext);

  return (
    <div className={cn("row", { isSelected: checked }, ["relative flex items-center"])} data-testid="row">
      <Checkbox
        cx={cn("checkbox", ["checkbox-list"])}
        size="18"
        value={checked}
        rawProps={{ "data-testid": "table-row-checkbox" }}
        onValueChange={onCheckToggle}
      />
      <div className={cn("cells", { row: true }, ["flex shadow"])}>
        {columns.map((column) => (
          <div
            key={column.key}
            className={cn("cell")}
            style={{
              minWidth: column.minWidth ? `${column.minWidth}px` : 0,
              flexBasis: `${column.width}px`,
            }}
          >
            {column.render(row, { subscribeParams, searchValue, onFileNameClick, onOpenInfoPanel })}
          </div>
        ))}
      </div>
    </div>
  );
};
