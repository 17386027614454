import React, { FC } from "react";

import { FlexRow, FlexSpacer } from "@epam/loveship";
import { ButtonProps } from "@epam/uui-components";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { LinkButtonWithAnalytics } from "Components/link-button-with-analytics";
import { withNaming } from "Helpers/bemClassname";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { ISubscribeToItemToggleParams } from "Store/actions/users.actions";

import "./document-footer.scss";

interface IDocumentFooterProps {
  buttons: ButtonProps[];
  attachedFile: IAttachedFile;
  subscribeParams: ISubscribeToItemToggleParams;
  isRoles?: boolean;
}

export const DocumentFooter: FC<IDocumentFooterProps> = ({ buttons, attachedFile, subscribeParams, isRoles }) => {
  const cn = withNaming("document-footer");

  return (
    <>
      <FlexSpacer />
      <div className={cn("divider")} />
      <FlexRow cx={cn("buttons", ["unselect"])}>
        {buttons.map((props) => (
          <LinkButtonWithAnalytics
            rawProps={{ "data-testid": `link-btn-${props.caption}` }}
            key={props.caption}
            cx={cn("button")}
            size="none"
            font="sans-semibold"
            fontSize="14"
            lineHeight="18"
            color="sky"
            target="_blank"
            {...props}
          />
        ))}
        <FlexSpacer />
        <DocumentActionsDropdown
          fileType={attachedFile?.fileInfo?.FileType}
          serverRelativeUrl={attachedFile?.fileInfo?.ServerRelativeUrl}
          viewUrl={isRoles ? attachedFile?.roleUrl : attachedFile?.viewUrl}
          friendlyUrl={attachedFile?.FriendlyUrl}
          subscribeParams={subscribeParams}
        />
      </FlexRow>
    </>
  );
};
