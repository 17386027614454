import { DocumentField } from "SP/fields";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { FilterInvolve, IFilter } from "../documents-filter.interface";
import { getFieldUniqueValues, getMinAndMaxDates } from "./filters.helpers";

export const getRegulationTemplatesFilters = (documents: IAttachedFile[]): IFilter[] => {
  return [
    {
      key: "Title",
      caption: DocumentField.Title,
      component: "picker-input",
      data: getFieldUniqueValues("Title", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "SubjectDoc",
      caption: DocumentField.SubjectDoc,
      component: "picker-input",
      data: getFieldUniqueValues("SubjectDoc", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "EPAMDocumentID",
      caption: DocumentField.EPAMDocumentID,
      component: "picker-input",
      data: getFieldUniqueValues("EPAMDocumentID", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "DocumentArea",
      caption: DocumentField.DocumentArea,
      component: "picker-input",
      data: getFieldUniqueValues("DocumentArea", documents),
    },
    {
      key: "LocationCountry",
      caption: DocumentField.LocationCountry,
      component: "picker-input",
      data: getFieldUniqueValues("LocationCountry", documents),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "OrganizationalUnit",
      caption: DocumentField.OrganizationalUnit,
      component: "picker-input",
      data: getFieldUniqueValues("OrganizationalUnit", documents, "Description"),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
    {
      key: "ApprovalDate",
      caption: DocumentField.ApprovalDate,
      component: "date-picker",
      data: getMinAndMaxDates("ApprovalDate", documents),
    },
  ];
};
