import React from "react";

import { LinkButton } from "@epam/loveship";
import { useUuiContext } from "@epam/uui";
import { PickerFooterProps } from "@epam/uui-components";

import { DocumentsModal } from "Components/documents-toolbar/documents-toolbar-modal";
import { FullPageComponentTypes } from "SP/sitePages/sitePages.types";

import { IDataPicker } from "./documents-toolbar.interface";

export interface IShowPickerFooterProps {
  pickerProps: PickerFooterProps<IDataPicker, string>;
  cn: (b, e?, m?) => string;
  pageType: FullPageComponentTypes;
  activeTab?: string;
}

export const ShowPickerFooter = (props: IShowPickerFooterProps) => {
  const svc = useUuiContext();

  const { cn, pickerProps, pageType, activeTab } = props;

  return (
    <LinkButton
      cx={cn("modal-button", ["flex justify-center"])}
      caption={"View Document type Descriptions".toUpperCase()}
      fontSize="14"
      lineHeight="24"
      onClick={() =>
        svc.uuiModals.show((modalProps) => (
          <DocumentsModal
            cn={cn}
            modalProps={modalProps}
            pickerProps={pickerProps}
            pageType={pageType}
            activeTab={activeTab}
          />
        ))
      }
    />
  );
};
