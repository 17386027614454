import _isEmpty from "lodash/isEmpty";

import { ClientNotFoundError } from "@App/errors/ClientNotFoundError";
import { HttpRequestError } from "@pnp/odata";
import { IBasePermissions, ICamlQuery, IFieldInfo, IItemUpdateResult, sp, Web } from "@pnp/sp/presets/all";

import { getFieldIfExist, getServerRelativeUrl, parseJson } from "Helpers/utils";
import { childPageInfo, parentPageInfo } from "SP/constants";
import { CertificatesService } from "SP/documents/certificates/certificates.service";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { PublishedRegulationService } from "SP/documents/publishedRegulations/publishedRegulations.service";
import { RegulationsService } from "SP/documents/regulations/regulations.service";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { TemplatesService } from "SP/documents/templates/templates.service";
import { IHyperLinkField, IItemTermInfo, ILibraryFolder } from "SP/helpers.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

import { SitePagesRepository } from "./sitePages.repository";
import {
  INewSitePageInfo,
  ISitePage,
  ISitePageDTO,
  ISitePageVersion,
  ISitePageVersionDTO,
  LibraryName,
  PageType,
} from "./sitePages.types";

export interface IAttachCommand {
  attachToDocumentInPolicyWork: boolean;
  updateWhatsNewRecord: boolean;
}

export class SitePagesService {
  repository = new SitePagesRepository();
  private mapSitePage = (page: ISitePageDTO, terms: ISPTermObject[]): ISitePage => {
    return {
      Author: { email: page.Author.EMail, id: page.Author.Id, name: page.Author.Title },
      Created: new Date(page.Created),
      Editor: { email: page.Editor.EMail, id: page.Editor.Id, name: page.Editor.Title },
      Id: page.Id,
      Modified: new Date(page.Modified),
      Title: page.Title,
      CheckoutUser: page.CheckoutUser && {
        email: page.CheckoutUser.EMail,
        id: page.CheckoutUser.Id,
        name: page.CheckoutUser.Title,
      },
      fileInfo: {
        Length: page.File.Length,
        Name: page.File.Name,
        ServerRelativeUrl: page.File.ServerRelativeUrl,
        FileType: page.File_x0020_Type,
        UniqueId: page.File.UniqueId,
      },
      parentNavigationTermSets: page.parentNavigationTermSets,
      pageInfo: parseJson(page.pageInfo),
      breadcrumbItems: this.formatBreadCrumbItems(page, terms),
      redirectToPage: this.formatRedirectToPage(page),
      sourceLibrary: LibraryName.pages,
      friendlyUrl: page.FriendlyURL,
    };
  };

  private formatRedirectToPage = (page: ISitePageDTO) => {
    if (page.redirectToPage) {
      const url = new URL(window.location.href);

      return page.redirectToPage + url.search;
    }
    return page.redirectToPage;
  };

  private mapSitePageVersion = (version: ISitePageVersionDTO): ISitePageVersion => {
    return {
      Editor: { email: version.Editor.Email, name: version.Editor.LookupValue, id: version.Editor.LookupId },
      Modified: new Date(version.Modified),
      Title: version.Title,
      pageInfo: parseJson(version.pageInfo),
      VersionLabel: version.VersionLabel,
      CheckInComment: version.OData__x005f_CheckinComment,
    };
  };

  private reversMapSitePage = (page: ISitePage, taxonomyField: IFieldInfo): Partial<ISitePageDTO> => {
    const updateData: Partial<ISitePageDTO> = {
      Title: page.Title,
      pageInfo: JSON.stringify(page.pageInfo),
      PageTextContent: "",
    };
    updateData[taxonomyField.InternalName] = this.repository.formatTaxonomyUpdateString(page.parentNavigationTermSets);
    return updateData;
  };
  /**
   * This method filter selected terms in page and returns only one needed
   * Formatting rule:
   * If page does not have associated term - page will not have breadcrumb, result array will be empty
   * If page has only one associated term - we use it for breadcrumb
   * If page has multiple associated terms - we use those term, which name is placed on path parameter in URL. If there is no path parameter in URL - page will not have breadcrumb, result array will be empty
   * If there is no result after previous steps - try to find term by name from path
   * In a result will be only one term that will be used for breadcrumb building
   * @param page page info
   * @returns filtered term
   */
  private filterTermSets(page: ISitePageDTO, terms: ISPTermObject[]): IItemTermInfo {
    const url = new URL(location.href);
    const path: string = new URLSearchParams(url.search).get("path");
    let termInfo: IItemTermInfo = undefined;
    let parentNavigationTermSets: IItemTermInfo[] = page.parentNavigationTermSets;
    if (parentNavigationTermSets.length > 0) {
      if (parentNavigationTermSets.length === 1) {
        termInfo = parentNavigationTermSets[0];
      } else {
        if (path) parentNavigationTermSets = parentNavigationTermSets.filter((term) => term.Label == path);
        if (parentNavigationTermSets.length === 1) termInfo = parentNavigationTermSets[0];
        if (parentNavigationTermSets.filter((term) => term.Label == page.Title).length === 1) {
          termInfo = parentNavigationTermSets.filter((termI) => termI["Label"] == page.Title)[0];
        }
      }
      if (termInfo == undefined && path) {
        const neededTerm = this.findNeededTermByPath(path, terms);
        if (neededTerm) termInfo = { Label: neededTerm.name, TermGuid: neededTerm.guid, WssId: undefined };
      }
      return termInfo;
    }
  }
  /**
   * This method search term by its name in nested array
   * @param path title of term
   * @param terms all terms in term store
   * @returns needed term
   */
  private findNeededTermByPath(path: string, terms: ISPTermObject[]): ISPTermObject {
    for (const term of terms) {
      if (term.name == path) return term;

      const found = this.findNeededTermByPath(path, term.terms);
      if (found) return found;
    }
  }
  /**
   * This method formats array for breadcrumb on page
   * @param page page info
   * @returns array with information for breadcrumb (link and description)
   */
  private formatBreadCrumbItems(page: ISitePageDTO, terms: ISPTermObject[]): IHyperLinkField[] {
    const breadcrumbItems: IHyperLinkField[] = [];
    const termInfo = this.filterTermSets(page, terms);
    if (termInfo) {
      const termsArray: ISPTermObject[] = [];
      const getTermsFromChildToRoot = function (terms: ISPTermObject[], guid: string) {
        if (terms) {
          for (const term of terms) {
            if (term.guid == guid) {
              return term;
            }
            const found = getTermsFromChildToRoot(term.terms, guid);
            if (found) {
              termsArray.unshift(term);
              return found;
            }
          }
        }
      };
      termsArray.push(getTermsFromChildToRoot(terms, termInfo.TermGuid));
      termsArray.forEach((term) => {
        if (!_isEmpty(term) && this.checkIfTermShouldBeAdded(term, page)) {
          breadcrumbItems.push({
            Description: term.name,
            Url: term.localCustomProperties._Sys_Nav_SimpleLinkUrl || term.localCustomProperties._Sys_Nav_TargetUrl,
          });
        }
      });
    }
    return breadcrumbItems;
  }

  private checkIfTermShouldBeAdded(term: ISPTermObject, page: ISitePageDTO) {
    const _Sys_Nav_TargetUrl = getFieldIfExist("_Sys_Nav_TargetUrl", term.localCustomProperties);
    return (
      term.localCustomProperties &&
      !term.localCustomProperties.hasOwnProperty("_Sys_Nav_ExcludedProviders") &&
      _Sys_Nav_TargetUrl?.toString().toLowerCase() !== page.File.ServerRelativeUrl.toLowerCase()
    );
  }

  public async getAll(terms: ISPTermObject[]): Promise<ISitePage[]> {
    const allPages = await this.repository.getAll();
    const mappedPages = allPages.map((page) => this.mapSitePage(page, terms));
    return mappedPages;
  }
  public async getById(id: number, terms: ISPTermObject[]): Promise<ISitePage> {
    const pageItem = await this.repository.getById(id);
    return this.mapSitePage(pageItem, terms);
  }

  public async getSitePageVersions(pageId: number) {
    const versions = await this.repository.getSitePageVersions(pageId);

    return versions.map(this.mapSitePageVersion);
  }

  public async getSitePageByServerRelativeUrl(url: string, terms: ISPTermObject[]): Promise<ISitePage> {
    const pageItem = await this.repository.getSitePageByServerRelativeUrl(url);
    return this.mapSitePage(pageItem, terms);
  }

  public async getSitePageByFriendlyUrl(friendlyUrl: string, terms: ISPTermObject[]): Promise<ISitePage> {
    const pages = await this.repository.getSitePageByFriendlyUrl(friendlyUrl);

    if (pages.length == 0) {
      throw new ClientNotFoundError("Can not find page");
    }

    return this.mapSitePage(pages[0], terms);
  }

  public async checkOutSitePage(id: number): Promise<void> {
    return await this.repository.checkOutSitePage(id);
  }
  public async checkInSitePage(id: number, comment?: string): Promise<void> {
    return await this.repository.checkInSitePage(id, comment);
  }
  public async updatePage(id: number, data: ISitePage): Promise<IItemUpdateResult> {
    const taxonomyField = await this.repository.getParentNavigationTermSetsField();
    const sitePage = this.reversMapSitePage(data, taxonomyField);
    return await this.repository.updatePage(id, sitePage);
  }
  public async addNewPage(pageInfo: INewSitePageInfo, terms: ISPTermObject[]): Promise<ISitePage> {
    const newPage = await this.repository.addNewPage(pageInfo.folderServerRelativeUrl, pageInfo.pageTitle);
    const newPageItem = await this.getById(newPage, terms);
    newPageItem.Title = pageInfo.pageTitle;
    newPageItem.parentNavigationTermSets = pageInfo.selectedTerms.map(this.mapSPTermObjectToItemTermInfo);

    if (pageInfo.pageType === PageType.Child) newPageItem.pageInfo = childPageInfo;
    else newPageItem.pageInfo = parentPageInfo;

    await this.updatePage(newPageItem.Id, newPageItem);

    return newPageItem;
  }
  private mapSPTermObjectToItemTermInfo(term: ISPTermObject): IItemTermInfo {
    return { Label: term.name, TermGuid: term.guid, WssId: 0 };
  }
  public async checkIfPageExist(folderServerRelativeUrl: string, pageTitle: string): Promise<boolean> {
    return await this.repository.checkIfPageExist(folderServerRelativeUrl, pageTitle);
  }
  public async discardCheckOutSitePage(id: number): Promise<void> {
    await this.repository.discardCheckOutSitePage(id);
  }
  public async getCurrentUserEffectivePermissions(): Promise<IBasePermissions> {
    return await this.repository.getCurrentUserEffectivePermissions();
  }
  public async checkIfUSerHasEditPermission(): Promise<boolean> {
    return await this.repository.checkIfUSerHasEditPermission();
  }
  /**
   * This method is getting folder hierarchy of Site Pages library
   * @param maxDepth - determines the maximum nesting of folders, default value is 10
   * @returns folder Hierarchy of library
   */
  public async getFolderHierarchy(maxDepth = 10): Promise<ILibraryFolder[]> {
    return await this.repository.getFolderHierarchy(maxDepth);
  }
  /**
   * This method runs when user press button in property pane and attaches page to documents
   * @param page current page
   * @param selectedDocuments array with ID of selected documents
   * @param selectedList name of selected list
   * @param command information about execution command
   * @returns boolean value if filling was successful or not
   */
  public async attachPageToDocuments(
    page: ISitePage,
    selectedDocuments: number[],
    selectedList: string,
    command: IAttachCommand,
    icons: IStandardIcon[],
  ): Promise<boolean> {
    const ServerRelativeUrl = getServerRelativeUrl();
    const policyWorkWeb = Web("/work");
    const policyWorkDocumentsLibrary = policyWorkWeb.lists.getByTitle("Documents");
    const pageUrl = this.getPageUrl(page);
    const linkToPage: IHyperLinkField = { Url: pageUrl, Description: page.Title };
    const whatsNewList = sp.web.lists.getByTitle("ECL News");
    if (selectedDocuments.length > 0) {
      let file: IRegulation | ICertificate;
      let fileService: RegulationsService | TemplatesService | CertificatesService;
      switch (selectedList) {
        case LibraryName.regulations:
          fileService = new RegulationsService();
          break;
        case LibraryName.templates:
          fileService = new TemplatesService();
          break;
        case LibraryName.certificates:
          fileService = new CertificatesService();
          break;
        default:
          alert(`Unsupported library`);
          return false;
      }
      for (const docId of selectedDocuments) {
        try {
          file = await fileService.getById(docId, icons);
          await fileService.updateDocument(docId, { PageName: linkToPage });

          if (selectedList === LibraryName.regulations) {
            this.updatePublishedRegulationPublishedPlace(file.EPAMDocumentID, linkToPage);
          }
          alert(`File ${file.fileInfo.Name} updated, Page Name filled with current page`);
        } catch (e) {
          const message = await this.parseErrorMessage(e);
          alert(message);
        }
        //Attaching page to document in policy work
        if (command.attachToDocumentInPolicyWork) {
          const policyWorkDocId = +file.EPAMDocumentID.split("-")[1];
          try {
            const relatedFileFromWork = await policyWorkDocumentsLibrary.items.getById(policyWorkDocId).file.get();
            await policyWorkDocumentsLibrary.items.getById(policyWorkDocId).file.checkout();
            await policyWorkDocumentsLibrary.items
              .getById(policyWorkDocId)
              .update({ Published_x0020_Place: linkToPage });
            await policyWorkDocumentsLibrary.items.getById(policyWorkDocId).file.checkin("Published Place filled");
            alert(`File ${relatedFileFromWork.Name} in policy/work updated, Publish Place filled with current page`);
          } catch (e) {
            const message = await this.parseErrorMessage(e);
            alert(message);
          }
          //Updating ECL News record
          if (command.updateWhatsNewRecord) {
            const eclNewsList = sp.web.lists.getByTitle("ECL News");
            let caml: ICamlQuery = {
              ViewXml: `<View><Query>
                  <Where>
                      <Contains>
                          <FieldRef Name="ItemName" />
                          <Value Type="URL">${file.fileInfo.Name}</Value>
                      </Contains>
                  </Where>
              </Query></View>`,
            };
            let whatsNewItems: unknown[] = await eclNewsList.getItemsByCAMLQuery(caml); //Trying to get items in ECL News list with selected file
            let last1000WhatsNewItems: unknown[];
            if (whatsNewItems.length == 0) {
              const camlGetLast1000: ICamlQuery = {
                ViewXml: `<View>
                <ViewFields>
                  <FieldRef Name='ItemName' />
                </ViewFields>
                <Query>
                  <OrderBy>
                    <FieldRef Name='Modified' Ascending='False' />
                  </OrderBy>
                </Query>
                <RowLimit Paged='False'>1000</RowLimit>
                </View>`,
              };
              last1000WhatsNewItems = await eclNewsList.getItemsByCAMLQuery(camlGetLast1000);
              //Trying to get items in ECL News list with selected file
              whatsNewItems = last1000WhatsNewItems.filter(
                (item) =>
                  item["ItemName"] &&
                  item["ItemName"]["Description"] &&
                  item["ItemName"]["Description"].includes(file.fileInfo.Name),
              );
            }
            if (whatsNewItems.length == 0) {
              if (selectedList == LibraryName.templates || selectedList == LibraryName.certificates) {
                caml = {
                  ViewXml: `<View><Query>
                      <Where>
                          <Contains>
                              <FieldRef Name="ItemName" />
                              <Value Type="URL">${ServerRelativeUrl + selectedList}</Value>
                          </Contains>
                      </Where>
                  </Query></View>`,
                };
              }
              whatsNewItems = await eclNewsList.getItemsByCAMLQuery(caml); //Trying to get items in ECL News list with selected file
              if (whatsNewItems.length == 0) {
                alert(`Related items to file ${file.fileInfo.Name} not found`);
              } else {
                for (const item of whatsNewItems) {
                  if (item["ItemName"]["Description"] == file.fileInfo.Name) {
                    await whatsNewList.items.getById(item["Id"]).update({ ItemName: linkToPage });
                    alert(`Item Name link updated in item with ID ${item["Id"]}`);
                    break;
                  }
                }
              }
            } else {
              for (const whatsNewItem of whatsNewItems) {
                await whatsNewList.items.getById(whatsNewItem["Id"]).update({ ItemName: linkToPage });
                alert(`Item Name link updated in item with ID ${whatsNewItem["Id"]}`);
              }
            }
          }
        }
      }

      return true;
    }
  }

  private async updatePublishedRegulationPublishedPlace(epamDocumentId: string, linkToPage: IHyperLinkField) {
    const publishedRegulationService = new PublishedRegulationService();
    const doc = await publishedRegulationService.getByEpamDocumentId(epamDocumentId);

    if (!doc) {
      return;
    }

    publishedRegulationService.updateDocument(doc.Id, { PageName: linkToPage });
  }

  private getPageUrl(page: ISitePage) {
    return page.friendlyUrl ? `${window.location.origin}/${page.friendlyUrl}` : page.fileInfo.ServerRelativeUrl;
  }

  private async parseErrorMessage(e): Promise<string> {
    let message: string;
    if (e?.isHttpRequestError) {
      const error: HttpRequestError = e;
      const json = await error.response.json();
      message = typeof json["odata.error"] === "object" ? json["odata.error"].message.value : e.message;
      if (e.status === 404) {
        console.error(e.statusText);
      } else {
        message = e.message;
      }
      return message;
    }
  }
}
