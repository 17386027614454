import React from "react";

export interface IWarningMessage {
  message: string;
}

export const WarningMessage = ({ message }: IWarningMessage) => {
  return (
    <div style={{ background: "#d4ae08", width: "100%", height: 20, textAlign: "center", position: "absolute" }}>
      {message}
    </div>
  );
};
