import { useDispatch, useSelector } from "react-redux";

import { getSelectedData } from "Components/documents-toolbar/documents-toolbar.helpers";
import { IOnDownloadParams } from "Components/documents-toolbar/documents-toolbar.interface";
import { getDocumentDownloadUrl } from "Helpers/utils";
import { IFoldersInfo } from "SP/helpers.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import {
  downloadDocuments,
  getAllDocuments,
  getDocumentTypeDescriptions,
  IAllDocuments,
} from "Store/actions/documents.actions";
import { IRootReducerState } from "Store/reducers";

export const useDocumentsData = () => {
  const allDocuments = useSelector<IRootReducerState, IAllDocuments>((state) => state.documents.allDocuments);
  const isDocumentsLoaded = useSelector<IRootReducerState, boolean>((state) => state.documents.isDocumentsLoaded);
  const downloadLoading = useSelector<IRootReducerState, boolean>((state) => state.documents.downloadLoading);
  const documentTypeDescriptions = useSelector<IRootReducerState, IFoldersInfo>((state) => state.documents.foldersInfo);
  const invalidItemsCount = useSelector<IRootReducerState, number>((state) => state.documents.invalidItemsCount);

  return {
    allDocuments,
    isDocumentsLoaded,
    downloadLoading,
    documentTypeDescriptions,
    invalidItemsCount,
  };
};

export const useDocumentsActions = () => {
  const { downloadDocuments } = useDocumentsMethods();

  const onDownload = ({ items, selectedRows, docType }: IOnDownloadParams) => {
    if (selectedRows.length === 1) {
      const selectedDocument = items.find((doc) => doc.UniqueId === selectedRows[0]);
      return window.open(getDocumentDownloadUrl(selectedDocument?.viewUrl, selectedDocument?.FriendlyUrl));
    }

    const selectedData = getSelectedData(items, selectedRows);
    downloadDocuments(selectedData, docType);
  };

  return { onDownload };
};

export const useDocumentsMethods = () => {
  const dispatch = useDispatch();

  const handleGetAllDocuments = () => dispatch(getAllDocuments());

  const handleGetDocumentTypeDescriptions = () => dispatch(getDocumentTypeDescriptions());

  const handleDownloadDocuments = (docs: IAttachedFile[], archiveName: string) => {
    dispatch(downloadDocuments(docs, archiveName));
  };

  return {
    getAllDocuments: handleGetAllDocuments,
    getDocumentTypeDescriptions: handleGetDocumentTypeDescriptions,
    downloadDocuments: handleDownloadDocuments,
  };
};
