export default (function () {
  enum Keys {
    "siteInfo" = "siteInfo",
  }

  const cache = new Map<string, unknown>();

  function add<T>(key: Keys, v: T): void {
    cache.set(key, v);
  }

  function get<T>(key: Keys): T {
    return cache.get(key) as T;
  }

  return {
    Keys,
    add,
    get,
  };
})();
