import { IItemUpdateResult } from "@pnp/sp/items";

import { PublishedRegulationRepository } from "./publishedRegulations.repository";
import { IPublishedRegulation, IPublishedRegulationDTO } from "./publishedRegulations.types";

export class PublishedRegulationService {
  repository = new PublishedRegulationRepository();

  public async getByEpamDocumentId(documentId: string) {
    const docs = await this.repository.getByEpamDocumentId(documentId);

    if (docs.length == 0) {
      return null;
    }

    return this.mapPublishedRegulations(docs[0]);
  }

  public async updateDocument(id: number, data: Partial<IPublishedRegulationDTO>): Promise<IItemUpdateResult> {
    return await this.repository.updateDocument(id, data);
  }

  private mapPublishedRegulations(item: IPublishedRegulationDTO): IPublishedRegulation {
    return { Id: item.Id, EPAMDocumentID: item.EPAMDocumentID_x0020_, PageName: item.PageName };
  }
}
