import _debounce from "lodash/debounce";
import React, { FC, useMemo } from "react";

import { SearchInput, TextInputMods } from "@epam/loveship";
import { IEditableDebouncerOptions } from "@epam/uui";
import { TextInputProps } from "@epam/uui-components";

export interface ValidatedSearchInputProps {
  changeValue: (newValue: string) => void;
  value: string;
  executeSearch: (searchValue: string) => void;
}

export const ValidatedSearchInput: FC<
  ValidatedSearchInputProps & Partial<TextInputProps> & TextInputMods & IEditableDebouncerOptions
> = (props) => {
  const { changeValue, executeSearch, debounceDelay } = props;

  const debounceSearch = useMemo(() => {
    return _debounce(executeSearch, debounceDelay);
  }, [debounceDelay, executeSearch]);

  const handleInputValue = (newValue: string) => {
    debounceSearch.cancel();

    changeValue(newValue);

    debounceSearch(newValue);
  };

  return <SearchInput {...props} disableDebounce={true} onValueChange={handleInputValue} />;
};
