import React, { FC } from "react";

import { Layout } from "Components/layout";
import { Search } from "Components/search/search";

export const SearchPage: FC = () => {
  return (
    <Layout>
      <Search />
    </Layout>
  );
};
