import React, { FC, Fragment, ReactElement } from "react";

import { ModalBlocker, ModalFooter, ModalHeader, ModalWindow, Panel, ScrollBars } from "@epam/loveship";
import { IModal } from "@epam/uui";

import { withNaming } from "Helpers/bemClassname";
import { IVoidCb } from "SP/helpers.types";

interface IModalProps {
  title: string;
  modalProps: IModal<string>;
  footer?: ReactElement;
  onClose?: IVoidCb;
  width?: number;
  scrollableContent?: boolean;
  disallowClickOutside?: boolean;
  blockerShadow?: "light" | "dark" | "none";
  className?: string;
}

export const Modal: FC<IModalProps> = ({
  width = 600,
  blockerShadow = "dark",
  disallowClickOutside,
  footer,
  children,
  title,
  modalProps,
  scrollableContent,
  className,
  onClose,
}) => {
  const cn = withNaming("modal");

  const ContentWrapper = scrollableContent ? ScrollBars : Fragment;
  const handleClose = () => {
    onClose?.();
    modalProps.abort();
  };

  return (
    <ModalBlocker
      {...modalProps}
      disallowClickOutside={disallowClickOutside}
      blockerShadow={blockerShadow}
      cx={cn("", { [className]: !!className })}
      abort={handleClose}
    >
      <ModalWindow style={{ width }}>
        <ModalHeader borderBottom title={title} onClose={handleClose} />
        <ScrollBars hasTopShadow hasBottomShadow>
          <ContentWrapper>
            <Panel
              cx={cn("content", {
                [`${className}__content`]: !!className,
              })}
              style={{ padding: 24 }}
            >
              {children}
            </Panel>
          </ContentWrapper>
        </ScrollBars>
        {footer && <ModalFooter borderTop>{footer}</ModalFooter>}
      </ModalWindow>
    </ModalBlocker>
  );
};
