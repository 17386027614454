import { FoldersToIgnore } from "Helpers/constants";
import { getEncodedText } from "Helpers/utils";
import { apostropheRegex, documentsFieldsToSearch, pagesFieldsToSearch } from "SP/constants";

import { IGlobalSearchProps } from "./search.repository";

export class KqlService {
  public static formatQueryExact(queryText: string): string {
    const formattedQuery = queryText.replaceAll('"', "");
    return `("${formattedQuery} *")`;
  }

  public static formatQueryAll(queryText: string): string {
    const formattedQuery = queryText.replaceAll('"', "").trim();
    const orQuery = this.splitQuery(formattedQuery)
      .map((query) => {
        if (this.shouldAddStar(query)) {
          return `(${query}*)`;
        }

        return query;
      })
      .join(" OR ");

    return `(${orQuery})`;
  }

  public static shouldAddStar(query: string) {
    return query.search(apostropheRegex) != query.length - 2;
  }

  public static splitQuery(query: string): string[] {
    return query.split(/\s+/);
  }

  private static getFoldersIgnoreQuery() {
    const itemPathQuery = FoldersToIgnore.map((x) => `(*${x}*)`).join(" OR ");

    return `NOT(ItemPath:${itemPathQuery})`;
  }

  public static createDocumentsQuery(query: string, listsIds: string[]): string {
    const formattedQuery = query.replaceAll(/[“”]/g, " ");

    const documentsIdsQuery = listsIds.map((x) => `ListId:${x}`).join(" OR ");
    const specificDocumentsFieldsQuery = documentsFieldsToSearch.map((x) => `${x}:${formattedQuery}`).join(" OR ");
    const documentsExtensionsQuery = ["xltx", "xlsx", "potx", "pptx", "vsdx"].join(" OR ");

    const foldersQuery = KqlService.getFoldersIgnoreQuery();

    return `((${specificDocumentsFieldsQuery}) AND (${documentsIdsQuery}) AND (FileType:${documentsExtensionsQuery} OR IsDocument:true) AND (${foldersQuery}))`;
  }

  public static createPagesQuery(queryText: string, listId: string): string {
    const formattedQuery = getEncodedText(queryText);
    const specificPagesFieldsQuery = pagesFieldsToSearch.map((x) => `${x}:${formattedQuery}`).join(" OR ");

    return `((${specificPagesFieldsQuery}) AND ListId:${listId})`;
  }

  public static createGlobalQuery(props: IGlobalSearchProps): string {
    const { QueryText, listsIds, pagesId, rolesId } = props;

    const documentsQuery = this.createDocumentsQuery(QueryText, listsIds);
    const pagesQuery = this.createPagesQuery(QueryText, pagesId);

    return `${documentsQuery} OR ${pagesQuery}`;
  }
}
