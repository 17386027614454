import React from "react";

import { ReactComponent as SearchIcon } from "@epam/assets/icons/common/action-search-18.svg";
import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { IconContainer, Text } from "@epam/loveship";
import { ArrayDataSource, Icon } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import { FilterInvolve, IFilter } from "Components/documents-filter/documents-filter.interface";
import { getCertificateFilters } from "Components/documents-filter/filters/certificates-filters";
import { getFieldUniqueValues } from "Components/documents-filter/filters/filters.helpers";
import { getRegulationTemplatesFilters } from "Components/documents-filter/filters/regulation-templates-filters";
import { renderLink, renderSubscribed, renderText } from "Components/documents-table/documents-table.helpers";
import { getSelectedData } from "Components/documents-toolbar/documents-toolbar.helpers";
import type { IToolbarAction } from "Components/documents-toolbar/documents-toolbar.interface";
import {
  checkHighlightAll,
  checkHighlightExact,
  getTextToHighlightAll,
  getTextToHighlightExact,
  renderHighlightedAll,
  renderHighlightedExact,
} from "Components/found-content/found-content";
import {
  ICardHeaderParams,
  ICardIconParams,
  IDocumentCardHeaderParams,
  IDocumentCardParams,
  IDocumentCardProps,
  ISearchFilters,
  IToolbarActionParams,
  SearchTabType,
} from "Components/search/search.interfaces";
import { SharePointImage } from "Components/sharepoint-image";
import { ISubscriptionItem, SubscriptionsTabType } from "Components/subscriptions/subscriptions.interfaces";
import type { ITab } from "Components/tabs-slider/tabs-slider";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { ActionIcons, FileIcons } from "Helpers/constants";
import highlight from "Helpers/highlight";
import { getNotificationSubscribeParams, handleSubscribeToggle, SubscribeItemTypes } from "Helpers/subscribeHelper";
import { highlightWithTiming } from "Helpers/timingElements";
import { getFullFriendlyUrl, getSubscribeParams, parseDownloadLink } from "Helpers/utils";
import type { ICertificate } from "SP/documents/certificates/certificates.types";
import type { IRegulation } from "SP/documents/regulations/regulations.types";
import type { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import type {
  IHitHighlightedAll,
  IHitHighlightedExact,
  ISearchInfo,
  ISearchItem,
  ISearchSitePage,
} from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { SearchTypes } from "Store/reducers/search.reducer";

export const tabs: ITab[] = Object.keys(SearchTabType).map((tab) => ({
  caption: SearchTabType[tab],
  key: SearchTabType[tab],
}));

export const isVisibleOnTab = (tab: SearchTabType, item: ISearchItem) => {
  switch (tab) {
    case SearchTabType.All:
      return true;
    case SearchTabType.Pages:
      return item.sourceLibrary === LibraryName.pages;
    case SearchTabType.Documents:
      return item.sourceLibrary === LibraryName.regulations || item.sourceLibrary === LibraryName.templates;
    case SearchTabType.Certificates:
      return item.sourceLibrary === LibraryName.certificates;
    default:
      return false;
  }
};

export const renderHighlight = {
  [SearchTypes.all]: (searchInfo: ISearchInfo) => helpRenderHighlightAll(searchInfo.HitHighlightedAll),
  [SearchTypes.exact]: (searchInfo: ISearchInfo) => helpRenderHighlightExact(searchInfo.HitHighlightedExact),
};

function helpRenderHighlightAll(hitHighlighted: IHitHighlightedAll) {
  const textToHighlight = getTextToHighlightAll(hitHighlighted);

  return renderHighlightedAll({ ...hitHighlighted, text: textToHighlight });
}

function helpRenderHighlightExact(hitHighlighted: IHitHighlightedExact) {
  const textToHighlight = getTextToHighlightExact(hitHighlighted);

  return renderHighlightedExact({ ...hitHighlighted, text: textToHighlight });
}

export const checkRenderHighlight = {
  [SearchTypes.all]: (searchInfo: ISearchInfo) => isRenderHighlightedAll(searchInfo.HitHighlightedAll),
  [SearchTypes.exact]: (searchInfo: ISearchInfo) => isRenderHighlightedExact(searchInfo.HitHighlightedExact),
};

function isRenderHighlightedAll(hitHighlightedAll: IHitHighlightedAll): boolean {
  return hitHighlightedAll.text && checkHighlightAll(hitHighlightedAll);
}

function isRenderHighlightedExact(hitHighlightedExact: IHitHighlightedExact): boolean {
  return hitHighlightedExact.text && checkHighlightExact(hitHighlightedExact);
}

export function getFiltersByActiveTab(
  activeTab: SearchTabType | SubscriptionsTabType,
  searchItems: ISearchItem[] | ISubscriptionItem[],
): ISearchFilters {
  const allInfoPanelFilters: IFilter[] = [
    {
      key: "Title",
      caption: "Title",
      component: "picker-input",
      data: getFieldUniqueValues("Title", searchItems),
      excludeFilterInvolveIds: [FilterInvolve.includeExact],
    },
  ];

  switch (activeTab) {
    case SearchTabType.Documents:
      return {
        filtersLibrary: LibraryName.regulationsAndTemplates,
        infoPanelFilters: getRegulationTemplatesFilters(searchItems),
      };
    case SearchTabType.Certificates:
      return { filtersLibrary: LibraryName.certificates, infoPanelFilters: getCertificateFilters(searchItems) };
    case SearchTabType.Pages:
      return { filtersLibrary: LibraryName.pages, infoPanelFilters: allInfoPanelFilters };
    default:
      return {
        filtersLibrary: LibraryName.all,
        infoPanelFilters: allInfoPanelFilters,
      };
  }
}

export const renderPageCardHeader = ({ cn, item, query, isExactPhrase }: ICardHeaderParams) => (
  <div className={cn("details-row", ["flex flex-col"])}>
    {renderLink({
      cx: "self-start hover-underline",
      font: "sans-semibold",
      fontSize: "18",
      lineHeight: "24",
      caption: highlightWithTiming({
        elementtiming: "page-name",
        query,
        value: (item as ISearchSitePage)?.Title,
        isExactPhrase,
      }),
      href: (item as ISearchSitePage)?.FriendlyUrl,
      target: "_blank",
    })}
    <div className="flex">{renderText("Page", { fontSize: "12", color: "night600" })}</div>
  </div>
);

const getRegulationTitle = (item: IRegulation) => {
  return item?.Title?.length > 0 ? item?.Title : (item as IRegulation)?.fileInfo?.Name.split(".")[0];
};

export const renderRegulationTemplateCardHeader = ({
  cn,
  item,
  query,
  isSubscribed,
  onFileNameClick,
  isSearchCard,
  isExactPhrase,
}: IDocumentCardHeaderParams) => (
  <div className={cn("details-row", ["flex flex-col"])}>
    {renderLink({
      cx: "self-start hover-underline",
      font: "sans-semibold",
      fontSize: "18",
      lineHeight: "24",
      caption: highlightWithTiming({
        elementtiming: "regulation-template-name",
        query,
        value: getRegulationTitle(item as IRegulation),
        isExactPhrase,
      }),
      href: (item as IRegulation)?.PublishedPlace?.Url,
      target: "_blank",
    })}
    <div className="flex">
      {renderLink({
        cx: cn("file-icon", ["hover-underline"]),
        fontSize: "12",
        caption: highlight.peek(query, (item as IRegulation)?.fileInfo?.Name, isExactPhrase),
        onClick: onFileNameClick(item as IRegulation),
        target: "_blank",
      })}
      <span className="dot" style={{ margin: "0 6px" }} />
      {renderText((item as IRegulation)?.SubjectDoc, { fontSize: "12", color: "night600" })}
      {isSearchCard && isSubscribed && renderSubscribed(cn, item.sourceLibrary)}
    </div>
  </div>
);

export const renderCertificateCardHeader = ({
  cn,
  item,
  query,
  isSubscribed,
  onFileNameClick,
  isSearchCard,
  isExactPhrase,
}: IDocumentCardHeaderParams) => (
  <div className={cn("details-row", ["flex items-center"])}>
    <div className="certificate-info">
      {renderLink({
        cx: "file-title hover-underline",
        caption: highlightWithTiming({
          elementtiming: "certificate-name",
          query,
          value: (item as ICertificate)?.fileInfo?.Name,
          isExactPhrase,
        }),
        fontSize: "18",
        lineHeight: "24",
        onClick: onFileNameClick(item as ICertificate),
        target: "_blank",
      })}
      <div className="flex">
        {renderText((item as ICertificate)?.CertificateArtifactType, {
          cx: "one-line",
          font: "sans",
          fontSize: "12",
          color: "night600",
        })}
        {isSearchCard && isSubscribed && renderSubscribed(cn, item.sourceLibrary)}
      </div>
    </div>
  </div>
);

export const renderRoleCardHeader = ({
  cn,
  item,
  query,
  isSubscribed,
  isSearchCard,
  isExactPhrase,
}: IDocumentCardHeaderParams) => (
  <div className={cn("details-row", ["flex items-center"])}>
    <div className="certificate-info">
      {renderLink({
        cx: "file-title hover-underline",
        caption: highlightWithTiming({
          elementtiming: "role-name",
          query,
          value: (item as IRole)?.Title,
          isExactPhrase,
        }),
        fontSize: "18",
        lineHeight: "24",
        link: { pathname: (item as IRole)?.roleUrl },
        target: "_blank",
      })}
      <div className="flex">
        {renderText("Role", { fontSize: "12", color: "night600" })}
        {isSearchCard && isSubscribed && renderSubscribed(cn, item.sourceLibrary)}
      </div>
    </div>
  </div>
);

export const renderCardIcon = ({ cn, prefix, icon, transformIcon }: ICardIconParams) =>
  icon && typeof icon !== "string" ? (
    <IconContainer cx={cn(`${prefix}-icon`, { transform: transformIcon })} icon={icon as Icon} />
  ) : (
    <img className={cn(`${prefix}-icon`, { transform: transformIcon })} width={48} src={icon as string} />
  );

export const getToolbarActions = (params: IToolbarActionParams): IToolbarAction[] => {
  const {
    items,
    itemType,
    isDownloadButtonPresent,
    isNeedToSubscribe,
    isAllSelected,
    selectedRows,
    docType: activeTab,
    subscriptionsLoading,
    handleSubscribe,
    onDownload,
    exportCallback,
    userTitle,
  } = params;

  return [
    {
      id: 1,
      caption: `${isNeedToSubscribe ? "Subscribe" : "Unsubscribe"}${isAllSelected ? " All" : ""}`,
      icon: ActionIcons.subscribe,
      onClick: handleSubscribeToggle(
        getNotificationSubscribeParams({
          type: itemType,
          isSubscribed: !isNeedToSubscribe,
          subscriptionsLoading,
          onSubscribe: handleSubscribe,
        }),
      ),
    },
    ...(isDownloadButtonPresent
      ? [
          {
            id: 2,
            caption: `Download${isAllSelected ? " All" : ""}`,
            icon: ActionIcons.download,
            onClick: () => {
              const selectedItems = getSelectedData(items, selectedRows);
              for (const item of selectedItems) {
                analyticsHelper.analyticsService?.provideDownload(userTitle, parseDownloadLink(item?.downloadUrl));
              }

              onDownload({ items, selectedRows, docType: activeTab });
            },
          },
        ]
      : []),
    {
      id: 3,
      caption: `Export${isAllSelected || !selectedRows.length ? " All" : ""}`,
      icon: ActionIcons.export,
      onClick: exportCallback,
    },
  ];
};

export const getDocumentCardProps = (params: IDocumentCardParams): IDocumentCardProps => {
  const {
    cn,
    prefix,
    item,
    isSearchCard,
    handleOpenDocumentInfo,
    onFileNameClick,
    checkIsSubscribed,
    query,
    isExactPhrase,
  } = params;
  const cardHeaderProps = { cn, item, isSearchCard, query, isExactPhrase };

  switch (item?.sourceLibrary) {
    case LibraryName.pages:
      const page = item as ISearchSitePage;
      return {
        icon: renderCardIcon({ cn, prefix, icon: FileIcons.default, transformIcon: true }),
        header: renderPageCardHeader(cardHeaderProps),
        transformIcon: true,
        viewUrl: getFullFriendlyUrl(page?.FriendlyUrl),
      };

    case LibraryName.roles: {
      const role = item as IRole;
      const subscribeParams = getSubscribeParams({
        item: SubscribeItemTypes.role,
        document: role,
        checkIsSubscribed,
      });

      return {
        subscribeParams,
        icon: renderCardIcon({ cn, prefix, icon: FileIcons.role, transformIcon: true }),
        header: renderRoleCardHeader({
          ...cardHeaderProps,
          ...(isSearchCard ? { isSubscribed: subscribeParams.isSubscribed } : {}),
        }),
        viewUrl: `${window.location.origin}${role?.roleUrl}`,
        setInfoPanelCallback: handleOpenDocumentInfo(role),
        transformIcon: true,
      };
    }

    case LibraryName.certificates: {
      const certificate = item as ICertificate;
      const subscribeParams = getSubscribeParams({
        item: SubscribeItemTypes.document,
        document: certificate,
        checkIsSubscribed,
      });

      return {
        subscribeParams,
        icon: <SharePointImage src={certificate.iconUrl} className={cn(`${prefix}-icon`)} width={48} />,
        header: renderCertificateCardHeader({
          ...cardHeaderProps,
          ...(isSearchCard ? { isSubscribed: subscribeParams.isSubscribed } : {}),
          onFileNameClick,
        }),
        fileType: certificate?.fileInfo?.FileType,
        serverRelativeUrl: certificate?.fileInfo?.ServerRelativeUrl,
        viewUrl: certificate?.viewUrl,
        downloadUrl: certificate?.downloadUrl,
        friendlyUrl: certificate?.FriendlyUrl,
        setInfoPanelCallback: handleOpenDocumentInfo(certificate),
      };
    }

    case LibraryName.templates:
    case LibraryName.regulations: {
      const document = item as IRegulation;
      const subscribeParams = getSubscribeParams({
        item: SubscribeItemTypes.document,
        document,
        checkIsSubscribed,
      });

      const iconUrl = FileIcons[document?.fileInfo?.FileType] || FileIcons.default;

      return {
        subscribeParams,
        icon: renderCardIcon({ cn, prefix, icon: iconUrl }),
        header: renderRegulationTemplateCardHeader({
          ...cardHeaderProps,
          ...(isSearchCard ? { isSubscribed: subscribeParams.isSubscribed } : {}),
          onFileNameClick,
        }),
        fileType: document?.fileInfo?.FileType,
        serverRelativeUrl: document?.fileInfo?.ServerRelativeUrl,
        viewUrl: document?.viewUrl,
        downloadUrl: document?.downloadUrl,
        friendlyUrl: document?.FriendlyUrl,
        setInfoPanelCallback: handleOpenDocumentInfo(document),
      };
    }
  }
};

export const renderSearchPickerToggler = (
  props: PickerTogglerProps<{ id: string; label: string }, string>,
  cn: (b, e?, m?) => string,
) => {
  return (
    <div
      className={cn("picker-toggler", { "show-picker": true, isOpen: props.isOpen }, [
        "flex justify-start items-center cursor-pointer",
      ])}
      onClick={props.onClick}
      ref={props.ref}
      data-testid="show-picker-toggler"
    >
      <IconContainer icon={SearchIcon} cx={cn("picker-toggler-chevron-search")} />
      <Text size="36" cx={cn("picker-toggler-text-search")} font="sans-semibold" fontSize="16" lineHeight="24">
        {`${props.selection[0]?.value?.label}:`}
      </Text>
      <IconContainer cx={cn("picker-toggler-chevron-search")} icon={props.isOpen ? chevronUp : chevronDown} />
    </div>
  );
};

export const renderLimitPickerToggler = (
  props: PickerTogglerProps<{ id: string; label: string }>,
  cn: (b, e?, m?) => string,
  defaultValue: string,
) => (
  <>
    <div
      className={cn("picker-toggler", { "show-picker": true, isOpen: props.isOpen }, [
        "flex justify-start items-center cursor-pointer",
      ])}
      onClick={props.onClick}
      ref={props.ref}
      data-testid="limit-picker-toggler"
    >
      <Text size="36" color="white" font="sans-semibold" fontSize="14" lineHeight="24">
        <span className={cn("picker-toggler-prefix")}>Show </span>
        {props.selection[0]?.value?.label || defaultValue}
      </Text>
      <IconContainer cx={cn("picker-toggler-chevron")} icon={props.isOpen ? chevronUp : chevronDown} />
      <Text size="36" color="white" font="sans-semibold" fontSize="14" lineHeight="24">
        <span className={cn("picker-toggler-prefix")}>items</span>
      </Text>
    </div>
  </>
);

export const LIMITS_VALUES = [100, 250, 500];

export const getLimitsOptions = () => {
  const mapItem = (x: number) => ({ id: x.toString(), label: x.toString() });
  return new ArrayDataSource({ items: LIMITS_VALUES.map(mapItem) });
};
