import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";

import handleError from "Helpers/errorHandler";

import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => handleError(err, store),
});
const composeEnhancer = composeWithDevTools({});

const store: Store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export default store;
