import { getDocumentViewUrl, getFullFriendlyUrl, replacePageUrlPrefix } from "Helpers/utils";
import { ISpDocument } from "SP/documents/documents.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { ISearchItem, ISearchSitePage } from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

import { createHyperLink, exportToExcel, formatExcelWorkBook, formatRowsAndColumns, ITableLink } from "./helpers";

export interface IExportedSearchResult {
  Name: ITableLink;
  Title: string;
  Type: LibraryName | "Page" | "Roles";
}

export function exportSearchResultsToExcel(results: ISearchItem[], fileName: string): void {
  const items: IExportedSearchResult[] = [];

  if (!fileName || !results || !results.length) {
    return;
  }

  for (const item of results) {
    if (
      item.sourceLibrary === LibraryName.certificates ||
      item.sourceLibrary === LibraryName.regulations ||
      item.sourceLibrary === LibraryName.templates
    ) {
      const document = item as ISpDocument;
      items.push({
        Name: createHyperLink(document.fileInfo?.Name, getDocumentViewUrl(document.viewUrl, document.FriendlyUrl)),
        Title: document.Title,
        Type: item.sourceLibrary,
      });
    } else if (item.sourceLibrary === LibraryName.pages) {
      const page = item as ISearchSitePage;
      items.push({
        Name: createHyperLink(
          page.Title,
          page.FriendlyUrl ? getFullFriendlyUrl(page.FriendlyUrl) : replacePageUrlPrefix(page.Url),
        ),
        Title: page.Title,
        Type: "Page",
      });
    } else if (item.sourceLibrary === LibraryName.roles) {
      const role = item as IRole;

      items.push({
        Name: createHyperLink(role.Title, getDocumentViewUrl(role.roleUrl, null)),
        Title: role.Title,
        Type: "Roles",
      });
    }
  }

  const { rows, columns } = formatRowsAndColumns(items);
  const workbook = formatExcelWorkBook(rows, columns, fileName);

  exportToExcel(workbook, `${fileName}.xlsx`);
}
