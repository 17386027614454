import { analyticsHelper } from "./analyticsHelper";
import { IAnalyticsService } from "./AnalyticsService";

type IWindow = Window &
  typeof globalThis & {
    dataLayer: any[];
  };

export class GoogleAnalytics implements IAnalyticsService {
  searchTimeout = null;

  provideDocumentView(jobTitle: string, documentLink: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_document_view",
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      documentLink,
      documentName: analyticsHelper.getDocumentName(documentLink),
    });
  }

  provideSearchTerm(searchTerm: string, jobTitle: string, searchType: string): void {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      (window as IWindow).dataLayer.push({
        event: "search",
        searchTerm,
        userTitle: analyticsHelper.getUserTitle(jobTitle),
        searchType,
      });
    }, 2000);
  }

  provideFilter(filterKey: string, jobTitle: string) {
    (window as IWindow).dataLayer.push({
      event: "filter_panel",
      filterKey,
      pageLink: window.location.href,
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  providePageView(jobTitle: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_page_view",
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideLinkClick(link: string, jobTitle: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_link_click",
      userTitle: analyticsHelper.getUserTitle(jobTitle),
      linkValue: link,
    });
  }

  provideButtonClick(caption: string, jobTitle: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_button_click",
      buttonName: caption,
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideDownload(jobTitle: string, documentLink: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_download",
      documentLink: documentLink,
      documentName: analyticsHelper.getDocumentName(documentLink),
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }

  provideSessionStart(jobTitle: string) {
    (window as IWindow).dataLayer.push({
      event: "ecl_session_start",
      userTitle: analyticsHelper.getUserTitle(jobTitle),
    });
  }
}
