import { ArrayDataSource, DataRowProps, IModal } from "@epam/uui";
import { Icon } from "@epam/uui/types/objects";
import { PickerFooterProps, PickerTogglerProps } from "@epam/uui-components";

import { SubscriptionsTabType } from "Components/subscriptions/subscriptions.interfaces";
import { IFoldersInfo, IVoidCb } from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { FullPageComponentTypes, IAttachedFile } from "SP/sitePages/sitePages.types";

export enum ViewModes {
  "list" = "List View",
  "card" = "Card View",
}

export enum SampleValues {
  "first" = "First Item",
  "second" = "Second Item",
  "third" = "Third Item",
}

export enum Tabs {
  "documents" = "documents",
  "certificates" = "certificates",
}

export interface IDocumentsToolbarProps {
  isHasTabs: boolean;
  searchValue: string;
  showFilter: string;
  selectedRows: string[];
  pageType: FullPageComponentTypes;
  allData: IAttachedFile[];
  onSearchValueChange: (n: string) => void;
  onShowFilterChange: (n: string) => void;
  onViewModeChange: (n: ViewModes) => void;
  viewMode?: ViewModes;
  activeTab?: string;
  onTabChange?: (tabKey: string) => IVoidCb;
}

export interface DocumentsModalProps {
  cn: (b, e?, m?) => string;
  modalProps: IModal<string>;
  pickerProps: PickerFooterProps<IDataPicker, string>;
  pageType: FullPageComponentTypes;
  activeTab?: string;
}

export interface IDataPicker {
  id: string;
  label: string;
  additionalLabel?: string;
  icon?: Icon;
  parentId?: string;
}

export interface IPickerInputProps {
  dataSource: ArrayDataSource;
  value: string;
  onValueChange: (newValue) => void;
  renderToggler?: (props: PickerTogglerProps<IDataPicker, string>) => React.ReactNode;
  renderRow?: (props: DataRowProps<IDataPicker, string>) => React.ReactNode;
  renderFooter?: (props: PickerFooterProps<IDataPicker, string>) => React.ReactNode;
  minBodyWidth?: number;
}

export interface IToolbarAction {
  id: number;
  caption: string;
  icon: Icon;
  onClick: () => void;
}

export interface IGenerateShowFilterItemsParams {
  activeTab?: string;
  allRoles: IRole[];
  pageType: FullPageComponentTypes;
  documentTypeDescriptions: IFoldersInfo;
}

export interface IOnDownloadParams {
  items: IAttachedFile[];
  selectedRows: string[];
  docType: FullPageComponentTypes | SubscriptionsTabType;
}
