import { call, fork, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { GetAllDocumentsService } from "SP/documents/getAllDocuments";
import { IFoldersInfo } from "SP/helpers.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import { createZipWithFiles, getAllDocumentTypeDescriptions } from "SP/sp.helpers";
import {
  DocumentsActionsTypes,
  downloadDocumentsFailure,
  downloadDocumentsSuccess,
  getAllDocumentsFailure,
  getAllDocumentsSuccess,
  getDocumentTypeDescriptionsFailure,
  getDocumentTypeDescriptionsSuccess,
  IAllDocuments,
  IDownloadDocumentsAction,
} from "Store/actions/documents.actions";
import { IRootReducerState } from "Store/reducers";

import { getStandardsIcons } from "./helpers/utils.sagas";

const getAllDocumentsService = new GetAllDocumentsService();

const documentTypeDescriptions = (state: IRootReducerState) => state.documents.foldersInfo;

export function* getAllDocuments() {
  try {
    const icons = yield call(getStandardsIcons);
    const allDocuments: IAllDocuments = yield call([getAllDocumentsService, "getAllDocuments"], icons);

    yield put(getAllDocumentsSuccess(allDocuments, getAllDocumentsService.InvalidItemsCount));
  } catch (e) {
    yield put(getAllDocumentsFailure(e));
    throw e;
  }
}

export function* getDocumentTypeDescriptions() {
  try {
    let foldersInfo: IFoldersInfo = yield select(documentTypeDescriptions);

    if (foldersInfo[LibraryName.regulations].length === 0 && foldersInfo[LibraryName.templates].length === 0) {
      foldersInfo = yield call(getAllDocumentTypeDescriptions);
    }

    yield put(getDocumentTypeDescriptionsSuccess(foldersInfo));
  } catch (e) {
    yield put(getDocumentTypeDescriptionsFailure(e));
    throw e;
  }
}

export function* downloadDocuments(action: IDownloadDocumentsAction) {
  try {
    yield call(createZipWithFiles, action.documents, action.archiveName);
    yield put(downloadDocumentsSuccess());
  } catch (e) {
    yield put(downloadDocumentsFailure(e));
    throw e;
  }
}

export function* watchGetAllDocuments() {
  yield takeEvery(DocumentsActionsTypes.GET_ALL_DOCUMENTS_REQUEST, getAllDocuments);
}

export function* watchGetDocumentTypeDescriptions() {
  yield takeEvery(DocumentsActionsTypes.GET_DOCUMENT_TYPE_DESCRIPTIONS_REQUEST, getDocumentTypeDescriptions);
}

export function* watchDownloadDocuments() {
  yield takeLatest(DocumentsActionsTypes.DOWNLOAD_DOCUMENTS_REQUEST, downloadDocuments);
}

export default function* documentsSagas(): Iterator<ForkEffect> {
  yield fork(watchGetAllDocuments);
  yield fork(watchGetDocumentTypeDescriptions);
  yield fork(watchDownloadDocuments);
}
