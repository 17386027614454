import { ClassNameFormatter } from "@bem-react/classname";
import { Icon } from "@epam/uui";

import { IFilter } from "Components/documents-filter/documents-filter.interface";
import { IOnDownloadParams } from "Components/documents-toolbar/documents-toolbar.interface";
import { ISubscriptionItem, SubscriptionsTabType } from "Components/subscriptions/subscriptions.interfaces";
import { SubscribeItemTypes } from "Helpers/subscribeHelper";
import { ICheckIsSubscribed } from "Hooks/useUsers";
import { IVoidCb } from "SP/helpers.types";
import { ISearchItem } from "SP/search/search.types";
import { FullPageComponentTypes, IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { ISubscribeToItemToggleParams } from "Store/actions/users.actions";

export enum SearchTabType {
  Documents = "Documents",
  Pages = "Pages",
  Certificates = "Certificates",
  All = "All",
}

export interface ISearchFilters {
  filtersLibrary: LibraryName;
  infoPanelFilters: IFilter[];
}

export interface SearchResultCardProps {
  cn: ClassNameFormatter;
  query: string;
  item: ISearchItem;
}

export interface ISearchResultListProps {
  cn: ClassNameFormatter;
  query: string;
  items: ISearchItem[];
  activeTab: SearchTabType;
  selectedRows: string[];
  onCheckRowCallback: (uniqueId: string) => (isChecked: boolean) => void;
}

export interface ISearchPanelTabsProps {
  cn: ClassNameFormatter;
  activeTab: SearchTabType;
  onTabChange: (v: SearchTabType) => IVoidCb;
}

export interface ISearchToolbarProps {
  cn: ClassNameFormatter;
  selectedAll: boolean;
  hasSelected: boolean;
  indeterminate: boolean;
  checkboxLabel: string;
  onToggleAll: IVoidCb;
  exportCallback: IVoidCb;
}

export interface ICardIconParams {
  cn: ClassNameFormatter;
  prefix: string;
  icon: Icon | string;
  transformIcon?: boolean;
}

export interface ICardHeaderParams {
  cn: ClassNameFormatter;
  query?: string;
  item: ISearchItem | ISubscriptionItem;
  isExactPhrase?: boolean;
}

export interface IDocumentCardHeaderParams extends ICardHeaderParams {
  isSearchCard?: boolean;
  isSubscribed?: boolean;
  onFileNameClick?: (row: IAttachedFile) => IVoidCb;
}

export interface IDocumentCardProps {
  icon: React.ReactChild | React.ReactFragment | React.ReactPortal;
  header: React.ReactChild | React.ReactFragment | React.ReactPortal;
  viewUrl?: string;
  fileType?: string;
  downloadUrl?: string;
  friendlyUrl?: string;
  transformIcon?: boolean;
  subscribeParams?: ISubscribeToItemToggleParams;
  serverRelativeUrl?: string;
  setInfoPanelCallback?: IVoidCb;
}

export interface IDocumentCardParams {
  cn: ClassNameFormatter;
  prefix: string;
  item: ISearchItem | ISubscriptionItem;
  handleOpenDocumentInfo: (item) => IVoidCb;
  onFileNameClick: (row: IAttachedFile) => IVoidCb;
  checkIsSubscribed: ICheckIsSubscribed;
  isSearchCard?: boolean;
  isExactPhrase?: boolean;
  query?: string;
}

export interface IToolbarActionParams {
  items: IAttachedFile[];
  itemType: SubscribeItemTypes;
  isDownloadButtonPresent: boolean;
  isNeedToSubscribe: boolean;
  isAllSelected: boolean;
  selectedRows: string[];
  docType: FullPageComponentTypes | SubscriptionsTabType;
  subscriptionsLoading: boolean;
  onDownload: (params: IOnDownloadParams) => void;
  handleSubscribe: (cb: IVoidCb) => void;
  exportCallback: () => void;
  userTitle: string;
}
