import { getServerRelativeUrl } from "Helpers/utils";

export enum ENVS {
  prod = "production",
  preprod = "preprod",
  qa = "qa",
  dev = "development",
  test = "test",
}

export const getEnvironment = () => {
  const ServerRelativeUrl = getServerRelativeUrl();
  const url = `${ServerRelativeUrl}`.toLocaleLowerCase();
  if (url.endsWith("policy")) {
    return ENVS.prod;
  } else if (url.endsWith("process")) {
    return ENVS.preprod;
  } else if (url.endsWith("policy-qa")) {
    return ENVS.qa;
  } else if (process.env.NODE_ENV === "test") {
    return ENVS.test;
  } else {
    return ENVS.dev;
  }
};
