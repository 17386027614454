import { Tabs, ViewModes } from "Components/documents-toolbar/documents-toolbar.interface";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import {
  FullPageComponentTypes,
  IAttachedFile,
  IDoubleListData,
  IRolesRegistryListData,
  ISingleListData,
  ISingleListFilterItem,
  SortColumns,
} from "SP/sitePages/sitePages.types";
import { IAllDocuments } from "Store/actions/documents.actions";

export type IDataType = ISingleListData | IDoubleListData | IRolesRegistryListData;

export enum ToolbarKeys {
  "activeTab" = "activeTab",
  "searchType" = "searchType",
  "searchQuery" = "searchQuery",
  "show" = "show",
  "sort" = "sort",
}

export interface IDocumentsViewProps {
  pageType: FullPageComponentTypes;
  listData: IDataType;
  sitePageUrl: string;
  view?: ViewModes;
}

export interface IDefaultSort {
  [key: string]: { field: SortColumns; asc: boolean };
}

export interface IGetViewDataParams {
  pageType: FullPageComponentTypes;
  allDocuments: IAllDocuments;
  allRoles: IRole[];
  listData: IDataType;
  defaultShowFilter: string;
}

export interface IGetViewDataFiltersParams {
  pageType: FullPageComponentTypes;
  data: IAttachedFile[];
  filterPaneItems: ISingleListFilterItem[];
  activeTab: Tabs;
}
