import _isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";

import { DocumentsViewContext } from "@App/context/DocumentsViewContext";

import { DocumentsCard } from "Components/documents-card";
import {
  useFiltersActions,
  useFiltersData,
  useFiltersMethods,
} from "Components/documents-filter/documents-filter.hooks";
import { DocumentsTable } from "Components/documents-table";
import { IColumn, IDocumentsTableProps, ISort } from "Components/documents-table/documents-table.interface";
import { DocumentsToolbar } from "Components/documents-toolbar";
import {
  corporateGovernanceId,
  getShowFilterDefaultItems,
  isCertificates,
  isRolesRegistry,
} from "Components/documents-toolbar/documents-toolbar.helpers";
import { Tabs, ViewModes } from "Components/documents-toolbar/documents-toolbar.interface";
import {
  getArrayDataByTab,
  getStringDataByTab,
  getViewData,
  getViewDataFilters,
} from "Components/documents-view/documents-view.helpers";
import { IDefaultSort, IDocumentsViewProps, ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { Links } from "Helpers/constants";
import highlight from "Helpers/highlight";
import { isNotEmpty } from "Helpers/utils";
import { useDocumentsData } from "Hooks/useDocuments";
import { useDocumentsSort } from "Hooks/useDocumentsSort";
import useQuery from "Hooks/useQuery";
import { useRowsCheck } from "Hooks/useRowsCheck";
import { useDocumentsSearch } from "Hooks/useSearch";
import { useUsersData } from "Hooks/useUsers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import {
  FullPageComponentTypes,
  IAttachedFile,
  ISingleListFilterItem,
  LibraryName,
} from "SP/sitePages/sitePages.types";
import { getAllRoles } from "Store/actions/general.actions";
import { ISelectedFilter } from "Store/reducers/filters.reducer";

const defaultShowFilterByPages = {
  [Links.corporateGovernance]: corporateGovernanceId,
};

const defaultViewMode = {
  [FullPageComponentTypes.RegulationsByDocumentType]: ViewModes.list,
  [FullPageComponentTypes.Regulations]: ViewModes.list,
  [FullPageComponentTypes.Templates]: ViewModes.list,
  [FullPageComponentTypes.Certificates]: ViewModes.list,
  [FullPageComponentTypes.RolesRegistry]: ViewModes.card,
};

const defaultSort: IDefaultSort = {
  [FullPageComponentTypes.RegulationsByDocumentType]: { field: "Document", asc: true },
  [FullPageComponentTypes.Regulations]: { field: "Document", asc: true },
  [FullPageComponentTypes.Templates]: { field: "Document", asc: true },
  [FullPageComponentTypes.Certificates]: { field: "Document", asc: true },
  [FullPageComponentTypes.RolesRegistry]: { field: "RoleName", asc: true },
};

export const DocumentsView: FC<IDocumentsViewProps> = ({
  pageType,
  listData,
  sitePageUrl,
  view /* view property needed only for testing reasons */,
}) => {
  const initializeRef = useRef(false);
  const { hasParams, query, setQuery, clearQuery } = useQuery();
  const showFilterItems = getShowFilterDefaultItems();
  const defaultShowFilterByPage = defaultShowFilterByPages[`/${sitePageUrl}`];

  const [activeTab, setActiveTab] = useState<Tabs>((query.activeTab as Tabs) || Tabs.documents);
  const [viewMode, setViewMode] = useState<ViewModes>(view || defaultViewMode[pageType]);
  const [showFilter, setShowFilter] = useState<string>(
    (query.show as string) || defaultShowFilterByPage || showFilterItems.all.id,
  );
  const [searchValue, setSearchValue] = useState<string>((query.searchQuery as string) || "");

  const { allDocuments, isDocumentsLoaded, invalidItemsCount } = useDocumentsData();

  const { allSelectedFilters, activePresetFilters, isResetDocumentViewToggle } = useFiltersData();
  const {
    setInfoPanelFilters,
    setFiltersLibrary,
    resetActivePreset,
    resetFilters,
    setIsPageToggle,
    getFilterByGuid,
    addFiltersGroup,
  } = useFiltersMethods();
  const { setFilter } = useFiltersActions();

  const { checkIsSubscribed } = useUsersData();

  const {
    allListsSearchResults,
    isListSearchResultsLoaded,
    getListSearchResults,
    clearListSearchResults,
    resetListSearchResults,
  } = useDocumentsSearch();

  const { getFilteredDataList, setFilterGroupsResults } = useFiltersMethods();

  const groupIndex = 0;

  const { filtersLibrary, filters, columns, viewDataList, originalViewDataList } = useMemo(() => {
    const viewData = getViewData({
      pageType,
      allDocuments,
      allRoles: [],
      listData,
      defaultShowFilter: defaultShowFilterByPage,
    });
    const viewDataList = getArrayDataByTab<IAttachedFile>(viewData.data, activeTab);
    const filterPaneItems = getArrayDataByTab<ISingleListFilterItem>(viewData.filterPaneItems, activeTab);
    const filtersLibrary = getStringDataByTab<LibraryName>(viewData.filtersLibrary, activeTab);

    const listSearchResultsByLibrary = allListsSearchResults[filtersLibrary];
    const dataList = listSearchResultsByLibrary || viewDataList;
    const filteredViewDataList = getFilteredDataList(
      dataList,
      allSelectedFilters[filtersLibrary] || [],
      checkIsSubscribed,
    );
    setFilterGroupsResults(filteredViewDataList, allSelectedFilters[filtersLibrary]);

    return {
      filtersLibrary,
      filters: getViewDataFilters({
        pageType,
        activeTab,
        filterPaneItems,
        data: dataList,
      }),
      columns: getArrayDataByTab<IColumn>(viewData.columns, activeTab),
      viewDataList: filteredViewDataList,
      originalViewDataList: viewDataList,
    };
  }, [pageType, listData, allDocuments, activeTab, allSelectedFilters, allListsSearchResults]);

  const isHasTabs = pageType === FullPageComponentTypes.RegulationsByDocumentType;
  const isRolesRegistryPage = isRolesRegistry(pageType);
  const isLibraryDataLoaded = isDocumentsLoaded;

  const { sortedData, sort, setSort } = useDocumentsSort(viewDataList, (query.sort as ISort) || defaultSort[pageType]);
  const { indeterminate, selectedRows, resetRows, onCheckToggle, onToggleAll } =
    useRowsCheck<IAttachedFile>(viewDataList);

  const { currentUser } = useUsersData();

  const onShowFilterChange = (newShowFilter: string) => {
    analyticsHelper.analyticsService?.provideButtonClick("Show", currentUser?.jobTitle);
    setShowFilter(newShowFilter);
    setFilter(ToolbarKeys.show, newShowFilter, groupIndex);
  };

  const onSearchValueChange = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
    setFilter(ToolbarKeys.searchQuery, newSearchValue, groupIndex);

    if (newSearchValue) {
      setSort(null);
      getListSearchResults(newSearchValue, filtersLibrary, originalViewDataList);
    } else {
      setSort(defaultSort[pageType]);
      clearListSearchResults(filtersLibrary);
    }
  };

  const onSortChange = (sort: ISort) => {
    setSort(sort);
    setFilter(ToolbarKeys.sort, sort, groupIndex);
  };

  const onTabChange = (tabKey: string) => () => {
    if (tabKey == activeTab) return;

    const newActiveTab = tabKey as Tabs;

    resetRows();
    setSort(defaultSort[pageType]);
    setSearchValue("");
    setShowFilter(showFilterItems.all.id);
    setActiveTab(newActiveTab);
    if (allSelectedFilters[filtersLibrary].length === 0) {
      addFiltersGroup("Filter Group 1");
    }

    setQuery({ activeTab: newActiveTab });
  };

  const handleSetNewFilters = (newFilters: ISelectedFilter) => {
    if (!_isEmpty(newFilters)) {
      setSort(newFilters.sort as ISort);
      setSearchValue((newFilters.searchQuery as string) || "");
      setShowFilter((newFilters.show as string) || showFilterItems.all.id);
    }
  };

  const handleSetDocumentViewState = (newFilter: IFavoriteFilter) => {
    const newFilters = newFilter?.filtersGroups[0]?.filters;
    if (_isEmpty(newFilter)) {
      clearQuery(isHasTabs ? [ToolbarKeys.activeTab] : null);
      setSearchValue("");
      setShowFilter(showFilterItems.all.id);
      setSort(defaultSort[pageType]);
    } else {
      handleSetNewFilters(newFilters || {});
    }

    if (newFilter?.guid) {
      setQuery({ filterGuid: newFilter.guid });
    }
  };

  useEffect(() => {
    const newSelectedFilters = allSelectedFilters[filtersLibrary];

    setFiltersLibrary(filtersLibrary);
    handleSetNewFilters(newSelectedFilters[0]?.filters || {});
  }, [filtersLibrary]);

  useEffect(() => {
    if (initializeRef.current) {
      handleSetDocumentViewState(activePresetFilters);
    }
  }, [activePresetFilters, isResetDocumentViewToggle]);

  useEffect(() => {
    if (isNotEmpty(filters[0]?.data)) {
      setInfoPanelFilters(filters);
    }
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      resetRows();
    }
  }, [allSelectedFilters[filtersLibrary]]);

  useEffect(() => {
    if (query?.searchQuery && isLibraryDataLoaded) {
      setSort(null);
      getListSearchResults(query.searchQuery as string, filtersLibrary, originalViewDataList);
    }
  }, [isLibraryDataLoaded]);

  useEffect(() => {
    if (hasParams && query.filterGuid) {
      getFilterByGuid(query.filterGuid as string, handleSetDocumentViewState);
    }

    if (isRolesRegistryPage) {
      setIsPageToggle("isRolesRegistryPage", true);
      getAllRoles();
    }

    initializeRef.current = true;

    return () => {
      if (isRolesRegistryPage) {
        setIsPageToggle("isRolesRegistryPage", false);
      }

      resetListSearchResults();
      resetActivePreset(true);
      resetFilters(true);
      setFiltersLibrary(null);
      highlight.clear();
    };
  }, []);

  const props: IDocumentsTableProps = {
    isCertificates: isCertificates(pageType, activeTab),
    isRolesRegistry: isRolesRegistryPage,
    isOverWall: !isHasTabs,
    sort,
    indeterminate,
    selectedRows,
    columns,
    onSortChange,
    onCheckToggle,
    onToggleAll,
    data: sortedData,
    isDataLoaded: isLibraryDataLoaded && (!searchValue || isListSearchResultsLoaded),
  };

  return (
    <DocumentsViewContext.Provider value={{ searchValue }}>
      <ReactTooltip place="right" className="tooltip" effect="solid" clickable={true} delayHide={200} />
      <DocumentsToolbar
        isHasTabs={isHasTabs}
        viewMode={isRolesRegistryPage ? null : viewMode}
        searchValue={searchValue}
        showFilter={showFilter}
        selectedRows={selectedRows}
        allData={viewDataList}
        pageType={pageType}
        onSearchValueChange={onSearchValueChange}
        onShowFilterChange={onShowFilterChange}
        onViewModeChange={setViewMode}
        {...(isHasTabs ? { activeTab, onTabChange } : {})}
      />
      {viewMode === ViewModes.list && <DocumentsTable key={activeTab} {...props} />}
      {viewMode === ViewModes.card && <DocumentsCard key={activeTab} {...props} />}
    </DocumentsViewContext.Provider>
  );
};
