import { IColumn } from "Components/documents-table/documents-table.interface";

export const rolesColumns: IColumn[] = [
  {
    key: "RoleName",
    caption: "Role Name",
    isSortable: true,
  },
  {
    key: "RoleCategory",
    caption: "Role Category",
    isSortable: false,
  },
  {
    key: "Group",
    caption: "Group",
    isSortable: false,
  },
];
