import { ISearchServiceResult } from "SP/search/search.service";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";
import { SearchTypes } from "Store/reducers/search.reducer";

export enum SearchActionsTypes {
  GLOBAL_SEARCH_RESULTS_REQUEST = "[SEARCH] GLOBAL SEARCH RESULTS REQUEST",
  GLOBAL_SEARCH_RESULTS_SUCCESS = "[SEARCH] GLOBAL SEARCH RESULTS SUCCESS",
  GLOBAL_SEARCH_RESULTS_FAILURE = "[SEARCH] GLOBAL SEARCH RESULTS FAILURE",

  RESET_GLOBAL_SEARCH_RESULTS = "[SEARCH] RESET GLOBAL SEARCH RESULTS",

  LIST_SEARCH_RESULTS_REQUEST = "[SEARCH] LIST SEARCH RESULTS REQUEST",
  LIST_SEARCH_RESULTS_SUCCESS = "[SEARCH] LIST SEARCH RESULTS SUCCESS",
  LIST_SEARCH_RESULTS_FAILURE = "[SEARCH] LIST SEARCH RESULTS FAILURE",

  CLEAR_LIST_SEARCH_RESULTS = "[SEARCH] CLEAR LIST SEARCH RESULTS",
  RESET_LIST_SEARCH_RESULTS = "[SEARCH] RESET LIST SEARCH RESULTS",

  SET_SEARCH_TYPE = "[SEARCH] SET SEARCH TYPE",
  SET_SEARCH_QUERY = "[SEARCH] SET SEARCH QUERY",

  REFINEMENT_SEARCH_REQUEST = "[SEARCH] REFINEMENT SEARCH REQUEST",
  REFINEMENT_SEARCH_SUCCESS = "[SEARCH] REFINEMENT SEARCH SUCCESS",
  REFINEMENT_SEARCH_FAILURE = "[SEARCH] REFINEMENT SEARCH FAILURE",

  SET_ROWS_LIMIT = "[SEARCH] SET ROWS LIMIT",
}

// GLOBAL SEARCH RESULTS

export interface IGlobalSearchResultsRequestAction {
  type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_REQUEST;
  query: string;
}

export function getGlobalSearchResults(query): IGlobalSearchResultsRequestAction {
  return {
    type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_REQUEST,
    query,
  };
}

export interface IGlobalSearchResultsSuccessAction {
  type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_SUCCESS;
  payload: ISearchServiceResult;
}

export function getGlobalSearchResultsSuccess(payload: ISearchServiceResult): IGlobalSearchResultsSuccessAction {
  return {
    type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_SUCCESS,
    payload,
  };
}

export interface IGlobalSearchResultsFailureAction {
  type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_FAILURE;
  error: string;
}

export function getGlobalSearchResultsFailure(error): IGlobalSearchResultsFailureAction {
  return {
    type: SearchActionsTypes.GLOBAL_SEARCH_RESULTS_FAILURE,
    error: error.message,
  };
}

export interface IResetGlobalSearchResultsAction {
  type: SearchActionsTypes.RESET_GLOBAL_SEARCH_RESULTS;
}

export function resetGlobalSearchResults(): IResetGlobalSearchResultsAction {
  return {
    type: SearchActionsTypes.RESET_GLOBAL_SEARCH_RESULTS,
  };
}

// LIST SEARCH RESULTS
export interface IListSearchResultsRequestAction {
  type: SearchActionsTypes.LIST_SEARCH_RESULTS_REQUEST;
  query: string;
  list: LibraryName;
  listItems: IAttachedFile[];
}

export function getListSearchResults(
  query: string,
  list: LibraryName,
  listItems: IAttachedFile[],
): IListSearchResultsRequestAction {
  return {
    type: SearchActionsTypes.LIST_SEARCH_RESULTS_REQUEST,
    query,
    list,
    listItems,
  };
}

export interface IListSearchResultsSuccessAction {
  type: SearchActionsTypes.LIST_SEARCH_RESULTS_SUCCESS;
  list: LibraryName;
  payload: IAttachedFile[];
}

export function getListSearchResultsSuccess(
  list: LibraryName,
  payload: IAttachedFile[],
): IListSearchResultsSuccessAction {
  return {
    type: SearchActionsTypes.LIST_SEARCH_RESULTS_SUCCESS,
    list,
    payload,
  };
}

export interface IListSearchResultsFailureAction {
  type: SearchActionsTypes.LIST_SEARCH_RESULTS_FAILURE;
  error: string;
}

export function getListSearchResultsFailure(error): IListSearchResultsFailureAction {
  return {
    type: SearchActionsTypes.LIST_SEARCH_RESULTS_FAILURE,
    error: error.message,
  };
}

export interface IClearListSearchResultsAction {
  type: SearchActionsTypes.CLEAR_LIST_SEARCH_RESULTS;
  list: LibraryName;
}

export function clearListSearchResults(list: LibraryName): IClearListSearchResultsAction {
  return {
    type: SearchActionsTypes.CLEAR_LIST_SEARCH_RESULTS,
    list,
  };
}

export interface IResetListSearchResultsAction {
  type: SearchActionsTypes.RESET_LIST_SEARCH_RESULTS;
}

export function resetListSearchResults(): IResetListSearchResultsAction {
  return {
    type: SearchActionsTypes.RESET_LIST_SEARCH_RESULTS,
  };
}

export interface ISetSearchTypeAction {
  type: SearchActionsTypes.SET_SEARCH_TYPE;
  payload: SearchTypes;
}

export function setSearchType(type: SearchTypes): ISetSearchTypeAction {
  return {
    type: SearchActionsTypes.SET_SEARCH_TYPE,
    payload: type,
  };
}

export interface ISetSearchQueryAction {
  type: SearchActionsTypes.SET_SEARCH_QUERY;
  payload: string;
}

export function setSearchQuery(query: string): ISetSearchQueryAction {
  return {
    type: SearchActionsTypes.SET_SEARCH_QUERY,
    payload: query,
  };
}

export interface IRefinementSearchRequest {
  type: SearchActionsTypes.REFINEMENT_SEARCH_REQUEST;
}

export function refinementSearchRequest() {
  return { type: SearchActionsTypes.REFINEMENT_SEARCH_REQUEST };
}

export interface IRefinementSearchSuccess {
  type: SearchActionsTypes.REFINEMENT_SEARCH_SUCCESS;
  payload: ISearchServiceResult;
}

export function refinementSearchSuccess(payload: ISearchServiceResult): IRefinementSearchSuccess {
  return {
    type: SearchActionsTypes.REFINEMENT_SEARCH_SUCCESS,
    payload,
  };
}

export interface IRefinementSearchFailure {
  type: SearchActionsTypes.REFINEMENT_SEARCH_FAILURE;
  error: string;
}

export function refinementSearchFailure(error): IRefinementSearchFailure {
  return {
    type: SearchActionsTypes.REFINEMENT_SEARCH_FAILURE,
    error,
  };
}

export interface ISetRowsLimitAction {
  type: SearchActionsTypes.SET_ROWS_LIMIT;
  payload: number;
}

export function setRowsLimit(rowsLimit: number): ISetRowsLimitAction {
  return {
    type: SearchActionsTypes.SET_ROWS_LIMIT,
    payload: rowsLimit,
  };
}

export type ISearchActionsTypes =
  | IGlobalSearchResultsRequestAction
  | IGlobalSearchResultsSuccessAction
  | IGlobalSearchResultsFailureAction
  | IResetGlobalSearchResultsAction
  | IListSearchResultsRequestAction
  | IListSearchResultsSuccessAction
  | IListSearchResultsFailureAction
  | IClearListSearchResultsAction
  | IResetListSearchResultsAction
  | ISetSearchTypeAction
  | ISetSearchQueryAction
  | IRefinementSearchRequest
  | IRefinementSearchSuccess
  | IRefinementSearchFailure
  | ISetRowsLimitAction;
