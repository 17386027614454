import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import {
  IFileInfo,
  IHyperLinkField,
  IItemTermInfo,
  ISpUser,
  IUnique,
  IUserLookupField,
  IVisibleProp,
} from "SP/helpers.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { ISearchInfo } from "SP/search/search.types";
import { ISPTermObject } from "SP/SPTermStoreService";
import { IUser } from "Store/actions/users.actions";

/**
 * Interface for item in Site Pages library
 */
export interface ISitePage {
  readonly Id: number;
  Title: string;
  readonly Created: Date;
  readonly Author: IUser;
  readonly Modified: Date;
  readonly Editor: IUser;
  readonly CheckoutUser: IUser;
  readonly fileInfo: IFileInfo;
  readonly redirectToPage: string;
  parentNavigationTermSets: IItemTermInfo[];
  pageInfo: IPageInfo;
  breadcrumbItems: IHyperLinkField[];
  readonly sourceLibrary: LibraryName;
  readonly friendlyUrl: string;
}

export enum LibraryName {
  "all" = "All", // Only used for filter purposes
  "regulationsAndTemplates" = "regulationsAndTemplates", // Only used for search purposes
  "regulations" = "EPAM Regulations",
  "templates" = "EPAM Templates",
  "certificates" = "EPAM Certificates",
  "pages" = "Site Pages",
  "roles" = "Roles Registry",
}

export type IAttachedFile = Partial<IRegulation & ICertificate & IRole & ISearchInfo> & IUnique;

export interface IAttachedFiles {
  library: LibraryName;
  items: number[];
}

export enum FullPageComponentTypes {
  "LandingPage" = "LandingPage",
  "RolesRegistry" = "RolesRegistry",
  "Certificates" = "Certificates",
  "Templates" = "Templates",
  "Regulations" = "Regulations",
  "RegulationsByDocumentType" = "RegulationsByDocumentType",
}

/**
 * Interface with Page item information
 */
export interface IPageInfo {
  attachedFiles: IAttachedFiles;
  showSubscribeButton: boolean;
  fullPageComponent?: {
    type: FullPageComponentTypes;
    data?: IRolesRegistryListData | ISingleListData | IDoubleListData;
  };
  sections?: ISection[];
}
/**
 * Interface for Roles Registry list
 */
export interface IRolesRegistryListData {
  filterPaneItems: IRolesRegistryListFilterItem[];
  columns: IRolesRegistryColumn[];
}
export interface IRolesRegistryListFilterItem extends IVisibleProp {
  prop: RolesRegistryFilterColumns;
}
export interface IRolesRegistryColumn extends IVisibleProp {
  prop: RolesRegistryColumns;
}
/**
 * Interface for single list: Templates, Regulations, Certificates
 */
export interface ISingleListData {
  list: LibraryName;
  filterPaneItems: ISingleListFilterItem[];
  columns: ISingleListColumn[];
}
export interface ISingleListFilterItem extends IVisibleProp {
  prop: DocumentsFilterColumns | CertificatesFilterColumns;
}
export interface ISingleListColumn extends IVisibleProp {
  prop: DocumentsColumns | CertificatesColumns;
}
/**
 * List columns for Certificates single list or Certificates tab in Reg by Doc Type page
 */
export type CertificatesColumns =
  | "Document"
  | "EPAMDocumentID"
  | "Standard"
  | "CertificationBody"
  | "LocationCountry"
  | "LocationCity"
  | "IssueDate"
  | "ValidFrom"
  | "ValidUntil";
/**
 * List columns for Regulations, Templates single list or Regulations and Templates tab in Reg by Doc Type page
 */
export type DocumentsColumns =
  | "Document"
  | "EPAMDocumentID"
  | "DocumentArea"
  | "LocationCountry"
  | "OrganizationalUnit"
  | "ApprovalDate";
/**
 * List columns for Roles single list
 */
export type RolesRegistryColumns = "RoleName" | "RoleCategory" | "Group" | "Status";
/**
 * Filter columns for Regulations, Templates single list or Regulations and Templates tab in Reg by Doc Type page
 */
export type DocumentsFilterColumns = "Title" | "SubjectDoc" | "DocumentArea" | "OrganizationalUnit" | "ApprovalDate";
/**
 * Filter columns for Certificates single list or Certificates tab in Reg by Doc Type page
 */
export type CertificatesFilterColumns =
  | "Title"
  | "ValidFrom"
  | "ValidUntil"
  | "Standard"
  | "LocationCity"
  | "LocationCountry"
  | "IssueDate"
  | "CertificationBody";
/**
 * Filter columns for Roles Registry list
 */
export type RolesRegistryFilterColumns =
  | "RoleName"
  | "RoleCategory"
  | "Group"
  | "Status"
  | "PublishedDate"
  | "Area"
  | "Regulations";
/**
 * Interface for Regulations by Document Type
 */
export interface IDoubleListData {
  documents: ISingleListData;
  certificates: ISingleListData;
}

export type SortColumns = DocumentsColumns | CertificatesColumns | RolesRegistryColumns;

export enum SectionTypes {
  "OneColumnLayout" = "OneColumnLayout",
  "TwoColumnsLayout" = "TwoColumnsLayout",
  "ThreeColumnsLayout" = "ThreeColumnsLayout",
  "OneThirdLeftLayout" = "OneThirdLeftLayout",
  "OneThirdRightLayout" = "OneThirdRightLayout",
}

export enum WebPartTypes {
  "RichTextEditorWebPart" = "RichTextEditorWebPart",
  "ParentRichTextEditorWebPart" = "ParentRichTextEditorWebPart",
}

export interface ISection {
  type: SectionTypes;
  data: IOneColumnLayout | ITwoColumnsLayout | IThreeColumnsLayout | IOneThirdLeftLayout | IOneThirdRightLayout;
}
export interface IOneColumnLayout {
  webParts: IWebPart[];
}
export interface ITwoColumnsLayout {
  left: IWebPart[];
  right: IWebPart[];
}
export interface IThreeColumnsLayout extends ITwoColumnsLayout {
  center: IWebPart[];
}
export interface IOneThirdLeftLayout {
  left: IWebPart[];
  right: IWebPart[];
}
export interface IOneThirdRightLayout {
  left: IWebPart[];
  right: IWebPart[];
}
/**
 * Interface with added web part
 */
export interface IWebPart {
  type: WebPartTypes;
  data: IRichTextEditorWebPart | IParentRichTextEditorWebPart;
}
/**
 * Interface with rich text editor webpart
 */
export interface IRichTextEditorWebPart {
  markup: string;
  surroundedBy: "accordion" | "card" | undefined;
  accordionTitle: string;
  displayDividers?: boolean;
  showCurtain?: boolean;
}
/**
 * Interface with rich text editor for parent page
 */
export interface IParentRichTextEditorWebPart {
  markup: string;
  webPartTitle: string;
  webPartTitleLink?: string;
  webPartLinkColor: string;
}
export interface ISitePageDTO {
  readonly Id: number;
  readonly Title: string;
  readonly Created: string;
  readonly Author: ISpUser;
  readonly Modified: string;
  readonly Editor: ISpUser;
  readonly CheckoutUser: ISpUser;
  readonly File: IFileInfo;
  readonly parentNavigationTermSets: IItemTermInfo[];
  readonly pageInfo: string;
  readonly PageTextContent: string;
  readonly File_x0020_Type: string;
  readonly redirectToPage: string;
  readonly VersionLabel: string;
  readonly FriendlyURL: string;
}

export interface ISitePageVersionDTO {
  readonly Title: string;
  readonly Modified: string;
  readonly Editor: IUserLookupField;
  readonly pageInfo: string;
  readonly VersionLabel: string;
  readonly OData__x005f_CheckinComment: string;
}

export interface ISitePageVersion {
  readonly Title: string;
  readonly Modified: Date;
  readonly Editor: IUser;
  readonly pageInfo: IPageInfo;
  readonly VersionLabel: string;
  readonly CheckInComment: string;
}

export enum PageType {
  Parent = "Parent",
  Child = "Child",
}

export interface INewSitePageInfo {
  folderServerRelativeUrl: string;
  pageTitle: string;
  pageType: PageType;
  selectedTerms: ISPTermObject[];
}
