export enum AnalyticsActionsTypes {
  SET_SESSION_START_SENT = "[ANALYTICS] SET SESSION START SENT",
}

export interface ISetSessionStartAction {
  type: AnalyticsActionsTypes.SET_SESSION_START_SENT;
  payload: boolean;
}

export function setSessionStartSent(payload: boolean): ISetSessionStartAction {
  return {
    type: AnalyticsActionsTypes.SET_SESSION_START_SENT,
    payload,
  };
}

export type IAnalyticsActionTypes = ISetSessionStartAction;
