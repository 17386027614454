import produce from "immer";
import { Reducer } from "redux";

import { setSitePageValue } from "Helpers/sitePageUpdater";
import { ILibraryFolder } from "SP/helpers.types";
import { IAttachedFile, ISitePage, ISitePageVersion } from "SP/sitePages/sitePages.types";

import { ISitePagesActionsTypes, SitePagesActionsTypes } from "../actions/site-pages.actions";

export type ISitePages = { [key: string]: ISitePage };

export type IAttachedFiles = { [key: string]: IAttachedFile[] };

export type ISitePageVersions = {
  [key: string]: { currentIndex: number; items: ISitePageVersion[] };
};

export enum DetailTypes {
  "main" = "main",
  "section" = "section",
  "card" = "card",
  "parentCard" = "parentCard",
}

export type ISelectedDetailInfo = {
  type: DetailTypes;
  sectionIndex?: number;
  webPartIndex?: number;
  column?: string;
};

export interface ISitePagesState {
  sitePages: ISitePages;
  isSitePageInVersionHistoryMode: boolean;
  isSitePageInEditMode: boolean;
  sitePageInValidProperties: Set<string>;
  editPageBackup: ISitePage | null;
  selectedDetailInfo: ISelectedDetailInfo;
  loading: boolean;
  sitePagesHierarchy: ILibraryFolder[];
  files: IAttachedFiles;
  versions: ISitePageVersions;
  filesLoading: Record<string, boolean>;
}

const initialState: ISitePagesState = {
  sitePages: {},
  isSitePageInVersionHistoryMode: false,
  isSitePageInEditMode: false,
  sitePageInValidProperties: new Set(),
  editPageBackup: null,
  selectedDetailInfo: {
    type: DetailTypes.main,
  },
  loading: false,
  sitePagesHierarchy: [],
  files: {},
  versions: {},
  filesLoading: {},
};

const resetSelectedDetailInfo = (draft) => {
  draft.selectedDetailInfo = {
    type: DetailTypes.main,
  };
};

const resetSitePage = (draft, sitePageUrl) => {
  if (draft.editPageBackup) {
    draft.sitePages[sitePageUrl] = draft.editPageBackup;
    draft.editPageBackup = null;
  }
};

const finishSitePageEdit = (draft) => {
  draft.isSitePageInEditMode = false;
  draft.sitePageInValidProperties.clear();
  draft.editPageBackup = null;
  draft.loading = false;
};

const sitePagesReducer: Reducer<ISitePagesState, ISitePagesActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case SitePagesActionsTypes.GET_SITE_PAGE_BY_FRIENDLY_URL:
    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL:
    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE:
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE:
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE:
    case SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS:
    case SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_REQUEST:
    case SitePagesActionsTypes.ADD_NEW_SITE_PAGE_REQUEST:
    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE:
    case SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION:
      draft.loading = true;
      break;

    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE:
    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_FAILURE:
    case SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_FAILURE:
    case SitePagesActionsTypes.ADD_NEW_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE:
    case SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_FAILURE:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_FAILURE:
      draft.loading = false;
      break;

    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS:
      draft.loading = false;
      draft.versions[action.pageUrl] = {
        currentIndex: 0,
        items: action.versions,
      };
      draft.isSitePageInVersionHistoryMode = true;
      break;

    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS:
      draft.loading = false;
      draft.isSitePageInVersionHistoryMode = false;
      draft.sitePages[action.pageUrl].pageInfo = action.page.pageInfo;
      draft.versions[action.pageUrl] = null;
      break;

    case SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_SUCCESS:
      draft.versions[action.pageUrl].currentIndex = action.versionIndex;
      draft.loading = false;
      break;

    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS:
      draft.filesLoading[action.payload.url] = true;
      draft.loading = false;
      draft.sitePages[action.payload.url] = action.payload.sitePage;
      break;

    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS:
      draft.sitePages[action.sitePageUrl].CheckoutUser = action.currentUser;
      draft.editPageBackup.CheckoutUser = action.currentUser;
      draft.loading = false;
      break;
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS:
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS:
      draft.sitePages[action.sitePageUrl] = action.sitePage;
      finishSitePageEdit(draft);
      resetSelectedDetailInfo(draft);
      break;
    case SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_SUCCESS:
      draft.loading = false;
      break;

    case SitePagesActionsTypes.UPDATE_SITE_PAGE:
      setSitePageValue(draft.sitePages, action.pathKeys, action.sitePageValue);
      break;

    case SitePagesActionsTypes.UPDATE_SITE_PAGE_FILES:
      draft.filesLoading[action.payload.url] = false;
      draft.files[action.payload.url] = action.payload.files;
      break;

    case SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL:
      draft.selectedDetailInfo = action.detailInfo;
      break;

    case SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE:
      if (action.toggle) {
        draft.editPageBackup = draft.sitePages[action.sitePageUrl];
      } else {
        resetSitePage(draft, action.sitePageUrl);
        resetSelectedDetailInfo(draft);
      }

      draft.isSitePageInEditMode = action.toggle;
      break;

    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_SUCCESS: {
      draft.isSitePageInVersionHistoryMode = action.toggle;
      draft.loading = false;
      break;
    }

    case SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY:
      if (action.toggle) {
        draft.sitePageInValidProperties.add(action.property);
      } else {
        draft.sitePageInValidProperties.delete(action.property);
      }
      break;
    case SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID:
      draft.sitePageInValidProperties.clear();
      break;

    case SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_SUCCESS:
      draft.loading = false;
      draft.sitePagesHierarchy = action.hierarchy;
      break;

    case SitePagesActionsTypes.ADD_NEW_SITE_PAGE_SUCCESS:
      draft.loading = false;
      break;
  }
}, initialState);

export default sitePagesReducer;
