import React, { useEffect, useState } from "react";

import { Spinner } from "@epam/loveship";

import { BlobCache } from "Helpers/cache/BlobCache";
import { getImageBlob } from "SP/sp.helpers";

export const SharePointImage = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
  const { src } = props;

  const [imageBlobUrl, setImageBlobUrl] = useState<string | undefined>();

  useEffect(() => {
    async function loadBlob(mimeType: string) {
      const cachedImages = await BlobCache.getKeys();

      let imageBlob = null;
      const url = new URL(src);
      const formattedSrc = `${url.origin}${url.pathname}`;

      if (cachedImages.includes(formattedSrc)) {
        imageBlob = await BlobCache.get(formattedSrc);
      } else {
        imageBlob = await getImageBlob(formattedSrc);

        await BlobCache.put(formattedSrc, imageBlob);
      }

      const windowBlob = new window.Blob([imageBlob], {
        type: mimeType,
      });

      const windowBlobUrl = URL.createObjectURL(windowBlob);

      setImageBlobUrl(windowBlobUrl);
    }

    loadBlob("image/png");
  }, []);

  return imageBlobUrl ? <img {...props} src={imageBlobUrl} /> : <Spinner />;
};
