import { IList, sp } from "@pnp/sp/presets/all";

import { IInfoPanelConfigurationDTO } from "./infoPanelConfiguration.types";

export class InfoPanelConfigurationRepository {
  private InfoPanelConfigurationList: IList = sp.web.lists.getByTitle("Info Panel Configuration");

  public async getInfoPanelConfiguration(): Promise<IInfoPanelConfigurationDTO[]> {
    return await this.InfoPanelConfigurationList.items
      .select("Id", "documents", "roles", "certificates")
      .top(1)
      .get<IInfoPanelConfigurationDTO[]>();
  }

  public async updateInfoPanelConfiguration(data: Partial<IInfoPanelConfigurationDTO>, id: number): Promise<void> {
    await this.InfoPanelConfigurationList.items.getById(id).update(data);
  }
  public async addInfoPanelConfiguration(data: Partial<IInfoPanelConfigurationDTO>): Promise<void> {
    await this.InfoPanelConfigurationList.items.add(data);
  }
}
