import { ApmBase, init as initApm } from "@elastic/apm-rum";

import { ENVS, getEnvironment } from "Helpers/environments";

const elasticConfig = {
  project: "epm-palx",
  serviceType: "ui",
  serviceName: "sp-ecl-ui",
};

const getAPMServerUrl = () => {
  if (process.env.REACT_APP_ELASTIC_APM_URL) return process.env.REACT_APP_ELASTIC_APM_URL;
  else
    return getEnvironment() === ENVS.prod
      ? "https://apm-cluster12.cloudapp.epam.com/"
      : "https://apm-sandbox.cloudapp.epam.com/";
};

export let elasticAPM: ApmBase = null;

export function configureElasticAPM(): void {
  elasticAPM = initApm({
    serverUrl: getAPMServerUrl(),
    environment: getEnvironment(),
    serviceName: elasticConfig.serviceName,
    serviceVersion: "0.1.1",
    transactionSampleRate: 0.2,
    breakdownMetrics: true,
    customPageLoadDelay: 7000,
  });

  elasticAPM.addLabels({ project: elasticConfig.project, service_type: elasticConfig.serviceType });
  elasticAPM.setInitialPageLoadName("ECL | Home");
}
