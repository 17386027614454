import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IAttachedFile, ISitePage } from "SP/sitePages/sitePages.types";

import { RecentRepository } from "./recent.repository";
import { IVisited, IVisitedDTO } from "./recent.types";

export class RecentService {
  private repository: RecentRepository = new RecentRepository();
  private mapVisited(visited: IVisitedDTO): IVisited {
    return {
      Id: visited.Id,
      extension: visited.extension,
      lastVisited: new Date(visited.lastVisited),
      list: visited.list,
      title: visited.title,
      url: visited.url,
      fileName: visited.fileName,
      ...(visited.extension === "role" && { category: visited.category }),
    };
  }
  /**
   * This method performs all needed actions and returns all visited items
   * @returns array of visited items
   */
  public fetchItems(): IVisited[] {
    const recentItems = this.repository.fetchItems();
    return recentItems.map((item) => this.mapVisited(item));
  }

  public addToRecent(item: IVisited) {
    this.repository.addToRecent(item);
  }
  /**
   * Adding document to recent items
   * @param docInfo object with data about document
   * @param library name of library
   */
  public addDocumentToRecent(docInfo: IAttachedFile): void {
    this.repository.addDocumentToRecent(docInfo);
  }
  /**
   * Adding page to recent items
   * @param page object with data about page
   */
  public addPageToRecent(page: ISitePage): void {
    this.repository.addPageToRecent(page);
  }
  /**
   * Adding role to recent items
   * @param role object with data about role
   */
  public addRoleToRecent(role: IRole): void {
    this.repository.addRoleToRecent(role);
  }
}
