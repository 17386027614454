import React, { FC } from "react";

import { SeeMore } from "Components/see-more/see-more";
import { ShowCurtain } from "Components/show-curtain/show-curtain";

export interface IShowCurtainSearchProps {
  link: string;
  cx?: string;
  maxRowsToShow?: number;
}

export const MAX_ROWS_TO_SHOW = 9;

export const ShowCurtainSearch: FC<IShowCurtainSearchProps> = ({
  link,
  children,
  cx = "",
  maxRowsToShow = MAX_ROWS_TO_SHOW,
}) => {
  return (
    <ShowCurtain maxHeight={36} byTextLines cx={cx}>
      <SeeMore maxHeight={18 * maxRowsToShow} link={link}>
        {children}
      </SeeMore>
    </ShowCurtain>
  );
};
