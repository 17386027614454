import produce from "immer";
import { ReactNode } from "react";
import { Reducer } from "redux";

import { IInfoPanelConfiguration } from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { IVisited } from "SP/recent/recent.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";

import { GeneralActionsTypes, IGeneralActionsTypes } from "../actions/general.actions";

export type InfoPanel = {
  component: ReactNode;
  componentName: "RecentItems" | "DocumentInfo" | "DocumentsFilter";
  hasScroll: boolean;
  id?: number;
};

export interface IGeneralState {
  recentItems: IVisited[];
  recentItemsLoading: boolean;
  menuNavigationToggle: boolean;
  allRoles: IRole[];
  allRolesLoading: boolean;
  isRolesLoaded: boolean;
  role: IRole;
  roleLoading: boolean;
  infoPanel: InfoPanel;
  infoPanelConfig: IInfoPanelConfiguration;
  infoPanelConfigLoading: boolean;
}

const initialState: IGeneralState = {
  recentItems: [],
  recentItemsLoading: false,
  menuNavigationToggle: false,
  allRoles: [],
  allRolesLoading: false,
  isRolesLoaded: false,
  role: null,
  roleLoading: false,
  infoPanel: null,
  infoPanelConfig: null,
  infoPanelConfigLoading: false,
};

const generalReducer: Reducer<IGeneralState, IGeneralActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case GeneralActionsTypes.GET_RECENT_ITEMS_REQUEST:
      draft.recentItemsLoading = true;
      break;
    case GeneralActionsTypes.GET_RECENT_ITEMS_SUCCESS:
      draft.recentItemsLoading = false;
      draft.recentItems = action.payload;
      break;
    case GeneralActionsTypes.GET_RECENT_ITEMS_FAILURE:
      draft.recentItemsLoading = false;
      break;

    case GeneralActionsTypes.GET_ALL_ROLES_REQUEST:
      draft.allRolesLoading = true;
      break;
    case GeneralActionsTypes.GET_ALL_ROLES_SUCCESS:
      draft.allRolesLoading = false;
      draft.allRoles = action.payload;
      draft.isRolesLoaded = true;
      break;
    case GeneralActionsTypes.GET_ALL_ROLES_FAILURE:
      draft.allRolesLoading = false;
      draft.isRolesLoaded = true;
      break;

    case GeneralActionsTypes.GET_ROLE_BY_ID_REQUEST:
      draft.roleLoading = true;
      break;
    case GeneralActionsTypes.GET_ROLE_BY_ID_SUCCESS:
      draft.roleLoading = false;
      draft.role = action.payload;
      break;
    case GeneralActionsTypes.GET_ROLE_BY_ID_FAILURE:
      draft.roleLoading = false;
      break;

    case GeneralActionsTypes.RESET_ROLE:
      draft.role = null;
      break;

    case GeneralActionsTypes.SET_MENU_NAVIGATION_TOGGLE:
      draft.menuNavigationToggle = action.toggle;
      break;

    case GeneralActionsTypes.SET_INFO_PANEL:
      draft.infoPanel = action.infoPanel;
      break;

    case GeneralActionsTypes.GET_INFO_PANEL_CONFIG_REQUEST:
    case GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_REQUEST:
      draft.infoPanelConfigLoading = true;
      break;
    case GeneralActionsTypes.GET_INFO_PANEL_CONFIG_SUCCESS:
    case GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_SUCCESS:
      draft.infoPanelConfigLoading = false;
      draft.infoPanelConfig = action.infoPanelConfig;
      break;
    case GeneralActionsTypes.GET_INFO_PANEL_CONFIG_FAILURE:
    case GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_FAILURE:
      draft.infoPanelConfigLoading = false;
      break;
  }
}, initialState);

export default generalReducer;
