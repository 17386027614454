import React, { useState } from "react";

import { ReactComponent as ExpandIcon } from "@epam/assets/icons/common/navigation-chevron-down_down-18.svg";
import { ReactComponent as CollapseIcon } from "@epam/assets/icons/common/navigation-chevron-up_up-18.svg";
import { LinkButton } from "@epam/loveship";

import { IFiltersGroup } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData, useFiltersMethods } from "./documents-filter.hooks";
import { DocumentsFilterGroupFilters } from "./documents-filter-group-filters";
import { DocumentsFilterGroupHeader } from "./documents-filter-group-header";
import { DocumentsFilterGroupParameters } from "./documents-filter-group-parameters";
import { DocumentsRefinementFilters } from "./documents-refinement-filters";

export interface IDocumentsFilterGroupProps {
  group: IFiltersGroup;
  groupIndex: number;
  resetToggleKey: React.MutableRefObject<boolean>;
  showHeader: boolean;
  showFilters: boolean;
  renderDivider?: boolean;
}

export const DocumentsFilterGroup: React.FC<IDocumentsFilterGroupProps> = (props) => {
  const { group, showHeader, renderDivider, groupIndex } = props;
  const [showFilters, setShowFilters] = useState(props.showFilters && group.enabled);
  const { toggleGroupEnable } = useFiltersMethods();
  const { isSearchPage } = useFiltersData();

  const onToggleEnable = () => {
    toggleGroupEnable(groupIndex);
  };

  const showGroupFilters = showFilters && group.enabled;

  return (
    <>
      {showHeader && <DocumentsFilterGroupHeader {...props} onToggleEnable={onToggleEnable} />}
      {(!showFilters || !group.enabled) && <DocumentsFilterGroupParameters group={props.group} />}
      {!group.enabled && <LinkButton caption="Enable" onClick={onToggleEnable} />}
      {!showFilters && showHeader && group.enabled && (
        <LinkButton icon={ExpandIcon} caption="Expand" onClick={() => setShowFilters(true)} />
      )}
      {showFilters && showHeader && group.enabled && (
        <LinkButton icon={CollapseIcon} caption="Collapse" onClick={() => setShowFilters(false)} />
      )}
      {showGroupFilters && isSearchPage && <DocumentsRefinementFilters {...props} selectedFilters={group.filters} />}
      {showGroupFilters && !isSearchPage && <DocumentsFilterGroupFilters {...props} selectedFilters={group.filters} />}
      {renderDivider && (
        <>
          <div className={cn("group-divider", ["divider"])} />
          <div className={cn("divider-text")}> OR</div>
        </>
      )}
    </>
  );
};
