import { useDispatch, useSelector } from "react-redux";

import { IInfoPanelConfiguration } from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { getInfoPanelConfig, updateInfoPanelConfig } from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";

export const useDocumentInfo = () => {
  const dispatch = useDispatch();

  const loading = useSelector<IRootReducerState, boolean>((state) => state.general.infoPanelConfigLoading);
  const infoPanelConfig = useSelector<IRootReducerState, IInfoPanelConfiguration>(
    (state) => state.general.infoPanelConfig,
  );

  const handleGetInfoPanelConfig = () => {
    return dispatch(getInfoPanelConfig());
  };

  const handleUpdateInfoPanelConfig = (config: IInfoPanelConfiguration) => {
    return dispatch(updateInfoPanelConfig(config));
  };

  return {
    loading,
    infoPanelConfig,
    getInfoPanelConfig: handleGetInfoPanelConfig,
    updateInfoPanelConfig: handleUpdateInfoPanelConfig,
  };
};
