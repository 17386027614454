import { refinerNameToPropertyName } from "Helpers/constants";
import { reverseObjectKeyValue } from "Helpers/utils";
import { IRefinementItem, IRefinementItemEntry } from "SP/search/search.types";
import {
  FilterValueType,
  IFiltersGroup,
  IRefinementFilter,
  IRefinementFiltersGroup,
  ISelectedFilter,
  ISubarea,
} from "Store/reducers/filters.reducer";

export function mapPropertyFiltersToRefinementFilters(propertyFilters: IFiltersGroup[], refiners: IRefinementItem[]) {
  const nameToRefiner = new Map(refiners.map((refiner) => [refiner.Name, refiner]));
  const result: IRefinementFiltersGroup[] = [];

  const propertyNameToRefinerName = reverseObjectKeyValue(refinerNameToPropertyName);

  const getFilterTokens = (entries: Map<string, IRefinementItemEntry>, subarea: ISubarea) => {
    return subarea.items.map((name) => entries.get(name)?.Token || "");
  };

  const getRefinementFilters = (selectedFilter: ISelectedFilter) => {
    const refinementFilters: IRefinementFilter = {};
    for (const propertyName of Object.keys(selectedFilter)) {
      if (!propertyNameToRefinerName.hasOwnProperty(propertyName)) continue;

      const refinerName = propertyNameToRefinerName[propertyName];

      if (selectedFilter[propertyName].type === FilterValueType.timePeriod) {
        refinementFilters[refinerName] = selectedFilter[propertyName];
        continue;
      }

      if (selectedFilter[propertyName].type == FilterValueType.subarea) {
        const refiner: IRefinementItem = nameToRefiner.get(refinerName);

        if (!refiner) continue;

        const entries = new Map(refiner.Entries.map((entry) => [entry.Value, entry]));

        const refinerTokens = getFilterTokens(entries, selectedFilter[propertyName]);

        const subarea = selectedFilter[propertyName] as ISubarea;
        refinementFilters[refinerName] = { ...subarea, items: refinerTokens };
      }
    }

    return refinementFilters;
  };

  for (const group of propertyFilters) {
    if (!group.enabled) continue;

    const refinementFilters = getRefinementFilters(group.filters);

    const refinementGroup: IRefinementFiltersGroup = { filters: refinementFilters };

    result.push(refinementGroup);
  }

  return result;
}
