import { getFullFriendlyUrl, getServerRelativeUrl } from "Helpers/utils";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IAttachedFile, ISitePage, LibraryName } from "SP/sitePages/sitePages.types";

import { IVisited, IVisitedDTO } from "./recent.types";

export class RecentRepository {
  private get localStorageKey(): string {
    const ServerRelativeUrl = getServerRelativeUrl();
    return `${ServerRelativeUrl}/recent`;
  }
  public fetchItems(): IVisitedDTO[] {
    let items: IVisitedDTO[] = [];
    const localStorageData = localStorage.getItem(this.localStorageKey);
    try {
      if (localStorageData) items = JSON.parse(localStorageData);
    } catch (error) {
      localStorage.removeItem(this.localStorageKey);
    }
    return items;
  }

  public addToRecent(item: IVisited) {
    this.fillLocalStorage({ ...item, lastVisited: new Date().toISOString() });
  }

  public addDocumentToRecent(item: IAttachedFile): void {
    const visitedDocumentInfo: IVisitedDTO = {
      Id: item.Id,
      extension: item.fileInfo.FileType,
      lastVisited: new Date().toISOString(),
      list: item.sourceLibrary,
      title: item.Title,
      url: item.FriendlyUrl ? getFullFriendlyUrl(item.FriendlyUrl) : item.viewUrl,
      fileName: item.fileInfo.Name,
    };
    this.fillLocalStorage(visitedDocumentInfo);
  }
  public addPageToRecent(page: ISitePage): void {
    const visitedPageInfo: IVisitedDTO = {
      Id: page.Id,
      extension: page.fileInfo.FileType,
      lastVisited: new Date().toISOString(),
      list: LibraryName.pages,
      title: page.Title,
      url: location.href,
    };
    this.fillLocalStorage(visitedPageInfo);
  }

  public addRoleToRecent(role: IRole): void {
    const visitedItem: IVisitedDTO = {
      Id: role.Id,
      extension: "role",
      lastVisited: new Date().toISOString(),
      list: LibraryName.roles,
      title: role.Title,
      url: window.location.href,
      category: role.RoleCategory,
    };
    this.fillLocalStorage(visitedItem);
  }

  private isItemVisitedTodayCallback = (lastVisited: IVisitedDTO, midnight: number) => (i: IVisitedDTO) =>
    i.list === lastVisited.list && i.Id === lastVisited.Id && +new Date(i.lastVisited) > midnight;

  private fillLocalStorage(visitedItem: IVisitedDTO): void {
    const midnight = new Date().setHours(0, 0, 0, 0);

    let items = this.fetchItems();
    const existedItem = items.findIndex(this.isItemVisitedTodayCallback(visitedItem, midnight));
    if (existedItem === -1) {
      items.push(visitedItem);
    } else {
      items[existedItem] = visitedItem;
    }
    items = this.sortItemsByLastVisited(items);
    items = this.localStorageReduction(items);
    localStorage.setItem(this.localStorageKey, JSON.stringify(items));
  }
  private sortItemsByLastVisited(items: IVisitedDTO[]) {
    return items.sort((x: IVisitedDTO, y: IVisitedDTO) => +new Date(y.lastVisited) - +new Date(x.lastVisited));
  }
  /**
   * This method reduces length of string in localStorage to a value less that 5000000 characters. Max length of localStorage is 5242878 characters for Chromium
   * @param items array of all visited items
   * @returns reduced array of all visited items
   */
  private localStorageReduction(items: IVisitedDTO[]) {
    while (JSON.stringify(items).length > 5000000) {
      items.pop();
    }
    return items;
  }
}
