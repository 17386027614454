import React from "react";

import { useUsersData } from "Hooks/useUsers";

import { analyticsHelper } from "./analyticsHelper";

export interface IWithDownloadAnalyticsProps {
  fileLink: string;
  onClick?: () => void;
}

export function withDownloadAnalytics<WrappedProps>(Component: React.FC<WrappedProps & IWithDownloadAnalyticsProps>) {
  return (props: WrappedProps & IWithDownloadAnalyticsProps) => {
    const { currentUser } = useUsersData();

    const onClick = () => {
      analyticsHelper.analyticsService?.provideDownload(currentUser?.jobTitle, props?.fileLink);

      props.onClick?.();
    };

    return <Component {...props} onClick={onClick} />;
  };
}
