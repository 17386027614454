import _cloneDeep from "lodash/cloneDeep";
import { useMemo, useState } from "react";

import { ISort } from "Components/documents-table/documents-table.interface";
import { handleSortByDate, handleSortByName } from "Helpers/utils";
import { IAttachedFile, SortColumns } from "SP/sitePages/sitePages.types";

type IFields = {
  sortAlgorithm?: "byName" | "byDate";
  getter: (row: IAttachedFile) => string | Date;
};

export const fields: { [key in SortColumns]: IFields } = {
  Document: {
    getter: (row) => row?.Title || "",
  },
  EPAMDocumentID: {
    getter: (row) => row?.EPAMDocumentID || "",
  },
  // Regulation and Template fields
  OrganizationalUnit: {
    getter: (row) => row?.OrganizationalUnit?.Description || "",
  },
  LocationCountry: {
    getter: (row) => row?.LocationCountry?.join(", ") || "",
  },
  DocumentArea: {
    getter: (row) => row?.DocumentArea?.join(", ") || "",
  },
  ApprovalDate: {
    sortAlgorithm: "byDate",
    getter: (row) => row?.ApprovalDate,
  },
  // Certificate fields
  Standard: {
    getter: (row) => row?.Standard?.join(", "),
  },
  CertificationBody: {
    getter: (row) => row?.CertificationBody,
  },
  LocationCity: {
    getter: (row) => row?.LocationCity?.join(", "),
  },
  IssueDate: {
    sortAlgorithm: "byDate",
    getter: (row) => row?.IssueDate,
  },
  ValidFrom: {
    sortAlgorithm: "byDate",
    getter: (row) => row?.ValidFrom,
  },
  ValidUntil: {
    sortAlgorithm: "byDate",
    getter: (row) => row?.ValidUntil,
  },
  // Role fields
  RoleName: {
    getter: (row) => row?.Title || "",
  },
  RoleCategory: {
    getter: (row) => row?.RoleCategory || "",
  },
  Group: {
    getter: (row) => row?.Group || "",
  },
  Status: {
    getter: (row) => row?.Status || "",
  },
};

export const sortAlgorithms = {
  byName: handleSortByName,
  byDate: handleSortByDate,
};

export const useDocumentsSort = (data: IAttachedFile[], defaultValue: ISort) => {
  const [sort, setSort] = useState<ISort>(defaultValue);

  const sortedData = useMemo(() => {
    const newData = _cloneDeep(data);
    const sortField = sort?.field;

    if (fields[sortField]) {
      const { getter, sortAlgorithm = "byName" } = fields[sortField];
      return newData.sort((a, b) => sortAlgorithms[sortAlgorithm](getter(a), getter(b), sort.asc));
    }

    return newData;
  }, [data, sort]);

  return {
    sortedData,
    sort,
    setSort,
  };
};
