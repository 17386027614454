import produce from "immer";
import { Reducer } from "react";

import { LibraryName } from "SP/sitePages/sitePages.types";
import { DrivesActionsTypes, IDrivesActionTypes } from "Store/actions/drives.actions";

export interface IDrivesState {
  listsDriverId: Partial<Record<LibraryName, string>>;
}

const initialState: IDrivesState = {
  listsDriverId: {},
};

export const drivesReducer: Reducer<IDrivesState, IDrivesActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case DrivesActionsTypes.SET_LIST_DRIVE_ID:
      draft.listsDriverId[action.library] = action.driveId;
      break;
  }
}, initialState);
