import { useDispatch, useSelector } from "react-redux";

import { ISPTermObject } from "SP/SPTermStoreService";
import { getTermsRequest, IGetTermsRequestAction } from "Store/actions/terms.actions";
import { IRootReducerState } from "Store/reducers";

interface ITermsDataHook {
  filteredTerms: ISPTermObject[];
  allTerms: ISPTermObject[];
  loading: boolean;
}

interface ITermsMethodsHook {
  getTerms: (termSetName: string) => IGetTermsRequestAction;
}

export const useTermsData = (): ITermsDataHook => {
  const filteredTerms = useSelector<IRootReducerState, ISPTermObject[]>((state) => state.terms.filteredTerms);
  const allTerms = useSelector<IRootReducerState, ISPTermObject[]>((state) => state.terms.allTerms);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.terms.loading);

  return {
    filteredTerms,
    allTerms,
    loading,
  };
};

export const useTermsMethods = (): ITermsMethodsHook => {
  const dispatch = useDispatch();

  const handleGetTerms = (termSetName) => dispatch(getTermsRequest(termSetName));

  return {
    getTerms: handleGetTerms,
  };
};
