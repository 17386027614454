import { object } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ErrorNotification, Snackbar, SuccessNotification, Text, WarningNotification } from "@epam/loveship";
import { INotification } from "@epam/uui";

import { deleteNotificationFromStack, INotificationObject } from "Store/actions/notifications.actions";
import { IRootReducerState } from "Store/reducers";

interface INotificationsStateProps {
  notifications: {
    [id: string]: INotificationObject;
  };
  lastNotificationId: string | null;
}

interface INotificationsDispatchProps {
  deleteNotification: (id: string) => void;
}

type INotificationsProps = INotificationsStateProps & INotificationsDispatchProps;

class Notifications extends React.Component<INotificationsProps> {
  static contextTypes = {
    uuiNotifications: object,
  };

  createNotification = (notification: INotificationObject, notificationProps: INotification) => {
    const { type, text, actions } = notification;

    const extendedNotificationProps = {
      ...notificationProps,
      ...(actions && { actions: actions(notificationProps) }),
    };

    const textElement = (
      <Text size="36" font="sans" fontSize="14">
        {text}
      </Text>
    );

    switch (type) {
      case "error":
        return <ErrorNotification {...extendedNotificationProps}>{textElement}</ErrorNotification>;
      case "success":
        return <SuccessNotification {...extendedNotificationProps}>{textElement}</SuccessNotification>;
      case "warning":
        return <WarningNotification {...extendedNotificationProps}>{textElement}</WarningNotification>;
    }
  };

  showNotification = (notification: INotificationObject) => {
    this.context.uuiNotifications.show((props: INotification) => this.createNotification(notification, props), {
      position: "top-center",
      duration: notification.duration || "3",
      type: notification.type,
    });
  };

  componentDidUpdate(prevProps: INotificationsProps) {
    const lastNotificationId = this.props.lastNotificationId;
    if (lastNotificationId && lastNotificationId !== prevProps.lastNotificationId) {
      this.showNotification(this.props.notifications[lastNotificationId]);
      this.props.deleteNotification(lastNotificationId);
    }
  }

  render() {
    return <Snackbar />;
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  notifications: state.notifications.notifications,
  lastNotificationId: state.notifications.lastNotificationId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteNotification: deleteNotificationFromStack,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
