import React from "react";

import { svc } from "@App/services";
import { Button, SuccessNotification, Text } from "@epam/loveship";
import { IModal, INotification } from "@epam/uui";
import { FlexSpacer } from "@epam/uui-components";

import { Modal } from "Components/modal";
import { IVoidCb } from "SP/helpers.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

export interface ISubscribe {
  isSubscribed: boolean;
  isLoading: boolean;
  onToggle: (cb: IVoidCb) => void;
  tooltipText: string;
  notificationText: string;
  modalTitle: string;
  modalText: string;
}

export enum SubscribeItemTypes {
  "role" = "role",
  "document" = "document",
  "certificate" = "certificate",
  "eclNews" = "ECL News",
  "item" = "item",
}

interface IGetSubscribeParams {
  isSubscribed: boolean;
  subscriptionsLoading: boolean;
  onSubscribe: (cb: IVoidCb) => void;
  type: SubscribeItemTypes;
  isRolePage?: boolean;
}

const getDocumentTypeByLibrary = (library: LibraryName) => {
  if (library === LibraryName.roles) {
    return SubscribeItemTypes.role;
  } else if (library === LibraryName.certificates) {
    return SubscribeItemTypes.certificate;
  }
  return SubscribeItemTypes.document;
};

export const getSubscribeTooltipText = (library: LibraryName) =>
  `You are subscribed to this ${getDocumentTypeByLibrary(library)}.`;

export const handleSubscribeToggle = (subscribe: ISubscribe) => () => {
  const successCb = () => {
    svc.uuiNotifications.show(
      (props: INotification) => (
        <SuccessNotification {...props}>
          <Text size="36" fontSize="14" cx="whitespace-pre-line">
            {subscribe.notificationText}
          </Text>
        </SuccessNotification>
      ),
      { position: "bot-left", duration: 7 },
    );
  };

  if (subscribe.isSubscribed) {
    const handleUnsubscribe = (abort: IVoidCb) => () => {
      if (process.env.UNIT_TEST) {
        svc.uuiModals.closeAll();
      } else {
        abort();
      }
      subscribe.onToggle(successCb);
    };

    return svc.uuiModals.show((modalProps: IModal<string>) => (
      <Modal
        modalProps={modalProps}
        title={subscribe.modalTitle}
        footer={
          <>
            <FlexSpacer />
            <Button cx="btn btn--grey" color="night600" fill="white" onClick={modalProps.abort} caption="Cancel" />
            <Button
              cx="btn btn--modal-fire"
              color="fire"
              caption="Unsubscribe"
              onClick={handleUnsubscribe(modalProps.abort)}
            />
          </>
        }
      >
        <Text size="none" fontSize="14" lineHeight="24">
          {subscribe.modalText}
        </Text>
      </Modal>
    ));
  }

  return subscribe.onToggle(successCb);
};

interface IMessagesText {
  subscribeTooltipText: string;
  unsubscribeTooltipText?: string;
  notificationText: string;
  modalTitle: string;
  modalText: string;
}

const messageTextsTemplate = {
  subscribeTooltipText: "subscribe to ECL notifications.",
  notificationText: "You are subscribed to",
  modalTitle: "Unsubscribe",
  modalText: "Do you want to unsubscribe? You will no longer receive",
};

const getNotificationSummaryMessage = (type: SubscribeItemTypes) => {
  if (type === SubscribeItemTypes.role) {
    return "up-to-date Roles in the Roles Registry";
  } else if (type === SubscribeItemTypes.certificate) {
    return "up-to-date Certificates";
  } else if (type === SubscribeItemTypes.eclNews) {
    return "new and up-to-date EPAM Regulations and changes in the library";
  }
  return "up-to-date documents";
};

const getNotificationTextEnd = (message: string, time: string, isInTable: boolean) => {
  return `You will receive emails with a summary of ${message} for the previous day every day at ${time} (UTC +3).\nYou can unsubscribe to stop receiving notifications by clicking the ${
    isInTable ? "Unsubscribe" : ""
  } button.`;
};

const getDocumentAndRoleMessages = (
  sample: IMessagesText,
  type: SubscribeItemTypes,
  isRolePage: boolean,
): IMessagesText => {
  const pointer = isRolePage ? "this" : "the";
  const wordForm = isRolePage ? "" : "(s)";

  return {
    subscribeTooltipText: `To receive ${pointer} ${type}${wordForm} updates daily, ${sample.subscribeTooltipText}`,
    unsubscribeTooltipText: `${sample.notificationText} this ${type}.`,
    notificationText: `${sample.notificationText} ${pointer} ${type}${wordForm}.\n${getNotificationTextEnd(
      getNotificationSummaryMessage(type),
      "8 PM",
      !isRolePage,
    )}`,
    modalTitle: `${sample.modalTitle}`,
    modalText: `${sample.modalText} ${pointer} ${type}${wordForm} updates.`,
  };
};

const getEclNewsMessages = (sample: IMessagesText): IMessagesText => {
  return {
    subscribeTooltipText: `To receive a daily summary of changes in ECL, ${sample.subscribeTooltipText}`,
    unsubscribeTooltipText: `${sample.notificationText} receive a summary of changes in ECL.`,
    notificationText: `${sample.notificationText} ECL News.\n${getNotificationTextEnd(
      getNotificationSummaryMessage(SubscribeItemTypes.eclNews),
      "10 AM",
      false,
    )}`,
    modalTitle: `${sample.modalTitle}`,
    modalText: `${sample.modalText} ECL News Summary.`,
  };
};

const getMessagesByType = (type: SubscribeItemTypes, isRolePage: boolean): IMessagesText => {
  if (type === SubscribeItemTypes.eclNews) {
    return getEclNewsMessages(messageTextsTemplate);
  }
  return getDocumentAndRoleMessages(messageTextsTemplate, type, isRolePage);
};

export const getNotificationSubscribeParams = (props: IGetSubscribeParams): ISubscribe => {
  const messages = getMessagesByType(props.type, props.isRolePage);

  return {
    onToggle: props.onSubscribe,
    isSubscribed: props.isSubscribed,
    isLoading: props.subscriptionsLoading,
    tooltipText: props.isSubscribed ? messages.unsubscribeTooltipText : messages.subscribeTooltipText,
    notificationText: props.isSubscribed ? "You were unsubscribed. We will miss You..." : messages.notificationText,
    modalTitle: messages.modalTitle,
    modalText: messages.modalText,
  };
};
