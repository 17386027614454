import React, { FC } from "react";

import { FlexCell, FlexRow, TabButton } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { IVoidCb } from "SP/helpers.types";

import "./tabs-slider.scss";

export type ITab = {
  caption: string;
  key: string;
};

export interface ITabsSliderProps {
  activeTab: string;
  tabs: ITab[];
  onTabChange: (tabKey: string) => IVoidCb;
  borderBottom?: boolean;
}

export const TabsSlider: FC<ITabsSliderProps> = ({ activeTab, tabs, onTabChange, borderBottom }) => {
  const cn = withNaming("tabs-slider");

  return (
    <FlexCell cx={cn("", { borderBottom })} grow={1}>
      <FlexRow cx={cn("content")} background="none">
        {tabs.map((tab) => (
          <TabButton
            key={tab.key}
            caption={tab.caption}
            cx="justify-center"
            isLinkActive={activeTab === tab.key}
            onClick={onTabChange(tab.key)}
          />
        ))}
      </FlexRow>
    </FlexCell>
  );
};
