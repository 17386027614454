import { CACHE_ERROR_MESSAGE, StorageCache } from "Helpers/cache/StorageCache";
import { LogError } from "Helpers/utils";

export abstract class ObjectStorageCache {
  public static Cache<T>(key: string, value: T, target: Storage = sessionStorage) {
    StorageCache.Cache(key, JSON.stringify(value), target);
  }

  public static Restore<T>(key: string, target: Storage = sessionStorage) {
    let result = null;

    try {
      result = JSON.parse(StorageCache.Restore(key, target));
    } catch (e) {
      LogError(CACHE_ERROR_MESSAGE, e);
    }

    return result as T;
  }

  public static CacheExpiring<T>(key: string, value: T, expiersInMinutes: number, target: Storage = sessionStorage) {
    StorageCache.CacheExpiring(key, JSON.stringify(value), expiersInMinutes, target);
  }

  public static RestoreExpiring<T>(key: string, target: Storage = sessionStorage) {
    let result = null;

    try {
      result = JSON.parse(StorageCache.RestoreExpiring(key, target));
    } catch (e) {
      LogError(CACHE_ERROR_MESSAGE, e);
    }

    return result as T;
  }

  public static Remove(key: string, target: Storage = sessionStorage) {
    try {
      StorageCache.Remove(key, target);
    } catch (e) {
      LogError(CACHE_ERROR_MESSAGE, e);
    }
  }
}
