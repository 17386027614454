import produce from "immer";
import { Reducer } from "redux";

import { IStandardsIconsActionsTypes, StandardsIconsActionTypes } from "Store/actions/standards-icons.actions";

export interface IStandardIcon {
  standard: string;
  icon: string;
}

export interface IStandardsIconsState {
  loading: boolean;
  isIconsLoaded: boolean;
  icons: IStandardIcon[];
}

const initialState: IStandardsIconsState = {
  loading: false,
  isIconsLoaded: false,
  icons: [],
};

const standardsIconsReducer: Reducer<IStandardsIconsState, IStandardsIconsActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case StandardsIconsActionTypes.GET_ICONS_REQUEST:
      draft.loading = true;
      break;
    case StandardsIconsActionTypes.GET_ICONS_SUCCESS:
      draft.loading = false;
      draft.isIconsLoaded = true;
      draft.icons = action.payload;
      break;
    case StandardsIconsActionTypes.GET_ICONS_FAILURE:
      draft.loading = false;
      draft.isIconsLoaded = false;
      break;
  }
}, initialState);

export default standardsIconsReducer;
