import React, { FC, useRef } from "react";

import { withNaming } from "Helpers/bemClassname";
import { useContentOverflow } from "Hooks/useContentOverflow";

import "./see-more.scss";

export interface ISeeMoreProps {
  maxHeight: number;
  link: string;
}

export const SeeMore: FC<ISeeMoreProps> = ({ maxHeight, link, children }) => {
  const cn = withNaming("see-more");
  const ref = useRef();
  const { isOverflow } = useContentOverflow({ contentRef: ref, maxHeight: maxHeight });

  return (
    <div className={cn("")}>
      <div style={{ maxHeight: maxHeight }}>
        <div style={{ maxHeight: maxHeight + 1 }} ref={ref}>
          {children}
        </div>
      </div>
      {isOverflow && (
        <a href={link} target="_blank" rel="noreferrer" className={cn("link-text")}>
          See more
        </a>
      )}
    </div>
  );
};
