import React, { FC, useRef } from "react";

import { Tooltip as UuiTooltip, TooltipMods } from "@epam/loveship";
import { TooltipProps } from "@epam/uui-components";

import useOutsideClick from "Hooks/useOutsideClick";

import "./tooltip.scss";

export interface ITooltipContentProps {
  onTooltipClose: (e) => void;
}

interface ITooltip extends TooltipProps, TooltipMods {
  content: (p: ITooltipContentProps) => React.ReactNode;
}

const TooltipContent = ({ tooltipRef, content }) => {
  const tooltipContentRef = useRef();

  const handleClose = (e) => {
    tooltipRef.current?.mouseClickHandler?.(e);
  };

  useOutsideClick(tooltipContentRef, handleClose, true);

  return (
    <div className="tooltip-content" ref={tooltipContentRef}>
      {content({ onTooltipClose: handleClose } as ITooltipContentProps)}
    </div>
  );
};

export const Tooltip: FC<ITooltip> = ({ cx, children, content, placement, trigger, color = "white" }) => {
  const tooltipRef = useRef();

  return (
    <UuiTooltip
      cx={`tooltip ${cx}`}
      ref={tooltipRef}
      trigger={trigger}
      content={<TooltipContent tooltipRef={tooltipRef} content={content} />}
      placement={placement}
      color={color}
    >
      {children}
    </UuiTooltip>
  );
};
