import { MutableRefObject, useCallback, useEffect, useRef } from "react";

function useOutsideClick(ref: MutableRefObject<HTMLElement>, handler: (e: MouseEvent) => void, when = true): void {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback((e: MouseEvent) => {
    if (ref.current?.contains?.(e.target as Element)) {
      return;
    }
    const popperElement = document.querySelector(".uui-popper");
    if (popperElement && popperElement.contains(e.target as Element)) {
      return;
    }

    savedHandler.current(e);
  }, []);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (when) {
      document.addEventListener("mousedown", memoizedCallback, true);
      return () => {
        document.removeEventListener("mousedown", memoizedCallback, true);
      };
    }
  }, [ref, when]);
}

export default useOutsideClick;
