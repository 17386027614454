import _omit from "lodash/omit";
import { useDispatch, useSelector } from "react-redux";

import { ToolbarKeys } from "Components/documents-view/documents-view.interface";
import { filterDataList } from "Helpers/filterDataList";
import { updateObject } from "Helpers/utils";
import { ICheckIsSubscribed } from "Hooks/useUsers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IVoidCb, IVoidCbWithParam } from "SP/helpers.types";
import { IRefinementItem } from "SP/search/search.types";
import { LibraryName } from "SP/sitePages/sitePages.types";
import {
  addFiltersGroup,
  deleteFiltersGroup,
  disableFiltersGroup,
  enableFiltersGroup,
  getFilterByGuidRequest,
  IIsPageKey,
  renameFiltersGroup,
  resetActivePreset,
  resetAllRefiners,
  resetFilters,
  saveTempFilterRequest,
  setActivePreset,
  setCheckedFilterField,
  setFilter,
  setFilters,
  setFiltersLibrary,
  setInfoPanelFilters,
  setIsPageToggle,
  toggleDocumentViewReset,
  updateFilterResult,
} from "Store/actions/filters.actions";
import { saveFavoriteFilter } from "Store/actions/users.actions";
import { IRootReducerState } from "Store/reducers";
import {
  IFilterGroupResult,
  IFiltersGroup,
  ISelectedFilter,
  ISelectedFilters,
  ISelectedFilterValue,
  isNeedToRemoveFilterKey,
} from "Store/reducers/filters.reducer";

import { IFilter, IFilterKeys } from "./documents-filter.interface";

export const useFiltersData = () => {
  const infoPanelFilters = useSelector<IRootReducerState, IFilter[]>((state) => state.filters.infoPanelFilters);
  const filtersLibrary = useSelector<IRootReducerState, LibraryName>((state) => state.filters.filtersLibrary);
  const allSelectedFilters = useSelector<IRootReducerState, ISelectedFilters>((state) => state.filters.selectedFilters);
  const isNotSubscriptionsPage = useSelector<IRootReducerState, boolean>((state) => !state.filters.isSubscriptionsPage);
  const isSearchPage = useSelector<IRootReducerState, boolean>((state) => state.filters.isSearchPage);
  const isRolesRegistryPage = useSelector<IRootReducerState, boolean>((state) => state.filters.isRolesRegistryPage);
  const filterGroupResults = useSelector<IRootReducerState, IFilterGroupResult[]>(
    (state) => state.filters.filterGroupResults,
  );
  const selectedFiltersGroups = useSelector<IRootReducerState, IFiltersGroup[]>(
    ({ filters }) => filters.selectedFilters[filters.filtersLibrary],
  );
  const presetSelectedFilters = useSelector<IRootReducerState, ISelectedFilter[]>(({ filters }) =>
    filters.selectedFilters[filtersLibrary]?.map((g) => _omit(g.filters, [ToolbarKeys.activeTab])),
  );
  const activePresetFilters = useSelector<IRootReducerState, IFavoriteFilter>(
    ({ filters }) => filters.activePresets[filters.filtersLibrary] || null,
  );
  const isResetDocumentViewToggle = useSelector<IRootReducerState, boolean>(
    (state) => state.filters.isResetDocumentViewToggle,
  );
  const filterGuid = useSelector<IRootReducerState, string>(
    (state) => state.filters.filtersGuids[state.filters.filtersLibrary],
  );

  const refiners = useSelector<IRootReducerState, IRefinementItem[]>(
    (state) => state.filters.refiners[state.filters.filtersLibrary],
  );

  return {
    filtersLibrary,
    infoPanelFilters,
    selectedFiltersGroups,
    presetSelectedFilters,
    allSelectedFilters,
    isNotSubscriptionsPage,
    isSearchPage,
    isRolesRegistryPage,
    activePresetFilters,
    isResetDocumentViewToggle,
    filterGuid,
    filterGroupResults,
    refiners,
  };
};

export const useFiltersActions = () => {
  const dispatch = useDispatch();

  const isNotSubscriptionsPage = useSelector<IRootReducerState, boolean>((state) => !state.filters.isSubscriptionsPage);
  const selectedFilters = useSelector<IRootReducerState, ISelectedFilter>(
    (state) => state.filters.selectedFilters[state.filters.filtersLibrary]?.[0]?.filters,
  );

  const handleSetFilter = (
    key: IFilterKeys,
    value: ISelectedFilterValue,
    groupIndex: number,
    isQueryNeedToUpdate = true,
  ) => {
    if (isNotSubscriptionsPage && isQueryNeedToUpdate) {
      let newSelectedFilters = updateObject(selectedFilters, { [key]: value });
      if (isNeedToRemoveFilterKey(value)) {
        newSelectedFilters = _omit(selectedFilters, [key]);
      }
    }

    return dispatch(setFilter(key, value, groupIndex));
  };

  return {
    setFilter: handleSetFilter,
  };
};

export const useFiltersMethods = () => {
  const dispatch = useDispatch();
  const { selectedFiltersGroups, activePresetFilters, filtersLibrary } = useFiltersData();

  const handleSetInfoPanelFilters = (infoPanelFilters: IFilter[]) => {
    return dispatch(setInfoPanelFilters(infoPanelFilters));
  };

  const handleSetFiltersLibrary = (filtersLibrary: LibraryName) => {
    return dispatch(setFiltersLibrary(filtersLibrary));
  };

  const handleSetFilters = (selectedFilters: IFiltersGroup[]) => {
    return dispatch(setFilters(selectedFilters));
  };

  const handleResetFilters = (isResetAll?: boolean) => {
    return dispatch(resetFilters(isResetAll));
  };

  const handleSetIsPageToggle = (pageKey: IIsPageKey, toggle: boolean) => {
    return dispatch(setIsPageToggle(pageKey, toggle));
  };

  const handleSetActivePresetFilters = (activePreset: IFavoriteFilter) => {
    return dispatch(setActivePreset(activePreset));
  };

  const handleResetActivePreset = (isResetAll?: boolean) => {
    return dispatch(resetActivePreset(isResetAll));
  };

  const handleToggleDocumentViewReset = () => {
    return dispatch(toggleDocumentViewReset());
  };

  const handleGetFilterByGuid = (guid: string, cb?: (value: IFavoriteFilter) => void) => {
    return dispatch(getFilterByGuidRequest(guid, cb));
  };

  const handleSaveTempFilter = (filter: IFavoriteFilter, cb?: IVoidCbWithParam) => {
    return dispatch(saveTempFilterRequest(filter, cb));
  };

  const handleUpdateFilterGroupResults = (filteredResult: any[]) => {
    return dispatch(updateFilterResult(filteredResult));
  };

  const handleAddFiltersGroup = (name: string) => {
    return dispatch(addFiltersGroup({ name, enabled: true, filters: {} }));
  };

  const handleToggleGroupEnable = (index: number) => {
    if (selectedFiltersGroups[index].enabled) {
      dispatch(disableFiltersGroup(index));
    } else {
      dispatch(enableFiltersGroup(index));
    }
  };

  const handleRenameFiltersGroup = (name: string, index: number) => {
    return dispatch(renameFiltersGroup(name, index));
  };

  const handleSetCheckedFilterField = (groupIndex: number, field?: string) => {
    return dispatch(setCheckedFilterField(groupIndex, field));
  };

  const getFilteredDataList = (
    dataList: any[],
    filtersGroups: IFiltersGroup[],
    checkIsSubscribed?: ICheckIsSubscribed,
  ) => {
    const filteredViewDataList = dataList.filter(filterDataList(filtersGroups, checkIsSubscribed));
    return filteredViewDataList;
  };

  const setFilterGroupsResults = (searchResults: any[], filtersGroups: IFiltersGroup[]) => {
    const filtersGroupResults = Array(filtersGroups.length).fill({ result: searchResults });

    handleUpdateFilterGroupResults(filtersGroupResults);
  };

  const handleDeleteFiltersGroup = (index: number) => {
    return dispatch(deleteFiltersGroup(index));
  };

  const handleSaveOrUpdateFiltersGroup = (group: IFiltersGroup, groupIndex: number, onSave?: IVoidCb) => {
    if (activePresetFilters) {
      const newFilter: IFavoriteFilter = {
        ...activePresetFilters,
        filtersGroups: activePresetFilters.filtersGroups.map((g, i) => (i === groupIndex ? group : g)),
      };

      dispatch(saveFavoriteFilter(newFilter, () => onSave?.()));
    } else {
      const newFilter: IFavoriteFilter = {
        id: undefined,
        title: "Not preset",
        filtersGroups: selectedFiltersGroups.map((g, i) => (i === groupIndex ? group : g)),
        page: filtersLibrary,
        guid: undefined,
        isPreset: false,
      };

      handleSaveTempFilter(newFilter);
    }
  };

  const handleResetRefiners = () => {
    return dispatch(resetAllRefiners());
  };

  return {
    setInfoPanelFilters: handleSetInfoPanelFilters,
    setFiltersLibrary: handleSetFiltersLibrary,
    setFilters: handleSetFilters,
    resetFilters: handleResetFilters,
    setIsPageToggle: handleSetIsPageToggle,
    setActivePresetFilters: handleSetActivePresetFilters,
    resetActivePreset: handleResetActivePreset,
    toggleDocumentViewReset: handleToggleDocumentViewReset,
    getFilterByGuid: handleGetFilterByGuid,
    saveTempFilter: handleSaveTempFilter,
    handleUpdateFilterGroupResults: handleUpdateFilterGroupResults,
    addFiltersGroup: handleAddFiltersGroup,
    toggleGroupEnable: handleToggleGroupEnable,
    renameFiltersGroup: handleRenameFiltersGroup,
    deleteFiltersGroup: handleDeleteFiltersGroup,
    getFilteredDataList,
    setFilterGroupsResults,
    setCheckedFilterField: handleSetCheckedFilterField,
    saveOrUpdateFiltersGroup: handleSaveOrUpdateFiltersGroup,
    resetRefiners: handleResetRefiners,
  };
};
