import { LibraryName } from "SP/sitePages/sitePages.types";

export enum DrivesActionsTypes {
  GET_LIST_DRIVE_ID = "[DRIVES] GET LIST DRIVE ID",
  SET_LIST_DRIVE_ID = "[DRIVES] SET LIST DRIVE ID",
}

export interface ISetListDriveIdAction {
  type: DrivesActionsTypes.SET_LIST_DRIVE_ID;
  library: LibraryName;
  driveId: string;
}

export function setListDriveId(library: LibraryName, driveId: string): ISetListDriveIdAction {
  return {
    type: DrivesActionsTypes.SET_LIST_DRIVE_ID,
    library,
    driveId,
  };
}

export interface IGetListDriveIdAction {
  type: DrivesActionsTypes.GET_LIST_DRIVE_ID;
  list: string;
}

export function getListDriveId(list: string): IGetListDriveIdAction {
  return {
    type: DrivesActionsTypes.GET_LIST_DRIVE_ID,
    list,
  };
}

export type IDrivesActionTypes = ISetListDriveIdAction | IGetListDriveIdAction;
