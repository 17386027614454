import _isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useState } from "react";

import { svc } from "@App/services";
import { Dropdown, DropdownMenuBody, DropdownMenuButton, LinkButton, PickerInput, Text } from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui";
import type { ButtonProps } from "@epam/uui-components";

import { cn } from "Components/documents-filter/documents-filter";
import {
  calculateSelectedFiltersCount,
  renderPresetsPickerFooter,
  renderPresetsPickerRow,
  renderPresetsPickerToggler,
} from "Components/documents-filter/documents-filter.helpers";
import { useFiltersData, useFiltersMethods } from "Components/documents-filter/documents-filter.hooks";
import { PresetsActionTypes } from "Components/documents-filter/documents-filter.interface";
import { DocumentsFilterPresetsModal } from "Components/documents-filter/documents-filter-presets-modal";
import { loaderIcon } from "Components/icons";
import useQuery from "Hooks/useQuery";
import { useUsersData, useUsersMethods } from "Hooks/useUsers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";

interface IDocumentsFilterPresets {
  hasSelectedParamsToSaveAsNewPreset: boolean;
  isActivePresetModified: boolean;
  onResetComponentsState: () => void;
}

type IFilterAction = { id: number } & ButtonProps;

export const DocumentsFilterPresets: FC<IDocumentsFilterPresets> = ({
  hasSelectedParamsToSaveAsNewPreset,
  isActivePresetModified,
  onResetComponentsState,
}) => {
  const { setQuery } = useQuery();
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const { favoriteFilters } = useUsersData();
  const { activePresetFilters, presetSelectedFilters, selectedFiltersGroups } = useFiltersData();
  const { getFavoriteFilters, saveFavoriteFilter } = useUsersMethods();
  const { setActivePresetFilters, setFilters, toggleDocumentViewReset } = useFiltersMethods();

  const selectedFiltersCount = presetSelectedFilters.reduce(
    (sum, filter) => sum + calculateSelectedFiltersCount(filter, false),
    0,
  );

  useEffect(() => {
    getFavoriteFilters();
  }, []);

  const handleSelectPreset = (preset: IFavoriteFilter) => {
    if (!preset || activePresetFilters?.id === preset?.id) return;

    onResetComponentsState();
    setQuery({ filterGuid: preset?.guid });
    setActivePresetFilters(preset);
    setFilters(preset?.filtersGroups);
  };

  const handleUpdateFavoriteFilter = () => {
    const updatedPreset: IFavoriteFilter = {
      ...activePresetFilters,
      filtersGroups: selectedFiltersGroups,
    };

    setDropdownLoading(true);
    saveFavoriteFilter(updatedPreset, (guid) => {
      setQuery({ filterGuid: guid });
      setDropdownLoading(false);
    });
  };

  const handleClearChanges = () => {
    onResetComponentsState();
    toggleDocumentViewReset();
    setFilters(activePresetFilters.filtersGroups);
  };

  const unsavedFiltersActions: IFilterAction[] = [
    {
      id: 1,
      caption: "Save",
      onClick: handleUpdateFavoriteFilter,
      icon: dropdownLoading ? loaderIcon : null,
      iconPosition: "right",
      isDisabled: dropdownLoading,
    },
    {
      id: 2,
      caption: "Save as New Preset",
      onClick: () =>
        svc.uuiModals.show((modalProps) => (
          <DocumentsFilterPresetsModal
            modalProps={modalProps}
            modalType={PresetsActionTypes.create}
            activePreset={activePresetFilters}
            onResetComponentsState={onResetComponentsState}
          />
        )),
    },
    {
      id: 3,
      caption: "Clear Changes",
      onClick: handleClearChanges,
    },
  ];

  const presetsDataSource = useArrayDataSource(
    {
      items: favoriteFilters,
    },
    [favoriteFilters],
  );

  return (
    <div className={cn("presets")}>
      <PickerInput
        dataSource={presetsDataSource}
        selectionMode="single"
        value={activePresetFilters}
        valueType="entity"
        onValueChange={handleSelectPreset}
        getName={(item) => item.title}
        minBodyWidth={100}
        disableClear-
        renderToggler={(props) => renderPresetsPickerToggler(props, activePresetFilters?.title)}
        renderRow={(props) => renderPresetsPickerRow(props, onResetComponentsState)}
        renderFooter={() => renderPresetsPickerFooter(onResetComponentsState)}
      />
      <div className={cn("presets-unsaved", ["flex"])}>
        {hasSelectedParamsToSaveAsNewPreset && selectedFiltersCount !== 0 && (
          <>
            <Text cx={cn("presets-unsaved-message", ["p-0"])} color="night800" fontSize="12" lineHeight="18">
              {selectedFiltersCount} parameter{selectedFiltersCount === 1 ? "" : "s"} selected.
            </Text>
            <LinkButton
              cx={cn("presets-unsaved-actions")}
              size="none"
              caption="Save as Preset"
              onClick={() =>
                svc.uuiModals.show((modalProps) => (
                  <DocumentsFilterPresetsModal
                    modalProps={modalProps}
                    modalType={PresetsActionTypes.create}
                    activePreset={activePresetFilters}
                    onResetComponentsState={onResetComponentsState}
                  />
                ))
              }
            />
          </>
        )}
        {isActivePresetModified && (selectedFiltersCount !== 0 || !_isEmpty(activePresetFilters)) && (
          <>
            <Text cx={cn("presets-unsaved-message", ["p-0"])} color="night800" fontSize="12" lineHeight="18">
              Has unsaved changes.
            </Text>
            <Dropdown
              renderTarget={(props) => (
                <LinkButton cx={cn("presets-unsaved-actions")} size="none" caption="Save" {...props} />
              )}
              renderBody={() => (
                <DropdownMenuBody cx="dropdown dropdown--no-icons" color="white">
                  {unsavedFiltersActions.map(({ id, ...props }) => (
                    <DropdownMenuButton key={id} {...props} />
                  ))}
                </DropdownMenuBody>
              )}
              closeOnMouseLeave="boundary"
              placement="bottom-start"
            />
          </>
        )}
      </div>
    </div>
  );
};
