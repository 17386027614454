import React, { useState } from "react";

import { DocumentInfo } from "Components/documents/document-info";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { getDocumentViewUrl } from "Helpers/utils";
import { useRecentItemsMethods } from "Hooks/useRecentItems";
import { useUsersData } from "Hooks/useUsers";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { IColumn, IColumnKey } from "./documents-table.interface";

function generateColumnsConfig(columns: IColumn[]): IColumnKey {
  const columnsConfig = {};

  columns.forEach((column) => {
    columnsConfig[column.key] = column;
  });

  return columnsConfig;
}

export const useColumns = (columns: IColumn[]) => {
  const [columnsConfig, setColumnsConfig] = useState(generateColumnsConfig(columns));

  return {
    columnsConfig,
    setColumnsConfig,
    columnsList: Object.values(columnsConfig),
  };
};

export const useDocumentActions = () => {
  const { addRecentItemDocument } = useRecentItemsMethods();
  const { infoPanel, setInfoPanel } = useInfoPanel();
  const { currentUser } = useUsersData();

  const onFileNameClick = (row: IAttachedFile) => () => {
    addRecentItemDocument(row);
    analyticsHelper.analyticsService?.provideLinkClick(row.viewUrl, currentUser?.jobTitle);
    window.open(getDocumentViewUrl(row?.viewUrl, row?.FriendlyUrl));
  };

  const handleOpenDocumentInfo = (row: IAttachedFile) => () => {
    if (infoPanel?.componentName === "DocumentInfo" && infoPanel.id === row.Id) {
      return setInfoPanel(null);
    }

    setInfoPanel({
      component: <DocumentInfo key={row.UniqueId} attachedFile={row} />,
      componentName: "DocumentInfo",
      id: row.Id,
      hasScroll: true,
    });
  };

  return {
    onFileNameClick,
    handleOpenDocumentInfo,
  };
};
