import React, { FC } from "react";

import { VirtualList } from "@epam/loveship";

import { CardWrapper } from "Components/card-wrapper";
import { ISearchResultListProps } from "Components/search/search.interfaces";
import { useVirtualList } from "Hooks/useVirtualList";
import { ISearchItem } from "SP/search/search.types";

import { SearchResultCard } from "./search-result-card";

export const SearchResultList: FC<ISearchResultListProps> = ({
  cn,
  items,
  selectedRows,
  query,
  onCheckRowCallback,
}) => {
  const { virtualState, visibleItems, setVirtualState } = useVirtualList<ISearchItem>(items, 8);

  const visibleRows = visibleItems.map((item, index) => (
    <CardWrapper
      key={item.UniqueId ?? index}
      isCheck={selectedRows.includes(item.UniqueId)}
      onCheck={onCheckRowCallback(item.UniqueId)}
      row
    >
      <SearchResultCard cn={cn} item={item} query={query} />
    </CardWrapper>
  ));

  return (
    <VirtualList
      cx={cn("virtual-list-body")}
      rawProps={{ "data-testid": "virtual-list-body" }}
      rows={visibleRows}
      value={virtualState}
      onValueChange={setVirtualState}
      rowsCount={items?.length}
    />
  );
};
