import React, { FC } from "react";

import { ReactComponent as emptyStateIcon } from "@App/assets/empty-state.svg";
import { ReactComponent as searchNotFoundIcon } from "@App/assets/not-found.svg";
import { ReactComponent as subscriptionNotFoundIcon } from "@App/assets/subscriptions-not-found.svg";
import { IconContainer } from "@epam/loveship";
import { Icon } from "@epam/uui/types/objects";

import { withNaming } from "Helpers/bemClassname";

import "./results-not-found.scss";

interface ResultsNotFoundProps {
  isSearchQueryPresent?: boolean;
  isExactPhrase?: boolean;
}

interface IResultParams {
  title: string;
  message: string;
  icon: Icon;
}

const subscriptionsMessage = "Add new files to favorites and you will see them here";

const searchMessages = {
  noFound: "Try adjusting your search or filter to find what you’re looking for",
  noFoundExact: "Try adjusting your search or filter to find what you’re looking for (e.g. select 'All words' option)",
  emptyState: "Start typing something in the search field to see results",
};

const getNotFoundAllParams = (): IResultParams => {
  return {
    title: "No results found",
    message: searchMessages.noFound,
    icon: searchNotFoundIcon,
  };
};

const getNotFoundYetParams = (): IResultParams => {
  return {
    title: "No results yet",
    message: searchMessages.emptyState,
    icon: emptyStateIcon,
  };
};

const getNotFoundExactParams = (): IResultParams => {
  return {
    title: "No results found",
    message: searchMessages.noFoundExact,
    icon: emptyStateIcon,
  };
};

export const SubscriptionsNotFound: FC = () => {
  return <NotFound title="No subscriptions yet" message={subscriptionsMessage} icon={subscriptionNotFoundIcon} />;
};

export const ResultsNotFound: FC<ResultsNotFoundProps> = (props) => {
  const params = getNotFoundParams(props);

  return <NotFound {...params} />;
};

export const getNotFoundParams = ({ isSearchQueryPresent, isExactPhrase }: ResultsNotFoundProps) => {
  if (!isSearchQueryPresent) return getNotFoundYetParams();

  if (isExactPhrase) return getNotFoundExactParams();

  return getNotFoundAllParams();
};

const NotFound: FC<IResultParams> = ({ title, message, icon }) => {
  const cn = withNaming("not-found");
  return (
    <div className={cn("", ["flex flex-col items-center"])}>
      <div className={cn("title")}>{title}</div>
      <div className={cn("message")}>{message}</div>
      <IconContainer cx={cn("icon")} icon={icon} />
    </div>
  );
};
