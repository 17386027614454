import { useDispatch, useSelector } from "react-redux";

import { ISetMenuNavigationToggleAction, setMenuNavigationToggle } from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";

interface IMenuNavigationDataHook {
  menuNavigationToggle: boolean;
}

interface IMenuNavigationMethodsHook {
  setMenuNavigationToggle: (toggle: boolean) => ISetMenuNavigationToggleAction;
}

export const useMenuNavigationData = (): IMenuNavigationDataHook => {
  const menuNavigationToggle = useSelector<IRootReducerState, boolean>((state) => state.general.menuNavigationToggle);

  return {
    menuNavigationToggle,
  };
};

export const useMenuNavigationMethods = (): IMenuNavigationMethodsHook => {
  const dispatch = useDispatch();

  const handleSetMenuNavigationToggle = (toggle: boolean) => dispatch(setMenuNavigationToggle(toggle));

  return {
    setMenuNavigationToggle: handleSetMenuNavigationToggle,
  };
};
