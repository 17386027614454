import _get from "lodash/get";
import React, { FC } from "react";

import { ScrollBars } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { removeUrlPrefix } from "Helpers/utils";
import { useSitePagesActions } from "Hooks/useSitePages";
import { ISitePage } from "SP/sitePages/sitePages.types";
import { DetailTypes, ISelectedDetailInfo } from "Store/reducers/site-pages.reducer";

import { PageDetailsBreadcrumbs } from "./page-details-breadcrumbs";
import { PageDetailsCard } from "./page-details-card";
import { PageDetailsMain } from "./page-details-main";
import { PageDetailsParent } from "./page-details-parent";
import { PageDetailsSection } from "./page-details-section";

import "./page-details.scss";

interface IPageDetailsProps {
  sitePage: ISitePage;
}

const content = {
  [DetailTypes.main]: PageDetailsMain,
  [DetailTypes.section]: PageDetailsSection,
  [DetailTypes.card]: PageDetailsCard,
  [DetailTypes.parentCard]: PageDetailsParent,
};

function generatePageDetailsKey(selectedDetailInfo: ISelectedDetailInfo, sitePage: ISitePage) {
  const { sectionIndex, column, webPartIndex } = selectedDetailInfo;
  const sectionsCount = sitePage.pageInfo.sections?.length;
  const webPartsCount = _get(sitePage.pageInfo, `sections[${sectionIndex}].data[${column}].length`, 0);

  return `${sectionIndex}.${column}.${webPartIndex}.${sectionsCount}.${webPartsCount}`;
}

function getContentProps(selectedDetailInfo: ISelectedDetailInfo, sitePage: ISitePage) {
  const sitePageUrl = sitePage.friendlyUrl ?? removeUrlPrefix(sitePage.fileInfo.ServerRelativeUrl);

  if (selectedDetailInfo.type === DetailTypes.main) {
    return {
      sitePageUrl,
      formData: {
        title: {
          value: sitePage.Title,
          pathKeys: [sitePageUrl, "Title"],
        },
        associatedTerms: {
          value: sitePage.parentNavigationTermSets,
          pathKeys: [sitePageUrl, "parentNavigationTermSets"],
        },
        library: {
          value: sitePage.pageInfo.attachedFiles?.library,
          pathKeys: [sitePageUrl, "pageInfo", "attachedFiles", "library"],
        },
        documents: {
          value: sitePage.pageInfo.attachedFiles?.items,
          pathKeys: [sitePageUrl, "pageInfo", "attachedFiles", "items"],
        },
      },
    };
  }

  return { sitePageUrl, key: generatePageDetailsKey(selectedDetailInfo, sitePage) };
}

export const PageDetails: FC<IPageDetailsProps> = ({ sitePage }) => {
  const cn = withNaming("page-details");
  const { selectedDetailInfo } = useSitePagesActions();

  const Content = content[selectedDetailInfo.type];

  return (
    <div className={cn("", ["shadow"])}>
      <div className={cn("head")}>
        <PageDetailsBreadcrumbs />
      </div>
      <div className={cn("content")}>
        <ScrollBars>
          <Content {...getContentProps(selectedDetailInfo, sitePage)} />
        </ScrollBars>
      </div>
    </div>
  );
};
