import { ClientNotFoundError } from "@App/errors/ClientNotFoundError";
import { IItemUpdateResult } from "@pnp/sp/presets/all";

import { LibraryName } from "SP/sitePages/sitePages.types";
import { mapDocuments } from "SP/sp.helpers";

import { IRegulation, IRegulationDTO } from "../regulations/regulations.types";
import { TemplatesRepository } from "./templates.repository";

export class TemplatesService {
  repository = new TemplatesRepository();
  public async getAll(): Promise<IRegulation[]> {
    const allDocs = await this.repository.getAll();
    const mappedDocs = allDocs.map((doc) => mapDocuments(doc, LibraryName.templates));
    const filteredByFolder = mappedDocs.filter((x) => filteredByFolder(x));

    return filteredByFolder;
  }
  public async getById(id: number): Promise<IRegulation> {
    const doc = await this.repository.getById(id);
    return mapDocuments(doc, LibraryName.templates);
  }

  public async getByServerRelativeUrl(url: string): Promise<IRegulation> {
    const doc = await this.repository.getByServerRelativeUrl(url);

    return mapDocuments(doc, LibraryName.templates);
  }

  public async getByFriendlyUrl(friendlyURL: string): Promise<IRegulation> {
    const docs = await this.repository.getByFriendlyUrl(friendlyURL);

    if (docs.length == 0) {
      throw new ClientNotFoundError(`Template ${friendlyURL} not found`);
    }

    return mapDocuments(docs[0], LibraryName.templates);
  }

  public async updateDocument(id: number, data: Partial<IRegulationDTO>): Promise<IItemUpdateResult> {
    return await this.repository.updateDocument(id, data);
  }
}
