import { IList, sp } from "@pnp/sp/presets/all";

import { standardsIconsRequestInfo } from "SP/constants";

import { IStandardIconDTO } from "./standardsIcons.types";

export class StandardsIconsRepository {
  private list: IList = sp.web.lists.getByTitle("Standards Icons");

  public getAllIcons(): Promise<IStandardIconDTO[]> {
    return this.list.items.select(...standardsIconsRequestInfo.selectArray).getAll(5000);
  }
}
