import React, { FC, useEffect, useState } from "react";

import { Button, FlexSpacer, LabeledInput, TextInput } from "@epam/loveship";
import { IModal } from "@epam/uui";

import { cn } from "Components/documents-filter/documents-filter";
import { useFiltersData, useFiltersMethods } from "Components/documents-filter/documents-filter.hooks";
import { PresetsActionTypes } from "Components/documents-filter/documents-filter.interface";
import { renderText } from "Components/documents-table/documents-table.helpers";
import { loaderIcon } from "Components/icons";
import { Modal } from "Components/modal";
import { getPresetErrorMessage } from "Helpers/utils";
import useQuery from "Hooks/useQuery";
import { useUsersData, useUsersMethods } from "Hooks/useUsers";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { defaultSelectedFilters, IFiltersGroup } from "Store/reducers/filters.reducer";

interface IDocumentsFilterPresetsModalProps {
  modalProps: IModal<string>;
  modalType: PresetsActionTypes;
  onResetComponentsState: () => void;
  activePreset?: IFavoriteFilter;
}

interface IGetModalParams {
  title: string;
  submitName: string;
  submitAction: () => void;
  label?: string;
}

export const DocumentsFilterPresetsModal: FC<IDocumentsFilterPresetsModalProps> = ({
  modalProps,
  modalType,
  activePreset,
  onResetComponentsState,
}) => {
  const { setQuery } = useQuery();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [presetName, setPresetName] = useState(activePreset?.title || "");

  const { selectedFiltersGroups, filtersLibrary, isRolesRegistryPage } = useFiltersData();
  const { setFilters } = useFiltersMethods();
  const { clearQuery } = useQuery();
  const { favoriteFilters, presetSaveErrorText } = useUsersData();
  const { saveFavoriteFilter, removeFavoriteFilter } = useUsersMethods();

  const [presetErrorMessage, setPresetErrorMessage] = useState(
    modalType === PresetsActionTypes.rename ? "" : getPresetErrorMessage(presetName, favoriteFilters),
  );
  const isInvalidPresetName = !!presetErrorMessage;
  const isDeleteType = modalType === PresetsActionTypes.delete;
  const isCreateEmptyType = modalType === PresetsActionTypes.createEmpty;

  useEffect(() => {
    if (presetSaveErrorText) {
      setButtonLoading(false);
      setPresetErrorMessage(presetSaveErrorText);
    }
  }, [presetSaveErrorText]);

  const defaultPresetFilters = {
    show: "",
    sort: { field: isRolesRegistryPage ? "RoleName" : "Document", asc: true },
  };

  const setEmptyFilters = () => {
    onResetComponentsState();
    setFilters([{ name: "Filter Group 1", enabled: true, filters: defaultPresetFilters }]);
    clearQuery();
  };

  const handleSetPresetName = (newPresetName: string) => {
    const newPresetErrorMessage = getPresetErrorMessage(newPresetName, favoriteFilters);

    setPresetErrorMessage(newPresetErrorMessage);
    setPresetName(newPresetName);
  };

  const handleCloseModal = () => {
    setButtonLoading(false);
    modalProps.abort();
    if (isCreateEmptyType) {
      setEmptyFilters();
    }
  };

  const handleSaveEmptyFavoriteFilter = () => {
    saveNewFavoriteFilter(defaultSelectedFilters[filtersLibrary]);
  };

  const handleSaveNewFavoriteFilter = () => {
    saveNewFavoriteFilter(selectedFiltersGroups);
  };

  const saveNewFavoriteFilter = (filtersGroups: IFiltersGroup[]) => {
    const newPreset = {
      id: undefined,
      title: presetName,
      filtersGroups,
      page: filtersLibrary,
      guid: undefined,
      isPreset: true,
    };

    setButtonLoading(true);
    saveFavoriteFilter(newPreset, (guid) => {
      setQuery({ filterGuid: guid });
      handleCloseModal();
    });
  };

  const handleRenameFavoriteFilter = () => {
    const renamedPreset = {
      ...activePreset,
      title: presetName,
    };
    setButtonLoading(true);
    saveFavoriteFilter(renamedPreset, handleCloseModal);
  };

  const handleRemoveFavoriteFilter = () => {
    setButtonLoading(true);
    removeFavoriteFilter(activePreset, handleCloseModal, onResetComponentsState);
  };

  function getModalParams(): IGetModalParams {
    if (modalType === PresetsActionTypes.create || isCreateEmptyType) {
      return {
        title: `New${isCreateEmptyType ? " Empty" : ""} Filter Preset`,
        label: "Name",
        submitName: "Create",
        submitAction: isCreateEmptyType ? handleSaveEmptyFavoriteFilter : handleSaveNewFavoriteFilter,
      };
    } else if (modalType === PresetsActionTypes.rename) {
      return {
        title: `Rename Preset "${activePreset.title}"`,
        label: "New Name",
        submitName: "Rename",
        submitAction: handleRenameFavoriteFilter,
      };
    }

    return {
      title: `Delete Preset "${activePreset.title}"`,
      submitName: "Delete",
      submitAction: handleRemoveFavoriteFilter,
    };
  }

  const modalParams = getModalParams();

  return (
    <Modal
      className={cn("modal")}
      modalProps={modalProps}
      width={580}
      title={modalParams.title}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn btn--grey" color="night600" fill="white" caption="Cancel" onClick={modalProps.abort} />
          <Button
            cx={`btn${isDeleteType ? " btn--modal-fire" : ""}`}
            color={isDeleteType ? "fire" : "grass"}
            caption={modalParams.submitName}
            onClick={modalParams.submitAction}
            icon={buttonLoading ? loaderIcon : null}
            iconPosition="right"
            isDisabled={buttonLoading || (!isDeleteType && isInvalidPresetName)}
          />
        </>
      }
    >
      <div className={cn("modal-body", ["flex"])}>
        {isDeleteType ? (
          renderText("You won’t be able to restore it")
        ) : (
          <LabeledInput
            cx={cn("label")}
            label={modalParams.label}
            isInvalid={isInvalidPresetName}
            validationMessage={presetErrorMessage}
            isRequired
          >
            <TextInput
              value={presetName}
              onValueChange={handleSetPresetName}
              placeholder="Enter name"
              isInvalid={isInvalidPresetName}
            />
          </LabeledInput>
        )}
      </div>
    </Modal>
  );
};
