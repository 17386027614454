import { ReactComponent as architectureIcon } from "@App/assets/architecture.svg";
import { ReactComponent as ethicsLineIcon } from "@App/assets/ethics_line.svg";
import { ReactComponent as certificateIcon } from "@App/assets/file_certificate.svg";
import { ReactComponent as fileIcon } from "@App/assets/file_default.svg";
import { ReactComponent as excelIcon } from "@App/assets/file_excel.svg";
import { ReactComponent as pdfIcon } from "@App/assets/file_pdf.svg";
import { ReactComponent as powerpointIcon } from "@App/assets/file_powerpoint.svg";
import { ReactComponent as visioIcon } from "@App/assets/file_visio.svg";
import { ReactComponent as wordIcon } from "@App/assets/file_word.svg";
import { ReactComponent as notificationIcon } from "@App/assets/notification_18dp.svg";
import { ReactComponent as externalLinkIcon } from "@epam/assets/icons/common/action-external_link-12.svg";
import { ReactComponent as searchIcon } from "@epam/assets/icons/common/action-search-18.svg";
import { ReactComponent as filterIcon } from "@epam/assets/icons/common/content-filtration-18.svg";
import { ReactComponent as chartArcIcon } from "@epam/assets/icons/common/editor-chart-arc-24.svg";
import { ReactComponent as downloadIcon } from "@epam/assets/icons/common/file-download-18.svg";
import { ReactComponent as exportIcon } from "@epam/assets/icons/common/file-file_excel-24.svg";
import { ReactComponent as fileTextIcon } from "@epam/assets/icons/common/file-file_text-24.svg";
import { ReactComponent as socialGroupIcon } from "@epam/assets/icons/common/social-group-18.svg";
import { ReactComponent as personIcon } from "@epam/assets/icons/common/social-person-24.svg";
import { EpamColor } from "@epam/loveship/components/types";

import { IconPositionTypes, RowTypes } from "Components/page-card/page-card-components/page-card-components.interface";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { IRefinementFilterKeys } from "Store/reducers/filters.reducer";

import { reverseObjectKeyValue } from "./utils";

export const CopyText = "Link is copied to your clipboard.";
export const CopyFileName = "File name is copied to your clipboard.";

export const RoleCategoryIconColor: Record<string, EpamColor> = {
  "Defined in EPAM processes": "sun",
  "Assigned on the projects": "grass",
};

export const FileIcons = {
  pdf: pdfIcon,
  doc: wordIcon,
  docx: wordIcon,
  dotx: wordIcon,
  xls: excelIcon,
  xlsx: excelIcon,
  xltm: excelIcon,
  xltx: excelIcon,
  vsd: visioIcon,
  vsdx: visioIcon,
  ppt: powerpointIcon,
  pptx: powerpointIcon,
  potx: powerpointIcon,
  default: fileIcon,
  certificate: certificateIcon,
  role: personIcon,
};

export const ActionIcons = {
  subscribe: notificationIcon,
  download: downloadIcon,
  filter: filterIcon,
  search: searchIcon,
  export: exportIcon,
};

export const validLinkRegex =
  /^(?:https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/;

export const linkHesColors = ["#EFF4FF", "#FCEDEB", "#F2FACB", "#FFF7E9", "#EFE6FF", "#EBFCF6", "#FCEBFA", "#EFF0F5"];

export const Links = {
  certificatesBI: "/pages/epam-certificates-bi",
  corporateGovernance: "/pages/corporate-governance",
  analytics: "/pages/ecl-user-analytics",
  byAreaOfApplicability: "/pages/regulations-by-area-of-applicability",
  byDocumentType: "/pages/epam-regulations-by-document-type",
  byUserRole: "/pages/roles-taxonomy-skillo",
  complianceContractReview: "/pages/compliance-contract-review",
  complianceImplementationGuides: "/pages/compliance-implementation-guides",
  complianceTrainingProgram: "/pages/compliance-training-program",
  eclNews: "/pages/ecl-news",
  eclPublishingRules: "/pages/ecl-publishing-rules",
  employeeHandbooks: "https://handbook.epam.com",
  epam: "https://www.epam.com/",
  epamCertificates: "/pages/epam-certificates",
  epamCodeOfEthicalConduct: "/pages/codes/code-of-ethical-conduct",
  ethicsAndComplianceWebsite: "https://www.epam.com/about/who-we-are/ethics-and-compliance",
  employeeJourney: "https://info.epam.com/journey.html",
  ethicsLine: "https://ethics.epam.com/",
  externalAuditIssues: "/pages/external-audit-issues",
  externalAuditPlan: "/pages/external-audit-plan",
  globalPolicies: "/pages/epam's-code-ethicsline-global-policies",
  glossary: "https://info.epam.com/acronyms.html",
  home: "/",
  siteSettings: "/_layouts/15/settings.aspx",
  siteContent: "/_layouts/15/viewlsts.aspx",
  termStoreManagementTool: "/_layouts/15/termstoremanager.aspx",
  internalAuditIssues: "/pages/internal-audit-issues",
  internalProcessAuditPlan: "/pages/internal-process-audit-plan",
  investors: "https://investors.epam.com/",
  infoportal: "https://info.epam.com/micro-sites/ethics.html",
  mandatoryTrainingProgram: "/pages/mandatory-training-program",
  regulationsMap: "/pages/regulations-map",
  supplierCodeOfConduct: "/pages/policies/supplier-code-of-conduct",
  supportRequests: "/pages/support-requests",
  templatesAndExamples: "/pages/templates-and-examples",
  userGuide: "https://kb.epam.com/x/8HYgTQ",
  yammer: "https://www.yammer.com/epam.com/groups/9342508",
  allRightReservedLink: "https://kb.epam.com/x/aeOBWQ",
  certificationCoverage: "/pages/certification-coverage",
};

export const PageCards = {
  LandingPage: {
    globalPolicies: {
      className: "global-policies",
      leftRow: [
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list",
          linkedList: [
            {
              caption: "EPAM’s Code, EthicsLine, Global Policies",
              link: Links.globalPolicies,
            },
            {
              caption: "Mandatory Training Program",
              link: Links.mandatoryTrainingProgram,
            },
            {
              caption: "Supplier Code of Conduct",
              link: Links.supplierCodeOfConduct,
            },
          ],
        },
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list-left-icon flex flex-row flex-wrap",
          linkedList: [
            {
              caption: "EthicsLine",
              link: Links.ethicsLine,
              icon: ethicsLineIcon,
            },
          ],
        },
      ],
      rightRow: [
        {
          type: RowTypes.LABEL,
          className: "label",
          label: {
            caption: "Associated Resources",
          },
        },
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list-left-icon-small",
          iconPosition: IconPositionTypes.LEFT,
          linkedList: [
            {
              caption: "Employee Journey",
              link: Links.employeeJourney,
              icon: externalLinkIcon,
            },
            {
              caption: "Employee Handbooks",
              link: Links.employeeHandbooks,
              icon: externalLinkIcon,
            },
            {
              caption: "Ethics and Compliance Website",
              link: Links.ethicsAndComplianceWebsite,
              icon: externalLinkIcon,
            },
            {
              caption: "Infoportal - Ethics & Compliance",
              link: Links.infoportal,
              icon: externalLinkIcon,
            },
          ],
        },
      ],
    },
    processGovernance: {
      className: "process-governance",
      title: "Process Governance",
      leftRow: [
        {
          type: RowTypes.BUTTON,
          className: "button landing-btn",
          button: {
            caption: "Regulations Map",
            link: Links.regulationsMap,
          },
        },
      ],
      rightRow: [
        {
          type: RowTypes.LABEL,
          className: "label",
          label: {
            caption: "EPAM Regulations:",
          },
        },
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list-left-icon flex flex-row flex-wrap",
          linkedList: [
            {
              caption: "By Area of Applicability",
              link: Links.byAreaOfApplicability,
              icon: chartArcIcon,
            },
            {
              caption: "By Document Type",
              link: Links.byDocumentType,
              icon: fileTextIcon,
            },
            {
              caption: "Templates & Examples",
              link: Links.templatesAndExamples,
              icon: architectureIcon,
            },
          ],
        },
        {
          type: RowTypes.LABEL,
          className: "role-label",
          label: {
            caption: "EPAM Roles:",
          },
        },
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list-left-icon flex flex-row flex-wrap",
          linkedList: [
            {
              caption: "Roles Taxonomy: Skillo",
              link: Links.byUserRole,
              icon: socialGroupIcon,
            },
          ],
        },
      ],
    },
    certificatesAndAudits: {
      className: "certificates-and-audits",
      title: "Certificates & Audits",
      leftRow: [
        {
          type: RowTypes.BUTTON,
          className: "button landing-btn",
          button: {
            caption: "EPAM\nCertificates",
            link: Links.epamCertificates,
          },
        },
      ],
      rightRow: [
        {
          type: RowTypes.LABEL,
          className: "label",
          label: {
            caption: "Audit Program",
          },
        },
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list-right-icon",
          linkedList: [
            {
              caption: "External Audit Plan",
              link: Links.externalAuditPlan,
            },
            {
              caption: "Internal Process Audit Plan",
              link: Links.internalProcessAuditPlan,
            },
            {
              caption: "External Audit Issues",
              link: Links.externalAuditIssues,
            },
            {
              caption: "Internal Audit Issues",
              link: Links.internalAuditIssues,
            },
            {
              caption: "Certification Coverage",
              link: Links.certificationCoverage,
            },
          ],
        },
      ],
    },
    complianceAssurance: {
      className: "compliance-assurance",
      title: "Compliance Assurance",
      leftRow: [
        {
          type: RowTypes.LINKED_LIST,
          className: "linked-list",
          linkedList: [
            {
              caption: "Compliance Contract Review",
              link: Links.complianceContractReview,
            },
            {
              caption: "Compliance Implementation Guides",
              link: Links.complianceImplementationGuides,
            },
            {
              caption: "Compliance Training Program",
              link: Links.complianceTrainingProgram,
            },
            {
              caption: "Support Requests",
              link: Links.supportRequests,
            },
          ],
        },
      ],
    },
  },
};

export enum ErrorMessages {
  "emptyOrHaveInvalidCharacters" = "The title is empty or contains invalid characters!",
  "long" = "This field can have no more than 255 characters!",
  "noLocation" = "You should select the target page location",
  "nameExists" = "Preset with this name already exists",
}

export const validatePageTitle = new RegExp("^[a-zA-Z0-9,:&/_()\\- ]*$");

export const documentDownloadTag = "PALDownloadDocument";

export const refinerNameToPropertyName: Record<IRefinementFilterKeys, keyof IAttachedFile> = {
  RefinableString00: "Title",
  RefinableString01: "LocationCountry",
  RefinableString02: "SubjectDoc",
  RefinableString03: "DocumentArea",
  RefinableString04: "Standard",
  RefinableString05: "LocationCity",
  RefinableString06: "CertificationBody",
  RefinableString07: "RoleCategory",
  RefinableString08: "Group",
  RefinableDate00: "ValidFrom",
  RefinableDate01: "ValidUntil",
  RefinableDate02: "IssueDate",
};

export const propertyNameToRefinerName = reverseObjectKeyValue(refinerNameToPropertyName);

export const PostHogApiHost = "https://app.posthog.com";

export const FriendlyCertificatePrefix = "/certificates";
export const FriendlyRegulationPrefix = "/files/regulations";
export const FriendlyTemplatePrefix = "/files/templates-examples";
export const FriendlyPagePrefix = "/pages";

export const FriendlyUrlPrefixes = [
  FriendlyCertificatePrefix,
  FriendlyRegulationPrefix,
  FriendlyTemplatePrefix,
  FriendlyPagePrefix,
];

export const FoldersToIgnore = ["/EPAM Templates/CORE/"];

export const WarningMessageText =
  "Please note: ECL system is currently undergoing updates, so some functionality may be temporarily affected. Update process is planned to be completed at 2 PM (GMT+3).";

export const Extensions = {
  Aspx: ".aspx",
};

export const DefaultRegistrationNumber = "n/a";
