import React, { FC } from "react";

import { FlexRow } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { useSitePagesActions } from "Hooks/useSitePages";
import {
  IOneColumnLayout,
  IOneThirdLeftLayout,
  ISection,
  IThreeColumnsLayout,
  ITwoColumnsLayout,
  SectionTypes,
} from "SP/sitePages/sitePages.types";
import { DetailTypes, ISelectedDetailInfo } from "Store/reducers/site-pages.reducer";

import { AddSectionButton } from "../sections-add-section";
import { OneColumnLayout, OneThirdLayout, ThreeColumnsLayout, TwoColumnsLayout } from "../sections-layouts";

import "./sections-section.scss";

interface ISectionProps {
  section: ISection;
  sectionIndex: number;
  isLastSection: boolean;
}

function getOneThirdLayoutProps(section: ISection) {
  return {
    isLeft: section.type === "OneThirdLeftLayout",
    isRight: section.type === "OneThirdRightLayout",
  };
}

function isSectionSelected(selectedDetail: ISelectedDetailInfo, sectionIndex: number) {
  return selectedDetail.type === DetailTypes.section && selectedDetail.sectionIndex === sectionIndex;
}

export const Section: FC<ISectionProps> = ({ section, sectionIndex, isLastSection }) => {
  const cn = withNaming("section");

  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } = useSitePagesActions();

  const handleSelectSection = () => {
    selectSitePageDetail({ type: DetailTypes.section, sectionIndex });
  };
  const renderLayout = () => {
    switch (section.type) {
      case SectionTypes.OneColumnLayout:
        return <OneColumnLayout sectionIndex={sectionIndex} data={section.data as IOneColumnLayout} />;
      case SectionTypes.TwoColumnsLayout:
        return <TwoColumnsLayout sectionIndex={sectionIndex} data={section.data as ITwoColumnsLayout} />;
      case SectionTypes.ThreeColumnsLayout:
        return <ThreeColumnsLayout sectionIndex={sectionIndex} data={section.data as IThreeColumnsLayout} />;
      default:
        return (
          <OneThirdLayout
            sectionIndex={sectionIndex}
            data={section.data as IOneThirdLeftLayout}
            {...getOneThirdLayoutProps(section)}
          />
        );
    }
  };

  return (
    <div
      key={sectionIndex}
      className={cn(
        "",
        { "edit-mode": isSitePageInEditMode, selected: isSectionSelected(selectedDetailInfo, sectionIndex) },
        ["flex flex-col"],
      )}
    >
      {isSitePageInEditMode && (
        <>
          <AddSectionButton position="prev" sectionIndex={sectionIndex} />
          <div className={cn("badge", ["cursor-pointer"])} onClick={handleSelectSection}>
            Section {sectionIndex + 1}
          </div>
        </>
      )}
      <FlexRow cx={cn("layouts")}>{renderLayout()}</FlexRow>
      {isSitePageInEditMode && isLastSection && <AddSectionButton position="next" sectionIndex={sectionIndex} />}
    </div>
  );
};
