import React, { FC } from "react";

import { FlexCell, FlexRow, FlexSpacer } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { useDocumentActions } from "Components/documents-table/documents-table.hooks";
import { getDocumentCardProps } from "Components/search/search.helpers";
import { SubscriptionsCardProps } from "Components/subscriptions/subscriptions.interfaces";
import { getDocumentDownloadUrl } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";

export const SubscriptionsCard: FC<SubscriptionsCardProps> = ({ cn, item }) => {
  const { onFileNameClick, handleOpenDocumentInfo } = useDocumentActions();
  const { checkIsSubscribed } = useUsersData();

  const { icon, header, viewUrl, fileType, friendlyUrl, subscribeParams, serverRelativeUrl, setInfoPanelCallback } =
    getDocumentCardProps({
      cn,
      prefix: "subscription",
      item,
      handleOpenDocumentInfo,
      onFileNameClick,
      checkIsSubscribed,
    });

  return (
    <FlexCell cx={cn("subscription-body")} width="100%">
      <FlexRow>
        {icon}
        <div className={cn("subscription-header", ["flex flex-col"])}>{header}</div>
        <FlexSpacer collapseToMore />
        <div className={cn("subscription-actions")}>
          <DocumentActionsDropdown
            fileType={fileType}
            serverRelativeUrl={serverRelativeUrl}
            viewUrl={viewUrl}
            downloadUrl={getDocumentDownloadUrl(viewUrl, friendlyUrl)}
            friendlyUrl={friendlyUrl}
            subscribeParams={subscribeParams}
            onOpenInfoPanel={setInfoPanelCallback}
          />
        </div>
      </FlexRow>
    </FlexCell>
  );
};
