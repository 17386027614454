import React, { FC } from "react";

import { Checkbox, FlexRow, FlexSpacer, Text } from "@epam/loveship";

import { DocumentsFilter } from "Components/documents-filter";
import {
  filterToolbarActions,
  getSelectedData,
  isThereUnsubscribedDocument,
} from "Components/documents-toolbar/documents-toolbar.helpers";
import { LinkButtonWithAnalytics } from "Components/link-button-with-analytics";
import { getToolbarActions } from "Components/search/search.helpers";
import { getItemNameByTab, isDownloadButtonPresent } from "Components/subscriptions/subscriptions.helpers";
import { ISubscriptionsToolbarProps } from "Components/subscriptions/subscriptions.interfaces";
import { SubscribeItemTypes } from "Helpers/subscribeHelper";
import { useDocumentsActions } from "Hooks/useDocuments";
import { useUsersActions, useUsersData, useUsersMethods } from "Hooks/useUsers";
import { IVoidCb } from "SP/helpers.types";

export const SubscriptionsToolbar: FC<ISubscriptionsToolbarProps> = ({
  cn,
  hasSelected,
  checkboxLabel,
  indeterminate,
  exportCallback,
  onToggleAll,
  activeTab,
  selectedRows,
  items,
}) => {
  const { checkIsSubscribed } = useUsersData();
  const { subscriptionsLoading } = useUsersActions();
  const isAllSelected = selectedRows.length === items.length;
  const isNeedToSubscribe = isThereUnsubscribedDocument(items, selectedRows, checkIsSubscribed, false);
  const { subscribeToItemToggle } = useUsersMethods();
  const { onDownload } = useDocumentsActions();
  const { currentUser } = useUsersData();

  function handleSubscribe(cb: IVoidCb) {
    const selectedData = getSelectedData(items, selectedRows);

    subscribeToItemToggle({
      item: SubscribeItemTypes.document,
      isSubscribed: !isNeedToSubscribe,
      items: selectedData,
      cb,
    });
  }

  const toolbarActions = getToolbarActions({
    items,
    itemType: getItemNameByTab(activeTab),
    isDownloadButtonPresent: isDownloadButtonPresent(activeTab),
    isNeedToSubscribe,
    isAllSelected,
    selectedRows,
    docType: activeTab,
    subscriptionsLoading,
    handleSubscribe,
    onDownload,
    exportCallback,
    userTitle: currentUser?.jobTitle,
  });

  return (
    <FlexRow cx={cn("toolbar")}>
      <Checkbox
        cx={cn("toolbar-checkbox")}
        value={hasSelected}
        onValueChange={onToggleAll}
        indeterminate={indeterminate}
      />
      <Text cx={cn("toolbar-text")}>{checkboxLabel}</Text>
      <FlexSpacer />
      {filterToolbarActions(toolbarActions, selectedRows).map((action) => (
        <LinkButtonWithAnalytics
          key={action.id}
          cx="toolbar-button"
          iconPosition="left"
          size="none"
          fontSize="14"
          lineHeight="18"
          color="white"
          caption={action.caption}
          icon={action.icon}
          onClick={action.onClick}
        />
      ))}
      <div className="toolbar-divider"></div>
      <DocumentsFilter />
    </FlexRow>
  );
};
