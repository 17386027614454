import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { IPathKeys } from "Helpers/sitePageUpdater";
import { updateObject } from "Helpers/utils";
import { IWebPart } from "SP/sitePages/sitePages.types";
import { updateSitePage } from "Store/actions/site-pages.actions";

interface IEditorParams {
  data: unknown;
  dataKey: string;
  pathKeys: IPathKeys;
  webPart: IWebPart;
}

type IEditor = [value: string, onChange: (v: string) => void];

export const useEditor = ({ data, dataKey, pathKeys, webPart }: IEditorParams): IEditor => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>("");

  const onChange = (newValue: string) => {
    setValue(newValue);
    handleUpdateSitePage(newValue);
  };

  const handleUpdateSitePage = useDebouncedCallback((newValue: string) => {
    const newWebPart = updateObject(webPart, {
      data: updateObject(webPart.data, {
        [dataKey]: newValue,
      }),
    });

    if (_isEqual(webPart, newWebPart)) return;

    dispatch(updateSitePage(pathKeys, newWebPart));
  }, 1000);

  useEffect(() => {
    if (value !== data[dataKey]) {
      setValue(data[dataKey]);
    }
  }, [data[dataKey]]);

  return [value, onChange];
};
