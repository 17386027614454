import { IRegulation } from "SP/documents/regulations/regulations.types";
import { ILibraryFolder, IPickerInputOption } from "SP/helpers.types";
import { ISPTermObject } from "SP/SPTermStoreService";

export function getFileName(fileName: string): string {
  if (!fileName) return;

  return fileName.split(".").shift();
}

export function getDocumentOptions(items: IRegulation[]) {
  return items.map((item) => {
    const fileName = getFileName(item.fileInfo.Name);

    return {
      id: item.Id,
      label: fileName,
      additionalLabel: `${item.fileInfo.FileType.toUpperCase()} • ${item.EPAMDocumentID}`,
    };
  });
}

export function getTermOptions(terms: ISPTermObject[]): IPickerInputOption<ISPTermObject>[] {
  const options = [];

  function collectOptions(terms: ISPTermObject[], parentId = null) {
    return terms.forEach((term) => {
      options.push({
        id: term.guid,
        label: term.name,
        parentId,
        item: term,
      });

      if (Array.isArray(term.terms)) {
        collectOptions(term.terms, term.guid);
      }
    });
  }

  collectOptions(terms);

  return options;
}

export function getSitePagesHierarchyOptions(
  sitePagesHierarchy: ILibraryFolder[],
): IPickerInputOption<ILibraryFolder>[] {
  const options = [];

  function collectOptions(sitePagesHierarchy: ILibraryFolder[], parentId = null) {
    return sitePagesHierarchy.forEach((sitePagesFolder: ILibraryFolder) => {
      options.push({
        id: sitePagesFolder.ServerRelativeUrl,
        label: sitePagesFolder.Name,
        parentId,
        item: sitePagesFolder,
      });

      if (Array.isArray(sitePagesFolder.Folders)) {
        collectOptions(sitePagesFolder.Folders, sitePagesFolder.ServerRelativeUrl);
      }
    });
  }

  collectOptions(sitePagesHierarchy);

  return options;
}
