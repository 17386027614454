import { IList, sp } from "@pnp/sp/presets/all";

import { IUser } from "Store/actions/users.actions";

import { IFavoriteFilter, IFavoriteFilterDTO } from "./favoriteFilters.types";

export class FavoriteFiltersRepository {
  private favoriteFiltersList: IList = sp.web.lists.getByTitle("Favorite Filters");

  public async getFilterByGuid(guid: string): Promise<IFavoriteFilterDTO> {
    const filters = await this.favoriteFiltersList.items
      .select("Id", "Title", "page", "filters", "GUID")
      .filter(`GUID eq '${guid}'`)
      .orderBy("Title")
      .getAll();

    return filters[0];
  }

  public async getCurrentUserFavoriteFilters(currentUser: IUser): Promise<IFavoriteFilterDTO[]> {
    return await this.favoriteFiltersList.items
      .select("Id", "Title", "page", "filters", "GUID")
      .filter(`(AuthorId eq ${currentUser.id}) and (IsPreset ne 'false')`)
      .orderBy("Title")
      .getAll();
  }

  public async updateFavoriteFilter(data: IFavoriteFilterDTO): Promise<void> {
    await this.favoriteFiltersList.items.getById(data.Id).update(data);
  }

  public async addFavoriteFilter(data: IFavoriteFilterDTO): Promise<string> {
    const result = await this.favoriteFiltersList.items.add(data);
    return result.data.GUID;
  }

  public async removeFavoriteFilter(data: IFavoriteFilter): Promise<void> {
    await this.favoriteFiltersList.items.getById(data.id).recycle();
  }
}
