import _get from "lodash/get";

import { ISpDocument } from "SP/documents/documents.types";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";

import { SubscriptionsRepository } from "./subscriptions.repository";
import { ISubscriptions, ISubscriptionsItem } from "./subscriptions.types";

export class SubscriptionsService {
  private repository = new SubscriptionsRepository();
  private mapSubscriptions = (subscriptions: Partial<ISubscriptionsItem> | ISubscriptions): ISubscriptions => {
    return {
      Id: subscriptions.Id,
      certificatesId: _get(subscriptions, "certificatesId.results", subscriptions.certificatesId),
      regulationsId: _get(subscriptions, "regulationsId.results", subscriptions.regulationsId),
      templatesId: _get(subscriptions, "templatesId.results", subscriptions.templatesId),
      subscribedToECLNews: subscriptions.subscribedToECLNews,
      rolesId: _get(subscriptions, "rolesId.results", subscriptions.rolesId),
      OData__UIVersionString: subscriptions.OData__UIVersionString,
    };
  };
  /**
   * Getting current user`s subscriptions. If user does not have subscriptions to something method will return undefined
   * @param userId Id of current user
   * @returns information about current user`s subscriptions
   */
  public async getCurrentUserSubscriptions(userId: number): Promise<ISubscriptions> {
    const subscriptions = await this.repository.getCurrentUserSubscriptions(userId);
    if (subscriptions) return this.mapSubscriptions(subscriptions);
  }
  /**
   * Subscribe to ECL News
   * @param subscriptions information about current user`s subscriptions
   */
  public async subscribeToECLNews(subscriptions?: ISubscriptions): Promise<ISubscriptions> {
    const subscribeObject = await this.repository.subscribeToECLNews(subscriptions);
    return this.mapSubscriptions(subscribeObject);
  }
  /**
   * Unsubscribe from ECL News
   * @param subscriptions user`s subscriptions info
   */
  public async unsubscribeFromECLNews(subscriptions: ISubscriptions): Promise<ISubscriptions> {
    const subscribeObject = await this.repository.unsubscribeFromECLNews(subscriptions);
    return this.mapSubscriptions(subscribeObject);
  }
  /**
   * Subscribe to selected items
   * @param items array of selected items
   * @param subscriptions information about current user`s subscriptions
   */
  public async subscribeToItems(items: ISpDocument[] | IRole[], subscriptions?: ISubscriptions) {
    const subscribeObject = await this.repository.subscribeToItems(items, subscriptions);
    return this.mapSubscriptions(subscribeObject);
  }
  /**
   * Unsubscribe from selected items
   * @param items array of selected items
   * @param subscriptions information about current user`s subscriptions
   */
  public async unsubscribeFromItems(items: ISpDocument[] | IRole[], subscriptions: ISubscriptions) {
    const subscribeObject = await this.repository.unsubscribeFromItems(items, subscriptions);
    return this.mapSubscriptions(subscribeObject);
  }
}
