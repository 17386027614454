import React, { FC } from "react";

import { Checkbox } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import "./card-wrapper.scss";

interface ICardWrapperProps {
  row?: boolean;
  isCheck: boolean;
  onCheck: (checked: boolean) => void;
}

export const CardWrapper: FC<ICardWrapperProps> = ({ row, isCheck, onCheck, children }) => {
  const cn = withNaming("card-wrapper");
  return (
    <div className={cn("", { isSelected: isCheck }, ["relative flex items-center"])} data-testid="card-wrapper">
      <Checkbox
        cx={cn("checkbox", ["checkbox-list"])}
        size="18"
        value={isCheck}
        rawProps={{ "data-testid": "card-wrapper-checkbox" }}
        onValueChange={onCheck}
      />
      <div className={cn("cells", { row }, ["flex shadow"])}>{children}</div>
    </div>
  );
};
