import _sortBy from "lodash/sortBy";

import { createDateString } from "@App/libs/dayjs";
import { ReactComponent as externalLinkIcon } from "@epam/assets/icons/common/action-external_link-18.svg";
import { ReactComponent as downloadIcon } from "@epam/assets/icons/common/file-download-18.svg";
import { ButtonProps } from "@epam/uui-components";

import {
  IDocumentInfoField,
  IDocumentInfoFields,
  IInfoPanelConfigKey,
} from "Components/documents/document-info/document-info.types";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { documentDownloadTag } from "Helpers/constants";
import { getDocumentDownloadUrl, getDocumentViewUrl } from "Helpers/utils";
import { CertificateField, CommonDocumentField, CommonField, DocumentField, RoleField } from "SP/fields";
import {
  CertificatePropNames,
  CertificateUniquePropNames,
  CommonDocumentPropNames,
  CommonPropNames,
  DocumentPropNames,
  DocumentUniquePropNames,
  IInfoPanelProp,
  RolePropNames,
  RoleUniquePropNames,
} from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { IAttachedFile, LibraryName } from "SP/sitePages/sitePages.types";

const timeFormat = "DD-MMM-YYYY H:mm";

function commonInfoItems(attachedFile: IAttachedFile) {
  return {
    [CommonPropNames.Created]: {
      label: CommonField.Created,
      caption: createDateString(attachedFile?.Created, timeFormat),
    },
    [CommonPropNames.Author]: {
      label: CommonField.Author,
      caption: attachedFile?.Author?.name,
    },
    [CommonPropNames.Modified]: {
      label: CommonField.Modified,
      caption: createDateString(attachedFile?.Modified, timeFormat),
    },
    [CommonPropNames.Editor]: {
      label: CommonField.Editor,
      caption: attachedFile?.Editor?.name,
    },
  };
}

function commonDocumentItems(attachedFile: IAttachedFile) {
  return {
    [CommonDocumentPropNames.FileDirRef]: {
      label: CommonDocumentField.FileDirRef,
      caption: attachedFile?.FileDirRef?.Description,
      href: attachedFile?.FileDirRef?.Url,
    },
    [CommonDocumentPropNames.publishedVersion]: {
      label: CommonDocumentField.publishedVersion,
      caption: attachedFile?.publishedVersion,
    },
    [CommonDocumentPropNames.WorkStorage]: {
      label: CommonDocumentField.WorkStorage,
      caption: attachedFile?.WorkStorage?.Description,
      href: attachedFile?.WorkStorage?.Url,
    },
  };
}

export function getRegulationTemplateItems(attachedFile: IAttachedFile): {
  [key in DocumentPropNames]: IDocumentInfoField;
} {
  return {
    [DocumentUniquePropNames.EPAMDocumentID]: {
      label: DocumentField.EPAMDocumentID,
      caption: attachedFile?.EPAMDocumentID,
    },
    [DocumentUniquePropNames.LocationCountry]: {
      label: DocumentField.LocationCountry,
      caption: attachedFile?.LocationCountry?.join(", "),
    },
    [DocumentUniquePropNames.DocumentArea]: {
      label: DocumentField.DocumentArea,
      caption: attachedFile?.DocumentArea?.join(", "),
    },
    [DocumentUniquePropNames.ApprovalDate]: {
      label: DocumentField.ApprovalDate,
      caption: createDateString(attachedFile?.ApprovalDate),
    },
    [DocumentUniquePropNames.ApproverName]: {
      label: DocumentField.ApproverName,
      caption: attachedFile?.ApproverName,
    },
    [DocumentUniquePropNames.ApproverPosition]: {
      label: DocumentField.ApproverPosition,
      caption: attachedFile?.ApproverPosition,
    },
    [DocumentUniquePropNames.DocumentOwnerPosition]: {
      label: DocumentField.DocumentOwnerPosition,
      caption: attachedFile?.DocumentOwnerPosition,
    },
    [DocumentUniquePropNames.OrganizationalUnit]: {
      label: DocumentField.OrganizationalUnit,
      caption: attachedFile?.OrganizationalUnit?.Description,
      href: attachedFile?.OrganizationalUnit?.Url,
    },
    [DocumentUniquePropNames.BriefDescription]: {
      label: DocumentField.Description,
      caption: attachedFile.BriefDescription,
    },
    [DocumentUniquePropNames.PublishedPlace]: {
      label: DocumentField.PublishedPlace,
      caption: attachedFile?.PublishedPlace?.Description,
      href: attachedFile?.PublishedPlace?.Url,
    },
    [DocumentUniquePropNames.Category]: {
      label: DocumentField.Category,
      caption: attachedFile?.Category,
    },
    [DocumentUniquePropNames.RequiredReviewDate]: {
      label: DocumentField.RequiredReviewDate,
      caption: createDateString(attachedFile?.RequiredReviewDate),
    },
    [DocumentUniquePropNames.Company]: {
      label: DocumentField.Company,
      caption: attachedFile?.Company,
    },
    [DocumentUniquePropNames.ReviewPeriod]: {
      label: DocumentField.ReviewPeriod,
      caption: attachedFile?.ReviewPeriod,
    },
    [DocumentUniquePropNames.Standards]: {
      label: DocumentField.Standards,
      caption: attachedFile?.Standards?.join(", "),
    },
    [DocumentUniquePropNames.Subarea]: {
      label: DocumentField.Subarea,
      caption: attachedFile?.Subarea,
    },
    ...commonDocumentItems(attachedFile),
    ...commonInfoItems(attachedFile),
  };
}

export function getCertificateItems(attachedFile: IAttachedFile): {
  [key in CertificatePropNames]: IDocumentInfoField;
} {
  return {
    [CertificateUniquePropNames.EPAMDocumentID]: {
      label: CertificateField.EPAMDocumentID,
      caption: attachedFile?.EPAMDocumentID,
    },
    [CertificateUniquePropNames.Standard]: {
      label: CertificateField.Standard,
      caption: attachedFile?.Standard?.join(", "),
    },
    [CertificateUniquePropNames.LocationCountry]: {
      label: CertificateField.LocationCountry,
      caption: attachedFile?.LocationCountry?.join(", "),
    },
    [CertificateUniquePropNames.LocationCity]: {
      label: CertificateField.LocationCity,
      caption: attachedFile?.LocationCity?.join(", "),
    },
    [CertificateUniquePropNames.ValidFrom]: {
      label: CertificateField.ValidFrom,
      caption: createDateString(attachedFile?.ValidFrom),
    },
    [CertificateUniquePropNames.ValidUntil]: {
      label: CertificateField.ValidUntil,
      caption: createDateString(attachedFile?.ValidUntil),
    },
    [CertificateUniquePropNames.IssueDate]: {
      label: CertificateField.IssueDate,
      caption: createDateString(attachedFile?.IssueDate),
    },
    [CertificateUniquePropNames.CertificationBody]: {
      label: CertificateField.CertificationBody,
      caption: attachedFile?.CertificationBody,
    },
    [CertificateUniquePropNames.LegalEntities]: {
      label: CertificateField.LegalEntities,
      caption: attachedFile?.LegalEntities,
    },
    [CertificateUniquePropNames.ScopeLimitation]: {
      label: CertificateField.ScopeLimitation,
      caption: attachedFile?.ScopeLimitation,
    },
    [CertificateUniquePropNames.CertificateArea]: {
      label: CertificateField.CertificateArea,
      caption: attachedFile?.CertificateArea,
    },
    [CertificateUniquePropNames.Responsible]: {
      label: CertificateField.Responsible,
      caption: attachedFile?.Responsible,
    },
    [CertificateUniquePropNames.RegistrationNumber]: {
      label: CertificateField.RegistrationNumber,
      caption: attachedFile?.RegistrationNumber,
    },
    ...commonDocumentItems(attachedFile),
    ...commonInfoItems(attachedFile),
  };
}

export function getRoleRegistryItems(attachedFile: IAttachedFile): {
  [key in RolePropNames]: IDocumentInfoField;
} {
  return {
    [RoleUniquePropNames.Group]: {
      label: RoleField.Group,
      caption: attachedFile?.Group,
    },
    [RoleUniquePropNames.PublishedDate]: {
      label: RoleField.PublishedDate,
      caption: createDateString(attachedFile?.PublishedDate),
    },
    [RoleUniquePropNames.Approvedby]: {
      label: RoleField.ApprovedBy,
      caption: attachedFile?.Approvedby?.join(", "),
    },
    [RoleUniquePropNames.DocumentArea]: {
      label: RoleField.DocumentArea,
      caption: attachedFile?.DocumentArea?.join(", "),
    },
    [RoleUniquePropNames.ShortDescription]: {
      label: RoleField.ShortDescription,
      caption: attachedFile?.ShortDescription,
    },
    [RoleUniquePropNames.Status]: {
      label: RoleField.Status,
      caption: attachedFile?.Status,
    },
    [RoleUniquePropNames.Status]: {
      label: RoleField.Status,
      caption: attachedFile?.Status,
    },
    [RoleUniquePropNames.Regulations]: {
      label: RoleField.Regulations,
      caption: attachedFile?.Title,
      multipleLinks: attachedFile?.Regulations,
    },
    [RoleUniquePropNames.Documents]: {
      label: RoleField.Documents,
      caption: attachedFile?.Documents.length === 0 ? undefined : attachedFile?.Title,
      multipleLinks: attachedFile?.Documents?.map((doc) => doc.PublishedPlace),
    },
    ...commonInfoItems(attachedFile),
  };
}

export function getDocumentButtons(attachedFile: IAttachedFile, addRecentItem, jobTitle: string): ButtonProps[] {
  const documentViewUrl = getDocumentViewUrl(attachedFile?.viewUrl, attachedFile?.FriendlyUrl);
  const documentDownloadUrl = getDocumentDownloadUrl(attachedFile?.viewUrl, attachedFile?.FriendlyUrl);

  return [
    {
      caption: "Open",
      icon: externalLinkIcon,
      onClick: () => {
        addRecentItem(attachedFile);
        window.open(documentViewUrl);
      },
    },
    {
      caption: "Download",
      icon: downloadIcon,
      href: documentDownloadUrl,
      cx: documentDownloadTag,
      onClick: () => {
        analyticsHelper.analyticsService?.provideDownload(jobTitle, documentDownloadUrl);
      },
    },
  ];
}

export function getRoleButtons(attachedFile: IAttachedFile): ButtonProps[] {
  return [
    {
      caption: "Open",
      icon: externalLinkIcon,
      link: { pathname: attachedFile.roleUrl },
    },
  ];
}

export const infoPanelConfigLibraryKeys: Partial<Record<LibraryName, IInfoPanelConfigKey>> = {
  [LibraryName.roles]: "roles",
  [LibraryName.certificates]: "certificates",
  [LibraryName.templates]: "documents",
  [LibraryName.regulations]: "documents",
};

export function getInfoPanelFields(attachedFile: IAttachedFile): IDocumentInfoFields {
  if (attachedFile.sourceLibrary === LibraryName.roles) {
    return getRoleRegistryItems(attachedFile);
  } else if (attachedFile.sourceLibrary === LibraryName.certificates) {
    return getCertificateItems(attachedFile);
  }

  return getRegulationTemplateItems(attachedFile);
}

export function getInfoPanelMergedFields(
  infoPanelFields: IDocumentInfoFields,
  infoPanelLibraryConfig: IInfoPanelProp[],
) {
  const infoPanelConfigurationFields = infoPanelLibraryConfig
    .filter(({ prop }) => !!infoPanelFields[prop])
    .map((libraryProps) => ({
      ...libraryProps,
      label: infoPanelFields[libraryProps.prop].label,
    }));

  const infoPanelRenderFields = infoPanelLibraryConfig
    .filter(({ visible, prop }) => visible && !!infoPanelFields[prop]?.caption)
    .map(({ prop, order }) => ({
      ...infoPanelFields[prop],
      order,
    }));

  return { infoPanelConfigurationFields, infoPanelRenderFields: _sortBy(infoPanelRenderFields, ["order"]) };
}
