import { useDispatch, useSelector } from "react-redux";

import { getIconsRequest } from "Store/actions/standards-icons.actions";
import { IRootReducerState } from "Store/reducers";
import { IStandardIcon } from "Store/reducers/standards-icons.reducer";

export function useIconsData() {
  const icons = useSelector<IRootReducerState, IStandardIcon[]>((state) => state.standardsIcons.icons);

  return { icons };
}

export function useIconsActions() {
  const dispatch = useDispatch();
  const handleGetIcons = () => dispatch(getIconsRequest());

  return { getIcons: handleGetIcons };
}
