import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import React, { FC, useCallback, useMemo, useRef } from "react";

import { ReactComponent as AddIcon } from "@epam/assets/icons/common/content-add-fill-18.svg";
import { ReactComponent as shareIcon } from "@epam/assets/icons/common/content-link-18.svg";
import { FlexRow, IconButton, LinkButton, ScrollBars, Text, Tooltip } from "@epam/loveship";
import { Tooltip as TooltipType } from "@epam/uui-components";

import { DocumentsFilterPresets } from "Components/documents-filter/documents-filter-presets";
import { CopyText } from "Helpers/constants";
import { isEqualFiltersGroups } from "Helpers/utils";
import { useCopyText } from "Hooks/useCopyText";
import useQuery from "Hooks/useQuery";
import { useSearch } from "Hooks/useSearch";
import { initialGroupDocuments, initialGroupRoles } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData, useFiltersMethods } from "./documents-filter.hooks";
import { DocumentsFilterGroup } from "./documents-filter-group";

export const DocumentsFilterPanel: FC = () => {
  const { clearQuery } = useQuery();
  const {
    activePresetFilters,
    filtersLibrary,
    selectedFiltersGroups,
    presetSelectedFilters,
    isNotSubscriptionsPage,
    isSearchPage,
  } = useFiltersData();
  const { setQuery } = useQuery();
  const { resetFilters, resetActivePreset, saveTempFilter, addFiltersGroup } = useFiltersMethods();

  const resetToggleKey = useRef(false);
  const tooltipRef = useRef<TooltipType>(null);
  const { isCopied, handleCopyUrl, copyUrlWithGuid } = useCopyText({ ref: tooltipRef, content: window.location.href });
  const { getRefinedGlobalSearchResults } = useSearch();

  const hasSelectedParamsToSaveAsNewPreset =
    !activePresetFilters &&
    presetSelectedFilters.some(
      (f) => !_isEmpty(f) && !_isEqual(f, initialGroupDocuments.filters) && !_isEqual(f, initialGroupRoles.filters),
    );

  const isActivePresetModified = useMemo(() => {
    return !!activePresetFilters && !isEqualFiltersGroups(activePresetFilters.filtersGroups, selectedFiltersGroups);
  }, [activePresetFilters, selectedFiltersGroups]);

  const isNotSearchAndSubscriptionPage = isNotSubscriptionsPage && !isSearchPage;

  const onResetComponentsState = () => {
    resetToggleKey.current = !resetToggleKey.current;
  };

  const handleClearAll = () => {
    onResetComponentsState();
    resetActivePreset();
    resetFilters();
    clearQuery();
    getRefinedGlobalSearchResults();
  };

  const onCopyLinkClick = (e) => {
    if (hasSelectedParamsToSaveAsNewPreset || isActivePresetModified) {
      const newPreset = {
        id: undefined,
        title: "Not preset",
        filtersGroups: selectedFiltersGroups,
        page: filtersLibrary,
        guid: undefined,
        isPreset: false,
      };
      saveTempFilter(newPreset, (guid) => {
        if (isActivePresetModified) {
          setQuery({ filterGuid: guid });
        }

        copyUrlWithGuid(e, guid);
      });
    } else {
      handleCopyUrl(e);
    }
  };

  const onAddGroup = () => {
    if (isLastGroupEmpty) return;

    addFiltersGroup(`Filter Group ${selectedFiltersGroups.length + 1}`);
  };

  const isLastGroup = useCallback(
    (i: number) => {
      return i === selectedFiltersGroups.length - 1;
    },
    [selectedFiltersGroups],
  );

  const isLastGroupEmpty = useMemo(() => {
    return (
      _isEmpty(selectedFiltersGroups[selectedFiltersGroups.length - 1].filters) ||
      Object.keys(selectedFiltersGroups[selectedFiltersGroups.length - 1].filters).every((key) =>
        Object.keys(initialGroupDocuments.filters).includes(key),
      )
    );
  }, [selectedFiltersGroups]);

  return (
    <div className={cn("panel", ["flex flex-col"])}>
      <div
        className={cn(
          "panel-head",
          {
            hasUnsavedChanges:
              isNotSearchAndSubscriptionPage && (hasSelectedParamsToSaveAsNewPreset || isActivePresetModified),
          },
          ["flex flex-col"],
        )}
      >
        <FlexRow cx="min-h-0">
          <Text color="night900" size="none" fontSize="16" lineHeight="24">
            Filters
          </Text>
          {isNotSubscriptionsPage && (
            <Tooltip ref={tooltipRef} content={isCopied ? CopyText : "Copy link"} placement="bottom">
              <IconButton cx={cn("share-icon")} icon={shareIcon} color="night500" onClick={onCopyLinkClick} />
            </Tooltip>
          )}
        </FlexRow>
        <LinkButton cx={cn("clear-all")} size="none" caption="Clear All" onClick={handleClearAll} />
        {isNotSearchAndSubscriptionPage && (
          <DocumentsFilterPresets
            hasSelectedParamsToSaveAsNewPreset={hasSelectedParamsToSaveAsNewPreset}
            isActivePresetModified={isActivePresetModified}
            onResetComponentsState={onResetComponentsState}
          />
        )}
      </div>
      <ScrollBars cx={cn("panel-body")} hasTopShadow>
        {selectedFiltersGroups.map((group, i) => (
          <DocumentsFilterGroup
            key={`${i}_${selectedFiltersGroups.length}`}
            group={group}
            resetToggleKey={resetToggleKey}
            groupIndex={i}
            showHeader={selectedFiltersGroups.length > 1}
            showFilters={isLastGroup(i) && group.enabled}
            renderDivider={!isLastGroup(i)}
          />
        ))}
      </ScrollBars>
      <div className={cn("button-divider", ["divider"])} />
      <div className={cn("button-divider-text")}> OR</div>
      {isLastGroupEmpty && (
        <Tooltip
          trigger="hover"
          renderContent={() => (
            <>New filter group can be added once at least one item has been selected in previous filter group</>
          )}
        >
          <div className={cn("add-group")} onClick={onAddGroup}>
            <IconButton cx={"icon"} color={"night400"} icon={AddIcon} />
            <Text cx={"text--disable"}>Add Filter Group</Text>
          </div>
        </Tooltip>
      )}
      {!isLastGroupEmpty && (
        <div className={cn("add-group")} onClick={onAddGroup}>
          <IconButton cx={"icon"} color={"sky"} icon={AddIcon} />
          <Text cx={"text"}>Add Filter Group</Text>
        </div>
      )}
    </div>
  );
};
