import { IPathKeys } from "Helpers/sitePageUpdater";
import { ILibraryFolder, IVoidCb, IVoidCbWithParam } from "SP/helpers.types";
import { IAttachCommand } from "SP/sitePages/sitePages.service";
import { IAttachedFile, INewSitePageInfo, ISitePage, ISitePageVersion } from "SP/sitePages/sitePages.types";
import { ISelectedDetailInfo } from "Store/reducers/site-pages.reducer";

import { IUser } from "./users.actions";

export enum SitePagesActionsTypes {
  ADD_NEW_SITE_PAGE_REQUEST = "[SITE PAGES] ADD NEW SITE PAGE REQUEST",
  ADD_NEW_SITE_PAGE_SUCCESS = "[SITE PAGES] ADD NEW SITE PAGE SUCCESS",
  ADD_NEW_SITE_PAGE_FAILURE = "[SITE PAGES] ADD NEW SITE PAGE FAILURE",

  UPDATE_SITE_PAGE = "[SITE PAGES] UPDATE SITE PAGE",
  UPDATE_SITE_PAGE_FILES = "[SITE PAGES] UPDATE SITE PAGE FILES",
  SELECT_SITE_PAGE_DETAIL = "[SITE PAGES] SELECT SITE PAGE DETAIL",
  SET_SITE_PAGE_EDIT_MODE = "[SITE PAGES] SET SITE PAGE EDIT MODE",

  SET_SITE_PAGE_VERSION_HISTORY_MODE = "[SITE PAGES] SET SITE PAGE VERSION HISTORY MODE",
  SET_SITE_PAGE_VERSION_HISTORY_MODE_SUCCESS = "[SITE PAGES] SET SITE PAGE VERSION HISTORY MODE SUCCESS",
  SET_SITE_PAGE_VERSION_HISTORY_MODE_FAILURE = "[SITE PAGES] SET SITE PAGE VERSION HISTORY MODE FAILURE",

  SET_SITE_PAGE_IN_VALID_PROPERTY = "[SITE PAGES] SET SITE PAGE IN VALID PROPERTY",
  CLEAR_SITE_PAGE_IN_VALID = "[SITE PAGES] CLEAR SITE PAGE IN VALID",

  GET_SITE_PAGE_BY_FRIENDLY_URL = "[SITE PAGES] GET SITE PAGE BY FRIENDLY URL",
  GET_SITE_PAGE_BY_URL = "[SITE PAGES] GET SITE PAGE BY URL",
  GET_SITE_PAGE_BY_URL_SUCCESS = "[SITE PAGES] GET SITE PAGE BY URL SUCCESS",
  GET_SITE_PAGE_BY_URL_FAILURE = "[SITE PAGES] GET SITE PAGE BY URL FAILURE",

  GET_SITE_PAGES_HIERARCHY_REQUEST = "[SITE PAGES] GET SITE PAGES HIERARCHY REQUEST",
  GET_SITE_PAGES_HIERARCHY_SUCCESS = "[SITE PAGES] GET SITE PAGES HIERARCHY SUCCESS",
  GET_SITE_PAGES_HIERARCHY_FAILURE = "[SITE PAGES] GET SITE PAGES HIERARCHY FAILURE",

  CHECK_OUT_SITE_PAGE = "[SITE PAGES] CHECK OUT SITE PAGE",
  CHECK_OUT_SITE_PAGE_SUCCESS = "[SITE PAGES] CHECK OUT SITE PAGE SUCCESS",
  CHECK_OUT_SITE_PAGE_FAILURE = "[SITE PAGES] CHECK OUT SITE PAGE FAILURE",

  DISCARD_CHECK_OUT_SITE_PAGE = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE",
  DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE SUCCESS",
  DISCARD_CHECK_OUT_SITE_PAGE_FAILURE = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE FAILURE",

  SAVE_AS_DRAFT_SITE_PAGE = "[SITE PAGES] SAVE AS DRAFT SITE PAGE",
  SAVE_AS_DRAFT_SITE_PAGE_SUCCESS = "[SITE PAGES] SAVE AS DRAFT SITE PAGE SUCCESS",
  SAVE_AS_DRAFT_SITE_PAGE_FAILURE = "[SITE PAGES] SAVE AS DRAFT SITE PAGE FAILURE",

  REPUBLISH_SITE_PAGE = "[SITE PAGES] REPUBLISH SITE PAGE",
  REPUBLISH_SITE_PAGE_SUCCESS = "[SITE PAGES] REPUBLISH SITE PAGE SUCCESS",
  REPUBLISH_SITE_PAGE_FAILURE = "[SITE PAGES] REPUBLISH SITE PAGE FAILURE",

  ATTACH_SITE_PAGE_TO_DOCUMENTS = "[SITE PAGES] ATTACH SITE PAGE TO DOCUMENTS",
  ATTACH_SITE_PAGE_TO_DOCUMENTS_SUCCESS = "[SITE PAGES] ATTACH SITE PAGE TO DOCUMENTS SUCCESS",
  ATTACH_SITE_PAGE_TO_DOCUMENTS_FAILURE = "[SITE PAGES] ATTACH SITE PAGE TO DOCUMENTS FAILURE",

  GET_SITE_PAGE_VERSIONS = "[SITE PAGES] GET SITE PAGE VERSIONS",
  GET_SITE_PAGE_VERSIONS_SUCCESS = "[SITE PAGES] GET SITE PAGE VERSIONS SUCCESS",
  GET_SITE_PAGE_VERSIONS_FAILURE = "[SITE PAGES] GET SITE PAGE VERSIONS FAILURE",

  SELECT_SITE_PAGE_VERSION = "[SITE PAGES] SELECT SITE PAGE VERSION",
  SELECT_SITE_PAGE_VERSION_SUCCESS = "[SITE PAGES] SELECT SITE PAGE VERSION SUCCESS",
  SELECT_SITE_PAGE_VERSION_FAILURE = "[SITE PAGES] SELECT SITE PAGE VERSION FAILURE",

  SET_SITE_PAGE_VERSION = "[SITE PAGES] SET SITE PAGE VERSION",
  SET_SITE_PAGE_VERSION_SUCCESS = "[SITE PAGES] SET SITE PAGE VERSION SUCCESS",
  SET_SITE_PAGE_VERSION_FAILURE = "[SITE PAGES] SET SITE PAGE VERSION FAILURE",
}

export interface IUpdateSitePageAction {
  type: SitePagesActionsTypes.UPDATE_SITE_PAGE;
  pathKeys: IPathKeys;
  // eslint-disable-next-line
  sitePageValue: any;
}

export function updateSitePage(pathKeys: IPathKeys, sitePageValue): IUpdateSitePageAction {
  return {
    type: SitePagesActionsTypes.UPDATE_SITE_PAGE,
    pathKeys,
    sitePageValue,
  };
}

export interface ISelectSitePageDetailAction {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL;
  detailInfo: ISelectedDetailInfo;
}

export function selectSitePageDetail(detailInfo: ISelectedDetailInfo): ISelectSitePageDetailAction {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL,
    detailInfo,
  };
}

export interface ISetSitePageEditModeAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE;
  toggle: boolean;
  sitePageUrl: string;
}

export function setSitePageEditMode(toggle: boolean, sitePageUrl: string): ISetSitePageEditModeAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE,
    toggle,
    sitePageUrl,
  };
}

export interface ISetSitePageVersionHistoryModeAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE;
  pageUrl: string;
  toggle: boolean;
}

export function setSitePageVersionHistoryMode(pageUrl: string, toggle: boolean): ISetSitePageVersionHistoryModeAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE,
    pageUrl,
    toggle,
  };
}

export interface ISetSitePageVersionHistoryModeSuccessAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_SUCCESS;
  toggle: boolean;
}

export function setSitePageVersionHistoryModeSuccess(toggle: boolean): ISetSitePageVersionHistoryModeSuccessAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_SUCCESS,
    toggle,
  };
}

export interface ISetSitePageVersionHistoryModeFailureAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_FAILURE;
  error: string;
}

export function setSitePageVersionHistoryModeFailure(error): ISetSitePageVersionHistoryModeFailureAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE_FAILURE,
    error,
  };
}

export interface ISetSitePageInValidPropertyAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY;
  toggle: boolean;
  property: string;
}

export function setSitePageInValidProperty(toggle: boolean, property: string): ISetSitePageInValidPropertyAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY,
    toggle,
    property,
  };
}

export interface IClearSitePageInValidAction {
  type: SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID;
}

export function clearSitePageInValid(): IClearSitePageInValidAction {
  return {
    type: SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID,
  };
}

// GET SITE PAGE BY URL START
export interface IGetSitePageByFriendlyUrlRequestAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_FRIENDLY_URL;
  friendlyUrl: string;
}

export function getSitePageByFriendlyUrl(friendlyUrl: string): IGetSitePageByFriendlyUrlRequestAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_FRIENDLY_URL,
    friendlyUrl,
  };
}

export interface IGetSitePageByUrlRequestAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL;
  url: string;
}

export function getSitePageByUrl(url: string): IGetSitePageByUrlRequestAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL,
    url,
  };
}

type ISitePagePayload = {
  url: string;
  sitePage: ISitePage;
};

export interface IGetSitePageByUrlSuccessAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS;
  payload: ISitePagePayload;
}

export function getSitePageByUrlSuccess(payload: ISitePagePayload): IGetSitePageByUrlSuccessAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS,
    payload,
  };
}

type IAttachedFilesPayload = {
  files: IAttachedFile[];
  url: string;
};

export interface IUpdateSitePageFilesAction {
  type: SitePagesActionsTypes.UPDATE_SITE_PAGE_FILES;
  payload: IAttachedFilesPayload;
}

export function updateSitePageFiles(payload: IAttachedFilesPayload): IUpdateSitePageFilesAction {
  return {
    type: SitePagesActionsTypes.UPDATE_SITE_PAGE_FILES,
    payload,
  };
}

export interface IGetSitePageByUrlFailureAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE;
  error: string;
}

export function getSitePageByUrlFailure(error): IGetSitePageByUrlFailureAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE,
    error: error.message,
  };
}
// GET SITE PAGE BY URL END

// CHECK OUT SITE PAGE START
export interface ICheckoutParams {
  sitePageId: number;
  sitePageUrl: string;
  currentUser: IUser;
  cb?: IVoidCb;
}

export interface ICheckOutSitePageRequestAction extends ICheckoutParams {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE;
}

export function checkOutSitePage(params: ICheckoutParams): ICheckOutSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE,
    ...params,
  };
}

export interface ICheckOutSitePageSuccessAction extends Omit<ICheckoutParams, "sitePageId" | "cb"> {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS;
}

export function checkOutSitePageSuccess(sitePageUrl: string, currentUser: IUser): ICheckOutSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS,
    sitePageUrl,
    currentUser,
  };
}

export interface ICheckOutSitePageFailureAction {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE;
  error: string;
}

export function checkOutSitePageFailure(error): ICheckOutSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE,
    error: error.message,
  };
}
// CHECK OUT SITE PAGE END

// DISCARD CHECK OUT SITE PAGE START
export interface IDiscardCheckoutParams {
  sitePageId: number;
  sitePageUrl: string;
}

export interface IDiscardCheckOutSitePageRequestAction extends IDiscardCheckoutParams {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE;
}

export function discardCheckOutSitePage(params: IDiscardCheckoutParams): IDiscardCheckOutSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE,
    ...params,
  };
}

export interface IDiscardCheckOutSitePageSuccessAction {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function discardCheckOutSitePageSuccess(
  sitePageUrl: string,
  sitePage: ISitePage,
): IDiscardCheckOutSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS,
    sitePageUrl,
    sitePage,
  };
}

export interface IDiscardCheckOutSitePageFailureAction {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE;
  error: string;
}

export function discardCheckOutSitePageFailure(error): IDiscardCheckOutSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE,
    error: error.message,
  };
}
// DISCARD CHECK OUT SITE PAGE END

// SAVE AS DRAFT SITE PAGE START
export interface ISaveAsDraftSitePageRequestAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE;
  sitePageId: number;
  sitePageUrl: string;
}

export function saveAsDraftSitePage(sitePageId: number, sitePageUrl: string): ISaveAsDraftSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE,
    sitePageId,
    sitePageUrl,
  };
}

export interface ISaveAsDraftSitePageSuccessAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function saveAsDraftSitePageSuccess(
  sitePageUrl: string,
  sitePage: ISitePage,
): ISaveAsDraftSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS,
    sitePageUrl: sitePageUrl,
    sitePage: sitePage,
  };
}

export interface ISaveAsDraftSitePageFailureAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE;
  error: string;
}

export function saveAsDraftSitePageFailure(error): ISaveAsDraftSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE,
    error: error.message,
  };
}
// SAVE AS DRAFT SITE PAGE END

// REPUBLISH SITE PAGE START
export interface IRepublishSitePageRequestAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE;
  sitePageId: number;
  sitePageUrl: string;
}

export function republishSitePage(sitePageId: number, sitePageUrl: string): IRepublishSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE,
    sitePageId,
    sitePageUrl,
  };
}

export interface IRepublishSitePageSuccessAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function republishSitePageSuccess(sitePageUrl: string, sitePage: ISitePage): IRepublishSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS,
    sitePageUrl,
    sitePage,
  };
}

export interface IRepublishSitePageFailureAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE;
  error: string;
}

export function republishSitePageFailure(error): IRepublishSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE,
    error: error.message,
  };
}
// REPUBLISH SITE PAGE END

// ATTACH SITE PAGE TO DOCUMENTS START
export interface IAttachSitePageParams {
  sitePageUrl: string;
  documents: number[];
  library: string;
  command: IAttachCommand;
}
export interface IAttachSitePageToDocumentsRequestAction extends IAttachSitePageParams {
  type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS;
}

export function attachSitePageToDocuments(params: IAttachSitePageParams): IAttachSitePageToDocumentsRequestAction {
  return {
    type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS,
    ...params,
  };
}

export interface IAttachSitePageToDocumentsSuccessAction {
  type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_SUCCESS;
}

export function attachSitePageToDocumentsSuccess(): IAttachSitePageToDocumentsSuccessAction {
  return {
    type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_SUCCESS,
  };
}

export interface IAttachSitePageToDocumentsFailureAction {
  type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_FAILURE;
  error: string;
}

export function attachSitePageToDocumentsFailure(error): IAttachSitePageToDocumentsFailureAction {
  return {
    type: SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS_FAILURE,
    error: error.message,
  };
}
// ATTACH SITE PAGE TO DOCUMENTS END

// GET SITE PAGES HIERARCHY START
export interface IGetSitePagesHierarchyRequestAction {
  type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_REQUEST;
}

export function getSitePagesHierarchyRequest(): IGetSitePagesHierarchyRequestAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_REQUEST,
  };
}

export interface IGetSitePagesHierarchySuccessAction {
  type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_SUCCESS;
  hierarchy: ILibraryFolder[];
}

export function getSitePagesHierarchySuccess(hierarchy: ILibraryFolder[]): IGetSitePagesHierarchySuccessAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_SUCCESS,
    hierarchy,
  };
}

export interface IGetSitePagesHierarchyFailureAction {
  type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_FAILURE;
  error: string;
}

export function getSitePagesHierarchyFailure(error): IGetSitePagesHierarchyFailureAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_FAILURE,
    error: error.message,
  };
}
// GET SITE PAGES HIERARCHY END

// CREATE SITE PAGE START
export interface IAddNewSitePageRequestAction {
  type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_REQUEST;
  newPageInfo: INewSitePageInfo;
  redirectCb: IVoidCbWithParam;
  errorNotificationCb: IVoidCbWithParam;
}

export function addNewSitePageRequest(
  newPageInfo: INewSitePageInfo,
  redirectCb: IVoidCbWithParam,
  errorNotificationCb: IVoidCbWithParam,
): IAddNewSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_REQUEST,
    newPageInfo,
    redirectCb,
    errorNotificationCb,
  };
}

export interface IAddNewSitePageSuccessAction {
  type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_SUCCESS;
}

export function addNewSitePageSuccess(): IAddNewSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_SUCCESS,
  };
}

export interface IAddNewSitePageFailureAction {
  type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_FAILURE;
}

export function addNewSitePageFailure(): IAddNewSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.ADD_NEW_SITE_PAGE_FAILURE,
  };
}
// CREATE SITE PAGE END

export interface IGetSitePageVersionsAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS;
  pageUrl: string;
}

export function getSitePageVersions(pageUrl: string): IGetSitePageVersionsAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS,
    pageUrl,
  };
}

export interface IGetSitePageVersionsSuccessAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS;
  pageUrl: string;
  versions: ISitePageVersion[];
}

export function getSitePageVersionsSuccess(
  pageUrl: string,
  versions: ISitePageVersion[],
): IGetSitePageVersionsSuccessAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS,
    pageUrl,
    versions,
  };
}

export interface IGetSitePageVersionsFailureAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE;
  error: string;
}

export function getSitePageVersionsFailure(error): IGetSitePageVersionsFailureAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE,
    error: error.message,
  };
}

export interface ISelectSitePageVersionAction {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION;
  pageUrl: string;
  versionIndex: number;
}

export function selectSitePageVersion(pageUrl: string, versionIndex: number): ISelectSitePageVersionAction {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION,
    pageUrl,
    versionIndex,
  };
}

export interface ISelectSitePageVersionActionSuccess {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_SUCCESS;
  pageUrl: string;
  versionIndex: number;
}

export function selectSitePageVersionSuccess(
  pageUrl: string,
  versionIndex: number,
): ISelectSitePageVersionActionSuccess {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_SUCCESS,
    pageUrl,
    versionIndex,
  };
}

export interface ISelectSitePageVersionActionFailure {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_FAILURE;
  error: string;
}

export function selectSitePageVersionFailure(error) {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION_FAILURE,
    error,
  };
}

export interface ISetSitePageVersionAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION;
  pageUrl: string;
  version: ISitePageVersion;
}

export function setSitePageVersion(pageUrl: string, version: ISitePageVersion): ISetSitePageVersionAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION,
    pageUrl,
    version,
  };
}

export interface ISetSitePageVersionSuccessAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS;
  pageUrl: string;
  page: ISitePage;
}

export function setSitePageVersionSuccess(pageUrl: string, page: ISitePage): ISetSitePageVersionSuccessAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS,
    pageUrl,
    page,
  };
}

export interface ISetSitePageVersionFailureAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE;
  error: string;
}

export function setSitePageVersionFailure(error): ISetSitePageVersionFailureAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE,
    error: error.message,
  };
}

export type ISitePagesActionsTypes =
  | IUpdateSitePageAction
  | IUpdateSitePageFilesAction
  | ISelectSitePageDetailAction
  | ISetSitePageEditModeAction
  | ISetSitePageVersionHistoryModeAction
  | ISetSitePageVersionHistoryModeSuccessAction
  | ISetSitePageVersionHistoryModeFailureAction
  | ISetSitePageInValidPropertyAction
  | IClearSitePageInValidAction
  | IGetSitePageByFriendlyUrlRequestAction
  | IGetSitePageByUrlRequestAction
  | IGetSitePageByUrlSuccessAction
  | IGetSitePageByUrlFailureAction
  | IGetSitePagesHierarchyRequestAction
  | IGetSitePagesHierarchySuccessAction
  | IGetSitePagesHierarchyFailureAction
  | ICheckOutSitePageRequestAction
  | ICheckOutSitePageSuccessAction
  | ICheckOutSitePageFailureAction
  | IDiscardCheckOutSitePageRequestAction
  | IDiscardCheckOutSitePageSuccessAction
  | IDiscardCheckOutSitePageFailureAction
  | ISaveAsDraftSitePageRequestAction
  | ISaveAsDraftSitePageSuccessAction
  | ISaveAsDraftSitePageFailureAction
  | IRepublishSitePageRequestAction
  | IRepublishSitePageSuccessAction
  | IRepublishSitePageFailureAction
  | IAttachSitePageToDocumentsRequestAction
  | IAttachSitePageToDocumentsSuccessAction
  | IAttachSitePageToDocumentsFailureAction
  | IAddNewSitePageRequestAction
  | IAddNewSitePageSuccessAction
  | IAddNewSitePageFailureAction
  | IGetSitePageVersionsAction
  | IGetSitePageVersionsSuccessAction
  | IGetSitePageVersionsFailureAction
  | ISelectSitePageVersionAction
  | ISelectSitePageVersionActionSuccess
  | ISelectSitePageVersionActionFailure
  | ISetSitePageVersionAction
  | ISetSitePageVersionSuccessAction
  | ISetSitePageVersionFailureAction;
