import React from "react";

import { IconContainer, Text } from "@epam/loveship";

import { calculateSelectedFiltersGroupCount } from "Components/documents-filter/documents-filter.helpers";
import { useFiltersData } from "Components/documents-filter/documents-filter.hooks";
import { useInfoPanel } from "Components/info-panel/info-panel.hooks";
import { withNaming } from "Helpers/bemClassname";
import { ActionIcons } from "Helpers/constants";

import { DocumentsFilterPanel } from "./documents-filter-panel";

import "./documents-filter.scss";

export const cn = withNaming("documents-filter");

export const DocumentsFilter = () => {
  const { infoPanel, setInfoPanel } = useInfoPanel();
  const { selectedFiltersGroups } = useFiltersData();

  const selectedFiltersCount = calculateSelectedFiltersGroupCount(selectedFiltersGroups);
  const isOpen = infoPanel?.componentName === "DocumentsFilter";
  const color = !!selectedFiltersCount ? "sky" : "white";

  const filterPanelToggle = () => {
    if (isOpen) {
      return setInfoPanel(null);
    }

    setInfoPanel({
      component: <DocumentsFilterPanel />,
      componentName: "DocumentsFilter",
      hasScroll: false,
    });
  };

  return (
    <div
      className={cn("", { isOpen, isSelected: !!selectedFiltersCount }, [
        "flex justify-between items-center cursor-pointer",
      ])}
      onClick={filterPanelToggle}
    >
      <IconContainer cx={cn("icon")} icon={ActionIcons.filter} color={color} />
      <Text size="36" color={color} font="sans-semibold" fontSize="14" lineHeight="18">
        Filter
      </Text>
      {!!selectedFiltersCount && (
        <Text
          cx={cn("counter", ["flex justify-center"])}
          size="none"
          font="sans-semibold"
          fontSize="12"
          lineHeight="18"
          color="white"
        >
          {selectedFiltersCount}
        </Text>
      )}
    </div>
  );
};
