import { FC, useEffect } from "react";

import handleError from "Helpers/errorHandler";

export const NotFound: FC = () => {
  useEffect(() => {
    handleError({ status: 404 });
  }, []);

  return null;
};
