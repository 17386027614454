import React, { FC, useCallback, useContext } from "react";

import { DocumentsViewContext } from "@App/context/DocumentsViewContext";
import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up-18.svg";
import { ReactComponent as tableSortAscIcon } from "@epam/assets/icons/common/table-sort_asc-18.svg";
import { ReactComponent as tableSortDescIcon } from "@epam/assets/icons/common/table-sort_desc-18.svg";
import { IconButton, PickerInput, Text } from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui";
import { PickerTogglerProps } from "@epam/uui-components";

import { IColumn, ISort } from "Components/documents-table/documents-table.interface";

interface ISortSelectProps {
  columns: IColumn[];
  sort: ISort;
  cn: (b, e?, m?) => string;
  onSort: (s: ISort) => void;
}

export const SortSelect: FC<ISortSelectProps> = ({ cn, columns, sort, onSort }) => {
  const sortDataSource = useArrayDataSource(
    {
      items: columns
        .filter((column) => !!column.isSortable)
        .map((column) => ({
          id: column.key,
          label: column.caption,
        })),
    },
    [columns],
  );

  const { searchValue } = useContext(DocumentsViewContext) || {};
  const isDisabled = !searchValue && sortDataSource.props.items.length === 1;

  const renderToggler = useCallback(
    (props: PickerTogglerProps<any>) => {
      const selectedItem = props.selection[0]?.value;

      return (
        <div
          className={cn("sort-picker-input", { isDisabled, isOpen: props.isOpen }, [
            "flex justify-between items-center",
          ])}
          onClick={props.onClick}
          ref={props.ref}
        >
          <Text
            cx={cn("sort-picker-value")}
            size="none"
            color={selectedItem ? "night900" : "night600"}
            fontSize="14"
            lineHeight="24"
          >
            {selectedItem ? props.getName(selectedItem) : props.placeholder}
          </Text>
          <IconButton icon={props.isOpen ? chevronUp : chevronDown} />
        </div>
      );
    },
    [isDisabled],
  );

  return (
    <div className={cn("sort-select", ["flex items-center"])}>
      <Text cx={cn("sort-prefix")} size="none" color="night600" fontSize="14" lineHeight="24">
        Sort by:
      </Text>
      <PickerInput
        disableClear
        selectionMode="single"
        valueType="id"
        dataSource={sortDataSource}
        getName={(item) => item.label}
        minBodyWidth={287}
        value={sort?.field}
        searchPosition="none"
        isDisabled={isDisabled}
        renderToggler={renderToggler}
        onValueChange={(v) => onSort({ field: v as string, asc: false })}
      />
      <div className={cn("sort-divider", { "empty-sort": !sort })} />
      {!!sort && (
        <IconButton
          color="night600"
          cx={cn("sort-asc-icon", ["flex items-center"])}
          onClick={() => onSort({ field: sort.field, asc: !sort.asc })}
          icon={sort.asc ? tableSortAscIcon : tableSortDescIcon}
          rawProps={{ "data-testid": "card-sort-arrow" }}
        />
      )}
    </div>
  );
};
