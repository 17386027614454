import React, { FC, useMemo } from "react";

import { FlexCell, FlexRow, FlexSpacer } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { useDocumentActions } from "Components/documents-table/documents-table.hooks";
import { checkRenderHighlight, getDocumentCardProps, renderHighlight } from "Components/search/search.helpers";
import { SearchResultCardProps } from "Components/search/search.interfaces";
import { ShowCurtainSearch } from "Components/show-curtain-search/show-curtain-search";
import { getDocumentDownloadUrl } from "Helpers/utils";
import { useSearch } from "Hooks/useSearch";
import { useUsersData } from "Hooks/useUsers";
import { SearchTypes } from "Store/reducers/search.reducer";

export const SearchResultCard: FC<SearchResultCardProps> = ({ cn, query, item }) => {
  const { onFileNameClick, handleOpenDocumentInfo } = useDocumentActions();
  const { checkIsSubscribed } = useUsersData();
  const { searchType } = useSearch();

  const { icon, header, viewUrl, fileType, friendlyUrl, subscribeParams, serverRelativeUrl, setInfoPanelCallback } =
    getDocumentCardProps({
      cn,
      prefix: "search-result",
      item,
      isSearchCard: true,
      handleOpenDocumentInfo,
      onFileNameClick,
      checkIsSubscribed,
      query,
      isExactPhrase: searchType === SearchTypes.exact,
    });

  const _highlightedText = useMemo(() => renderHighlight[searchType](item), [searchType, item]);

  return (
    <FlexCell cx={cn("search-result-body")} width="100%">
      <FlexRow>
        {icon}
        <div className={cn("search-result-header", ["flex flex-col"])}>{header}</div>
        <FlexSpacer collapseToMore />
        <div className={cn("search-result-actions")}>
          {setInfoPanelCallback && (
            <DocumentActionsDropdown
              fileType={fileType}
              serverRelativeUrl={serverRelativeUrl}
              viewUrl={viewUrl}
              downloadUrl={getDocumentDownloadUrl(viewUrl, friendlyUrl)}
              friendlyUrl={friendlyUrl}
              subscribeParams={subscribeParams}
              onOpenInfoPanel={setInfoPanelCallback}
            />
          )}
        </div>
      </FlexRow>
      {checkRenderHighlight[searchType](item) && (
        <FlexRow cx={cn("search-result-summary-row")}>
          <ShowCurtainSearch cx={cn("search-result-summary")} link={viewUrl}>
            {_highlightedText}
          </ShowCurtainSearch>
        </FlexRow>
      )}
    </FlexCell>
  );
};
