import _noop from "lodash/noop";
import React, { FC } from "react";

import { ReactComponent as addIcon } from "@epam/assets/icons/common/action-add-12.svg";
import { LinkButton } from "@epam/loveship";

import { Tooltip } from "Components/tooltip";
import { ITooltipContentProps } from "Components/tooltip/tooltip";
import { withNaming as cn } from "Helpers/bemClassname";

import "./add-tooltip-button.scss";

interface IAddTooltipButtonProps {
  caption: string;
  hideBefore?: boolean;
  content: (p: ITooltipContentProps) => React.ReactNode;
}

export const AddTooltipButton: FC<IAddTooltipButtonProps> = ({ caption, hideBefore, content }) => {
  return (
    <Tooltip trigger="click" placement="bottom-start" content={content}>
      <LinkButton
        cx={cn("add-tooltip-button")("", { "hide-before": hideBefore })}
        caption={caption}
        size="none"
        color="night500"
        icon={addIcon}
        onClick={_noop}
      />
    </Tooltip>
  );
};
