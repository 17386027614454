import { elasticAPM } from "@App/elasticAPM";
import type { BearerTokenFetchClient, IFetchOptions } from "@pnp/common";

export function elasticLoggingFetchClientDecorator<T extends { new (...args: any[]): BearerTokenFetchClient }>(
  constructor: T,
) {
  return class extends constructor {
    async fetch(url: string, options: IFetchOptions = {}): Promise<Response> {
      const response = await super.fetch(url, options);

      if (!response.ok) {
        const error = new Error(`HTTP error: status: ${response.status}, requestURL: ${response.url}`);
        elasticAPM.captureError(error);
      }

      return response;
    }
  };
}

export function elasticLogHandledErrorsDecorator(func: (error: any, ...args: any[]) => any) {
  return function (error: any, ...args: any[]) {
    const e = new Error(`Custom error: ${error.message}`);
    elasticAPM.captureError(e);
    return func(error, ...args);
  };
}
