import posthog from "posthog-js";

import { IAnalyticsService } from "./AnalyticsService";
import { getDocumentName } from "./utils";

function getUrlWithoutParameters() {
  return `${window.location.origin}${window.location.pathname}`;
}

export function initPosthog() {
  posthog.init(process.env.REACT_APP_POSTHOG_TOKEN, { api_host: process.env.REACT_APP_POSTHOG_API_HOST });
}

export class PosthogAnalytics implements IAnalyticsService {
  provideDocumentView(jobTitle: string, documentLink: string) {
    posthog.capture("ecl_document_view", {
      userTitle: jobTitle,
      document_link: documentLink,
      document_name: getDocumentName(documentLink),
    });
  }

  provideSearchTerm(searchTerm: string, jobTitle: string, searchType: string): void {
    posthog.capture("search", {
      searchTerm,
      userTitle: jobTitle,
      searchType,
      urlWithoutParameters: getUrlWithoutParameters(),
    });
  }

  provideFilter(filterKey: string, jobTitle: string) {
    posthog.capture("filter_panel", {
      filterKey,
      pageLink: window.location.href,
      userTitle: jobTitle,
      urlWithoutParameters: getUrlWithoutParameters(),
    });
  }

  providePageView(jobTitle: string) {
    posthog.capture("$pageview", { userTitle: jobTitle, urlWithoutParameters: getUrlWithoutParameters() });
  }

  provideLinkClick(link: string, jobTitle: string) {
    posthog.capture("ecl_link_click", {
      userTitle: jobTitle,
      linkValue: link,
      urlWithoutParameters: getUrlWithoutParameters(),
    });
  }

  provideButtonClick(caption: string, jobTitle: string) {
    posthog.capture("ecl_button_click", {
      buttonName: caption,
      userTitle: jobTitle,
      urlWithoutParameters: getUrlWithoutParameters(),
    });
  }
  provideDownload(jobTitle: string, documentLink: string) {
    posthog.capture("ecl_download", {
      documentLink,
      userTitle: jobTitle,
      documentName: getDocumentName(documentLink),
    });
  }

  provideSessionStart(jobTitle: string, pageTitle: string) {
    posthog.capture("ecl_session_start", { userTitle: jobTitle, title: `ECL | ${pageTitle}` });
  }
}
