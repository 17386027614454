import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IVisited } from "SP/recent/recent.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { addRecentItem, addRecentItemDocument, getRecentItems } from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";

export const useRecentItemsData = () => {
  const recentItems = useSelector<IRootReducerState, IVisited[]>((state) => state.general.recentItems);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.general.recentItemsLoading);

  return {
    recentItems,
    loading,
  };
};

export const useRecentItemsMethods = () => {
  const dispatch = useDispatch();

  const handleGetRecentItems = () => dispatch(getRecentItems());
  const handleAddRecentItemDocument = (attachedFile: IAttachedFile) => {
    return dispatch(addRecentItemDocument(attachedFile));
  };

  const handleAddRecentItem = (item: IVisited) => {
    return dispatch(addRecentItem(item));
  };

  return {
    getRecentItems: handleGetRecentItems,
    addRecentItemDocument: handleAddRecentItemDocument,
    addRecentItem: handleAddRecentItem,
  };
};

export const RECENT_FORMAT = "DD-MM-YYYY";

export enum ListNames {
  all = "All",
  "Site Pages" = "Pages",
  "EPAM Regulations" = "Documents",
  "EPAM Templates" = "Documents",
  "EPAM Certificates" = "Certificates",
  "Roles Registry" = "Roles",
}

function isAddRecentItem(activeTab, extension) {
  return activeTab === ListNames.all || activeTab === extension;
}

function getRecentItemDate(recentItem: IVisited) {
  return dayjs(recentItem.lastVisited).format(RECENT_FORMAT);
}

export function getGroupsFromRecentItems(recentItems: IVisited[], activeTab: ListNames) {
  const groups: { [key: string]: IVisited[] } = {};

  recentItems
    .filter((item) => isAddRecentItem(activeTab, ListNames[item.list]))
    .sort((a, b) => +b.lastVisited - +a.lastVisited)
    .forEach((item) => {
      const dateKey = getRecentItemDate(item);
      groups[dateKey] = groups[dateKey] || [];
      groups[dateKey].push(item);
    });

  return groups;
}

export function getDatesAndTypesFromRecentItems(recentItems: IVisited[]) {
  const dates = new Set<string>();
  const tabs = new Set<string>();

  recentItems.forEach((item) => {
    const dateKey = getRecentItemDate(item);
    dates.add(dateKey);
    tabs.add(ListNames[item.list]);
  });

  return {
    dates,
    tabs: [ListNames.all, ...tabs],
  };
}

const getReversedGroupDate = (groupsRef, matchedGroupRef) => {
  const groupRef = Object.keys(groupsRef).find((key) => groupsRef[key] === matchedGroupRef);
  return groupRef?.split("-").reverse().join("-");
};

const isOffsetBetweenGroupDates = (scrollTop, group, i, arr) => {
  const prevBlock = arr[i - 1];
  if (prevBlock && group) {
    return i > 0 && scrollTop >= prevBlock["offsetTop"] && scrollTop < group["offsetTop"];
  }
  return false;
};

const setScrollPosition = (groupKey, groupsRef) => {
  groupsRef[groupKey]?.scrollIntoView({ behavior: "smooth" });
};

export const useRecentItemsActions = () => {
  const [filterDate, setFilterDate] = useState("");
  const [tabs, setTabs] = useState<string[]>([ListNames.all]);
  const [activeTab, setActiveTab] = useState<ListNames>(ListNames.all);
  const [recentItemsDates, setRecentItemsDates] = useState<Set<string>>();
  const { recentItems } = useRecentItemsData();
  const groupsRef = useRef({});

  const groupedRecentItems = useMemo(
    () => getGroupsFromRecentItems(recentItems, activeTab),
    [recentItems, activeTab, filterDate],
  );

  const onTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const setCurrentScrollableDate = (scrollTop) => {
    Object.values(groupsRef.current).forEach((group, i, arr) => {
      if (isOffsetBetweenGroupDates(scrollTop, group, i, arr)) {
        setFilterDate(getReversedGroupDate(groupsRef.current, arr[i - 1]));
      }
    });
  };

  const onDateChange = (date) => {
    const groupKey = dayjs(date).format(RECENT_FORMAT);

    setFilterDate(date);
    setScrollPosition(groupKey, groupsRef.current);
  };

  useEffect(() => {
    if (recentItems.length > 0) {
      const { tabs, dates } = getDatesAndTypesFromRecentItems(recentItems);
      setTabs(tabs);
      setRecentItemsDates(dates);
    }
  }, [recentItems]);

  return {
    filterDate,
    activeTab,
    tabs,
    recentItemsDates,
    setCurrentScrollableDate,
    groupedRecentItems,
    groupsRef,
    onTabChange,
    onDateChange,
  };
};
