import React, { FC, useCallback } from "react";

import { createDateString } from "@App/libs/dayjs";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-18.svg";
import { Avatar, FlexRow, FlexSpacer, LinkButton, Text, VirtualList } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { isLastItem } from "Helpers/utils";
import { useSitePagesActions, useSitePageVersions } from "Hooks/useSitePages";
import { useVirtualList } from "Hooks/useVirtualList";
import { ISitePageVersion } from "SP/sitePages/sitePages.types";

import "./page-versions.scss";

interface IPageVersionsProps {
  sitePageUrl: string;
}

export const PageVersions: FC<IPageVersionsProps> = ({ sitePageUrl }) => {
  const cn = withNaming("page-versions");
  const { setSitePageVersionHistoryMode, selectSitePageVersion } = useSitePagesActions();
  const { currentVersionIndex, pageVersions } = useSitePageVersions(sitePageUrl);
  const { virtualState, visibleItems, setVirtualState } = useVirtualList<ISitePageVersion>(pageVersions, 15);

  const handleSelectPageVersion = useCallback(
    (sitePageUrl: string, versionIndex: number) => () => {
      selectSitePageVersion(sitePageUrl, versionIndex);
    },
    [],
  );

  const visibleRows = visibleItems?.map((version) => {
    const versionIndex = pageVersions.indexOf(version);
    const isSelected = versionIndex === currentVersionIndex;

    return (
      <div key={`${version?.Title}_${versionIndex}`}>
        <FlexRow
          cx={cn("version-info", { selected: isSelected })}
          onClick={handleSelectPageVersion(sitePageUrl, versionIndex)}
          rawProps={{ "data-testid": "history-item" }}
        >
          <Avatar
            cx={cn("version-info-avatar")}
            alt="avatar"
            size="36"
            img={`${process.env.REACT_APP_BASE_URL}/_layouts/15/userphoto.aspx?size=S&username=${version?.Editor?.email}`}
          />
          <div className={cn("version-info-wrapper", ["flex flex-col"])}>
            {versionIndex === 0 && (
              <Text cx={cn("version-info-text-primary", { switch: isSelected })}>{"Current Version:"}</Text>
            )}
            <Text cx={cn("version-info-text-primary", { switch: isSelected })}>
              {createDateString(version?.Modified, "DD-MMM-YYYY, HH:mm A")}
            </Text>
            <Text cx={cn("version-info-text-secondary")}>{`by ${version?.Editor?.name}`}</Text>
          </div>
        </FlexRow>
        {!isLastItem(pageVersions, versionIndex) && <div className={cn("version-info-line")} />}
      </div>
    );
  });

  return (
    <div className={cn("", ["shadow"])}>
      <div className={cn("head", ["flex items-center"])}>
        <LinkButton cx={cn("link")} caption="Page History" color="white" size="30" />
        <FlexSpacer />
        <LinkButton
          cx={cn("link")}
          color="white"
          fontSize="14"
          lineHeight="18"
          caption="Exit History"
          icon={closeIcon}
          onClick={() => setSitePageVersionHistoryMode(sitePageUrl, false)}
        />
      </div>
      <VirtualList
        cx={cn("content")}
        rows={visibleRows}
        value={virtualState}
        onValueChange={setVirtualState}
        rowsCount={pageVersions?.length}
      />
    </div>
  );
};
