import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import { InfoPanelConfigurationService } from "SP/infoPanelConfiguration/infoPanelConfiguration.service";
import { IInfoPanelConfiguration } from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { RecentService } from "SP/recent/recent.service";
import { IVisited } from "SP/recent/recent.types";
import { RolesService } from "SP/rolesRegistry/rolesRegistry.service";
import { IRole } from "SP/rolesRegistry/rolesRegistry.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import {
  GeneralActionsTypes,
  getAllRolesFailure,
  getAllRolesSuccess,
  getInfoPanelConfigFailure,
  getInfoPanelConfigSuccess,
  getRecentItemsFailure,
  getRecentItemsSuccess,
  getRoleByIdFailure,
  getRoleByIdSuccess,
  IAddRecentItemAction,
  IAddRecentItemDocumentAction,
  IGetRoleByIdRequestAction,
  IUpdateInfoPanelConfigRequestAction,
  updateInfoPanelConfigFailure,
  updateInfoPanelConfigSuccess,
} from "Store/actions/general.actions";
import { IRootReducerState } from "Store/reducers";

import { getRegulations } from "./helpers/utils.sagas";

const recentService = new RecentService();
const rolesService = new RolesService();
const infoPanelConfigService = new InfoPanelConfigurationService();

export function* getRecentItems() {
  try {
    const recentItems: IVisited[] = yield call([recentService, "fetchItems"]);
    yield put(getRecentItemsSuccess(recentItems));
  } catch (e) {
    yield put(getRecentItemsFailure(e));
    throw e;
  }
}

export function* addRecentItem(action: IAddRecentItemAction) {
  try {
    yield call([recentService, "addToRecent"], action.item);
  } catch (e) {
    throw e;
  }
}

export function* addRecentItemDocument(action: IAddRecentItemDocumentAction) {
  try {
    yield call([recentService, "addDocumentToRecent"], action.attachedFile);
  } catch (e) {
    throw e;
  }
}

export function* getAllRoles() {
  try {
    let allRoles: IRole[] = yield select((state: IRootReducerState) => state.general.allRoles);

    if (!allRoles.length) {
      const regulations: IAttachedFile[] = yield call(getRegulations);
      allRoles = yield call([rolesService, "getAll"], regulations);
    }

    yield put(getAllRolesSuccess(allRoles));
  } catch (e) {
    yield put(getAllRolesFailure(e));
    throw e;
  }
}

export function* getRoleById(action: IGetRoleByIdRequestAction) {
  try {
    const regulations: IAttachedFile[] = yield call(getRegulations);
    const role: IRole = yield call([rolesService, "getById"], action.roleId, regulations);
    yield call([recentService, "addRoleToRecent"], role);
    yield put(getRoleByIdSuccess(role));
  } catch (e) {
    yield put(getRoleByIdFailure(e));
    throw e;
  }
}

export function* getInfoPanelConfig() {
  try {
    let infoPanelConfig: IInfoPanelConfiguration = yield select(
      (state: IRootReducerState) => state.general.infoPanelConfig,
    );

    if (!infoPanelConfig) {
      infoPanelConfig = yield call([infoPanelConfigService, "getInfoPanelConfiguration"]);
    }

    yield put(getInfoPanelConfigSuccess(infoPanelConfig));
  } catch (e) {
    yield put(getInfoPanelConfigFailure(e));
    throw e;
  }
}

export function* updateInfoPanelConfig(action: IUpdateInfoPanelConfigRequestAction) {
  try {
    yield call([infoPanelConfigService, "updateInfoPanelConfiguration"], action.infoPanelConfig);
    yield put(updateInfoPanelConfigSuccess(action.infoPanelConfig));
  } catch (e) {
    yield put(updateInfoPanelConfigFailure(e));
    throw e;
  }
}

export function* watchGetRecentItems() {
  yield takeEvery(GeneralActionsTypes.GET_RECENT_ITEMS_REQUEST, getRecentItems);
}

export function* watchAddRecentItem() {
  yield takeEvery(GeneralActionsTypes.ADD_RECENT_ITEM, addRecentItem);
}

export function* watchAddRecentItemDocument() {
  yield takeEvery(GeneralActionsTypes.ADD_RECENT_ITEM_DOCUMENT, addRecentItemDocument);
}

export function* watchGetAllRoles() {
  yield takeEvery(GeneralActionsTypes.GET_ALL_ROLES_REQUEST, getAllRoles);
}

export function* watchGetRoleById() {
  yield takeEvery(GeneralActionsTypes.GET_ROLE_BY_ID_REQUEST, getRoleById);
}

export function* watchGetInfoPanelConfig() {
  yield takeEvery(GeneralActionsTypes.GET_INFO_PANEL_CONFIG_REQUEST, getInfoPanelConfig);
}

export function* watchUpdateInfoPanelConfig() {
  yield takeEvery(GeneralActionsTypes.UPDATE_INFO_PANEL_CONFIG_REQUEST, updateInfoPanelConfig);
}

export default function* generalSagas(): Iterator<ForkEffect> {
  yield fork(watchGetRecentItems);
  yield fork(watchAddRecentItem);
  yield fork(watchAddRecentItemDocument);
  yield fork(watchGetAllRoles);
  yield fork(watchGetRoleById);
  yield fork(watchGetInfoPanelConfig);
  yield fork(watchUpdateInfoPanelConfig);
}
