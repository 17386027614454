export const tinymceStyle = `
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

:root {
  --sky: #009ecc;
  --lavanda: #2e3a9d;
  --night900: #1d1e26;
}

body {
  color: var(--night900);
  font-family: "Source Sans Pro", sans-serif;
}

h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}

h4 > span {
  font-size: inherit !important;
}

h4,
h5,
h6 {
  font-weight: 600;
  margin: 12px 0;
}

p {
  font-size: 14px;
  margin: 12px 0;
}

p > span {
  font-size: inherit !important;
}

ul,
ol {
  font-size: 14px;
  padding: 0;
}

ul li,
ol li {
  margin-left: 18px;
}

a {
  color: var(--sky);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 18px;
}

a:visited {
  color: var(--lavanda);
}

a:hover {
  opacity: 0.9;
}
`;
