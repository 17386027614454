import { useCallback, useState } from "react";

import { VirtualListState } from "@epam/uui";

export const useVirtualList = <T>(data: T[], visibleCount: number) => {
  const [virtualState, setVirtualState] = useState<VirtualListState>({ topIndex: 0, visibleCount });
  const visibleItems = data.slice(virtualState.topIndex, virtualState.topIndex + virtualState.visibleCount);

  const resetVirtualState = useCallback(() => {
    setVirtualState({ topIndex: 0, visibleCount });
  }, []);

  return {
    virtualState,
    visibleItems,
    setVirtualState,
    resetVirtualState,
  };
};
