import { LibraryName } from "SP/sitePages/sitePages.types";
import { IUser } from "Store/actions/users.actions";

import { FavoriteFiltersRepository } from "./favoriteFilters.repository";
import { IFavoriteFilter, IFavoriteFilterDTO } from "./favoriteFilters.types";

export class FavoriteFiltersService {
  private repository = new FavoriteFiltersRepository();

  private mapFavoriteFilters = (filter: IFavoriteFilterDTO): IFavoriteFilter => {
    return {
      id: filter.Id,
      title: filter.Title,
      filtersGroups: JSON.parse(filter.filters),
      page: filter.page as LibraryName,
      guid: filter.GUID,
      isPreset: filter.IsPreset,
    };
  };

  private reversMapFavoriteFilter = (data: IFavoriteFilter): IFavoriteFilterDTO => {
    const updateData: IFavoriteFilterDTO = {
      Id: data.id,
      Title: data.title,
      filters: JSON.stringify(data.filtersGroups),
      page: data.page,
      GUID: data.guid,
      IsPreset: data.isPreset,
    };
    return updateData;
  };

  public async getCurrentUserFavoriteFilters(currentUser: IUser): Promise<IFavoriteFilter[]> {
    const userFilters = await this.repository.getCurrentUserFavoriteFilters(currentUser);
    return userFilters.map(this.mapFavoriteFilters);
  }

  public async saveFavoriteFilter(data: IFavoriteFilter): Promise<string> {
    const updateData = this.reversMapFavoriteFilter(data);
    if (updateData.Id) {
      await this.repository.updateFavoriteFilter(updateData);
      return updateData.GUID;
    }

    return this.repository.addFavoriteFilter(updateData);
  }
  public async removeFavoriteFilter(data: IFavoriteFilter): Promise<void> {
    await this.repository.removeFavoriteFilter(data);
  }

  public async getFilterByGuid(guid: string): Promise<IFavoriteFilter> {
    const filter = await this.repository.getFilterByGuid(guid);
    return this.mapFavoriteFilters(filter);
  }

  public async saveTempFilter(data: IFavoriteFilter): Promise<string> {
    data.isPreset = false;
    const filterData = this.reversMapFavoriteFilter(data);
    return this.repository.addFavoriteFilter(filterData);
  }
}
