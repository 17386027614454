import produce from "immer";
import { Reducer } from "redux";

import { AnalyticsActionsTypes, IAnalyticsActionTypes } from "Store/actions/analytics.actions";

export interface IAnalyticsState {
  sessionStartSent: boolean;
}

const initialState: IAnalyticsState = {
  sessionStartSent: false,
};

const analyticsReducer: Reducer<IAnalyticsState, IAnalyticsActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case AnalyticsActionsTypes.SET_SESSION_START_SENT:
      draft.sessionStartSent = action.payload;
      break;
  }
}, initialState);

export default analyticsReducer;
