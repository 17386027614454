import _isEmpty from "lodash/isEmpty";
import React from "react";

import { ReactComponent as notificationIcon } from "@App/assets/notification.svg";
import { createDateString } from "@App/libs/dayjs";
import { IconContainer, Tooltip } from "@epam/loveship";

import { DocumentActionsDropdown } from "Components/document-actions-dropdown";
import { FoundContent } from "Components/found-content";
import { FileIcons } from "Helpers/constants";
import highlight from "Helpers/highlight";
import { getSubscribeTooltipText } from "Helpers/subscribeHelper";
import { highlightWithTiming } from "Helpers/timingElements";
import { getDocumentDownloadUrl, transformFileName } from "Helpers/utils";
import { DocumentField } from "SP/fields";
import { IAttachedFile } from "SP/sitePages/sitePages.types";

import { renderLink, renderLinkWithTooltip, renderText, renderTextWithTooltip } from "../documents-table.helpers";
import { IColumn, IRowProps } from "../documents-table.interface";

function renderFirstColumn(row: IAttachedFile, { searchValue, onFileNameClick, subscribeParams }: IRowProps) {
  const isEmptyTitle = _isEmpty(row?.Title);
  const linkClassName = isEmptyTitle ? "link" : null;
  const onLinkClick = isEmptyTitle ? onFileNameClick : null;

  return (
    <>
      <div className="flex">
        <Tooltip cx={"single-line-tooltip"} content={row.Title} placement="bottom">
          {renderLink({
            cx: "file-title hover-underline",
            caption: highlight.peek(searchValue, row.Title),
            href: row.PublishedPlace?.Url,
            target: "_blank",
          })}
        </Tooltip>
        {subscribeParams.isSubscribed && (
          <Tooltip content={getSubscribeTooltipText(row.sourceLibrary)} placement="bottom">
            <IconContainer cx="file-subscribe file-subscribe--regulation" icon={notificationIcon} color="night500" />
          </Tooltip>
        )}
      </div>
      <div className="flex">
        <Tooltip cx={"single-line-tooltip"} content={row.fileInfo.Name} placement="bottom">
          {renderLink({
            cx: "file-name hover-underline",
            fontSize: "12",
            font: "sans",
            icon: FileIcons[row.fileInfo.FileType] || FileIcons.default,
            caption: highlightWithTiming({
              elementtiming: "regulation-templates-name",
              query: searchValue,
              value: transformFileName(row.fileInfo.Name),
            }),
            onClick: onFileNameClick,
          })}
        </Tooltip>
        <span className="dot" style={{ margin: "0 3px" }} />
        <div className={linkClassName} onClick={onLinkClick}>
          <Tooltip cx={"single-line-tooltip"} content={row.SubjectDoc} placement="bottom">
            {renderText(row.SubjectDoc, { cx: "one-line", font: "sans", fontSize: "12", color: "night600" })}
          </Tooltip>
        </div>
      </div>
      {!!searchValue && (
        <div className="flex">
          <FoundContent hitHighlightedAll={row.HitHighlightedAll} />
        </div>
      )}
    </>
  );
}

export const regulationTemplatesColumns: IColumn[] = [
  {
    key: "Document",
    caption: DocumentField.Document,
    render: (row, rowProps) => renderFirstColumn(row, rowProps),
    width: 274,
    isSortable: true,
    minWidth: 184,
  },
  {
    key: "EPAMDocumentID",
    caption: DocumentField.EPAMDocumentID,
    render: (row) => renderTextWithTooltip(row?.EPAMDocumentID, { cx: "one-line" }),
    isSortable: true,
    width: 185,
    minWidth: 125,
  },
  {
    key: "DocumentArea",
    caption: DocumentField.DocumentArea,
    render: (row) => renderTextWithTooltip(row?.DocumentArea?.join(", "), { cx: "two-lines" }),
    isSortable: true,
    width: 152,
    minWidth: 122,
  },
  {
    key: "LocationCountry",
    caption: DocumentField.LocationCountry,
    render: (row) => renderText(row?.LocationCountry),
    isSortable: true,
    width: 94,
    minWidth: 90,
  },
  {
    key: "OrganizationalUnit",
    caption: DocumentField.OrganizationalUnit,
    render: (row) =>
      renderLinkWithTooltip(row?.OrganizationalUnit?.Description, {
        cx: "two-lines hover-underline",
        caption: row?.OrganizationalUnit?.Description,
        href: row?.OrganizationalUnit?.Url,
        target: "_blank",
      }),
    isSortable: true,
    width: 118,
    minWidth: 116,
  },
  {
    key: "ApprovalDate",
    caption: DocumentField.ApprovalDate,
    render: (row) => renderText(createDateString(row?.ApprovalDate)),
    isSortable: true,
    width: 96,
    minWidth: 86,
  },
  {
    key: "actions",
    caption: "",
    render: (row, { subscribeParams, onOpenInfoPanel }) => (
      <DocumentActionsDropdown
        fileType={row.fileInfo.FileType}
        serverRelativeUrl={row.fileInfo.ServerRelativeUrl}
        viewUrl={row.viewUrl}
        downloadUrl={getDocumentDownloadUrl(row.downloadUrl, row.FriendlyUrl)}
        friendlyUrl={row.FriendlyUrl}
        subscribeParams={subscribeParams}
        onOpenInfoPanel={onOpenInfoPanel}
      />
    ),
    width: 18,
  },
];
