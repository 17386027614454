import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import { ErrorMessages } from "Helpers/constants";
import { ICertificate } from "SP/documents/certificates/certificates.types";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { FavoriteFiltersService } from "SP/favoriteFilters/favoriteFilters.service";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { SitePagesService } from "SP/sitePages/sitePages.service";
import { SubscriptionsService } from "SP/subscriptions/subscriptions.service";
import { ISubscriptions } from "SP/subscriptions/subscriptions.types";
import { UsersService } from "SP/users/users.service";
import { setActivePreset, setFilterGuid, setFilters, toggleDocumentViewReset } from "Store/actions/filters.actions";
import {
  getCurrentUserFailure,
  getCurrentUserFavoriteFiltersFailure,
  getCurrentUserFavoriteFiltersSuccess,
  getCurrentUserSuccess,
  IGetCurrentUserFavoriteFiltersRequestAction,
  IGetCurrentUserRequestAction,
  IRemoveFavoriteFilterRequestAction,
  ISaveFavoriteFilterRequestAction,
  ISubscribeToItemToggleRequestAction,
  IUser,
  IUserWithPermsAndSubscriptions,
  removeFavoriteFilterFailure,
  removeFavoriteFilterSuccess,
  saveFavoriteFilterFailure,
  saveFavoriteFilterSuccess,
  subscribeToItemToggleFailure,
  subscribeToItemToggleSuccess,
  UsersActionsTypes,
} from "Store/actions/users.actions";
import { IRootReducerState } from "Store/reducers";

import "@pnp/sp/profiles";

const sitePagesService = new SitePagesService();
const subscriptionsService = new SubscriptionsService();
const favoriteFiltersService = new FavoriteFiltersService();
const usersService = new UsersService();

const usersSelectors = {
  filtersLibrary: (state: IRootReducerState) => state.filters.filtersLibrary,
  currentUser: (state: IRootReducerState) => state.users.currentUser,
  favoriteFilters: (state: IRootReducerState) => state.users.favoriteFilters,
  activePreset: (state: IRootReducerState) => state.filters.activePresets[state.filters.filtersLibrary],
};

export function* getCurrentUser(action: IGetCurrentUserRequestAction) {
  try {
    const user = yield select(usersSelectors.currentUser);
    if (user) {
      action.cb?.(user);
      return;
    }

    const currentUser: IUser = yield call([usersService, "getCurrentUser"]);
    const canEditSitePage = yield call([sitePagesService, "checkIfUSerHasEditPermission"]);
    const subscriptions = yield call([subscriptionsService, "getCurrentUserSubscriptions"], currentUser.id);
    yield put(getCurrentUserSuccess({ ...currentUser, canEditSitePage, subscriptions }));

    action.cb?.(currentUser);
  } catch (e) {
    yield put(getCurrentUserFailure(e));
    throw e;
  }
}

export function* subscribeToItemToggle(action: ISubscribeToItemToggleRequestAction) {
  try {
    const currentUser: IUserWithPermsAndSubscriptions = yield select(usersSelectors.currentUser);
    let subscriptions: ISubscriptions = yield call(
      [subscriptionsService, "getCurrentUserSubscriptions"],
      currentUser.id,
    );
    let method: string & keyof SubscriptionsService;
    let params: (string | ISubscriptions | number[] | Partial<IRegulation & ICertificate>[])[];

    if (action.item === "eclNews") {
      method = action.isSubscribed ? "unsubscribeFromECLNews" : "subscribeToECLNews";
      params = [subscriptions];
    } else {
      method = action.isSubscribed ? "unsubscribeFromItems" : "subscribeToItems";
      params = [action.items, subscriptions];
    }

    // eslint-disable-next-line
    subscriptions = yield call([subscriptionsService, method as any], ...params);

    if (action.cb) {
      action.cb();
    }

    yield put(subscribeToItemToggleSuccess(subscriptions));
  } catch (e) {
    yield put(subscribeToItemToggleFailure(e));
    throw e;
  }
}

export function* getCurrentUserFavoriteFilters(action: IGetCurrentUserFavoriteFiltersRequestAction) {
  try {
    const currentUser: IUserWithPermsAndSubscriptions = yield select(usersSelectors.currentUser);
    let favoriteFilters: IFavoriteFilter[] = yield select(usersSelectors.favoriteFilters);

    if (!favoriteFilters.length || action.isNeedForceUpdate) {
      favoriteFilters = yield call([favoriteFiltersService, "getCurrentUserFavoriteFilters"], currentUser);
    }

    yield put(getCurrentUserFavoriteFiltersSuccess(favoriteFilters));

    return favoriteFilters;
  } catch (e) {
    yield put(getCurrentUserFavoriteFiltersFailure(e));
  }
}

export function* saveFavoriteFilter(action: ISaveFavoriteFilterRequestAction) {
  try {
    const newPreset = action.payload;

    const favoriteFilters = yield call(getCurrentUserFavoriteFilters, {
      type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST,
      isNeedForceUpdate: true,
    });

    const isPresetNameExist = favoriteFilters?.some(
      (item) => item.title === newPreset.title && item.page === newPreset.page,
    );

    if (!isPresetNameExist || newPreset.id) {
      const filterGuid: string = yield call([favoriteFiltersService, "saveFavoriteFilter"], newPreset);
      yield put(saveFavoriteFilterSuccess());

      const favoriteFilters = yield call(getCurrentUserFavoriteFilters, {
        type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST,
        isNeedForceUpdate: true,
      });
      if (!newPreset.id) {
        newPreset.id = favoriteFilters.find((item) => item.guid === filterGuid)?.id;
      }

      newPreset.guid = filterGuid;

      yield put(setActivePreset(newPreset));
      yield put(setFilterGuid(filterGuid));
      yield put(setFilters(newPreset.filtersGroups));

      if (action.cb) {
        action.cb(filterGuid);
      }
    } else {
      yield put(saveFavoriteFilterFailure({ message: ErrorMessages.nameExists }));
    }
  } catch (e) {
    yield put(saveFavoriteFilterFailure(e));
  }
}

export function* removeFavoriteFilter(action: IRemoveFavoriteFilterRequestAction) {
  try {
    const activePreset: IFavoriteFilter = yield select(usersSelectors.activePreset);

    yield call([favoriteFiltersService, "removeFavoriteFilter"], action.payload);
    yield put(removeFavoriteFilterSuccess());

    yield call(getCurrentUserFavoriteFilters, {
      type: UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST,
      isNeedForceUpdate: true,
    });

    if (activePreset?.id === action.payload.id) {
      yield put(setActivePreset(null));
      yield put(setFilters([{ name: "Filter Group 1", enabled: true, filters: {} }]));
      yield put(toggleDocumentViewReset());
      yield call(action.matchCb);
    }

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    yield put(removeFavoriteFilterFailure(e));
  }
}

export function* watchGetCurrentUser() {
  yield takeEvery(UsersActionsTypes.GET_CURRENT_USER_REQUEST, getCurrentUser);
}

export function* watchSubscribeToItemToggle() {
  yield takeEvery(UsersActionsTypes.SUBSCRIBE_TO_ITEM_TOGGLE_REQUEST, subscribeToItemToggle);
}

export function* watchGetCurrentUserFavoriteFilters() {
  yield takeEvery(UsersActionsTypes.GET_CURRENT_USER_FAVORITE_FILTERS_REQUEST, getCurrentUserFavoriteFilters);
}

export function* watchSaveFavoriteFilter() {
  yield takeEvery(UsersActionsTypes.SAVE_FAVORITE_FILTER_REQUEST, saveFavoriteFilter);
}

export function* watchRemoveFavoriteFilter() {
  yield takeEvery(UsersActionsTypes.REMOVE_FAVORITE_FILTER_REQUEST, removeFavoriteFilter);
}

export default function* usersSagas(): Iterator<ForkEffect> {
  yield fork(watchGetCurrentUser);
  yield fork(watchSubscribeToItemToggle);
  yield fork(watchGetCurrentUserFavoriteFilters);
  yield fork(watchSaveFavoriteFilter);
  yield fork(watchRemoveFavoriteFilter);
}
