import React, { FC } from "react";

import { FlexRow, LinkButton, Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";
import { getLinkProps } from "Helpers/utils";

import { ILinkedListProps, IPageTextProps } from "./page-card-components.interface";

const cn = withNaming("page-card");

export const PageText: FC<IPageTextProps> = ({ className, caption, fontSize, lineHeight }) => (
  <FlexRow cx={cn("text-wrapper")}>
    <Text cx={cn(className)} color="night900" font="sans-semibold" fontSize={fontSize} lineHeight={lineHeight}>
      {caption}
    </Text>
  </FlexRow>
);

export const LinkedList: FC<ILinkedListProps> = ({ className, linkedList, iconPosition, onLinkClicked }) => (
  <ul className={cn(className)}>
    {linkedList.map(({ caption, icon, link }) => (
      <li key={caption}>
        <LinkButton
          caption={caption}
          icon={icon}
          iconPosition={iconPosition}
          size="24"
          fontSize="12"
          lineHeight="18"
          font="sans"
          color="sky"
          onClick={() => onLinkClicked(link, caption)}
          {...getLinkProps(link)}
        />
      </li>
    ))}
  </ul>
);
