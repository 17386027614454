import _get from "lodash/get";
import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { listSubscriptionKeys } from "SP/constants";
import { IFavoriteFilter } from "SP/favoriteFilters/favoriteFilters.types";
import { IVoidCb, IVoidCbWithParam } from "SP/helpers.types";
import {
  getCurrentUser,
  getCurrentUserFavoriteFilters,
  ISubscribeToItemToggleParams,
  IUserWithPermsAndSubscriptions,
  removeFavoriteFilter,
  saveFavoriteFilter,
  subscribeToItemToggle,
} from "Store/actions/users.actions";
import { IRootReducerState } from "Store/reducers";

export type ICheckIsSubscribed = (pageName: string, documentId: number) => boolean;

export const useUsersData = () => {
  const currentUser = useSelector<IRootReducerState, IUserWithPermsAndSubscriptions>(
    (state) => state.users.currentUser,
  );

  const checkIsSubscribed: ICheckIsSubscribed = useCallback(
    (pageName: string, documentId: number): boolean => {
      const pageKey = listSubscriptionKeys[pageName];
      const subscriptionIds = _get(currentUser?.subscriptions, `[${pageKey}]`, []);
      return subscriptionIds.includes(documentId);
    },
    [currentUser?.subscriptions],
  );

  const favoriteFilters = useSelector<IRootReducerState, IFavoriteFilter[]>(
    (state) => state.users.favoriteFilters.filter((item) => item.page === state.filters.filtersLibrary),
    shallowEqual,
  );

  const presetSaveErrorText = useSelector<IRootReducerState, string>((state) => state.users.presetSaveErrorText);

  return {
    currentUser,
    checkIsSubscribed,
    favoriteFilters,
    presetSaveErrorText,
  };
};

export const useUsersActions = () => {
  const subscriptionsLoading = useSelector<IRootReducerState, boolean>((state) => state.users.subscriptionsLoading);

  return {
    subscriptionsLoading,
  };
};

export const useUsersMethods = () => {
  const dispatch = useDispatch();

  const handleGetCurrentUser = (cb?: (IUser) => void) => dispatch(getCurrentUser(cb));

  const handleSubscribeToDocumentToggle = (params: ISubscribeToItemToggleParams) => {
    return dispatch(subscribeToItemToggle(params));
  };

  const handleGetFavoriteFilters = (isNeedForceUpdate = false) =>
    dispatch(getCurrentUserFavoriteFilters(isNeedForceUpdate));

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSaveFavoriteFilter = (filter: IFavoriteFilter, cb: IVoidCbWithParam = () => {}) => {
    return dispatch(saveFavoriteFilter(filter, cb));
  };

  const handleRemoveFavoriteFilter = (filter: IFavoriteFilter, cb: IVoidCb, matchCb: IVoidCb) => {
    return dispatch(removeFavoriteFilter(filter, cb, matchCb));
  };

  return {
    getCurrentUser: handleGetCurrentUser,
    subscribeToItemToggle: handleSubscribeToDocumentToggle,
    getFavoriteFilters: handleGetFavoriteFilters,
    saveFavoriteFilter: handleSaveFavoriteFilter,
    removeFavoriteFilter: handleRemoveFavoriteFilter,
  };
};
