import { sp } from "@pnp/sp/presets/all";

import { UserPhoto } from "Helpers/storage";
import { graphConfig, requestWithAuth } from "Libs/graph";
import type { IUser } from "Store/actions/users.actions";

export class UsersService {
  async getCurrentUser(): Promise<IUser> {
    const profileDataPromise = sp.profiles.myProperties.select("Title", "Email", "DisplayName").get();
    const userIdPromise = sp.web.currentUser.select("Id").get();
    const photoPromise = this.getCurrentUserPhoto();

    const [profileData, userIdData, photo] = await Promise.all([profileDataPromise, userIdPromise, photoPromise]);

    return {
      id: userIdData.Id,
      email: profileData.Email,
      name: profileData.DisplayName,
      jobTitle: profileData.Title,
      photo,
    };
  }

  async getCurrentUserPhoto(): Promise<string> {
    const userPhoto = UserPhoto.get();
    if (userPhoto) {
      return userPhoto;
    }

    const photoBlob = await requestWithAuth<Blob>({ url: graphConfig.graphMePhotoEndpoint, readAs: "blob" });
    if (!photoBlob) {
      return "";
    }

    const photoUrl = await this.getBase64ImageFromBlob(photoBlob);
    UserPhoto.set(photoUrl, 60 * 60);

    return photoUrl;
  }

  getBase64ImageFromBlob(photoBlob: Blob): Promise<string> {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        res(reader.result as string);
      });
      reader.readAsDataURL(photoBlob);
    });
  }
}
