import React, { FC, useRef, useState } from "react";

import { ReactComponent as searchIcon } from "@epam/assets/icons/common/action-search-18.svg";
import { ReactComponent as clearIcon } from "@epam/assets/icons/common/content-clear-18.svg";
import { SearchInput } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassname";

import "./search-menu-item.scss";

interface ISearchMenuItemProps {
  expandCB: (o: boolean) => void;
  placeholder: string;
  value: string;
  onValueChange: (newValue: string) => void;
}

export const SearchMenuItem: FC<ISearchMenuItemProps> = ({ value, placeholder, expandCB, onValueChange }) => {
  const cn = withNaming("search-menu-item");
  const [isOpen, setIsOpen] = useState(!!value);
  const searchRef = useRef(null);

  const handleOpenToggle = (isIconClick?: boolean) => () => {
    if (value) return;

    const newOpen = !isOpen;

    if (!newOpen && !isIconClick) return;

    if (newOpen && isIconClick) {
      searchRef.current?.lastElementChild?.focus();
    }

    expandCB(newOpen);
    setIsOpen(newOpen);
  };

  return (
    <div className={cn("", { isOpen: isOpen || !!value }, ["cursor-pointer"])}>
      <SearchInput
        autoFocus
        cx={cn("input")}
        size="none"
        icon={searchIcon}
        cancelIcon={clearIcon}
        placeholder={placeholder}
        value={value}
        onValueChange={onValueChange}
        onClick={handleOpenToggle()}
        onIconClick={handleOpenToggle(true)}
        isReadonly={!isOpen && !value}
        rawProps={{ ref: searchRef, "data-testid": "search-menu-item" }}
      />
    </div>
  );
};
