import { useDispatch, useSelector } from "react-redux";

import { IPathKeys } from "Helpers/sitePageUpdater";
import { IVoidCbWithParam } from "SP/helpers.types";
import { IAttachedFile, INewSitePageInfo, ISitePage, ISitePageVersion } from "SP/sitePages/sitePages.types";
import {
  addNewSitePageRequest,
  attachSitePageToDocuments,
  checkOutSitePage,
  clearSitePageInValid,
  discardCheckOutSitePage,
  getSitePageByFriendlyUrl,
  getSitePageByUrl,
  getSitePagesHierarchyRequest,
  getSitePageVersions,
  IAttachSitePageParams,
  ICheckoutParams,
  IDiscardCheckoutParams,
  republishSitePage,
  saveAsDraftSitePage,
  selectSitePageDetail,
  selectSitePageVersion,
  setSitePageEditMode,
  setSitePageInValidProperty,
  setSitePageVersion,
  setSitePageVersionHistoryMode,
  updateSitePage,
  updateSitePageFiles,
} from "Store/actions/site-pages.actions";
import { IRootReducerState } from "Store/reducers";
import { ISelectedDetailInfo, ISitePageVersions } from "Store/reducers/site-pages.reducer";

export const useSitePagesData = (url?: string) => {
  const sitePage = useSelector<IRootReducerState, ISitePage>((state) => state.sitePages.sitePages[url]);
  const editPageBackup = useSelector<IRootReducerState, ISitePage>((state) => state.sitePages.editPageBackup);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.sitePages.loading);
  const files = useSelector<IRootReducerState, IAttachedFile[]>((state) => state.sitePages.files[url]);
  const filesLoading = useSelector<IRootReducerState, boolean>((state) => state.sitePages.filesLoading[url]);

  return {
    sitePage,
    editPageBackup,
    loading,
    files,
    filesLoading,
  };
};

export const useSitePageVersions = (pageUrl: string) => {
  const versions = useSelector<IRootReducerState, ISitePageVersions>((state) => state.sitePages.versions);
  const currentVersionIndex = versions[pageUrl] ? versions[pageUrl]?.currentIndex : -1;
  const pageVersions = versions[pageUrl]?.items || [];
  const selectedPageVersion = pageVersions.length ? pageVersions[currentVersionIndex] : null;

  return {
    currentVersionIndex,
    pageVersions,
    selectedPageVersion,
  };
};

export const useSitePagesActions = () => {
  const dispatch = useDispatch();

  const selectedDetailInfo = useSelector<IRootReducerState, ISelectedDetailInfo>(
    (state) => state.sitePages.selectedDetailInfo,
  );

  const isSitePageInVersionHistoryMode = useSelector<IRootReducerState, boolean>(
    (state) => state.sitePages.isSitePageInVersionHistoryMode,
  );

  const isSitePageInEditMode = useSelector<IRootReducerState, boolean>((state) => state.sitePages.isSitePageInEditMode);

  const sitePageInValidProperties = useSelector<IRootReducerState, Set<string>>(
    (state) => state.sitePages.sitePageInValidProperties,
  );

  const handleSelectSitePageDetail = (detailInfo: ISelectedDetailInfo) => dispatch(selectSitePageDetail(detailInfo));

  const handleSetSitePageEditMode = (toggle: boolean, sitePageUrl: string) => {
    return dispatch(setSitePageEditMode(toggle, sitePageUrl));
  };

  const handleSetSitePageInValidProperty = (toggle: boolean, property: string) => {
    if ((toggle && sitePageInValidProperties.has(property)) || (!toggle && !sitePageInValidProperties.has(property))) {
      return;
    }
    return dispatch(setSitePageInValidProperty(toggle, property));
  };

  const handleClearSitePageInValid = () => dispatch(clearSitePageInValid());

  const handleGetSitePageVersions = (pageUrl: string) => dispatch(getSitePageVersions(pageUrl));

  const handleSelectPageVersion = (pageUrl: string, versionIndex: number) =>
    dispatch(selectSitePageVersion(pageUrl, versionIndex));

  const handleSetSitePageVersionHistoryMode = (pageUrl: string, toggle: boolean) =>
    dispatch(setSitePageVersionHistoryMode(pageUrl, toggle));

  const handleSetSitePageVersion = (pageUrl: string, version: ISitePageVersion) =>
    dispatch(setSitePageVersion(pageUrl, version));

  return {
    selectedDetailInfo,
    isSitePageInVersionHistoryMode,
    isSitePageInEditMode,
    isSitePageInValid: sitePageInValidProperties.size > 0,
    selectSitePageDetail: handleSelectSitePageDetail,
    setSitePageEditMode: handleSetSitePageEditMode,
    setSitePageInValidProperty: handleSetSitePageInValidProperty,
    clearSitePageInValid: handleClearSitePageInValid,
    getSitePageVersions: handleGetSitePageVersions,
    selectSitePageVersion: handleSelectPageVersion,
    setSitePageVersionHistoryMode: handleSetSitePageVersionHistoryMode,
    setSitePageVersion: handleSetSitePageVersion,
  };
};

export const useSitePagesMethods = () => {
  const dispatch = useDispatch();

  const handleGetSitePageByFriendlyUrl = (friendlyUrl: string) => dispatch(getSitePageByFriendlyUrl(friendlyUrl));

  const handleGetSitePageByUrl = (url: string) => dispatch(getSitePageByUrl(url));

  const handleCheckOutSitePage = (params: ICheckoutParams) => dispatch(checkOutSitePage(params));

  const handleDiscardCheckOutSitePage = (params: IDiscardCheckoutParams) => dispatch(discardCheckOutSitePage(params));

  const handleSaveAsDraftSitePage = (sitePageId: number, sitePageUrl: string) => {
    return dispatch(saveAsDraftSitePage(sitePageId, sitePageUrl));
  };

  const handleRepublishSitePage = (sitePageId: number, sitePageUrl: string) => {
    return dispatch(republishSitePage(sitePageId, sitePageUrl));
  };

  const handleAttachSitePageToDocuments = (params: IAttachSitePageParams) => {
    return dispatch(attachSitePageToDocuments(params));
  };

  const handleUpdateSitePage = (pathKeys: IPathKeys, sitePageValue) => {
    return dispatch(updateSitePage(pathKeys, sitePageValue));
  };

  const handleUpdateSitePageFiles = (files: IAttachedFile[], sitePageUrl: string) => {
    return dispatch(updateSitePageFiles({ files, url: sitePageUrl }));
  };

  const handleGetSitePagesHierarchy = () => dispatch(getSitePagesHierarchyRequest());

  const handleAddNewSitePage = (
    newPageInfo: INewSitePageInfo,
    redirectCb: IVoidCbWithParam,
    errorCb: IVoidCbWithParam,
  ) => {
    return dispatch(addNewSitePageRequest(newPageInfo, redirectCb, errorCb));
  };

  return {
    getSitePageByFriendlyUrl: handleGetSitePageByFriendlyUrl,
    getSitePageByUrl: handleGetSitePageByUrl,
    onCheckOutSitePage: handleCheckOutSitePage,
    onDiscardCheckOutSitePage: handleDiscardCheckOutSitePage,
    onSaveAsDraftSitePage: handleSaveAsDraftSitePage,
    onRepublishSitePage: handleRepublishSitePage,
    attachSitePageToDocuments: handleAttachSitePageToDocuments,
    updateSitePage: handleUpdateSitePage,
    updateSitePageFiles: handleUpdateSitePageFiles,
    getSitePagesHierarchy: handleGetSitePagesHierarchy,
    addNewSitePage: handleAddNewSitePage,
  };
};
