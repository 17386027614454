export enum CommonField {
  Created = "Created",
  Author = "Created By",
  Modified = "Modified",
  Editor = "Modified By",
}

export enum CommonDocumentField {
  WorkStorage = "Work Storage",
  publishedVersion = "Published Version",
  FileDirRef = "Path",
}

export enum PageField {
  Title = "Title",
}

export enum CertificateField {
  Title = "Title",
  Document = "Certificate Name",
  EPAMDocumentID = "Document ID",
  Standard = "Standard",
  CertificationBody = "Certification Body",
  LocationCountry = "Country",
  LocationCity = "City",
  ValidFrom = "Valid From",
  ValidUntil = "Valid Until",
  LegalEntities = "Legal Entities",
  ScopeLimitation = "Scope Limitation",
  CertificateArtifactType = "Certificate Artifact Type",
  CertificateArea = "Area",
  IssueDate = "Issue Date",
  Name = "Name",
  Responsible = "Responsible",
  RegistrationNumber = "Registration № of the Certificate",
}

export enum DocumentField {
  Title = "Title",
  Document = "Document Name",
  EPAMDocumentID = "Document ID",
  DocumentArea = "Document Area",
  LocationCountry = "Location (Country)",
  OrganizationalUnit = "Organizational Unit - Owner",
  ApprovalDate = "Approval Date",
  DocumentOwnerPosition = "Document Owner (position)",
  ApproverPosition = "Approver Position",
  ApproverName = "Approver Name",
  SubjectDoc = "Artifact Type",
  Description = "Description",
  PublishedPlace = "Published Place",
  Category = "Category",
  Name = "Name",
  RequiredReviewDate = "Required Review Date",
  Company = "Company",
  ReviewPeriod = "Review Period",
  Standards = "Standards",
  Subarea = "Subarea",
}

export enum RoleField {
  RoleName = "Role Name",
  RoleCategory = "Role Category",
  Group = "Group",
  PublishedDate = "Published date",
  ApprovedBy = "Approved by",
  DocumentArea = "Area",
  Regulations = "Regulations",
  Status = "Status",
  Title = "Role Name",
  ShortDescription = "Short Description in ECL",
  Documents = "Documents",
}
