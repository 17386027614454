import React, { FC, Fragment, useMemo } from "react";

import { createDateString } from "@App/libs/dayjs";
import { ReactComponent as downloadIcon } from "@epam/assets/icons/common/file-download-18.svg";
import { ReactComponent as infoIcon } from "@epam/assets/icons/common/table-info-fill-18.svg";
import { FlexCell, FlexRow, IconContainer, LinkButton, Panel, Tooltip } from "@epam/loveship";
import { ButtonProps } from "@epam/uui-components";

import { DocumentFooter } from "Components/documents/document-footer";
import { DocumentHeader } from "Components/documents/document-header";
import { DocumentText } from "Components/documents/document-text";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { documentDownloadTag, FileIcons } from "Helpers/constants";
import { getDocumentDownloadUrl, getDocumentViewUrl } from "Helpers/utils";
import { useRecentItemsMethods } from "Hooks/useRecentItems";
import { useUsersData } from "Hooks/useUsers";
import { DocumentField } from "SP/fields";
import { IVoidCb } from "SP/helpers.types";
import { IAttachedFile } from "SP/sitePages/sitePages.types";
import { ISubscribeToItemToggleParams } from "Store/actions/users.actions";

import "./document-card.scss";

interface IDocumentCardProps {
  attachedFile: IAttachedFile;
  isSingle: boolean;
  isNoSections: boolean;
  subscribeParams: ISubscribeToItemToggleParams;
  onOpenInfoPanel: IVoidCb;
}

interface IItem {
  caption: string;
  item: string;
  isVisible?: boolean;
  href?: string;
}

export const DocumentCard: FC<IDocumentCardProps> = ({
  attachedFile,
  isSingle,
  isNoSections,
  subscribeParams,
  onOpenInfoPanel,
}) => {
  const cn = withNaming("document-card");
  const { addRecentItemDocument } = useRecentItemsMethods();
  const { currentUser } = useUsersData();

  const documentDownloadUrl = useMemo(() => {
    return getDocumentDownloadUrl(attachedFile.viewUrl, attachedFile.FriendlyUrl);
  }, [attachedFile.viewUrl, attachedFile.FriendlyUrl]);

  const items: IItem[] = [
    {
      caption: DocumentField.OrganizationalUnit,
      item: attachedFile.OrganizationalUnit?.Description,
      href: attachedFile.OrganizationalUnit?.Url,
      isVisible: isNoSections,
    },
    {
      caption: DocumentField.EPAMDocumentID,
      item: attachedFile.EPAMDocumentID,
    },
    {
      caption: DocumentField.ApprovalDate,
      item: createDateString(attachedFile.ApprovalDate),
    },
    {
      caption: DocumentField.DocumentArea,
      item: attachedFile.DocumentArea?.join(", "),
      isVisible: isNoSections,
    },
  ];

  const buttons: ButtonProps[] = [
    {
      caption: "Download",
      icon: downloadIcon,
      href: documentDownloadUrl,
      cx: documentDownloadTag,
      onClick: () => {
        analyticsHelper.analyticsService?.provideDownload(currentUser?.jobTitle, documentDownloadUrl);
      },
    },
    {
      caption: "Get Info",
      icon: infoIcon,
      onClick: onOpenInfoPanel,
    },
  ];

  return (
    <Panel
      cx={cn("", { isSingle, isNoSections }, ["shadow"])}
      background="white"
      rawProps={{ "data-testid": "document-card" }}
    >
      <DocumentHeader attachedFile={attachedFile} isSubscribed={subscribeParams.isSubscribed} />
      <FlexRow>
        <IconContainer cx={cn("icon")} icon={FileIcons[attachedFile.fileInfo.FileType] || FileIcons.default} />
        <FlexCell width="auto">
          <LinkButton
            cx={cn("title")}
            caption={attachedFile.Title}
            fontSize="16"
            lineHeight="18"
            color="sky"
            onClick={() => {
              analyticsHelper.analyticsService?.provideLinkClick(attachedFile.viewUrl, currentUser?.jobTitle);
              addRecentItemDocument(attachedFile);
              window.open(getDocumentViewUrl(attachedFile?.viewUrl, attachedFile?.FriendlyUrl));
            }}
            rawProps={{ "data-testid": "open-file-btn" }}
          />
          <Tooltip cx={"single-line-tooltip"} content={attachedFile.fileInfo.Name} placement="bottom">
            <DocumentText caption={attachedFile.fileInfo.Name} />
          </Tooltip>
        </FlexCell>
      </FlexRow>
      <FlexRow cx={cn("items")} alignItems="stretch">
        {items.map(({ caption, item, href, isVisible = true }) => (
          <Fragment key={caption}>
            {isVisible && (
              <div className={cn("item")}>
                <DocumentText caption={caption} />
                <DocumentText caption={item || "-"} isItem href={href} />
              </div>
            )}
          </Fragment>
        ))}
      </FlexRow>
      <DocumentFooter buttons={buttons} attachedFile={attachedFile} subscribeParams={subscribeParams} />
    </Panel>
  );
};
