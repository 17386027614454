import React, { FC, useEffect, useMemo, useRef } from "react";

import { svc } from "@App/services";
import { ReactComponent as copyContent } from "@epam/assets/icons/common/action-copy_content-18.svg";
import { ReactComponent as editIcon } from "@epam/assets/icons/common/content-edit-18.svg";
import { Button, IconButton, Spinner, Text, Tooltip } from "@epam/loveship";
import { IModal } from "@epam/uui";
import { Tooltip as TooltipType } from "@epam/uui-components/src/overlays/Tooltip";

import { DocumentFooter } from "Components/documents/document-footer";
import { DocumentHeader } from "Components/documents/document-header";
import {
  getDocumentButtons,
  getInfoPanelFields,
  getInfoPanelMergedFields,
  getRoleButtons,
  infoPanelConfigLibraryKeys,
} from "Components/documents/document-info/document-info.helpers";
import { useDocumentInfo } from "Components/documents/document-info/document-info.hooks";
import { IDocumentInfoProps } from "Components/documents/document-info/document-info.types";
import { DocumentText } from "Components/documents/document-text";
import { FieldsConfigurationModal } from "Components/fields-configuration-modal";
import { IField } from "Components/fields-configuration-modal/fields-configuration-modal";
import { RoleCategory } from "Components/role-category/role-category";
import { ShowCurtain } from "Components/show-curtain";
import { withNaming } from "Helpers/bemClassname";
import { CopyFileName } from "Helpers/constants";
import { getSubscribeParams, updateObject } from "Helpers/utils";
import { useCopyText } from "Hooks/useCopyText";
import { useRecentItemsMethods } from "Hooks/useRecentItems";
import { useUsersData } from "Hooks/useUsers";
import { IInfoPanelProp, ILibraryPropNames } from "SP/infoPanelConfiguration/infoPanelConfiguration.types";
import { LibraryName } from "SP/sitePages/sitePages.types";

import "./document-info.scss";

export const DocumentInfo: FC<IDocumentInfoProps> = ({ attachedFile }) => {
  const cn = withNaming("document-info");
  const tooltipRef = useRef<TooltipType>(null);

  const { isCopied, handleCopyUrl } = useCopyText({
    ref: tooltipRef,
    content: attachedFile?.fileInfo?.Name,
    isFileName: true,
  });
  const { currentUser, checkIsSubscribed } = useUsersData();
  const { addRecentItemDocument } = useRecentItemsMethods();
  const { loading, infoPanelConfig, getInfoPanelConfig, updateInfoPanelConfig } = useDocumentInfo();

  const isRoles = attachedFile?.sourceLibrary === LibraryName.roles;

  const { infoPanelConfigLibraryKey, infoPanelConfigurationFields, infoPanelRenderFields } = useMemo(() => {
    const infoPanelFields = getInfoPanelFields(attachedFile);
    const infoPanelConfigLibraryKey = infoPanelConfigLibraryKeys[attachedFile.sourceLibrary];
    const infoPanelLibraryConfig = infoPanelConfig ? infoPanelConfig[infoPanelConfigLibraryKey] : [];

    const { infoPanelConfigurationFields, infoPanelRenderFields } = getInfoPanelMergedFields(
      infoPanelFields,
      infoPanelLibraryConfig,
    );

    return {
      infoPanelConfigLibraryKey,
      infoPanelConfigurationFields,
      infoPanelRenderFields,
    };
  }, [attachedFile, infoPanelConfig]);

  const subscribeParams = getSubscribeParams({
    item: isRoles ? "role" : "document",
    document: attachedFile,
    checkIsSubscribed,
  });

  const handleUpdateInfoPanelConfig = (orderedFields: IField[]) => {
    const newInfoPanelLibraryConfig = orderedFields.map(
      ({ prop, order, visible }): IInfoPanelProp => ({
        prop: prop as ILibraryPropNames,
        order,
        visible,
      }),
    );

    const newInfoPanelConfig = updateObject(infoPanelConfig, {
      [infoPanelConfigLibraryKey]: newInfoPanelLibraryConfig,
    });

    updateInfoPanelConfig(newInfoPanelConfig);
  };

  const handleShowConfigModal = () => {
    svc.uuiModals.show((modalProps: IModal<string>) => (
      <FieldsConfigurationModal
        modalProps={modalProps}
        title="Configure columns"
        fields={infoPanelConfigurationFields}
        onSave={handleUpdateInfoPanelConfig}
      />
    ));
  };

  useEffect(() => {
    if (!infoPanelConfig) {
      getInfoPanelConfig();
    }
  }, []);

  return (
    <div className={cn()}>
      <DocumentHeader attachedFile={attachedFile} isSubscribed={subscribeParams.isSubscribed} />
      <Text cx={cn("title")} size="30" lineHeight="24" fontSize="16" color="night900">
        {attachedFile?.Title}
      </Text>
      <div className={cn("subtitle-container", ["flex justify-start items-center"])}>
        {isRoles ? (
          <RoleCategory text={attachedFile?.RoleCategory} />
        ) : (
          [
            <Tooltip
              key={attachedFile?.UniqueId}
              cx={"single-line-tooltip"}
              content={attachedFile?.fileInfo.Name}
              placement="bottom"
            >
              <DocumentText isCut caption={attachedFile?.fileInfo.Name} />
            </Tooltip>,
            <Tooltip
              key={attachedFile?.Id}
              cx={"single-line-tooltip"}
              ref={tooltipRef}
              content={isCopied ? CopyFileName : "Copy file name"}
              placement={isCopied ? "bottom-end" : "bottom-start"}
            >
              <IconButton cx={cn("copy-icon")} color="sky" icon={copyContent} onClick={handleCopyUrl} />
            </Tooltip>,
          ]
        )}
      </div>
      <div className={cn("fields", ["relative flex flex-col"])}>
        {loading && <Spinner cx={cn("spinner", ["absolute"])} />}
        {infoPanelRenderFields.map(({ label, caption, href, multipleLinks }) => (
          <div key={label} className={cn("field")}>
            <DocumentText caption={label} />
            <ShowCurtain maxHeight={180}>
              {multipleLinks ? (
                multipleLinks
                  .filter(Boolean)
                  .map(({ Description, Url }) => (
                    <DocumentText key={Description} caption={Description} isItem href={Url} />
                  ))
              ) : (
                <DocumentText caption={caption} isItem href={href} />
              )}
            </ShowCurtain>
          </div>
        ))}
        {!loading && currentUser?.canEditSitePage && (
          <Button
            cx={cn("edit-button", ["self-start"])}
            caption="Edit Info"
            fill="none"
            icon={editIcon}
            onClick={handleShowConfigModal}
          />
        )}
      </div>
      <DocumentFooter
        buttons={
          isRoles
            ? getRoleButtons(attachedFile)
            : getDocumentButtons(attachedFile, addRecentItemDocument, currentUser?.jobTitle)
        }
        attachedFile={attachedFile}
        isRoles={isRoles}
        subscribeParams={subscribeParams}
      />
    </div>
  );
};
