import * as powerbiClient from "powerbi-client";
import * as models from "powerbi-models";
import React, { useEffect, useRef, useState } from "react";

import { Spinner } from "@epam/loveship";

import { EmbeddedReport, PowerBIService } from "./power-bi-service";

const powerbi = new powerbiClient.service.Service(
  powerbiClient.factories.hpmFactory,
  powerbiClient.factories.wpmpFactory,
  powerbiClient.factories.routerFactory,
);

interface PowerBIReportProps {
  reportId?: string;
  width?: string;
  height?: string;
}

export const PowerBIReport = ({ reportId, width = "640px", height = "480px" }: PowerBIReportProps) => {
  const ref = useRef(null);
  const [embeddedReport, setEmbeddedReport] = useState<EmbeddedReport | undefined>();

  useEffect(() => {
    let timeout;

    async function loadData() {
      const report = await PowerBIService.getEmbeddedReport(reportId);

      setEmbeddedReport(report);
    }

    loadData();

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (ref.current && embeddedReport) {
      const embedContainer = ref.current as HTMLElement;

      const config: models.IReportEmbedConfiguration = {
        type: "report",
        tokenType: models.TokenType.Aad,
        accessToken: embeddedReport.accessToken,
        embedUrl: embeddedReport.embedUrl,
        id: embeddedReport.id,
        permissions: models.Permissions.Read,
        viewMode: models.ViewMode.View,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: true,
            },
          },
        },
        eventHooks: {
          accessTokenProvider: PowerBIService.getAadToken,
        },
      };

      try {
        powerbi.embed(embedContainer, config) as powerbiClient.Report;
      } catch (_) {}
    }
  }, [ref, embeddedReport]);

  return (
    <div style={{ width, height, backgroundColor: "#F5F6FA" }} ref={ref} data-testid="report-container">
      <Spinner />
    </div>
  );
};
