import htmlParser from "html-react-parser";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";

import { Spinner } from "@epam/loveship";
import { Editor } from "@tinymce/tinymce-react";

import { PowerBIReport } from "Components/power-bi-report/power-bi-report";
import { ShowCurtain } from "Components/show-curtain";
import { analyticsHelper } from "Helpers/analyticsHelper";
import { withNaming } from "Helpers/bemClassname";
import { FriendlyPagePrefix } from "Helpers/constants";
import { IPathKeys } from "Helpers/sitePageUpdater";
import { handleClick, isInternalUrl, isPageUrl, replaceAllPageUrlPrefixes } from "Helpers/utils";
import { useUsersData } from "Hooks/useUsers";
import { IParentRichTextEditorWebPart, IRichTextEditorWebPart, IWebPart } from "SP/sitePages/sitePages.types";

import { useEditor } from "./rich-text-editor.hooks";
import { tinyFontFormats, tinyFontSizeFormats, tinyPlugins, tinyToolbar } from "./tinymce/tinymce.configs";
import { tinymceStyle } from "./tinymce/tinymce.style";

import "./rich-text-editor.scss";

interface IRichTextEditorProps {
  cx: string;
  isEditMode: boolean;
  data: IRichTextEditorWebPart | IParentRichTextEditorWebPart;
  webPart: IWebPart;
  pathKeys: IPathKeys;
}

enum LinkClasses {
  external = "ext_link",
  restricted = "res_link",
  list = "list_link",
  parent = "parent_link",
  special = "special_link",
}

const dataKey = "markup";

const getSizeWithPostfix = (value: string) => (value.includes("%") ? value : `${value}px`);

const handlePowerBiIframes = (parsedView) => {
  const iframes = parsedView?.querySelectorAll("iframe");
  if (!iframes) return;

  iframes?.forEach((iframe) => {
    const reportId = iframe.src.match(/reportId=[^&]*/)?.[0]?.replace("reportId=", "");
    if (!reportId) return;

    const newIframeRoot = document.createElement("span");
    iframe.insertAdjacentElement("beforebegin", newIframeRoot);
    iframe.remove();

    ReactDOM.render(
      <PowerBIReport
        reportId={reportId}
        width={getSizeWithPostfix(iframe.width)}
        height={getSizeWithPostfix(iframe.height)}
      />,
      newIframeRoot,
    );
  });
};

const encodedParentPagesURI = encodeURI("/Policy/Parent Pages/");

const isParentFriendlyUrl = (pathname: string) =>
  pathname.startsWith(FriendlyPagePrefix) && pathname.split("/").length == 3;

const isAddParentClass = (link) =>
  (link.href.includes(encodedParentPagesURI) || isParentFriendlyUrl(link.pathname)) &&
  !(
    link.classList.contains(LinkClasses.list) ||
    link.classList.contains(LinkClasses.restricted) ||
    link.classList.contains(LinkClasses.special)
  );

export const RichTextEditor: FC<IRichTextEditorProps> = ({ cx, isEditMode, data, pathKeys, webPart }) => {
  const cn = withNaming("rich-text-editor");
  const { push } = useHistory();
  const editorRef = useRef(null);
  const parsedViewRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [value, onValueChange] = useEditor({
    data,
    dataKey,
    pathKeys,
    webPart,
  });

  const { currentUser } = useUsersData();

  useEffect(() => {
    if (parsedViewRef.current) {
      function handleInternalClick(href: string) {
        return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          analyticsHelper.analyticsService?.provideLinkClick(href, currentUser?.jobTitle);
          handleClick(href, event, push);
        };
      }

      function handleExternalClick(href: string) {
        return () => {
          analyticsHelper.analyticsService?.provideLinkClick(href, currentUser?.jobTitle);
        };
      }

      function handleLinkElement(link) {
        link.onclick = handleExternalClick(link.href);
        if (isInternalUrl(link.href)) {
          if (isAddParentClass(link)) link.classList.add(LinkClasses.parent);
          if (link.target !== "_blank") {
            if (isPageUrl(link.href)) {
              link.onclick = handleInternalClick(link.href);
            } else {
              link.setAttribute("target", "_blank");
            }
          }
        } else {
          if (!link.classList.contains(LinkClasses.special)) {
            link.classList.add(LinkClasses.external);
          }
        }
      }

      const links = parsedViewRef.current.querySelectorAll("a:not([href^='mailto:'])");
      links.forEach(handleLinkElement);
    }
  }, [pathKeys[0]]);

  useEffect(() => {
    !isEditMode && handlePowerBiIframes(parsedViewRef.current);
  }, [isEditMode]);

  const htmlContent = data[dataKey] && htmlParser(replaceAllPageUrlPrefixes(data[dataKey]));

  return (
    <div className={cn("", [cx])}>
      {loader && isEditMode && <Spinner />}
      {!isEditMode && (
        <div
          className={cn("parsed-view", { displayDividers: "displayDividers" in data && data.displayDividers })}
          ref={parsedViewRef}
        >
          {"showCurtain" in data && data.showCurtain ? (
            <ShowCurtain maxHeight={180}>{htmlContent}</ShowCurtain>
          ) : (
            htmlContent
          )}
        </div>
      )}
      {isEditMode && (
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          value={value}
          onEditorChange={onValueChange}
          init={{
            link_class_list: [
              { title: "None", value: "" },
              { title: "Restricted Link", value: LinkClasses.restricted },
              { title: "List Link", value: LinkClasses.list },
              { title: "No Icon", value: LinkClasses.special },
            ],
            content_style: tinymceStyle,
            setup(editor) {
              editor.on("init", () => {
                setLoader(false);
              });
            },
            height: 500,
            menubar: true,
            font_formats: tinyFontFormats,
            plugins: tinyPlugins,
            fontsize_formats: tinyFontSizeFormats,
            toolbar: tinyToolbar,
            browser_spellcheck: true,
            relative_urls: false,
            remove_script_host: false,
          }}
        />
      )}
    </div>
  );
};
