import React, { useMemo } from "react";

import { libraryNameToFields } from "Helpers/utils";
import { IFiltersGroup } from "Store/reducers/filters.reducer";

import { cn } from "./documents-filter";
import { useFiltersData } from "./documents-filter.hooks";
import { DocumentsFilterTooltip } from "./documents-filter-tooltip";

export interface IFilterGroupParametersProps {
  group: IFiltersGroup;
}

export const DocumentsFilterGroupParameters: React.FC<IFilterGroupParametersProps> = ({ group }) => {
  const { filters } = group;

  const { infoPanelFilters, filtersLibrary } = useFiltersData();

  const fields = useMemo(() => {
    return libraryNameToFields(filtersLibrary);
  }, [filtersLibrary]);

  const parametersNames = useMemo(() => {
    const result = [];
    if (!filters) return result;

    if (filters.showSubscriptionsOnly) {
      result.push("Subscriptions only");
    }

    for (const key of Object.keys(filters)) {
      if (filters[key] && fields[key]) {
        result.push(fields[key]);
      }
    }

    return result;
  }, [filters]);

  if (parametersNames.length === 0) {
    return <div className={cn("group-parameters")}>No parameters selected</div>;
  }

  return (
    <>
      <DocumentsFilterTooltip
        selectedFilters={group.filters}
        title={group.name}
        enabled={true}
        infoPanelFilters={infoPanelFilters}
      >
        <div className={cn("group-parameters", { disable: !group.enabled })}>{parametersNames.join(", ")}</div>
      </DocumentsFilterTooltip>
    </>
  );
};
