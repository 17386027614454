import { useState } from "react";

import { handleCheckboxSelection } from "Helpers/utils";
import { IUnique } from "SP/helpers.types";

const isAllRowsSelected = <T extends IUnique>(rows: T[], selectedRows: string[]) => {
  return rows.length === selectedRows.length;
};

export const useRowsCheck = <T extends IUnique>(rows: T[]) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const onCheckToggle = (id: string) => {
    return handleCheckboxSelection<string>({ item: id, items: selectedRows, setter: setSelectedRows });
  };

  const onToggleAll = () => {
    const value = isAllRowsSelected(rows, selectedRows) ? [] : rows.map((item) => item.UniqueId);
    setSelectedRows(value);
  };

  const clearAll = () => {
    setSelectedRows([]);
  };

  return {
    resetRows: () => setSelectedRows([]),
    selectedRows,
    onCheckToggle,
    onToggleAll,
    indeterminate: !selectedRows.length ? undefined : !isAllRowsSelected(rows, selectedRows),
    clearAll,
  };
};
